import React, { useState, useEffect } from "react";
import { useSwitchNetwork, useNetwork } from "wagmi";
import fsn from "./assets/fsn.svg";
import fsnActive from "./assets/fsn.svg";
import esc from "./assets/esc.svg";
import escActive from "./assets/escActive.svg";
import arthera from "./assets/arthera.svg";
import artheraActive from "./assets/arthera.svg";

const ChainTabs = ({ onChainSelect }) => {
  const { switchNetwork } = useSwitchNetwork();
  const { chain } = useNetwork();
  const [selectedChain, setSelectedChain] = useState("esc");

  const getChainName = (chainId) => {
    switch (chainId) {
      case 32659:
        return "fsn";
      case 20:
        return "esc";
      case 43114:
        return "arthera";
      default:
        return "unsupported";
    }
  };

  useEffect(() => {
    if (chain) {
      setSelectedChain(getChainName(chain.id));
    }
  }, [chain]);

  const handleSelectChain = (chain, chainId) => {
    setSelectedChain(chain);
    switchNetwork(chainId);
    onChainSelect(chain);
  };

  return (
    <div className="col-12 col-lg-8 col-xl-6 flex flex-wrap gap-3 justify-center mt-4 md:justify-around lg:justify-end xl:justify-center px-0 xl:px-2 w-full">
      <div
        className={`stake-item position-relative flex-column flex-lg-row d-flex align-items-center gap-2 ${selectedChain === "fsn" ? "eth-item-active" : ""}`}
        onClick={() => handleSelectChain("fsn", 32659)} // FSN Chain ID
      >
        <img
          src={selectedChain === "fsn" ? fsnActive : fsn}
          alt="FSN"
        />
        <div className="d-flex flex-column align-items-center align-items-lg-start">
          <p className="text-white" style={{ fontSize: "12px", fontWeight: 300 }}>FSN</p>
          <p style={{ fontSize: "12px", fontWeight: 500, color: "#f7f7fc", whiteSpace: "pre" }}>25% APR</p>
        </div>
      </div>
      <div
        className={`stake-item position-relative flex-column flex-lg-row d-flex align-items-center gap-2 ${selectedChain === "esc" ? "bsc-item-active" : ""}`}
        onClick={() => handleSelectChain("esc", 20)} // ESC Chain ID
      >
        <img
          src={selectedChain === "esc" ? escActive : esc}
          alt="ESC"
        />
        <div className="d-flex flex-column align-items-center align-items-lg-start">
          <p className="text-white" style={{ fontSize: "12px", fontWeight: 300 }}>ESC</p>
          <p style={{ fontSize: "12px", fontWeight: 500, color: "#f7f7fc", whiteSpace: "pre" }}>75% APR</p>
        </div>
      </div>
      <div
        className={`stake-item position-relative flex-column flex-lg-row d-flex align-items-center gap-2 ${selectedChain === "arthera" ? "arthera-item-active" : "blurrypool"}`}
        onClick={() => handleSelectChain("arthera", 43114)} // Arthera Chain ID
      >
        <img
          src={selectedChain === "arthera" ? artheraActive : arthera}
          alt="Arthera"
        />
        <div className="d-flex flex-column align-items-center align-items-lg-start">
          <p className="text-white" style={{ fontSize: "12px", fontWeight: 300 }}>Arthera</p>
          <p style={{ fontSize: "12px", fontWeight: 500, color: "#f7f7fc", whiteSpace: "pre" }}>12.5% APR</p>
        </div>
      </div>
    </div>
  );
};

export default ChainTabs;
