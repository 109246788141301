import React, { useEffect, useState } from "react";
import TopPoolsCard from "../../top-pools-card/TopPoolsCard";
import TopPoolsListCard from "../../top-pools-card/TopPoolsListCard";
import axios from "axios";
import getFormattedNumber from "../../../functions/getFormattedNumber2";
import { FadeLoader } from "react-spinners";
import useWindowSize from "../../../functions/useWindowSize";
import StakeDypiusEth from "../../FARMINNG/constant-staking-dypius-new";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import closeX from "../../earnOther/assets/closeX.svg";
import { ClickAwayListener } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import moreinfo from "../../FARMINNG/assets/more-info.svg";
import eth from "../assets/eth.svg";
import ethActive from "../assets/ethActive.svg";
const EarnTopPicks = ({
  topList,
  listType,
  coinbase,
  the_graph_result,
  lp_id,
  isConnected,
  chain,
  chainId,
  handleConnection,
  referrer,
  pool,
  routeOption,
  customChain,
  handleSwitchNetwork,
  expiredPools,
  networkId,
  isPremium,
  onConnectWallet,
  onChainSelect,
}) => {
  const vault = [
    {
      icon: "weth.svg",
      pair_name: "WETH",
      apy_percent: "3 - 13",
      tvl_usd: ``,
      lock_time: "No lock",
      top_pick: true,
      new_badge: false,
      link: "https://vault.dyp.finance/vault-weth",
    },
    {
      icon: "wbtc.svg",
      pair_name: "WBTC",
      apy_percent: "3 - 13",
      tvl_usd: ``,
      lock_time: "No lock",
      link: "https://vault.dyp.finance/vault-wbtc",
    },
    {
      icon: "usdc.svg",
      pair_name: "USDC",
      apy_percent: "8 - 22",
      tvl_usd: ``,
      lock_time: "No lock",
      new_badge: false,
      top_pick: false,
      link: "https://vault.dyp.finance/vault-usdc",
    },
    {
      icon: "usdt.svg",
      pair_name: "USDT",
      apy_percent: "9 - 23",
      tvl_usd: ``,
      lock_time: "No lock",
      new_badge: false,
      top_pick: false,
      link: "https://vault.dyp.finance/vault-usdt",
    },
    {
      icon: "dai.svg",
      pair_name: "DAI",
      apy_percent: "8 - 21",
      tvl_usd: ``,
      lock_time: "No lock",
      new_badge: false,
      top_pick: false,
      link: "https://vault.dyp.finance/vault-dai",
    },
  ];
  const vaultNew = [
    {
      icon: "weth.svg",
      pair_name: "WETH",
      apy_percent: "1.9 - 3.7",
      tvl_usd: ``,
      lock_time: "No lock",
      top_pick: true,
      new_badge: false,
    },
    {
      icon: "wbtc.svg",
      pair_name: "WBTC",
      apy_percent: "1.6 - 3.4",
      tvl_usd: ``,
      lock_time: "No lock",
    },
    {
      icon: "usdc.svg",
      pair_name: "USDC",
      apy_percent: "2.1 - 4.2",
      tvl_usd: ``,
      lock_time: "No lock",
      new_badge: false,
      top_pick: false,
    },
    {
      icon: "usdt.svg",
      pair_name: "USDT",
      apy_percent: "2.2 - 4.9",
      tvl_usd: ``,
      lock_time: "No lock",
      new_badge: false,
      top_pick: false,
    },
    {
      icon: "dai.svg",
      pair_name: "DAI",
      apy_percent: "2.3 - 5.3",
      tvl_usd: ``,
      lock_time: "No lock",
      new_badge: false,
      top_pick: false,
    },
  ];
  const [farmingItem, setFarming] = useState([]);
  const [showDetails, setShowDetails] = useState(false);
  const [topPools, setTopPools] = useState([]);
  const [activePools, setActivePools] = useState([]);
  const [ethPools, setEthPools] = useState([]);
  const [bnbPools, setBnbPools] = useState([]);
  const [avaxPools, setAvaxPools] = useState([]);
  const [expiredDYPPools, setExpiredPools] = useState([]);
  const [listing, setListing] = useState(listType);
  const [cawsCard, setCawsCard] = useState([]);
  const [cawsCard2, setCawsCard2] = useState([]);
  const [landCard, setLandCard] = useState({});
  const [userPools, setuserPools] = useState([]);
  const [cawsLandCard, setCawsLandCard] = useState([]);
  const [customIndex, setCustomIndex] = useState(3);
  const [theBnbPool, setTheBnbPool] = useState({});
  const [tvlTotal, setTvlTotal] = useState();
  const [wbnbPrice, setWbnbPrice] = useState();
  const [selectedTab, setselectedTab] = useState("deposit");
  const [selectedBtn, setselectedBtn] = useState("flexible");
  const [selectedPool, setselectedPool] = useState([]);
  const [aprTooltip, setaprTooltip] = useState(false);
  const [selectedchain, setselectedchain] = useState(chain);
  const windowSize = useWindowSize();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width:
      windowSize.width > 1400 ? "auto" : windowSize.width > 786 ? "50%" : "95%",
    boxShadow: 24,
    p: 4,
    overflow: "auto",
    minHeight: 200,
    overflowX: "hidden",
    borderRadius: "10px",
    height: windowSize.width < 500 ? "480px" : "auto",
    background: `#1A1A36`,
  };
  const fetchUserPools = async () => {
    if (coinbase && coinbase.includes("0x")) {
      const result = await axios
        .get(`https://api.dyp.finance/api/user_pools/${coinbase}`)
        .then((data) => {
          return data.data.PoolsUserIn;
        });
      setuserPools(result);
    }
  };
  const aprOpen = () => {
    setaprTooltip(true);
  };
  const aprClose = () => {
    setaprTooltip(false);
  };
  const fetchEthStaking = async () => {
    const eth_result = await axios
      .get(`https://api.dyp.finance/api/get_staking_info_eth`)
      .catch((err) => {
        console.log(err);
      });
    const eth_result2 = await axios
      .get(`https://api.dyp.finance/api/get_staking_info_eth_new`)
      .catch((err) => {
        console.log(err);
      });
    if (
      eth_result &&
      eth_result.status === 200 &&
      eth_result2 &&
      eth_result2.status === 200
    ) {
      const dypIdyp = eth_result.data.stakingInfoiDYPEth;
      const dypData = eth_result2.data.stakingInfoDYPEth;
      const object2 = dypData.map((item) => {
        return { ...item, tvl_usd: item.tvl_usd, type: "dyp", chain: "eth" };
      });
      const activeEth = dypIdyp.filter((item) => {
        return item.expired !== "Yes";
      });
      const object2activeEth = activeEth.map((item) => {
        return { ...item, tvl_usd: item.tvl_usd, type: "idyp", chain: "eth" };
      });
      const activeEth2 = object2.filter((item) => {
        return item.expired !== "Yes";
      });
      const allActiveEth = [...object2activeEth, ...activeEth2];
      const sortedActive = allActiveEth.sort(function (a, b) {
        return b.tvl_usd - a.tvl_usd;
      });
      setEthPools(sortedActive);
    }
  };
  const fetchAvaxStaking = async () => {
    const avax_result = await axios
      .get(`https://api.dyp.finance/api/get_staking_info_avax`)
      .catch((err) => {
        console.log(err);
      });
    const avax_result2 = await axios
      .get(`https://api.dyp.finance/api/get_staking_info_avax_new`)
      .catch((err) => {
        console.log(err);
      });
    if (
      avax_result &&
      avax_result.status === 200 &&
      avax_result2 &&
      avax_result2.status === 200
    ) {
      const dypIdypAvax = avax_result.data.stakingInfoiDYPAvax;
      const dypAvax = avax_result2.data.stakingInfoDYPAvax;
      const object2 = dypAvax.map((item) => {
        return { ...item, tvl_usd: item.tvl_usd, type: "dyp", chain: "avax" };
      });
      const activeAvax = dypIdypAvax.filter((item) => {
        return item.expired !== "Yes";
      });
      const object2activeAvax = activeAvax.map((item) => {
        return { ...item, tvl_usd: item.tvl_usd, type: "idyp", chain: "avax" };
      });
      const activeAvax2 = object2.filter((item) => {
        return item.expired !== "Yes";
      });
      const allActiveAvax = [...object2activeAvax, ...activeAvax2];
      const sortedActive = allActiveAvax.sort(function (a, b) {
        return b.tvl_usd - a.tvl_usd;
      });
      setAvaxPools(sortedActive);
    }
  };
  const fetchEthStaking2 = async () => {
    const eth_result = await axios
      .get(`https://api.dyp.finance/api/get_staking_info_eth`)
      .catch((err) => {
        console.log(err);
      });
    const eth_result2 = await axios
      .get(`https://api.dyp.finance/api/get_staking_info_eth_new`)
      .catch((err) => {
        console.log(err);
      });
    if (
      eth_result &&
      eth_result.status === 200 &&
      eth_result2 &&
      eth_result2.status === 200
    ) {
      const dypIdyp = eth_result.data.stakingInfoiDYPEth;
      const dypData = eth_result2.data.stakingInfoDYPEth;
      const object2 = dypData.map((item) => {
        return { ...item, tvl_usd: item.tvl_usd, type: "dyp", chain: "eth" };
      });
      const activeEth = dypIdyp.filter((item) => {
        return item.expired !== "Yes";
      });
      const object2activeEth = activeEth.map((item) => {
        return { ...item, tvl_usd: item.tvl_usd, type: "idyp", chain: "eth" };
      });
      const activeEth2 = object2.filter((item) => {
        return item.expired !== "Yes";
      });
      const allActiveEth = [...object2activeEth, ...activeEth2];
      const sortedActive = allActiveEth.sort(function (a, b) {
        return b.tvl_usd - a.tvl_usd;
      });
      setActivePools(sortedActive);
      setTopPools([...object2activeEth, ...activeEth2]);
      setCawsCard(eth_result.data.stakingInfoCAWS);
      setCawsCard2(eth_result.data.stakingInfoCAWS[0]);
      setLandCard(eth_result.data.stakingInfoLAND[0]);
      const land = eth_result.data.stakinginfoCAWSLAND[0];
      setCawsLandCard(land);
    }
  };
  const fetchBnbStaking2 = async () => {
    const bnb_result = await axios
      .get(`https://api.dyp.finance/api/get_staking_info_bnb`)
      .catch((err) => {
        console.log(err);
      });
    const bnb_result2 = await axios
      .get(`https://api.dyp.finance/api/get_staking_info_bnb_new`)
      .catch((err) => {
        console.log(err);
      });
    if (
      bnb_result &&
      bnb_result.status === 200 &&
      bnb_result2 &&
      bnb_result2.status === 200
    ) {
      const dypIdypBnb = bnb_result.data.stakingInfoiDYPBnb;
      const dypBnb = bnb_result2.data.stakingInfoDYPBnb;
      const object2 = dypBnb.map((item) => {
        return { ...item, tvl_usd: item.tvl_usd, type: "dyp", chain: "bnb" };
      });
      const expiredBnb = dypIdypBnb.filter((item) => {
        return item.expired !== "No";
      });
      const activeBnb = dypIdypBnb.filter((item) => {
        return item.expired !== "Yes";
      });
      const object2activeBnb = activeBnb.map((item) => {
        return { ...item, tvl_usd: item.tvl_usd, type: "idyp", chain: "bnb" };
      });
      const activeBnb2 = object2.filter((item) => {
        return item.expired === "No";
      });
      const expiredBnb2 = object2.filter((item) => {
        return item.expired === "Yes";
      });
      const allActiveBnb = [...object2activeBnb, ...activeBnb2];
      const allExpireBnb = [...expiredBnb, ...expiredBnb2];
      const sortedActive = allActiveBnb.sort(function (a, b) {
        return b.tvl_usd - a.tvl_usd;
      });
      const sortedExpired = allExpireBnb.sort(function (a, b) {
        return b.tvl_usd - a.tvl_usd;
      });
      setActivePools(sortedActive);
      setExpiredPools(sortedExpired);
      setTopPools([...dypIdypBnb, ...object2]);
    }
  };
  const fetchAvaxStaking2 = async () => {
    const avax_result = await axios
      .get(`https://api.dyp.finance/api/get_staking_info_avax`)
      .catch((err) => {
        console.log(err);
      });
    const avax_result2 = await axios
      .get(`https://api.dyp.finance/api/get_staking_info_avax_new`)
      .catch((err) => {
        console.log(err);
      });
    if (
      avax_result &&
      avax_result.status === 200 &&
      avax_result2 &&
      avax_result2.status === 200
    ) {
      const dypIdypAvax = avax_result.data.stakingInfoiDYPAvax;
      const dypAvax = avax_result2.data.stakingInfoDYPAvax;
      const object2 = dypAvax.map((item) => {
        return { ...item, tvl_usd: item.tvl_usd, type: "dyp", chain: "avax" };
      });
      const activeAvax = dypIdypAvax.filter((item) => {
        return item.expired !== "Yes";
      });
      const object2activeAvax = activeAvax.map((item) => {
        return { ...item, tvl_usd: item.tvl_usd, type: "idyp", chain: "avax" };
      });
      const activeAvax2 = object2.filter((item) => {
        return item.expired !== "Yes";
      });
      const allActiveAvax = [...object2activeAvax, ...activeAvax2];
      const sortedActive = allActiveAvax.sort(function (a, b) {
        return b.tvl_usd - a.tvl_usd;
      });
      setActivePools(sortedActive);
      setTopPools([...dypIdypAvax, ...object2]);
    }
  };
  const fetchEthFarming = async () => {
    await axios
      .get("https://api.dyp.finance/api/the_graph_eth_v2")
      .then((res) => {
        let temparray = Object.entries(res.data.the_graph_eth_v2.lp_data);
        let farming2 = [];
        temparray.map((item) => {
          farming2.push(item[1]);
        });
        const expiredFarmingEth = farming2.filter((item) => {
          return item.expired !== "No";
        });
        const sortedExpired = expiredFarmingEth.sort(function (a, b) {
          return b.tvl_usd - a.tvl_usd;
        });
        setTopPools(sortedExpired);
        setExpiredPools(sortedExpired);
      })
      .catch((err) => console.error(err));
  };
  const fetchBscFarming = async () => {
    await axios
      .get("https://api.dyp.finance/api/the_graph_bsc_v2")
      .then((res) => {
        let temparray = Object.entries(res.data.the_graph_bsc_v2.lp_data);
        let bnbpool = temparray.filter((item) => {
          return (
            item.id ===
            "0x1bc61d08a300892e784ed37b2d0e63c85d1d57fb-0x5bc3a80a1f2c4fb693d9dddcebbb5a1b5bb15d65"
          );
        });
        setTheBnbPool(bnbpool);
        let farming2 = [];
        temparray.map((item) => {
          farming2.push(item[1]);
        });
        const expiredFarmingBsc = farming2.filter((item) => {
          return item.expired !== "No";
        });
        const sortedExpired = expiredFarmingBsc.sort(function (a, b) {
          return b.tvl_usd - a.tvl_usd;
        });
        setTopPools(sortedExpired);
        setExpiredPools(sortedExpired);
      })
      .catch((err) => console.error(err));
  };
  const fetchBnbPool = async () => {
    await axios
      .get("https://api.dyp.finance/api/the_graph_bsc_v2")
      .then((res) => {
        let temparray = Object.entries(res.data.the_graph_bsc_v2.lp_data);
        let bnbpool = temparray.find((item) => {
          return (
            item[0] ===
            "0x1bc61d08a300892e784ed37b2d0e63c85d1d57fb-0x5bc3a80a1f2c4fb693d9dddcebbb5a1b5bb15d65"
          );
        });
        setWbnbPrice(res.data.the_graph_bsc_v2.usd_per_eth);
        setTheBnbPool(bnbpool[1]);
      })
      .catch((err) => console.error(err));
  };
  const fetchAvaxFarming = async () => {
    await axios
      .get("https://api.dyp.finance/api/the_graph_avax_v2")
      .then((res) => {
        let temparray = Object.entries(res.data.the_graph_avax_v2.lp_data);
        let farming2 = [];
        temparray.map((item) => {
          farming2.push(item[1]);
        });
        const expiredFarmingAvax = farming2.filter((item) => {
          return item.expired !== "No";
        });
        const sortedExpired = expiredFarmingAvax.sort(function (a, b) {
          return b.tvl_usd - a.tvl_usd;
        });
        setTopPools(sortedExpired);
        setExpiredPools(sortedExpired);
      })
      .catch((err) => console.error(err));
  };
  const [customPool, setCustomPool] = useState(pool);
  const [activeCard, setActiveCard] = useState();
  const [activeCardNFT, setActiveCardNFT] = useState();
  const [activeCardLandNFT, setActiveCardLandNFT] = useState();
  const [activeCardCawsLand, setActiveCardCawsLand] = useState();
  const [activeCard2, setActiveCard2] = useState();
  const [activeCard3, setActiveCard3] = useState();
  const [activeCard4, setActiveCard4] = useState();
  const [activeCard5, setActiveCard5] = useState();
  const [activeCard6, setActiveCard6] = useState();
  const [activeCard7, setActiveCard7] = useState();
  const [activeCard8, setActiveCard8] = useState();
  const [activeCard9, setActiveCard9] = useState();
  const [activeCard10, setActiveCard10] = useState();
  const [activeCard11, setActiveCard11] = useState();
  const [activeCard12, setActiveCard12] = useState();
  const [cardIndex, setcardIndex] = useState(0);
  const [cardIndexiDyp, setcardIndexiDyp] = useState(0);
  const [cardIndexavax30, setcardIndexavax30] = useState(0);
  const [cardIndexavaxiDyp, setcardIndexavaxiDyp] = useState(0);
  const [details, setDetails] = useState(0);
  const eth_address = "ETH";
  const { rebase_factors, rebase_factorsavax, rebase_factorsbsc } = window;
  const { LP_IDs_V2BNB } = window;
  const locktimeFarm = ["No Lock", "3 Days", "30 Days", "60 Days", "90 Days"];
  const fetchStakingData = async () => {
    if (topList === "Staking") {
      if (chain !== "eth" && chain !== "bnb" && chain === "avax") {
        await fetchAvaxStaking2();
      } else if (
        chain === "eth" &&
        chain !== "bnb" &&
        chain !== "avax" &&
        topList === "Staking"
      ) {
        await fetchEthStaking2();
      } else if (chain !== "eth" && chain === "bnb" && chain !== "avax") {
        await fetchBnbStaking2();
      }
    }
  };
  useEffect(() => {
    setActiveCard();
    setActiveCard2();
    setActiveCard3();
    setActiveCard4();
    setActiveCard5();
    setActiveCard6();
    setActiveCard7();
    setActiveCard8();
    setActiveCard9();
    setActiveCard10();
    setActiveCard11();
    setActiveCard12();
    setcardIndex();
    setcardIndexiDyp();
    setcardIndexavax30();
    setcardIndexavaxiDyp();
    setDetails();
    setActiveCardNFT();
    setActiveCardLandNFT();
    setActiveCardCawsLand();
  }, [topList, chain]);
  useEffect(() => {
    if (topList === "Staking" && chain === "eth") {
      setCustomIndex(2);
    } else {
      setCustomIndex(3);
    }
    if (customPool !== null) {
      if (routeOption === "Staking" && chain === "eth") {
        setDetails(0);
        setActiveCard(topPools[0]);
        handleCardIndexStake(0);
      }

      if (
        routeOption === "Farming" &&
        chain === "eth" &&
        expiredPools === true
      ) {
        setDetails(4);
        setActiveCard2(topPools[4]);
        handleCardIndexStake(4);
      }
      if (
        routeOption === "Farming" &&
        chain === "bnb" &&
        expiredPools === true
      ) {
        setDetails(3);
        setActiveCard2(topPools[3]);
        handleCardIndexStake(3);
      }
      if (
        routeOption === "Farming" &&
        chain === "avax" &&
        expiredPools === true
      ) {
        setDetails(4);
        setActiveCard2(topPools[4]);
        handleCardIndexStake(4);
      }
    } else {
      setDetails();
      setActiveCard(null);
    }
    setCustomPool(null);
    if (networkId === "20" && topList === "Farming" && expiredPools === true) {
      fetchEthFarming();
    } else if (
      networkId === "56" &&
      topList === "Farming" &&
      expiredPools === true
    ) {
      fetchBscFarming();
    } else if (
      networkId === "43114" &&
      topList === "Farming" &&
      expiredPools === true
    ) {
      fetchAvaxFarming();
    }
    setListing(listType);
    fetchBnbPool();
  }, [
    topList,
    listType,
    networkId,
    expiredPools,
    customChain,
    routeOption,
    chain,
  ]);
  useEffect(() => {
    if (chainId === "20") {
      setselectedchain("eth");
    } else {
      setselectedchain("eth");
    }
  }, [chainId]);
  useEffect(() => {
    setShowDetails(false);
    setselectedPool([]);
  }, [topList]);
  useEffect(() => {
    if (topList === "Vault" && chainId === "20" && expiredPools === true) {
      setTopPools(vault);
      setExpiredPools(vault);
    } else if (
      topList === "Vault" &&
      chainId === "20" &&
      expiredPools === false
    ) {
      setTopPools(vaultNew);
      setActivePools(vaultNew);
    }
  }, [topList, chainId, chain, coinbase, expiredPools]);
  useEffect(() => {
    fetchUserPools();
    setActiveCard();
    fetchStakingData();
  }, [topList, chain, coinbase, networkId, chainId, expiredPools, listType]);
  useEffect(() => {
    console.log("Helloooo");
    setActiveCard(null);
    setDetails(null);
  }, [topList]);

  const handleCardIndexStake = (index) => {
    if (topList === "Staking") {
      if (index >= 3) {
        const newIndex = index - 3;
        setcardIndexiDyp(newIndex);
        setcardIndex(index);
      } else setcardIndex(index);
    } else setcardIndex(index);
  };

  useEffect(() => {
    fetchAvaxStaking();
    fetchEthStaking();
  }, []);
  return (
    <>
      <div className={`row w-100 justify-content-center gap-4`}>
        {listing === "table" && topPools.length > 0 ? (
          windowSize.width > 1300 ? (
            <div className="px-0">
              <>
                <div className="top-picks-container">
                  {activePools.slice(0, 3).map((pool, index) => (
                    <TopPoolsCard
                      key={index}
                      chain={chain}
                      top_pick={pool.top_pick}
                      tokenName={pool.pair_name}
                      apr={pool.apy_percent + "%"}
                      tvl={
                        pool.tvl_usd === "--"
                          ? pool.tvl_usd
                          : "$" + getFormattedNumber(pool.tvl_usd)
                      }
                      lockTime={
                        pool.lock_time ? pool.lock_time : locktimeFarm[index]
                      }
                      tokenLogo={
                        pool.icon
                          ? pool.icon
                          : pool.pair_name === "BUNNY"
                          ? "dyplogo.svg"
                          : "idypius.svg"
                      }
                      onShowDetailsClick={() => {
                        setActiveCard(topPools[index]);
                        setActiveCard2(null);
                        setActiveCard3(null);
                        setActiveCard4(null);
                        setActiveCardCawsLand(null);
                        setActiveCardNFT(false);
                        setActiveCardLandNFT(false);
                        handleCardIndexStake(index);
                        setDetails(index);
                        setselectedPool(topList !== "Vault" && pool);
                        setShowDetails(topList !== "Vault" && true);
                      }}
                      onHideDetailsClick={() => {
                        setActiveCard(null);
                        setDetails();
                      }}
                      cardType={topList}
                      details={details === index ? true : false}
                      isNewPool={pool.new_pool === "Yes" ? true : false}
                      isStaked={
                        userPools.length > 0
                          ? userPools.find(
                              (obj) => obj.contract_address === pool.id
                            )
                            ? true
                            : false
                          : false
                      }
                      expired={false}
                      network={chainId}
                      isPremium={isPremium}
                    />
                  ))}
                </div>
              </>
              <div
                className="top-picks-container"
                style={{ marginTop: "25px" }}
              >
                {activePools.slice(3, 6).map((pool, index) => (
                  <TopPoolsCard
                    display={
                      pool.expired ? (pool.expired === "Yes" ? "none" : "") : ""
                    }
                    key={index}
                    chain={chain}
                    top_pick={pool.top_pick}
                    tokenName={
                      pool.tokenName
                        ? pool.tokenName
                        : pool.pair_name
                        ? pool.pair_name
                        : ""
                    }
                    apr={
                      pool.apy_percent ? pool.apy_percent + "%" : pool.apy + "%"
                    }
                    tvl={
                      pool.tvl_usd === "--"
                        ? pool.tvl_usd
                        : "$" + getFormattedNumber(pool.tvl_usd)
                    }
                    lockTime={
                      pool.lock_time ? pool.lock_time : locktimeFarm[index + 3]
                    }
                    tokenLogo={
                      pool.icon
                        ? pool.icon
                        : pool.pair_name === "BUNNY"
                        ? "dyplogo.svg"
                        : "idypius.svg"
                    }
                    onShowDetailsClick={() => {
                      setActiveCard(null);
                      setActiveCard2(topPools[index + 3]);
                      setActiveCard3(null);
                      setActiveCardNFT(false);
                      setActiveCardLandNFT(false);
                      handleCardIndexStake(index + 3);
                      setDetails(index + 3);
                      setselectedPool(topList !== "Vault" && pool);
                      setShowDetails(topList !== "Vault" && true);
                    }}
                    onHideDetailsClick={() => {
                      setActiveCard2(null);
                      setDetails();
                    }}
                    cardType={topList}
                    details={details === index + 3 ? true : false}
                    isNewPool={pool.new_pool === "Yes" ? true : false}
                    isStaked={
                      userPools.length > 0
                        ? userPools.find(
                            (obj) => obj.contract_address === pool.id
                          )
                          ? true
                          : false
                        : false
                    }
                    expired={false}
                    network={chainId}
                    isPremium={isPremium}
                  />
                ))}
              </div>

              <div
                className="top-picks-container"
                style={{ marginTop: "25px" }}
              >
                {activePools.slice(6, 9).map((pool, index) => (
                  <TopPoolsCard
                    network={chainId}
                    display={
                      pool.expired ? (pool.expired === "Yes" ? "none" : "") : ""
                    }
                    key={index}
                    chain={chain}
                    top_pick={pool.top_pick}
                    tokenName={
                      pool.tokenName
                        ? pool.tokenName
                        : pool.pair_name
                        ? pool.pair_name
                        : ""
                    }
                    apr={pool.apy_percent + "%"}
                    tvl={
                      pool.tvl_usd === "--"
                        ? pool.tvl_usd
                        : "$" + getFormattedNumber(pool.tvl_usd)
                    }
                    lockTime={
                      pool.lock_time ? pool.lock_time : locktimeFarm[index]
                    }
                    tokenLogo={
                      pool.icon
                        ? pool.icon
                        : pool.pair_name === "iDYP"
                        ? "idypius.svg"
                        : "dyplogo.svg"
                    }
                    onShowDetailsClick={() => {
                      setActiveCard(null);
                      setActiveCard2(null);
                      setActiveCard3(topPools[index + 6]);
                      setActiveCardNFT(false);
                      setActiveCardLandNFT(false);
                      handleCardIndexStake(index + 6);
                      setDetails(index + 6);
                      setselectedPool(topList !== "Vault" && pool);
                      setShowDetails(topList !== "Vault" && true);
                    }}
                    onHideDetailsClick={() => {
                      setActiveCard3(null);
                      setDetails();
                    }}
                    cardType={topList}
                    details={details === index + 6 ? true : false}
                    isNewPool={pool.new_pool === "Yes" ? true : false}
                    isStaked={
                      userPools.length > 0
                        ? userPools.find(
                            (obj) => obj.contract_address === pool.id
                          )
                          ? true
                          : false
                        : false
                    }
                    expired={false}
                    isPremium={isPremium}
                  />
                ))}
              </div>
              <div
                className="top-picks-container"
                style={{ marginTop: activePools.length > 9 && "25px" }}
              >
                {activePools.slice(9, activePools.length).map((pool, index) => (
                  <TopPoolsCard
                    network={chainId}
                    display={
                      pool.expired ? (pool.expired === "Yes" ? "none" : "") : ""
                    }
                    key={index}
                    chain={chain}
                    top_pick={pool.top_pick}
                    tokenName={
                      pool.tokenName
                        ? pool.tokenName
                        : pool.pair_name
                        ? pool.pair_name
                        : ""
                    }
                    apr={pool.apy + "%"}
                    tvl={
                      pool.tvl_usd === "--"
                        ? pool.tvl_usd
                        : "$" + getFormattedNumber(pool.tvl_usd)
                    }
                    lockTime={
                      pool.lock_time ? pool.lock_time : locktimeFarm[index]
                    }
                    tokenLogo={
                      pool.icon
                        ? pool.icon
                        : pool.pair_name === "iDYP"
                        ? "idypius.svg"
                        : "dyplogo.svg"
                    }
                    onShowDetailsClick={() => {
                      setActiveCard(null);
                      setActiveCard2(null);
                      setActiveCard3(null);
                      setActiveCard4(topPools[index + 9]);
                      setActiveCardNFT(false);
                      setActiveCardLandNFT(false);
                      handleCardIndexStake(index + 9);
                      setDetails(index + 9);
                      setselectedPool(topList !== "Vault" && pool);
                      setShowDetails(topList !== "Vault" && true);
                    }}
                    onHideDetailsClick={() => {
                      setActiveCard4(null);
                      setDetails();
                    }}
                    cardType={topList}
                    details={details === index + 9 ? true : false}
                    isNewPool={pool.new_pool === "Yes" ? true : false}
                    expired={false}
                    isStaked={
                      userPools.length > 0
                        ? userPools.find(
                            (obj) => obj.contract_address === pool.id
                          )
                          ? true
                          : false
                        : false
                    }
                    isPremium={isPremium}
                  />
                ))}
              </div>
            </div>
          ) : windowSize.width > 786 ? (
            <div className="px-0">
              <div
                className="top-picks-container"
                style={{ marginTop: "26px" }}
              >
                {activePools.slice(2, 4).map((pool, index) => (
                  <TopPoolsCard
                    network={chainId}
                    display={
                      pool.expired ? (pool.expired === "Yes" ? "none" : "") : ""
                    }
                    expired={false}
                    key={index}
                    chain={chain}
                    top_pick={pool.top_pick}
                    tokenName={
                      pool.tokenName
                        ? pool.tokenName
                        : pool.pair_name
                        ? pool.pair_name
                        : ""
                    }
                    apr={pool.apy_percent + "%"}
                    tvl={
                      pool.tvl_usd === "--"
                        ? pool.tvl_usd
                        : "$" + getFormattedNumber(pool.tvl_usd)
                    }
                    lockTime={
                      pool.lockTime
                        ? pool.lockTime
                        : pool.lock_time
                        ? pool.lock_time
                        : locktimeFarm[index]
                    }
                    tokenLogo={
                      pool.icon
                        ? pool.icon
                        : pool.pair_name === "iDYP"
                        ? "idypius.svg"
                        : "dyplogo.svg"
                    }
                    onShowDetailsClick={() => {
                      setActiveCard(null);
                      setActiveCard2(topPools[index + 2]);
                      setActiveCard3(null);
                      setActiveCard4(null);
                      setActiveCard5(null);
                      setActiveCard6(null);
                      setActiveCardNFT(false);
                      setActiveCardLandNFT(false);
                      handleCardIndexStake(index + 2);
                      setDetails(index + 2);
                      setselectedPool(topList !== "Vault" && pool);
                      setShowDetails(topList !== "Vault" && true);
                    }}
                    onHideDetailsClick={() => {
                      setActiveCard2(null);
                      setDetails();
                    }}
                    cardType={topList}
                    details={details === index + 2 ? true : false}
                    isNewPool={pool.new_pool === "Yes" ? true : false}
                    isStaked={
                      userPools.length > 0
                        ? userPools.find(
                            (obj) => obj.contract_address === pool.id
                          )
                          ? true
                          : false
                        : false
                    }
                    isPremium={isPremium}
                  />
                ))}
              </div>

              <div
                className="top-picks-container"
                style={{ marginTop: "25px" }}
              >
                {activePools.slice(4, 6).map((pool, index) => (
                  <TopPoolsCard
                    network={chainId}
                    display={
                      pool.expired ? (pool.expired === "Yes" ? "none" : "") : ""
                    }
                    expired={false}
                    key={index}
                    chain={chain}
                    top_pick={pool.top_pick}
                    tokenName={
                      pool.tokenName
                        ? pool.tokenName
                        : pool.pair_name
                        ? pool.pair_name
                        : ""
                    }
                    apr={pool.apy_percent + "%"}
                    tvl={
                      pool.tvl_usd === "--"
                        ? pool.tvl_usd
                        : "$" + getFormattedNumber(pool.tvl_usd)
                    }
                    lockTime={
                      pool.lockTime
                        ? pool.lockTime
                        : pool.lock_time
                        ? pool.lock_time
                        : locktimeFarm[index]
                    }
                    tokenLogo={
                      pool.icon
                        ? pool.icon
                        : pool.pair_name === "iDYP"
                        ? "idypius.svg"
                        : "dyplogo.svg"
                    }
                    onShowDetailsClick={() => {
                      setActiveCard(null);
                      setActiveCard3(topPools[index + 4]);
                      setActiveCard2(null);
                      setActiveCard4(null);
                      setActiveCard5(null);
                      setActiveCard6(null);
                      setActiveCardNFT(false);
                      setActiveCardLandNFT(false);
                      handleCardIndexStake(index + 4);
                      setDetails(index + 4);
                      setselectedPool(topList !== "Vault" && pool);
                      setShowDetails(topList !== "Vault" && true);
                    }}
                    onHideDetailsClick={() => {
                      setActiveCard2(null);
                      setActiveCard3(null);
                      setDetails();
                    }}
                    cardType={topList}
                    details={details === index + 4 ? true : false}
                    isNewPool={pool.new_pool === "Yes" ? true : false}
                    isStaked={
                      userPools.length > 0
                        ? userPools.find(
                            (obj) => obj.contract_address === pool.id
                          )
                          ? true
                          : false
                        : false
                    }
                    isPremium={isPremium}
                  />
                ))}
              </div>

              <div
                className="top-picks-container"
                style={{ marginTop: "25px" }}
              >
                {activePools.slice(6, 8).map((pool, index) => (
                  <TopPoolsCard
                    network={chainId}
                    display={
                      pool.expired ? (pool.expired === "Yes" ? "none" : "") : ""
                    }
                    expired={false}
                    key={index}
                    chain={chain}
                    top_pick={pool.top_pick}
                    tokenName={
                      pool.tokenName
                        ? pool.tokenName
                        : pool.pair_name
                        ? pool.pair_name
                        : ""
                    }
                    apr={pool.apy_percent + "%"}
                    tvl={
                      pool.tvl_usd === "--"
                        ? pool.tvl_usd
                        : "$" + getFormattedNumber(pool.tvl_usd)
                    }
                    lockTime={
                      pool.lockTime
                        ? pool.lockTime
                        : pool.lock_time
                        ? pool.lock_time
                        : locktimeFarm[index]
                    }
                    tokenLogo={
                      pool.icon
                        ? pool.icon
                        : pool.pair_name === "iDYP"
                        ? "idypius.svg"
                        : "dyplogo.svg"
                    }
                    onShowDetailsClick={() => {
                      setActiveCard(null);
                      setActiveCard2(null);
                      setActiveCard3(null);
                      setActiveCard4(topPools[index + 6]);
                      setActiveCard5(null);
                      setActiveCard6(null);
                      setActiveCardNFT(false);
                      setActiveCardLandNFT(false);
                      handleCardIndexStake(index + 6);
                      setDetails(index + 6);
                      setselectedPool(topList !== "Vault" && pool);
                      setShowDetails(topList !== "Vault" && true);
                    }}
                    onHideDetailsClick={() => {
                      setActiveCard4(null);
                      setDetails();
                    }}
                    cardType={topList}
                    details={details === index + 6 ? true : false}
                    isNewPool={pool.new_pool === "Yes" ? true : false}
                    isStaked={
                      userPools.length > 0
                        ? userPools.find(
                            (obj) => obj.contract_address === pool.id
                          )
                          ? true
                          : false
                        : false
                    }
                    isPremium={isPremium}
                  />
                ))}
              </div>

              <div
                className="top-picks-container"
                style={{ marginTop: activePools.length > 8 && "25px" }}
              >
                {activePools.slice(8, activePools.length).map((pool, index) => (
                  <TopPoolsCard
                    network={chainId}
                    display={
                      pool.expired ? (pool.expired === "Yes" ? "none" : "") : ""
                    }
                    expired={false}
                    key={index}
                    chain={chain}
                    top_pick={pool.top_pick}
                    tokenName={
                      pool.tokenName
                        ? pool.tokenName
                        : pool.pair_name
                        ? pool.pair_name
                        : ""
                    }
                    apr={pool.apy_percent + "%"}
                    tvl={
                      pool.tvl_usd === "--"
                        ? pool.tvl_usd
                        : "$" + getFormattedNumber(pool.tvl_usd)
                    }
                    lockTime={
                      pool.lockTime
                        ? pool.lockTime
                        : pool.lock_time
                        ? pool.lock_time
                        : locktimeFarm[index]
                    }
                    tokenLogo={
                      pool.icon
                        ? pool.icon
                        : pool.pair_name === "iDYP"
                        ? "idypius.svg"
                        : "dyplogo.svg"
                    }
                    onShowDetailsClick={() => {
                      setActiveCard(null);
                      setActiveCard2(null);
                      setActiveCard3(null);
                      setActiveCard4(null);
                      setActiveCard5(topPools[index + 6]);
                      setActiveCard6(null);
                      setActiveCardNFT(false);
                      setActiveCardLandNFT(false);
                      handleCardIndexStake(index + 6);
                      setDetails(index + 6);
                      setselectedPool(topList !== "Vault" && pool);
                      setShowDetails(topList !== "Vault" && true);
                    }}
                    onHideDetailsClick={() => {
                      setActiveCard5(null);
                      setDetails();
                    }}
                    cardType={topList}
                    details={details === index + 6 ? true : false}
                    isNewPool={pool.new_pool === "Yes" ? true : false}
                    isStaked={
                      userPools.length > 0
                        ? userPools.find(
                            (obj) => obj.contract_address === pool.id
                          )
                          ? true
                          : false
                        : false
                    }
                    isPremium={isPremium}
                  />
                ))}
              </div>

              <div
                className="top-picks-container"
                style={{ marginTop: activePools.length > 9 && "25px" }}
              >
                {activePools
                  .slice(10, activePools.length)
                  .map((pool, index) => (
                    <TopPoolsCard
                      network={chainId}
                      display={
                        pool.expired
                          ? pool.expired === "Yes"
                            ? "none"
                            : ""
                          : ""
                      }
                      expired={false}
                      key={index}
                      chain={chain}
                      top_pick={pool.top_pick}
                      tokenName={
                        pool.tokenName
                          ? pool.tokenName
                          : pool.pair_name
                          ? pool.pair_name
                          : ""
                      }
                      apr={pool.apy_percent + "%"}
                      tvl={
                        pool.tvl_usd === "--"
                          ? pool.tvl_usd
                          : "$" + getFormattedNumber(pool.tvl_usd)
                      }
                      lockTime={
                        pool.lockTime
                          ? pool.lockTime
                          : pool.lock_time
                          ? pool.lock_time
                          : locktimeFarm[index]
                      }
                      tokenLogo={
                        pool.icon
                          ? pool.icon
                          : pool.pair_name === "iDYP"
                          ? "idypius.svg"
                          : "dyplogo.svg"
                      }
                      onShowDetailsClick={() => {
                        setActiveCard(null);
                        setActiveCard2(null);
                        setActiveCard3(null);
                        setActiveCard4(null);
                        setActiveCard5(null);
                        setActiveCard6(topPools[index + 10]);
                        setActiveCardNFT(false);
                        setActiveCardLandNFT(false);
                        handleCardIndexStake(index + 10);
                        setDetails(index + 10);
                        setselectedPool(topList !== "Vault" && pool);
                        setShowDetails(topList !== "Vault" && true);
                      }}
                      onHideDetailsClick={() => {
                        setActiveCard6(null);
                        setDetails();
                      }}
                      cardType={topList}
                      details={details === index + 10 ? true : false}
                      isNewPool={pool.new_pool === "Yes" ? true : false}
                      isStaked={
                        userPools.length > 0
                          ? userPools.find(
                              (obj) => obj.contract_address === pool.id
                            )
                            ? true
                            : false
                          : false
                      }
                      isPremium={isPremium}
                    />
                  ))}
              </div>
            </div>
          ) : (
            <div className="px-0">
              <>
                <div className="top-picks-container">
                  {activePools.slice(0, 1).map((pool, index) => (
                    <TopPoolsCard
                      network={chainId}
                      display={
                        pool.expired
                          ? pool.expired === "Yes"
                            ? "none"
                            : ""
                          : ""
                      }
                      expired={false}
                      key={index}
                      chain={chain}
                      top_pick={pool.top_pick}
                      tokenName={
                        pool.tokenName
                          ? pool.tokenName
                          : pool.pair_name
                          ? pool.pair_name
                          : ""
                      }
                      apr={pool.apy_percent + "%"}
                      tvl={
                        pool.tvl_usd === "--"
                          ? pool.tvl_usd
                          : "$" + getFormattedNumber(pool.tvl_usd)
                      }
                      lockTime={
                        pool.lockTime
                          ? pool.lockTime
                          : pool.lock_time
                          ? pool.lock_time
                          : locktimeFarm[index]
                      }
                      tokenLogo={
                        pool.icon
                          ? pool.icon
                          : pool.pair_name === "iDYP"
                          ? "idypius.svg"
                          : "dyplogo.svg"
                      }
                      onShowDetailsClick={() => {
                        setActiveCard(topPools[index]);
                        setActiveCard2(null);
                        setActiveCard3(null);
                        setActiveCard4(null);
                        setActiveCard5(null);
                        setActiveCard6(null);
                        setActiveCard7(null);
                        setActiveCard8(null);
                        setActiveCard9(null);
                        setActiveCard10(null);
                        setActiveCard11(null);
                        setActiveCard12(null);
                        setActiveCardNFT(false);
                        setActiveCardLandNFT(false);
                        setActiveCardCawsLand(null);
                        setselectedPool(topList !== "Vault" && pool);
                        setShowDetails(topList !== "Vault" && true);
                        handleCardIndexStake(index);
                        setDetails(index);
                      }}
                      onHideDetailsClick={() => {
                        setActiveCard(null);
                        setDetails();
                      }}
                      cardType={topList}
                      details={details === index ? true : false}
                      isNewPool={pool.new_pool === "Yes" ? true : false}
                      isStaked={
                        userPools.length > 0
                          ? userPools.find(
                              (obj) => obj.contract_address === pool.id
                            )
                            ? true
                            : false
                          : false
                      }
                      isPremium={isPremium}
                    />
                  ))}
                </div>
              </>
              <>
                <div
                  className="top-picks-container"
                  style={{ marginTop: "25px" }}
                >
                  {activePools.slice(1, 2).map((pool, index) => (
                    <TopPoolsCard
                      network={chainId}
                      display={
                        pool.expired
                          ? pool.expired === "Yes"
                            ? "none"
                            : ""
                          : ""
                      }
                      expired={false}
                      key={index}
                      chain={chain}
                      top_pick={pool.top_pick}
                      tokenName={
                        pool.tokenName
                          ? pool.tokenName
                          : pool.pair_name
                          ? pool.pair_name
                          : ""
                      }
                      apr={pool.apy_percent + "%"}
                      tvl={
                        pool.tvl_usd === "--"
                          ? pool.tvl_usd
                          : "$" + getFormattedNumber(pool.tvl_usd)
                      }
                      lockTime={
                        pool.lockTime
                          ? pool.lockTime
                          : pool.lock_time
                          ? pool.lock_time
                          : locktimeFarm[index]
                      }
                      tokenLogo={
                        pool.icon
                          ? pool.icon
                          : pool.pair_name === "iDYP"
                          ? "idypius.svg"
                          : "dyplogo.svg"
                      }
                      onShowDetailsClick={() => {
                        setActiveCard(null);
                        setActiveCard2(topPools[index + 1]);
                        setActiveCard3(null);
                        setActiveCard4(null);
                        setActiveCard5(null);
                        setActiveCard6(null);
                        setActiveCard7(null);
                        setActiveCard8(null);
                        setActiveCard9(null);
                        setActiveCard10(null);
                        setActiveCard11(null);
                        setActiveCard12(null);
                        setActiveCardNFT(false);
                        setActiveCardLandNFT(false);
                        handleCardIndexStake(index + 1);
                        setDetails(index + 1);
                        setselectedPool(topList !== "Vault" && pool);
                        setShowDetails(topList !== "Vault" && true);
                      }}
                      onHideDetailsClick={() => {
                        setActiveCard2(null);
                        setDetails();
                      }}
                      cardType={topList}
                      details={details === index + 1 ? true : false}
                      isNewPool={pool.new_pool === "Yes" ? true : false}
                      isStaked={
                        userPools.length > 0
                          ? userPools.find(
                              (obj) => obj.contract_address === pool.id
                            )
                            ? true
                            : false
                          : false
                      }
                      isPremium={isPremium}
                    />
                  ))}
                </div>
              </>
              <>
                <div
                  className="top-picks-container"
                  style={{ marginTop: "25px" }}
                >
                  {activePools.slice(2, 3).map((pool, index) => (
                    <TopPoolsCard
                      network={chainId}
                      display={
                        pool.expired
                          ? pool.expired === "Yes"
                            ? "none"
                            : ""
                          : ""
                      }
                      expired={false}
                      key={index}
                      chain={chain}
                      top_pick={pool.top_pick}
                      tokenName={
                        pool.tokenName
                          ? pool.tokenName
                          : pool.pair_name
                          ? pool.pair_name
                          : ""
                      }
                      apr={pool.apy_percent + "%"}
                      tvl={
                        pool.tvl_usd === "--"
                          ? pool.tvl_usd
                          : "$" + getFormattedNumber(pool.tvl_usd)
                      }
                      lockTime={
                        pool.lockTime
                          ? pool.lockTime
                          : pool.lock_time
                          ? pool.lock_time
                          : locktimeFarm[index]
                      }
                      tokenLogo={
                        pool.icon
                          ? pool.icon
                          : pool.pair_name === "iDYP"
                          ? "idypius.svg"
                          : "dyplogo.svg"
                      }
                      onShowDetailsClick={() => {
                        setActiveCard(null);
                        setActiveCard2(null);
                        setActiveCard3(topPools[index + 2]);
                        setActiveCard4(null);
                        setActiveCard5(null);
                        setActiveCard6(null);
                        setActiveCard7(null);
                        setActiveCard8(null);
                        setActiveCard9(null);
                        setActiveCard10(null);
                        setActiveCard11(null);
                        setActiveCard12(null);
                        setActiveCardNFT(false);
                        setActiveCardLandNFT(false);
                        handleCardIndexStake(index + 2);
                        setDetails(index + 2);
                        setselectedPool(topList !== "Vault" && pool);
                        setShowDetails(topList !== "Vault" && true);
                      }}
                      onHideDetailsClick={() => {
                        setActiveCard3(null);
                        setDetails();
                      }}
                      cardType={topList}
                      details={details === index + 2 ? true : false}
                      isNewPool={pool.new_pool === "Yes" ? true : false}
                      isStaked={
                        userPools.length > 0
                          ? userPools.find(
                              (obj) => obj.contract_address === pool.id
                            )
                            ? true
                            : false
                          : false
                      }
                      isPremium={isPremium}
                    />
                  ))}
                </div>
              </>
              <>
                <div
                  className="top-picks-container"
                  style={{ marginTop: "25px" }}
                >
                  {activePools.slice(3, 4).map((pool, index) => (
                    <TopPoolsCard
                      network={chainId}
                      display={
                        pool.expired
                          ? pool.expired === "Yes"
                            ? "none"
                            : ""
                          : ""
                      }
                      expired={false}
                      key={index}
                      chain={chain}
                      top_pick={pool.top_pick}
                      tokenName={
                        pool.tokenName
                          ? pool.tokenName
                          : pool.pair_name
                          ? pool.pair_name
                          : ""
                      }
                      apr={pool.apy_percent + "%"}
                      tvl={
                        pool.tvl_usd === "--"
                          ? pool.tvl_usd
                          : "$" + getFormattedNumber(pool.tvl_usd)
                      }
                      lockTime={
                        pool.lockTime
                          ? pool.lockTime
                          : pool.lock_time
                          ? pool.lock_time
                          : locktimeFarm[index]
                      }
                      tokenLogo={
                        pool.icon
                          ? pool.icon
                          : pool.pair_name === "iDYP"
                          ? "idypius.svg"
                          : "dyplogo.svg"
                      }
                      onShowDetailsClick={() => {
                        setActiveCard(null);
                        setActiveCard2(null);
                        setActiveCard3(null);
                        setActiveCard4(topPools[index + 3]);
                        setActiveCard5(null);
                        setActiveCard6(null);
                        setActiveCard7(null);
                        setActiveCard8(null);
                        setActiveCard9(null);
                        setActiveCard10(null);
                        setActiveCard11(null);
                        setActiveCard12(null);
                        setActiveCardNFT(false);
                        setActiveCardLandNFT(false);
                        handleCardIndexStake(index + 3);
                        setDetails(index + 3);
                        setselectedPool(topList !== "Vault" && pool);
                        setShowDetails(topList !== "Vault" && true);
                      }}
                      onHideDetailsClick={() => {
                        setActiveCard4(null);
                        setDetails();
                      }}
                      cardType={topList}
                      details={details === index + 3 ? true : false}
                      isNewPool={pool.new_pool === "Yes" ? true : false}
                      isStaked={
                        userPools.length > 0
                          ? userPools.find(
                              (obj) => obj.contract_address === pool.id
                            )
                            ? true
                            : false
                          : false
                      }
                      isPremium={isPremium}
                    />
                  ))}
                </div>
              </>
              <>
                <div
                  className="top-picks-container"
                  style={{ marginTop: "25px" }}
                >
                  {activePools.slice(4, 5).map((pool, index) => (
                    <TopPoolsCard
                      network={chainId}
                      display={
                        pool.expired
                          ? pool.expired === "Yes"
                            ? "none"
                            : ""
                          : ""
                      }
                      expired={false}
                      key={index}
                      chain={chain}
                      top_pick={pool.top_pick}
                      tokenName={
                        pool.tokenName
                          ? pool.tokenName
                          : pool.pair_name
                          ? pool.pair_name
                          : ""
                      }
                      apr={pool.apy_percent + "%"}
                      tvl={
                        pool.tvl_usd === "--"
                          ? pool.tvl_usd
                          : "$" + getFormattedNumber(pool.tvl_usd)
                      }
                      lockTime={
                        pool.lockTime
                          ? pool.lockTime
                          : pool.lock_time
                          ? pool.lock_time
                          : locktimeFarm[index]
                      }
                      tokenLogo={
                        pool.icon
                          ? pool.icon
                          : pool.pair_name === "iDYP"
                          ? "idypius.svg"
                          : "dyplogo.svg"
                      }
                      onShowDetailsClick={() => {
                        setActiveCard(null);
                        setActiveCard2(null);
                        setActiveCard3(null);
                        setActiveCard4(null);
                        setActiveCard5(topPools[index + 4]);
                        setActiveCard6(null);
                        setActiveCard7(null);
                        setActiveCard8(null);
                        setActiveCard9(null);
                        setActiveCard10(null);
                        setActiveCard11(null);
                        setActiveCard12(null);
                        setActiveCardNFT(false);
                        setActiveCardLandNFT(false);
                        handleCardIndexStake(index + 4);
                        setDetails(index + 4);
                        setselectedPool(topList !== "Vault" && pool);
                        setShowDetails(topList !== "Vault" && true);
                      }}
                      onHideDetailsClick={() => {
                        setActiveCard5(null);
                        setDetails();
                      }}
                      cardType={topList}
                      details={details === index + 4 ? true : false}
                      isNewPool={pool.new_pool === "Yes" ? true : false}
                      isStaked={
                        userPools.length > 0
                          ? userPools.find(
                              (obj) => obj.contract_address === pool.id
                            )
                            ? true
                            : false
                          : false
                      }
                      isPremium={isPremium}
                    />
                  ))}
                </div>
              </>
              <>
                <div
                  className="top-picks-container"
                  style={{ marginTop: activePools.length >= 7 && "25px" }}
                >
                  {activePools.slice(5, 6).map((pool, index) => (
                    <TopPoolsCard
                      network={chainId}
                      display={
                        pool.expired
                          ? pool.expired === "Yes"
                            ? "none"
                            : ""
                          : ""
                      }
                      expired={false}
                      key={index}
                      chain={chain}
                      top_pick={pool.top_pick}
                      tokenName={
                        pool.tokenName
                          ? pool.tokenName
                          : pool.pair_name
                          ? pool.pair_name
                          : ""
                      }
                      apr={pool.apy_percent + "%"}
                      tvl={
                        pool.tvl_usd === "--"
                          ? pool.tvl_usd
                          : "$" + getFormattedNumber(pool.tvl_usd)
                      }
                      lockTime={
                        pool.lockTime
                          ? pool.lockTime
                          : pool.lock_time
                          ? pool.lock_time
                          : locktimeFarm[index]
                      }
                      tokenLogo={
                        pool.icon
                          ? pool.icon
                          : pool.pair_name === "iDYP"
                          ? "idypius.svg"
                          : "dyplogo.svg"
                      }
                      onShowDetailsClick={() => {
                        setActiveCard(null);
                        setActiveCard2(null);
                        setActiveCard3(null);
                        setActiveCard4(null);
                        setActiveCard5(null);
                        setActiveCard6(topPools[index + 5]);
                        setActiveCard7(null);
                        setActiveCard8(null);
                        setActiveCard9(null);
                        setActiveCard10(null);
                        setActiveCard11(null);
                        setActiveCard12(null);
                        setActiveCardNFT(false);
                        setActiveCardLandNFT(false);
                        handleCardIndexStake(index + 5);
                        setDetails(index + 5);
                        setselectedPool(topList !== "Vault" && pool);
                        setShowDetails(topList !== "Vault" && true);
                      }}
                      onHideDetailsClick={() => {
                        setActiveCard6(null);
                        setDetails();
                      }}
                      cardType={topList}
                      details={details === index + 5 ? true : false}
                      isNewPool={pool.new_pool === "Yes" ? true : false}
                      isStaked={
                        userPools.length > 0
                          ? userPools.find(
                              (obj) => obj.contract_address === pool.id
                            )
                            ? true
                            : false
                          : false
                      }
                      isPremium={isPremium}
                    />
                  ))}
                </div>
              </>
            </div>
          )
        ) : listing !== "table" && topPools.length > 0 ? (
          <div className="list-pools-container px-0">
            {activePools.map((pool, index) => (
              <TopPoolsListCard
                key={index}
                expiredPools={expiredDYPPools}
                activePools={activePools}
                expired={false}
                chain={chain}
                top_pick={pool.top_pick}
                tokenName={
                  pool.tokenName
                    ? pool.tokenName
                    : pool.pair_name
                    ? pool.pair_name
                    : ""
                }
                apr={pool.apy_percent + "%"}
                tvl={
                  pool.tvl_usd === "--"
                    ? pool.tvl_usd
                    : "$" + getFormattedNumber(pool.tvl_usd)
                }
                lockTime={
                  pool.lockTime
                    ? pool.lockTime
                    : pool.lock_time
                    ? pool.lock_time
                    : locktimeFarm[index]
                }
                cardType={topList}
                tokenLogo={
                  pool.icon
                    ? pool.icon
                    : pool.pair_name === "iDYP"
                    ? "idypius.svg"
                    : "dyplogo.svg"
                }
                listType={listType}
                onShowDetailsClick={() => {
                  setActiveCard(topPools[index]);
                  handleCardIndexStake(index);
                  setselectedPool(pool);
                  setShowDetails(true);
                }}
                onHideDetailsClick={() => {
                  setActiveCard(null);
                }}
                showDetails={showDetails}
                topList={topList}
                cardIndex={index + 1}
                chainId={chainId}
                handleConnection={handleConnection}
                handleSwitchNetwork={handleSwitchNetwork}
                coinbase={coinbase}
                referrer={referrer}
                lp_id={lp_id[cardIndex]}
                the_graph_result={the_graph_result}
                isConnected={isConnected}
                display={
                  pool.expired
                    ? pool.expired === "Yes"
                      ? "none"
                      : "flex"
                    : "flex"
                }
                isNewPool={pool.new_pool === "Yes" ? true : false}
                totalTvl={pool.tvl_usd}
                isPremium={isPremium}
              />
            ))}
          </div>
        ) : topPools.length === 0 ? (
          <div
            className="w-100 d-flex justify-content-center align-items-center mt-5"
            style={{ minHeight: "240px" }}
          >
            <FadeLoader color="#7770DF" />
          </div>
        ) : (
          <div
            className="w-100 d-flex justify-content-center align-items-center mt-5"
            style={{ minHeight: "240px" }}
          >
            <FadeLoader color="#7770DF" />
          </div>
        )}
      </div>
      {showDetails && topList !== "Vault" && (
        <Modal
          open={showDetails}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="d-flex flex-column justify-content-center position-relative">
              <div className="d-flex flex-column gap-3 align-items-center justify-content-between">
                <div className="d-flex gap-2 w-100 align-items-center">
                  <div className="d-flex align-items-center gap-5 w-100">
                    <span
                      className={
                        selectedTab === "deposit"
                          ? "switchchain-txt-active"
                          : "switchchain-txt"
                      }
                      onClick={() => {
                        setselectedTab("deposit");
                      }}
                    >
                      Deposits
                    </span>
                    <span
                      className={
                        selectedTab === "withdraw"
                          ? "switchchain-txt-active"
                          : "switchchain-txt"
                      }
                      onClick={() => {
                        setselectedTab("withdraw");
                      }}
                    >
                      Withdraw
                    </span>
                  </div>
                  <img
                    src={closeX}
                    alt=""
                    className="close-x position-relative cursor-pointer "
                    onClick={() => {
                      setShowDetails(false);
                      setselectedTab("deposit");
                      setDetails(888);
                    }}
                    style={{
                      bottom: "17px",
                      alignSelf: "end",
                      width: 16,
                      height: 16,
                    }}
                  />
                </div>
                <div className="locktimewrapper align-items-center gap-2">
                  <button
                    className={
                      selectedPool?.lock_time?.split(" ")[0] === "No"
                        ? "method-btn-active"
                        : "method-btn-disabled"
                    }
                  >
                    Flexible
                  </button>
                  <button
                    className={
                      parseInt(selectedPool?.lock_time?.split(" ")[0]) === 30
                        ? "method-btn-active"
                        : "method-btn-disabled"
                    }
                  >
                    30 Days
                  </button>
                  <button
                    className={
                      parseInt(selectedPool?.lock_time?.split(" ")[0]) === 60
                        ? "method-btn-active"
                        : "method-btn-disabled"
                    }
                  >
                    60 Days
                  </button>
                  <button
                    className={
                      parseInt(selectedPool?.lock_time?.split(" ")[0]) === 90
                        ? "method-btn-active"
                        : "method-btn-disabled"
                    }
                  >
                    90 Days
                  </button>
                  <button
                    className={
                      parseInt(selectedPool?.lock_time?.split(" ")[0]) === 120
                        ? "method-btn-active"
                        : "method-btn-disabled"
                    }
                  >
                    120 Days
                  </button>
                </div>
                <div className="d-flex gap-3 align-items-center justify-content-start w-100">
                  <div
                    className={`position-relative col-lg-3 ${
                      selectedchain === "eth"
                        ? "chain-popup-item-eth"
                        : "chain-popup-item"
                    }`}
                    onClick={() => {
                      setselectedchain("eth");
                      onChainSelect("eth");
                      setselectedPool(
                        selectedPool.type === "dyp"
                          ? ethPools.find((item) => {
                              return item.type === "dyp";
                            })
                          : ethPools.find((item) => {
                              return item.type === "idyp";
                            })
                      );
                    }}
                  >
                    <h6
                      className={`d-flex justify-content-center align-items-center chain-popup-text`}
                    >
                      <img
                        src={selectedchain === "eth" ? ethActive : eth}
                        alt=""
                        className="popup-chains-icon"
                      />
                      Elastos
                    </h6>
                  </div>
                </div>
                <div className="info-pool-wrapper p-3 w-100">
                  <div className="info-pool-inner-wrapper d-flex flex-column flex-lg-row align-items-center gap-2">
                    <div className="info-pool-item p-2">
                      <div className="d-flex justify-content-between gap-1 align-items-center">
                        <span className="info-pool-left-text">
                          APR{" "}
                          <ClickAwayListener onClickAway={aprClose}>
                            <Tooltip
                              open={aprTooltip}
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                              placement="top"
                              title={
                                <div className="tooltip-text">
                                  {
                                    "APR reflects the interest rate of earnings on an account over the course of one year."
                                  }
                                </div>
                              }
                            >
                              <img src={moreinfo} alt="" onClick={aprOpen} />
                            </Tooltip>
                          </ClickAwayListener>
                        </span>
                        <span className="info-pool-right-text">
                          {selectedPool?.apy_performancefee}%
                        </span>
                      </div>
                    </div>
                    <div className="info-pool-item p-2">
                      <div className="d-flex justify-content-between gap-1 align-items-center">
                        <span className="info-pool-left-text">TVL</span>
                        <span className="info-pool-right-text">
                          ${getFormattedNumber(selectedPool.tvl_usd, 2)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                {topList === "Staking" &&
                selectedPool?.id ===
                  "0x0376F01cBedcdc6c9B32258e89254Aa015101Db8" &&
                chain === "eth" ? (
                  <StakeDypiusEth
                    selectedPool={selectedPool}
                    selectedTab={selectedTab}
                    staking={window.constant_staking_dypius_eth1}
                    apr={selectedPool?.apy_percent}
                    liquidity={eth_address}
                    expiration_time={"09 Nov 2024"}
                    finalApr={selectedPool?.apy_performancefee}
                    lockTime={
                      selectedPool?.lock_time?.split(" ")[0] === "No"
                        ? "No Lock"
                        : parseInt(selectedPool?.lock_time?.split(" ")[0])
                    }
                    listType={listType}
                    other_info={false}
                    fee={selectedPool?.performancefee}
                    is_wallet_connected={isConnected}
                    coinbase={coinbase}
                    the_graph_result={the_graph_result}
                    chainId={chainId}
                    handleConnection={handleConnection}
                    handleSwitchNetwork={handleSwitchNetwork}
                    expired={false}
                    referrer={referrer}
                    onConnectWallet={() => {
                      setShowDetails(false);
                      onConnectWallet();
                      setselectedPool([]);
                      setDetails(999);
                    }}
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
          </Box>
        </Modal>
      )}
    </>
  );
};
export default EarnTopPicks;
