import { useEffect, useState } from "react";
import { useAccount, useConnect, useNetwork } from "wagmi";
import { injected } from "./connectors";
import { ethers } from "ethers";

const onSignIn = async ({ address, chainId }) => {
  // Check if Ethereum object and methods are available in window
  if (!window.ethereum || !window.ethereum.request) {
    console.error("Ethereum provider (e.g., MetaMask) is not available.");
    return;
  }
  if (!address || !chainId) return;
  const balance = await window.ethereum.request({
    method: "eth_getBalance",
    params: [address, "latest"],
  });
  return ethers.utils.formatEther(balance);
};

export function useEagerConnect() {
  const { address, isConnected } = useAccount();
  const { connect } = useConnect({
    connector: injected,
  });
  const { chain } = useNetwork();
  const [tried, setTried] = useState(false);
  const [currencyAmount, setCurrencyAmount] = useState("");

  useEffect(() => {
    const eagerConnect = async () => {
      if (isConnected) {
        const ethBalance = await onSignIn({ address, chainId: chain?.id });
        if (ethBalance) {
          setCurrencyAmount(ethBalance);
        }
        setTried(true);
      } else {
        connect();
      }
    };

    eagerConnect();
  }, [isConnected, address, connect, chain]);

  useEffect(() => {
    if (isConnected) {
      setTried(true);
    }
  }, [isConnected]);

  return [tried, currencyAmount];
}

export function useInactiveListener(suppress = false) {
  const { isConnected } = useAccount();
  const { connect } = useConnect({
    connector: injected,
  });

  useEffect(() => {
    const { ethereum } = window;

    if (ethereum && ethereum.on && !isConnected && !suppress) {
      const handleChainChanged = () => {
        connect();
      };

      const handleAccountsChanged = (accounts) => {
        if (accounts.length > 0) {
          connect();
        }
      };

      ethereum.on("chainChanged", handleChainChanged);
      ethereum.on("accountsChanged", handleAccountsChanged);

      return () => {
        if (ethereum.removeListener) {
          ethereum.removeListener("chainChanged", handleChainChanged);
          ethereum.removeListener("accountsChanged", handleAccountsChanged);
        }
      };
    }

    return undefined;
  }, [isConnected, suppress, connect]);
}

export const handleSwitchNetworkhook = async (chainID) => {
  const { ethereum } = window;

  const networks = {
    20: {
      chainId: "0x14", // A 0x-prefixed hexadecimal string
      chainName: "Elastos Smart Chain",
      nativeCurrency: {
        name: "ESC",
        symbol: "ESC",
        decimals: 18,
      },
      rpcUrls: ["https://api.elastos.io/esc"],
      blockExplorerUrls: ["https://esc.elastos.io"],
    },
    32659: {
      chainId: "0x7f93", // A 0x-prefixed hexadecimal string for FSN
      chainName: "Fusion Mainnet",
      nativeCurrency: {
        name: "FSN",
        symbol: "FSN",
        decimals: 18,
      },
      rpcUrls: ["https://mainnet.fusionnetwork.io"],
      blockExplorerUrls: ["https://fsnscan.com"],
    }
  };

  try {
    await ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: chainID }],
    });
  } catch (switchError) {
    if (switchError.code === 4902) {
      try {
        await ethereum.request({
          method: "wallet_addEthereumChain",
          params: [networks[chainID]],
        });
      } catch (addError) {
        console.log(addError);
      }
    }
    console.log(switchError, "switch");
  }
};
