import React from 'react';
import axios from 'axios';
import cn from 'classnames';

// Correct the import paths
import Circle from '../circle';
import Icon_loading from '../icons/loading';
import Icon_check from '../icons/check';
import Icon_close from '../icons/close';
import Icon_copy from '../icons/copy';
import '../styles/pages/audit.module.css';
// CONFIG
import config from '../config';

// CONTEXT
import { Context } from '../context';

// UTILS
import { str_copy } from '../utils/index';

// STYLES
import style from '../styles/pages/audit.module.css';

class Audit extends React.Component {
  static contextType = Context;

  constructor(props) {
    super(props);
    this.state = {
      address: '',
      loading: false,
      chains_selector_open: false,
      token_chain_id: 1,
      token_address: '',
      result: null,
      result_desc: '',
      result_score: 0,
      result_analytics: [],
      result_info: [],
      circle_key: 0,
    };

    this.CHAINS = {
      1: { name: 'Ethereum', img: '/images/ethereum.png' },
      20: { name: 'Elastos', img: '/images/esc.svg' },
      10: { name: 'Optimism', img: '/images/optimism.png' },
      25: { name: 'Cronos', img: '/images/cronos.png' },
      56: { name: 'BNB', img: '/images/bnb.png' },
      100: { name: 'Gnosis', img: '/images/gnosis.png' },
      137: { name: 'Polygon', img: '/images/polygon.png' },
      250: { name: 'Fantom', img: '/images/fantom.png' },
      42161: { name: 'Arbitrum', img: '/images/arbitrum.png' },
      43114: { name: 'Avalanche', img: '/images/avalanche.png' },
    };

    this.on_click_audit = this.on_click_audit.bind(this);
    this.ref_button = React.createRef();
    this.ref_warningbar = React.createRef();
    this.ref_passedbar = React.createRef();
  }

  async on_click_audit(address, chain_id) {
    if (this.state.loading) return;

    this.setState({ loading: true });

    const url_query = `?contract_addresses=${address}`;
    const url = `https://api.gopluslabs.io/api/v1/token_security/${chain_id}${url_query}`;

    try {
      const res = await axios.get(url);

      if (!res.data.result || !res.data.result[address.toLowerCase()]) {
        const errorMessage = res.data.message || 'Chain might be wrong for this token address';
        this.context.set_state({
          ...this.context.state,
        });
        this.setState({ loading: false });
        return;
      }

      const result = res.data.result[address.toLowerCase()];
      const analytics = this.generateAnalytics(result);
      const info = this.generateInfo(result, address);

      const score = this.calculateScore(analytics);
      const desc = this.generateDescription(score, analytics.length);

      this.updateProgressBar(score);

      this.setState({
        loading: false,
        result,
        result_desc: desc,
        result_score: score,
        result_analytics: analytics,
        result_info: info,
        circle_key: this.state.circle_key + 1,
      });

      this.animateItems(analytics, info);

      const url_audit_create = `${config.url_api}/v1/audits`;
      await axios.post(url_audit_create, {
        address,
        chain_id,
        name: result.token_name,
        symbol: result.token_symbol,
      });
    } catch (error) {
      console.error('Audit request failed:', error);
      this.setState({ loading: false });
    }
  }

  generateAnalytics(result) {
    const analytics = [];
    const fields = [
      { key: 'is_anti_whale', trueDesc: 'Token has anti whale protection', falseDesc: "Token doesn't have anti whale protection" },
      { key: 'is_blacklisted', trueDesc: 'Token is blacklisted', falseDesc: 'Token is not blacklisted' },
      { key: 'is_honeypot', trueDesc: 'Token have honeypot', falseDesc: "Token doesn't have honeypot" },
      { key: 'is_in_dex', trueDesc: 'Token is in dex', falseDesc: 'Token is not in dex' },
      { key: 'is_mintable', trueDesc: 'Token have a mint function', falseDesc: 'No mint function found' },
      { key: 'is_open_source', trueDesc: "Token's source code is open", falseDesc: "Token's source code is not open" },
      { key: 'is_proxy', trueDesc: 'Token is proxy', falseDesc: 'Token is not a proxy' },
      { key: 'is_whitelisted', trueDesc: 'Token is whitelisted', falseDesc: 'Token is not whitelisted' },
    ];

    fields.forEach(({ key, trueDesc, falseDesc }) => {
      analytics.push({
        desc: result[key] === '1' ? trueDesc : falseDesc,
        passed: result[key] === '1',
        animation: false,
      });
    });

    return analytics;
  }

  generateInfo(result, address) {
    return [
      { key: 'Token Address', value: this.truncateAddress(address), on_click_icon: () => this.copyToClipboard(address), icon: <Icon_copy /> },
      { key: 'Buy Tax', value: result.buy_tax },
      { key: 'Sell Tax', value: result.sell_tax },
      { key: 'Creator Address', value: this.truncateAddress(result.creator_address), on_click_icon: () => this.copyToClipboard(result.creator_address), icon: <Icon_copy /> },
      { key: 'Anti Whale Modifiable', value: result.anti_whale_modifiable === '1' ? 'Yes' : 'No' },
      { key: 'Creator Balance', value: result.creator_balance },
      { key: 'Cannot Sell All', value: result.cannot_sell_all === '1' ? 'Yes' : 'No' },
      { key: 'Owner Balance', value: result.owner_balance },
      { key: 'Hidden Owner', value: result.hidden_owner === '1' ? 'Yes' : 'No' },
      { key: 'Slippage Modifiable', value: result.slippage_modifiable === '1' ? 'Yes' : 'No' },
      { key: 'Can Take Back Ownership', value: result.can_take_back_ownership === '1' ? 'Yes' : 'No' },
    ];
  }

  calculateScore(analytics) {
    const point = 100 / analytics.length;
    let score = 0;
    analytics.forEach((item) => {
      if (item.passed) score += point;
    });
    return score;
  }

  generateDescription(score, total) {
    return `The score of this contract address is ${parseInt(
      score
    )}% out of 100. Upon detailed examination, ${
      (100 - score) / (100 / total)
    } important issues were discovered. You can get information about these issues and get a service offer by contacting Devchain.net!`;
  }

  updateProgressBar(score) {
    const passedbar_width = (score / 100) * 100;
    const warningbar_width = 100 - passedbar_width;

    if (this.ref_warningbar.current) {
      this.ref_warningbar.current.style.width = `${warningbar_width}%`;
    }
    if (this.ref_passedbar.current) {
      this.ref_passedbar.current.style.width = `${passedbar_width}%`;
    }
  }

  animateItems(analytics, info) {
    analytics.forEach((_, i) => {
      setTimeout(() => {
        analytics[i].animation = true;
        this.setState({ result_analytics: analytics });
      }, (i + 1) * 200);
    });

    info.forEach((_, i) => {
      setTimeout(() => {
        info[i].animation = true;
        this.setState({ result_info: info });
      }, (i + 1) * 200);
    });
  }

  truncateAddress(address) {
    return `${address.slice(0, 6)}...`;
  }

  async copyToClipboard(text) {
    await str_copy(text);
    this.context.set_state({
      ...this.context.state,

    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.address !== prevProps.address) {
      this.on_click_audit(this.props.address, this.props.chainId);
    }
  }

  render() {
    return (
      <div className={cn(style['audit'])}>
        <div className={cn(style['audit-header'])}>
          <div className={cn(style['audit-header-options'])}>
            <div
              onClick={() => {
                if (!this.state.chains_selector_open) {
                  this.setState({
                    chains_selector_open: !this.state.chains_selector_open,
                  });
                }
              }}
              className={cn(style['audit-header-options-chain'])}
            >
              <img
                loading="lazy"
                decoding="async"
                src={this.CHAINS[this.state.token_chain_id].img}
                alt={this.CHAINS[this.state.token_chain_id].name}
                title={this.CHAINS[this.state.token_chain_id].name}
                className={cn(style['audit-header-options-chain-img'])}
              />

              <div
                className={cn(
                  style['audit-header-options-chain-chains'],
                  this.state.chains_selector_open
                    ? style['audit-header-options-chain-chainsopen']
                    : null
                )}
              >
                {Object.values(this.CHAINS).map((current, index) => (
                  <div
                    key={index}
                    onClick={() => {
                      this.setState({
                        token_chain_id: Number(Object.keys(this.CHAINS)[index]),
                        chains_selector_open: false,
                      });
                    }}
                    className={cn(
                      style['audit-header-options-chain-chains-item']
                    )}
                  >
                    <img
                      src={current.img}
                      className={cn(
                        style['audit-header-options-chain-chains-item-img']
                      )}
                    />
                    <div
                      className={cn(
                        style['audit-header-options-chain-chains-item-name']
                      )}
                    >
                      {current.name}
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <input
              className={cn(style['audit-header-options-input'])}
              value={this.state.token_address}
              onChange={(e) => {
                this.setState({ token_address: e.target.value });
              }}
              placeholder="0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce"
            />

            <button
              ref={this.ref_button}
              onClick={() =>
                this.on_click_audit(
                  this.state.token_address,
                  this.state.token_chain_id
                )
              }
              className={cn(
                style['audit-header-options-audit'],
                this.state.loading
                  ? style['audit-header-options-auditdisabled']
                  : null
              )}
            >
              {this.state.loading ? (
                <div
                  className={cn(
                    style['audit-header-options-audit-loadingicon']
                  )}
                >
                  <Icon_loading />
                </div>
              ) : (
                'AUDIT'
              )}
            </button>
          </div>
        </div>

        <div className={cn(style['audit-body'])}>
          <div className={cn(style['audit-body-token'])}>
            <div className={cn(style['audit-body-token-left'])}>
              <h2
                className={cn(
                  style['audit-body-token-left-name'],
                  !this.state.result
                    ? style['audit-body-token-left-nameplaceholder']
                    : null
                )}
              >
                {this.state.result ? this.state.result.token_name : null}{' '}
                {this.state.result
                  ? `(${this.state.result.token_symbol})`
                  : null}
              </h2>

              <div className={cn(style['audit-body-token-left-address'])}></div>
            </div>

            <div
              className={cn(
                style['audit-body-token-right'],
                !this.state.result
                  ? style['audit-body-token-rightplaceholder']
                  : null
              )}
            >
              {this.state.result ? (
                <>
                  <span>Holders: </span> {this.state.result.holder_count}
                </>
              ) : null}
            </div>
          </div>

          <div className={cn(style['audit-body-score'])}>
            <div className={cn(style['audit-body-score-left'])}>
              <Circle
                c={200}
                data={this.state.result_score}
                key={this.state.circle_key}
              />
            </div>

            <div className={cn(style['audit-body-score-right'])}>
              {!this.state.result ? (
                <div
                  className={cn(style['audit-body-score-right-placeholder'])}
                >
                  <div
                    className={cn(
                      style['audit-body-score-right-placeholder-textmedium']
                    )}
                  ></div>
                  <div
                    className={cn(
                      style['audit-body-score-right-placeholder-textsmall']
                    )}
                  ></div>
                  <div
                    className={cn(
                      style['audit-body-score-right-placeholder-textlarge']
                    )}
                  ></div>
                  <div
                    className={cn(
                      style['audit-body-score-right-placeholder-textmedium']
                    )}
                  ></div>
                  <div
                    className={cn(
                      style['audit-body-score-right-placeholder-textmedium']
                    )}
                  ></div>
                  <div
                    className={cn(
                      style['audit-body-score-right-placeholder-textlarge']
                    )}
                  ></div>
                  <div
                    className={cn(
                      style['audit-body-score-right-placeholder-textsmall']
                    )}
                  ></div>
                  <div
                    className={cn(
                      style['audit-body-score-right-placeholder-textlarge']
                    )}
                  ></div>
                </div>
              ) : (
                this.state.result_desc
              )}
            </div>
          </div>

          <div className={cn(style['audit-body-bars'])}>
            <label className={cn(style['audit-body-bars-warninglabel'])}>
              Warnings
            </label>
            <div className={cn(style['audit-body-bars-warning'])}>
              <div
                ref={this.ref_warningbar}
                className={cn(style['audit-body-bars-warning-bar'])}
              ></div>
            </div>
            <label className={cn(style['audit-body-bars-passedlabel'])}>
              Passed
            </label>
            <div className={cn(style['audit-body-bars-passed'])}>
              <div
                ref={this.ref_passedbar}
                className={cn(style['audit-body-bars-passed-bar'])}
              ></div>
            </div>
          </div>

          <div className={cn(style['audit-body-analytics'])}>
            <div className={cn(style['audit-body-analytics-title'])}>
              Analytics
            </div>
            <div className={cn(style['audit-body-analytics-list'])}>
              {!this.state.result_analytics.length ? (
                <>
                  <div
                    className={cn(
                      style['audit-body-analytics-itemplaceholder']
                    )}
                  >
                    <div
                      className={cn(
                        style['audit-body-analytics-item-iconplaceholder']
                      )}
                    ></div>
                    <div
                      className={cn(
                        style['audit-body-analytics-item-descplaceholder']
                      )}
                    ></div>
                  </div>
                  <div
                    className={cn(
                      style['audit-body-analytics-itemplaceholder']
                    )}
                  >
                    <div
                      className={cn(
                        style['audit-body-analytics-item-iconplaceholder']
                      )}
                    ></div>
                    <div
                      className={cn(
                        style['audit-body-analytics-item-descplaceholder']
                      )}
                    ></div>
                  </div>
                  <div
                    className={cn(
                      style['audit-body-analytics-itemplaceholder']
                    )}
                  >
                    <div
                      className={cn(
                        style['audit-body-analytics-item-iconplaceholder']
                      )}
                    ></div>
                    <div
                      className={cn(
                        style['audit-body-analytics-item-descplaceholder']
                      )}
                    ></div>
                  </div>
                </>
              ) : (
                this.state.result_analytics.map((current, index) => (
                  <div
                    key={index}
                    className={cn(
                      style['audit-body-analytics-item'],
                      current.animation
                        ? style['audit-body-analytics-itemanimation']
                        : null
                    )}
                  >
                    <div
                      className={cn(
                        style['audit-body-analytics-item-icon'],
                        current.passed
                          ? style['audit-body-analytics-item-iconpassed']
                          : null
                      )}
                    >
                      {current.passed ? <Icon_check /> : <Icon_close />}
                    </div>
                    <div className={cn(style['audit-body-analytics-item-desc'])}>
                      {current.desc}
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>

          <div className={cn(style['audit-body-info'])}>
            <div className={cn(style['audit-body-info-title'])}>Token info</div>
            <div className={cn(style['audit-body-info-list'])}>
              {!this.state.result_info.length ? (
                <>
                  <div className={cn(style['audit-body-info-itemplaceholder'])}>
                    <div
                      className={cn(
                        style['audit-body-info-item-keyplaceholder']
                      )}
                    ></div>
                    <div
                      className={cn(
                        style['audit-body-info-item-valueplaceholder']
                      )}
                    ></div>
                  </div>
                  <div className={cn(style['audit-body-info-itemplaceholder'])}>
                    <div
                      className={cn(
                        style['audit-body-info-item-keyplaceholder']
                      )}
                    ></div>
                    <div
                      className={cn(
                        style['audit-body-info-item-valueplaceholder']
                      )}
                    ></div>
                  </div>
                </>
              ) : (
                this.state.result_info.map((current, index) => (
                  <div
                    key={index}
                    className={cn(
                      style['audit-body-info-item'],
                      current.animation
                        ? style['audit-body-info-itemanimation']
                        : null
                    )}
                  >
                    <div className={cn(style['audit-body-info-item-key'])}>
                      {current.key}
                    </div>
                    <div className={cn(style['audit-body-info-item-value'])}>
                      <div
                        className={cn(
                          style['audit-body-info-item-value-icon']
                        )}
                        onClick={current.on_click_icon}
                      >
                        {current.icon}
                      </div>
                      {current.value}
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Audit;
