import React, { useState, useEffect } from 'react';
import BunnyFlip from './BunnyFlip';
import { CONTRACT_ABI, CONTRACT_ADDRESS } from './contractInfo';
import { ConnectButton, getDefaultWallets } from '@rainbow-me/rainbowkit';
import { WagmiConfig, createClient, configureChains, chain, useAccount, useContract, useProvider, useSigner } from 'wagmi';
import { publicProvider } from 'wagmi/providers/public';
import { ethers } from 'ethers';
import { jsonRpcProvider } from "wagmi/providers/jsonRpc";

const elastosChain = {
  id: 20,
  name: "Elastos Smart Chain",
  network: "elastos",
  nativeCurrency: {
    name: "ELA",
    symbol: "ELA",
    decimals: 18,
  },
  rpcUrls: {
    default: "https://rpc.glidefinance.io",
  },
  blockExplorers: {
    default: { name: "Elastos Explorer", url: "https://esc.elastos.io/" },
  },
  testnet: false,
};

const { chains, provider } = configureChains(
  [elastosChain],
  [
    jsonRpcProvider({
      rpc: (chain) => ({ http: chain.rpcUrls.default }),
    }),
    publicProvider(),
  ]
);

const { connectors } = getDefaultWallets({
  appName: "Bunny - Mega Hub on Elastos Smart Chain",
  projectId: "2c81428fbd056eb72fe3c35dd9970e72", // Replace with your project ID
  chains,
});

 

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
});

function Dapps() {
  const { address, isConnected } = useAccount();
  const provider = useProvider();
  const { data: signer } = useSigner();
  const [contract, setContract] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (isConnected && signer) {
      const bunnyFlipContract = new ethers.Contract(CONTRACT_ADDRESS, CONTRACT_ABI, signer);
      setContract(bunnyFlipContract);
    }
  }, [isConnected, signer]);

  return (
    <WagmiConfig client={wagmiClient}>
    <div className="container-lg p-0">
        {error && <div>Error: {error}</div>}
          <BunnyFlip contract={contract} />
 
      </div>
    </WagmiConfig>
  );
}

export default Dapps;
