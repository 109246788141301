import React, { useState, useEffect } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { useAccount, useConnect, useDisconnect, useNetwork, useSwitchNetwork } from "wagmi";
import Locker from "./components/locker";
import Admin from "./components/admin";
import News from "./components/news/news";
import Sidebar from "./components/sidebar/sidebar";
import Header from "./components/header/header";
import { RedirectPathToHomeOnly } from "./functions/redirects";
import Dashboard from "./components/dashboard/Dashboard";
import Launchpad from "./components/launchpad/Launchpad";
import LaunchpadForm from "./components/launchpad/launchpadform/LaunchpadForm";
import LaunchpadDetails from "./components/launchpad/launchpaddetails/LaunchpadDetails";
import TierLevels from "./components/launchpad/tierlevels/TierLevels";
import Footer from "./components/Footer/footer";
import BuyDyp from "./components/buydyp/BuyDyp";
import MobileMenu from "./components/sidebar/MobileMenu";
import ScrollToTop from "./functions/ScrollToTop";
import AlertRibbon from "./components/alert-ribbon/AlertRibbon";
import EarnOtherNft from "./components/earnOther/EarnOtherNft";
import Mint from "./components/mint/App";
import Mint2 from "./components/mint/App2";
import AppDomain from "./components/domain/App";
import AppFlip from "./components/coinflip/App";
import AppLottery from "./components/lottery/App";
import AuditPage from "./components/Audit/pages/audit";
import AppLottery2 from "./components/lottery copy/App";
import Account from "./components/account";
import Earn from "./components/earn/Earn";
import EarnInnerPoolNft from "./components/earnOther/EarnInnerPool/EarnInnerPoolNft";
import Root from "./components/staking/Root";
import Dapps from "./components/dapps/App";

const App = (props) => {
  const { address, isConnected, connector } = useAccount();
  const { connect } = useConnect();
  const { disconnect } = useDisconnect();
  const { chain } = useNetwork();
  const { switchNetwork } = useSwitchNetwork();

  const [state, setState] = useState({
    theme: "theme-dark",
    ethPrice: "...",
    gasPrice: "...",
    isMinimized: false && window.innerWidth >= 992,
    isOpenInMobile: false,
    isConnected: false,
    chainId: undefined,
    coinbase: null,
    windowWidth: 0,
    windowHeight: 0,
    subscribedPlatformTokenAmount: "...",
    isPremium: null,
    hotPairs: [],
    networkId: 20,
    explorerNetworkId: 20,
    show: false,
    referrer: "",
    showRibbon: true,
    showRibbon2: true,
    showWalletPopup: false,
    aggregatorPools: [],
    userCurrencyBalance: 0,
  });

  useEffect(() => {
    const updateWindowDimensions = () => {
      setState((prevState) => ({
        ...prevState,
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight,
      }));
    };

    const handleResize = () => {
      updateWindowDimensions();
    };

    window.addEventListener("resize", handleResize);
    updateWindowDimensions();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (isConnected) {
      setState((prevState) => ({
        ...prevState,
        isConnected: true,
        coinbase: address,
        chainId: chain?.id,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        isConnected: false,
        coinbase: null,
        chainId: undefined,
      }));
    }
  }, [isConnected, address, chain]);

  useEffect(() => {
    const savedChainId = localStorage.getItem("selectedChainId");
    if (savedChainId && switchNetwork) {
      switchNetwork(parseInt(savedChainId, 10));
    }
  }, [switchNetwork]);

  const handleConnection = async () => {
    const InjectedConnector = new InjectedConnector({ chains: [chain] });
    try {
      await connect({ connector: InjectedConnector });
    } catch (error) {
      console.error("Failed to connect", error);
    }
  };

  const handleSwitchNetwork = async (chainId) => {
    if (connector) {
      try {
        await connector.switchChain(chainId);
        setState((prevState) => ({
          ...prevState,
          chainId: chainId,
        }));
        localStorage.setItem("selectedChainId", chainId);
      } catch (error) {
        console.error("Failed to switch chain", error);
      }
    } else if (switchNetwork) {
      try {
        await switchNetwork(chainId);
        setState((prevState) => ({
          ...prevState,
          chainId: chainId,
        }));
        localStorage.setItem("selectedChainId", chainId);
      } catch (error) {
        console.error("Failed to switch chain", error);
      }
    }
  };

  const showModal = () => {
    setState((prevState) => ({ ...prevState, show: true }));
  };

  const hideModal = () => {
    setState((prevState) => ({ ...prevState, show: false }));
  };

  const toggleMinimizeSidebar = () => {
    const f = () => window.dispatchEvent(new Event("resize"));
    setState(
      (prevState) => ({ ...prevState, isMinimized: !prevState.isMinimized }),
      () => f()
    );
    f();
    let newInterval = setInterval(f, 16);
    setTimeout(() => clearInterval(newInterval), 1000);
  };

  const toggleMobileSidebar = () => {
    setState((prevState) => ({
      ...prevState,
      isOpenInMobile: !prevState.isOpenInMobile,
    }));
  };

  const renderRoutes = () => (
    <Switch>
      <Route exact path="/mint" render={(props) => <Mint />} />
      <Route exact path="/apr" render={(props) => <Mint2 />} />

      <Route exact path="/lottery">
        {chain?.id === 20 ? (
          <AppLottery />
        ) : chain?.id === 32659 ? (
          <AppLottery2 />
        ) : (
          <AppLottery />
        )}
      </Route>
      <Route exact path="/domain" render={(props) => <AppDomain />} />
      <Route exact path="/coinflip" render={(props) => <AppFlip />} />
      <Route exact path="/dapps" render={(props) => <Dapps />} />
      <Route exact path="/audit" render={() => <AuditPage />} />
      <Route exact path="/launchpad" render={() => <Launchpad />} />
      <Route
        exact
        path="/launchpad/details/:id"
        render={() => <LaunchpadDetails />}
      />
      <Route
        exact
        path="/earn/nft-staking"
        render={() => <EarnOtherNft type={"nft"} />}
      />
      <Route
        exact
        path="/account"
        render={() => (
          <Account
            appState={state}
            theme={state.theme}
            networkId={parseInt(state.networkId)}
            handleSwitchNetwork={handleSwitchNetwork}
            coinbase={state.coinbase}
            isConnected={state.isConnected}
            isPremium={state.isPremium}
            showRibbon={state.showRibbon2}
          />
        )}
      />


{/* <Route
        exact
        path="/s"
        render={() => (
          <Root
          />
        )}
      /> */}
 
 

      <Route
        exact
        path="/earn/dypius"
        render={() => (
          <Earn
          />
        )}
      />
      <Route exact path="/launchpad/form" render={() => <LaunchpadForm />} />
      <Route exact path="/launchpad/tiers" render={() => <TierLevels />} />
      <Route exact path="/buybunny" render={() => <BuyDyp />} />
      <Route exact path="/" render={() => <Dashboard />} />
      <Route exact path="/news/:news_id?" render={(props) => <News />} />
      <Route exact path="/locker/:pair_id?" render={(props) => <Locker />} />
      <Route exact path="/admin" render={(props) => <Admin />} />
      <Route component={RedirectPathToHomeOnly} />
    </Switch>
  );

  return (
    <div className={`page_wrapper ${state.isMinimized ? "minimize" : ""}`}>
      {state.showRibbon && (
        <AlertRibbon
          onClose={() =>
            setState((prevState) => ({ ...prevState, showRibbon: false }))
          }
          onComplete={() => {
            setState((prevState) => ({
              ...prevState,
              showRibbon: false,
              showRibbon2: false,
            }));
          }}
        />
      )}
      <div>
        {(props.location.pathname === "/st" && window.innerWidth < 786) ||
        (props.location.pathname === "/st" &&
          window.innerWidth < 786) ? null : (
          <Header
            coinbase={state.coinbase}
            theme={state.theme}
            toggleMobileSidebar={toggleMobileSidebar}
            isOpenInMobile={state.isOpenInMobile}
            chainId={parseInt(state.networkId)}
            logout={disconnect}
            handleSwitchNetwork={handleSwitchNetwork}
            handleConnection={handleConnection}
            showModal={showModal}
            hideModal={hideModal}
            show={state.show}
            isConnected={state.isConnected}
            appState={state}
            onSetCurrencyAmount={(value) =>
              setState((prevState) => ({
                ...prevState,
                userCurencyBalance: value,
              }))
            }
          />
        )}
        <div className="content-wrapper container-fluid d-flex justify-content-center justify-content-lg-start">
          <div className="row w-100">
            <div className="col-1">
              <Sidebar
                appState={state}
                theme={state.theme}
                isConnected={state.isConnected}
                toggleMobileSidebar={toggleMobileSidebar}
                isOpenInMobile={state.isOpenInMobile}
                showModal={showModal}
                hideModal={hideModal}
                show={state.show}
                checkConnection={handleConnection}
                isPremium={state.isPremium}
                network={state.networkId}
                showRibbon={state.showRibbon}
              />
            </div>
            <div
              className={`${
                state.windowWidth < 991
                  ? "col-12 px-1"
                  : state.windowWidth < 1490
                  ? "col-11"
                  : "col-10"
              }`}
            >
              <div className="right-content pr-0 my-4 my-lg-5">
                <ScrollToTop />
                {renderRoutes()}
              </div>
            </div>
            <div className="col-1"></div>
          </div>
          {props.location.pathname === "/st" ||
          props.location.pathname === "/st" ? null : (
            <MobileMenu />
          )}
        </div>
      </div>
      {(props.location.pathname === "/st" && window.innerWidth < 786) ||
      (props.location.pathname === "/st" && window.innerWidth < 786) ? null : (
        <Footer />
      )}
    </div>
  );
};

export default withRouter(App);
