import { AppBar, Toolbar, Box, Button, SvgIcon } from "@material-ui/core";
import ConnectMenu from "./ConnectMenu.js";
import styled from 'styled-components'
import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import { GiHamburgerMenu } from 'react-icons/gi'
import "./topbar.scss";


function TopBar({ setNotification }) {
  const [hamburgeropen, setHamburgerOpen] = useState(false);

  const dialog = useRef();
  const hamburger = useRef();

  useEffect(() => {
    document.addEventListener('mouseup', function (event) {
      if (dialog.current && !dialog.current.contains(event.target) && !hamburger.current.contains(event.target)) {
        setHamburgerOpen(false);
      }
    });
  }, []);

  useEffect(() => {
    console.log(hamburgeropen)
  }, [hamburgeropen])
  return (
    <StyledContainer className="header-menu-div" >
      <Box display={'flex'} justifyContent={'space-between'} pr={'16px'} pl={'16px'}>
        <Box display={'flex'} alignItems={'center'}>
          <Box display={'flex'} alignItems={'center'}>
            <Box mr={'10px'} mt={'8px'}>
              <img src={'/logo.svg'} width={'60px'} height={'100%'} alt={'logo'} />
            </Box>
            <Menus className="contents-menu">
              <Link className="link-a staking-bottom" to={'/liquidity'} >ADD LIQUIDITY</Link>
            </Menus>
          </Box>
        </Box>
        <Box display={'flex'} alignItems={'center'}>
          <Box display={'flex'} alignItems={'center'} height={'64px'} >
            <ConnectMenu setNotification={setNotification} />
            <Hamburger onClick={() => setHamburgerOpen(!hamburgeropen)} ref={hamburger}>
              <GiHamburgerMenu />
            </Hamburger>
          </Box>
        </Box>
      </Box>
      <div ref={dialog}>
        <HamburgerMenu className="menu-topen-2" width={'100%'} open={hamburgeropen}>
          <Menus className="opened-menu-h" open={hamburgeropen}>
            <a target={'_blank'} href="https://bunnypunk.io/" className="link-a staking-bottom">HOME</a>
            <Box><Link className="link-a staking-bottom" to={'/farms'} style={{ textDecoration: 'unset' }}>Farms</Link></Box>
            <a target={'_blank'} href="https://stakev2.bunnypunk.io/" className="link-a staking-bottom">NFT STAKING</a>
            <a target={'_blank'} href="https://bunnypunk.io/litepaper" className="link-a staking-bottom">LITEPAPER</a>
            <a target={'_blank'} href="https://snapshot.org/#/bunnypunk.eth" className="link-a staking-bottom">GOVERNANCE</a>
            <Link className="link-a staking-bottom" to={'/liquidity'} >ADD LIQUIDITY</Link>

          </Menus>
        </HamburgerMenu>
      </div>
    </StyledContainer >
  );
}
const StyledContainer = styled(Box)`
    width : 100%;
    padding : 0 20px;
    @media screen and (max-width : 450px){
      padding : 0;
    }
    @media screen and (max-width : 1175px){
        >div:nth-child(1)>div:nth-child(1)>div{
            justify-content : start;
            >div{
                width : fit-content;
                margin-left : 10px;
            }
        }
    }
    position : fixed;
    top : 0;
    z-index : 10;
`;

const Menus = styled(Box)`
    display : flex;
    justify-content : space-evenly;
    width : 100%;
    max-width : 100%;
    >div{
      position: relative;
      display: flex;
      align-items: center;
        height: auto;
        padding: 0px 16px;
        cursor : pointer;
        >a {
          font-size : 16px;
          font-weight: 400;
        }
        &:hover {
          background: transparent;
          border-radius: 16px;
      }
    }
    
    @media screen and (max-width : 1175px){
        display : ${({ open }) => open ? '' : 'none'};
        align-items : center;
        flex-direction : column;
        max-width : unset;
        font-size : 16px;
        >div{
            padding : 5px;
        }
    }
`;
const Hamburger = styled.div`
    font-size : 24px;
    color: #f6e7c9;
    text-shadow: 1px 1px 15px rgb(211 104 44 / 76%);
    margin-top : 7px;
    margin-left : 20px;
    cursor : pointer;
    display : none;
    @media screen and (max-width : 1175px){
        display : unset;
    }
`;

const HamburgerMenu = styled(Box)`
    transition : all 0.3s;
    height : ${({ open }) => open ? '110px' : '0'};
    overflow : hidden;
    @media screen and (min-width : 1175px){
        display : none;
    }
`;
export default TopBar;
