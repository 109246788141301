import React from "react";
import Web3 from "web3";
import getFormattedNumber from "../../functions/get-formatted-number";
import { NavLink } from "react-router-dom";
import Error from "../../assets/error.svg";
import Placeholder from "../../assets/person.svg";
import "./account.css";
import NftCawCard from "../caws/NftMinting/components/General/NftCawCard/NftCawCard";
import TierLevels from "../launchpad/tierlevels/TierLevels";
import coinStackIcon from "../launchpad/assets/coinStackIcon.svg";
import axios from "axios";
import openNameChange from "./assets/openNameChange.svg";
import { ClickAwayListener, Tooltip } from "@mui/material";
import { shortAddress } from "../../functions/shortAddress";
import TopPoolsCard from "../top-pools-card/TopPoolsCard";
import useWindowSize from "../../functions/useWindowSize";
import gotoWod from "./assets/gotoWod.svg";
import { useHistory } from "react-router-dom";
import ClaimRewards2 from "../FARMINNG/claimRewards";
import ClaimRewards from "../customNFTSTAKING/claimRewards2";
import ClaimRewards3 from "../customNFTSTAKING/claimRewards3";
import ClaimRewards4 from "../customNFTSTAKING/claimRewards4";
import ClaimRewards5 from "../customNFTSTAKING/claimRewards5";
import ClaimRewards6 from "../customNFTSTAKING/claimRewards6";

const { BigNumber } = window;

export default class Subscription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      coinbase: "",
      networkId: 0,
      selectedSubscriptionToken: Object.keys(
        window.config.subscription_tokens
      )[0],
      tokenBalance: "",
      price: "",
      formattedPrice: 0.0,
      favorites: [],
      favoritesETH: [],
      selectedFile: null,
      image: Placeholder,
      lockActive: false,
      status: "",
      loadspinner: false,
      loadspinnerSub: false,
      loadspinnerSave: false,
      loadspinnerRemove: false,
      showSavebtn: false,
      showRemovebtn: false,
      subscribe_now: false,
      usdtAddress: "0xdac17f958d2ee523a2206206994597c13d831ec7",
      usdteAddress: "0xc7198437980c041c805a1edcba50c1ce5db95118",
      triggerText: "See more V",
      isApproved: false,
      viewall: false,
      username: "",
      userNameInput: "",
      showInput: false,
      openTooltip: false,
      dypBalance: "0.0",
      userPools: [],
      ethStake: [],
      bnbStake: [],
      avaxStake: [],
      ethFarm: [],
      bscFarm: [],
      avaxFarm: [],
      ethBalance: "0.0",
      bnbBalance: "0.0",
      avaxBalance: "0.0",
      balance: null,
      loading: true,
    };
  }

  fetchUserPools = async () => {
    if (this.props.coinbase && this.props.coinbase.includes("0x")) {
      const result = await axios
        .get(`https://api.dyp.finance/api/user_pools/${this.props.coinbase}`)
        .then((data) => {
          return data.data.PoolsUserIn;
        });
      this.setState({ userPools: result });
    }
  };

  fetchfavData() {
    window
      .getFavoritesETH()
      .then((favorites) => {
        this.setState({ favoritesETH: favorites });
      })
      .catch(console.error);
    if (this.props.networkId === 20) {
      this.setState({
        selectedSubscriptionToken: Object.keys(
          window.config.subscriptioneth_tokens
        )[0],
      });
    } else if (this.props.networkId !== 20) {
      this.setState({
        selectedSubscriptionToken: Object.keys(
          window.config.subscription_tokens
        )[0],
      });
    }
    window
      .getFavorites()
      .then((favorites) => {
        this.setState({ favorites: favorites });
      })
      .catch(console.error);
  }

  fetchUsername = async () => {
    if (this.props.coinbase) {
      await axios
        .get(
          `https://api-image.dyp.finance/api/v1/username/${this.props.coinbase}`
        )
        .then((res) => {
          if (res.data?.username) {
            this.setState({ username: res.data?.username });
          } else {
            this.setState({ username: "Dypian" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  postUsername = async (userInput) => {
    const usernameData = {
      username: userInput,
    };

    await axios
      .post(
        `https://api-image.dyp.finance/api/v1/username/${this.props.coinbase}`,
        usernameData
      )
      .then((res) => {
        this.setState({ username: res.data?.username });
        this.fetchUsername();
        this.setState({ userNameInput: "", showInput: false });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  componentDidUpdate(prevProps) {
    if (this.props.isPremium === false) {
      window.location.href = "/plans";
    }

    if (this.props.coinbase !== prevProps.coinbase) {
      this.fetchUserPools();
      this.fetchAvatar();
      this.fetchUsername();
      this.fetchUserPools();
    }

    if (this.props.networkId !== prevProps.networkId) {
      if (this.props.networkId === 43114) {
        this.handleSubscriptionTokenChange(this.state.usdteAddress);
      } else if (this.props.networkId === 20) {
        this.handleSubscriptionTokenChange(this.state.usdtAddress);
      }
    }
  }

  componentDidMount() {
    if (this.props.isPremium === false) {
      window.location.href = "/plans";
    }

    this.fetchUserPools();
    this.fetchAvatar();
    this.fetchUsername();
    this.fetchUserPools();
    this.fetchBalance();
    this.setState({ coinbase: this.props.coinbase });

    window.scrollTo(0, 0);
  }

  fetchBalance = async () => {
    try {
      const response = await axios.get(
        "https://esc.elastos.io/api?module=account&action=tokenbalance&contractaddress=0x75740FC7058DA148752ef8a9AdFb73966DEb42a8&address=0x9A754044FbfA95d15b252453c1BB5401320A8386"
      );
      if (response.data.status === "1") {
        this.setState({ balance: response.data.result, loading: false });
      } else {
        console.error("Error fetching balance");
        this.setState({ loading: false });
      }
    } catch (error) {
      console.error("Error fetching balance:", error);
      this.setState({ loading: false });
    }
  };

  handleSubscriptionTokenChange = async (tokenAddress) => {
    const token =
      this.props.networkId === 20
        ? this.state.usdtAddress
        : this.state.usdteAddress;
    let tokenDecimals =
      this.props.networkId === 20
        ? window.config.subscriptioneth_tokens[token]?.decimals
        : window.config.subscription_tokens[token]?.decimals;
    this.setState({
      selectedSubscriptionToken: token,
      tokenBalance: "",
      formattedPrice: "",
      price: "",
    });

    let price =
      this.props.networkId === 20
        ? await window.getEstimatedTokenSubscriptionAmountETH(token)
        : await window.getEstimatedTokenSubscriptionAmount(token);
    price = new BigNumber(price).times(1.1).toFixed(0);

    let formattedPrice = getFormattedNumber(
      price / 10 ** tokenDecimals,
      tokenDecimals
    );
    let tokenBalance = await window.getTokenHolderBalance(
      token,
      this.props.coinbase
    );
    this.setState({ price, formattedPrice, tokenBalance });
  };

  handleCheckIfAlreadyApproved = async () => {
    const web3eth = new Web3(
      "https://mainnet.infura.io/v3/94608dc6ddba490697ec4f9b723b586e"
    );

    const ethsubscribeAddress = window.config.subscriptioneth_address;
    const avaxsubscribeAddress = window.config.subscription_address;
  };

  handleSubscribe = async (e) => {
    e.preventDefault();

    let subscriptionContract = await window.getContract({
      key: this.props.networkId === 20 ? "SUBSCRIPTIONETH" : "SUBSCRIPTION",
    });

    this.setState({ loadspinnerSub: true });

    await subscriptionContract.methods
      .subscribe(this.state.selectedSubscriptionToken, this.state.price)
      .send({ from: await window.getCoinbase() })
      .then(() => {
        this.setState({ loadspinnerSub: false });
      })
      .catch((e) => {
        this.setState({ status: "An error occurred. Please try again" });
        this.setState({ loadspinnerSub: false });
      });
  };

  handleUnsubscribe = async (e) => {
    e.preventDefault();
    let subscriptionContract = await window.getContract({
      key: this.props.networkId === 20 ? "SUBSCRIPTIONETH" : "SUBSCRIPTION",
    });
    await subscriptionContract.methods
      .unsubscribe()
      .send({ from: await window.getCoinbase() })
      .then(() => {
        // this.setState({ loadspinner: false });
      })
      .catch((e) => {
        this.setState({ status: "An error occurred. Please try again" });
        // this.setState({ loadspinner: false });
      });
  };

  onImageChange = (event) => {
    const fileTypes = [
      "image/apng",
      "image/bmp",
      "image/gif",
      "image/jpeg",
      "image/pjpeg",
      "image/png",
      "image/svg+xml",
      "image/tiff",
      "image/webp",
      "image/x-icon",
    ];

    this.setState({ showSavebtn: true, showRemovebtn: true });

    if (fileTypes.includes(event.target.files[0].type)) {
      if (event.target.files && event.target.files[0]) {
        this.setState({ selectedFile: event.target.files[0] });
        let reader = new FileReader();
        reader.onload = (e) => {
          this.setState({ image: e.target.result });
          this.handleSubmission();
        };
        reader.readAsDataURL(event.target.files[0]);
      }
    } else {
      window.alertify.error("Image type not supported");
    }
  };

  handleSubmission = async () => {
    const formData = new FormData();
    formData.append("image", this.state.selectedFile);
    let coinbase = this.props.coinbase;
    this.setState({ loadspinnerSave: true });
    if (!coinbase) {
      await window.connectWallet(undefined, false);
    }
    let signature;

    try {
      signature = await window.sign(
        window.config.metamask_message2,
        await window.getCoinbase()
      );
    } catch (e) {
      console.error(e);
      console.log(e);
      this.setState({ loadspinnerSave: false });

      return;
    }

    if (!signature) {
      window.alertify("No Signature provided");
      this.setState({ loadspinnerSave: false });

      return;
    }

    formData.append("signature", signature);

    await fetch("https://api-image.dyp.finance/api/v1/upload/avatar", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((result) => {
        console.log("Success:", result);
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    window.alertify.message("Avatar has been uploaded successfully!");
    this.setState({ loadspinnerSave: false, showSavebtn: false });
    // this.fetchAvatar().then();
  };

  fetchAvatar = async () => {
    const response = await fetch(
      `https://api-image.dyp.finance/api/v1/avatar/${this.props.coinbase}`
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        this.setState({ image: data.status === 0 ? Placeholder : data.avatar });
      })
      .catch(console.error);

    return response;
  };

  deleteAvatar = async () => {
    const response = await fetch(
      `https://api-image.dyp.finance/api/v1/avatar/${this.props.coinbase}/delete`
    )
      .then((res) => {
        return res.json();
      })
      .then(() => {
        this.setState({ image: Placeholder });
      })
      .catch(console.error);

    return response;
  };

  GetSubscriptionForm = () => {
    let tokenDecimals =
      this.props.networkId === 20
        ? window.config.subscriptioneth_tokens[
            this.state.selectedSubscriptionToken
          ]?.decimals
        : window.config.subscription_tokens[
            this.state.selectedSubscriptionToken
          ]?.decimals;

    const focusInput = (input) => {
      document.getElementById(input).focus();
    };

    const freePlanItems = [
      "Yields",
      "News Section",
      "BUNNY Locker",
      "dApps access",
    ];

    const paidPlanItems = [
      "All free features included",
      "Manual research info for projects",
      "Full access to Community Trust Vote",
      "Perform any votes",
      "Early access to new features released in the future",
      "Guaranteed allocation to presales of new projects launched using our Launchpad",
    ];

    const handleTooltipClose = () => {
      this.setState({ openTooltip: false });
    };

    const handleTooltipOpen = () => {
      this.setState({ openTooltip: true });
    };

    return this.props ? (
      <div>
        <div className="d-flex align-items-start align-items-lg-0 justify-content-between flex-column flex-lg-row gap-4 gap-lg-0">
          <div
            className={`d-flex flex-column ${
              this.state.showInput ? "gap-5 gap-lg-2" : "gap-2"
            }`}
          >
            <div
              className={`d-flex align-items-center w-100 justify-content-between justify-content-lg-start gap-3`}
            >
              <div className="position-relative">
                <div className="avatar-border"></div>
                <img
                  src={require("./assets/changeImage.svg").default}
                  alt=""
                  className="add-image"
                />
                <img
                  src={this.state.image}
                  alt="your image"
                  className="avatarimg"
                />
                <input
                  type="file"
                  id="group_image"
                  onChange={this.onImageChange}
                />
              </div>
              <div className="d-flex flex-column gap-2">
                <div className="d-flex flex-row flex-lg-row align-items-start align-items-lg-center gap-2 gap-lg-3">
                  <h6 className="account-username">
                    {this.state.username ? this.state.username : "Bunny"}
                  </h6>
                  {this.state.showInput ? (
                    <div className="d-flex align-items-center gap-2">
                      <div
                        className="input-container px-0"
                        style={{ width: "190px" }}
                      >
                        <input
                          type="text"
                          min={1}
                          max={365}
                          id="username"
                          name="username"
                          placeholder=" "
                          className="text-input"
                          style={{ width: "100%" }}
                          value={this.state.userNameInput}
                          onChange={(e) =>
                            this.setState({ userNameInput: e.target.value })
                          }
                        />
                        <label
                          htmlFor="username"
                          className="label"
                          onClick={() => focusInput("username")}
                        >
                          Enter a new name
                        </label>
                        <img
                          src={require(`./assets/clearFieldIcon.svg`).default}
                          className="clear-icon cursor-pointer"
                          alt="clear field"
                          onClick={() => this.setState({ showInput: false })}
                        />
                      </div>
                      <button
                        className="btn outline-btn py-2"
                        onClick={() =>
                          this.postUsername(this.state.userNameInput)
                        }
                      >
                        Submit
                      </button>
                    </div>
                  ) : (
                    <img
                      src={openNameChange}
                      className="cursor-pointer"
                      alt=""
                      onClick={() => this.setState({ showInput: true })}
                      style={{ zIndex: 2 }}
                    />
                  )}
                </div>
                <div className="d-flex align-items-center gap-2">
                  <span className="account-wallet-address">
                    {shortAddress(this.props.coinbase)}
                  </span>
                  <ClickAwayListener onClickAway={handleTooltipClose}>
                    <Tooltip
                      PopperProps={{
                        disablePortal: true,
                      }}
                      onClose={handleTooltipClose}
                      open={this.state.openTooltip}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      placement="top"
                      title={
                        <div className="tooltip-text">
                          {"Wallet address copied!"}
                        </div>
                      }
                    >
                      <img
                        src={require("./assets/clipboardIcon.svg").default}
                        className="cursor-pointer"
                        alt="clipboard"
                        onClick={() => {
                          navigator.clipboard.writeText(this.props.coinbase);
                          handleTooltipOpen();
                        }}
                      />
                    </Tooltip>
                  </ClickAwayListener>
                </div>
              </div>
            </div>
          </div>
          <div
            className={
              this.props.coinbase
                ? "mb-3 d-flex justify-content-between justify-content-lg-end"
                : "d-none"
            }
          >
            <div className="d-flex flex-column align-items-start align-items-lg-start w-100 justify-content-between justify-content-lg-end gap-1">
              <div className="plan-tag py-2 px-4 d-flex align-items-center gap-2">
                <img
                  src={require("./assets/premiumDypIcon.svg").default}
                  alt=""
                  style={{ width: 28, height: 28 }}
                />
                <span className="plan-tag-title">
                  {this.props.isPremium ? "Premium" : "Free"}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-4 gap-3 gap-lg-0">
          <div className="col-12 col-lg-6">
            <div className="dyp-balances-wrapper d-flex flex-column gap-4 p-3">
              <h6 className="balances-title">Multichain BUNNY Balance</h6>
              <div className="d-flex flex-column flex-lg-row gap-3 align-items-center justify-content-between">
                <div className="dyp-balance-wrapper d-flex align-items-center justify-content-between justify-content-lg-center p-2 gap-3 gap-xxl-3 gap-lg-1">
                  <img
                    src={require(`./assets/esc.svg`).default}
                    width={20}
                    height={20}
                    alt=""
                  />
                <div className="dyp-balance-wrapper d-flex align-items-center justify-content-between justify-content-lg-center p-2  gap-3 gap-xxl-3 gap-lg-1">
                    <span className="balance-amount mb-0">
                    {parseFloat(parseFloat(this.state.balance) / 10**18).toFixed(2)}  
                    </span>
                    <img
                      src={require(`./assets/bpicon.webp`)}
                      width={20}
                      height={20}
                      alt=""
                    />
                  </div>
                </div>
                <div className="blurrypool dyp-balance-wrapper d-flex align-items-center justify-content-between justify-content-lg-center p-2  gap-3 gap-xxl-3 gap-lg-1">
                  <img
                    src={require(`./assets/fsn.svg`).default}
                    width={20}
                    height={20}
                    alt=""
                  />
                  <div className="d-flex align-items-center gap-1">
                    <span className="balance-amount mb-0">
                      {getFormattedNumber(this.state.bnbBalance)}  
                    </span>
                    <img
                      src={require(`./assets/bpicon.webp`)}
                      width={20}
                      height={20}
                      alt=""
                    />
                  </div>
                </div>
                <div className="blurrypool dyp-balance-wrapper d-flex align-items-center justify-content-between justify-content-lg-center p-2 gap-3 gap-xxl-3 gap-lg-1">
                  <img
                    className="w-6"
                    src={require(`./arthera.svg`).default}
                    alt=""
                  />
                  <div className="d-flex align-items-center gap-1">
                    <span className="balance-amount mb-0">
                    {parseFloat(parseFloat(this.state.balance) / 10**18).toFixed(2)}  
                    </span>
                    <img
                      src={require(`./assets/dypv1Icon.svg`).default}
                      width={20}
                      height={20}
                      alt=""
                    />
                  </div>
                </div>
            
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <a
             href="/apr"
              className="free-plan-container d-flex flex-column align-items-end gap-3 p-3"
            >
              <h6 className="wod-title">Boost farming & staking APR with APR boosters</h6>
              <div className="d-flex align-items-center gap-1">
                <span className="explore-wod">Explore more</span>
                <img src={gotoWod} alt="" />
              </div>
            </a>
          </div>
        </div>
        <div className="row mt-5 gap-4 gap-lg-0">
          <div className="col-12 col-lg-6 position-relative d-flex justify-content-center">
            <div
              className="purplediv"
              style={{
                top: "15px",
                zIndex: 1,
                left: "12px",
                background: "#8E97CD",
              }}
            ></div>
            <div className="row free-plan-container p-3 position-relative w-100">
              <div className="d-flex align-items-center gap-2">
                <img
                  src={require("./assets/freePlanIcon.svg").default}
                  alt=""
                />
                <h6 className="free-plan-title">Free plan</h6>
              </div>
              <div className="col-12 col-lg-6">
                <div className="d-flex flex-column gap-1 mt-3">
                  {freePlanItems.map((item, index) => (
                    <div
                      key={index}
                      className="free-plan-item d-flex align-items-center justify-content-between p-2"
                    >
                      <span className="free-plain-item-text">{item}</span>
                      <img
                        src={require("./assets/freeCheck.svg").default}
                        alt=""
                      />
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-12 col-lg-6 free-plan-image"></div>
              <div className="col-12">
                <hr className="form-divider my-4" style={{ height: "2px" }} />
                <div className="d-flex flex-column">
                  <span className="inactive-plan">Active</span>
                  <span className="inactive-plan">Free plan bundle</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 position-relative d-flex justify-content-center">
            <div
              className="purplediv"
              style={{
                top: "15px",
                zIndex: 1,
                left: "12px",
                background: "#8E97CD",
              }}
            ></div>
            <div className="row free-plan-container p-3 position-relative w-100">
              <div className="d-flex align-items-center gap-2">
                <img
                  src={require("./assets/paidPlanIcon.svg").default}
                  alt=""
                />
                <h6 className="free-plan-title">Bunny plan</h6>
              </div>
              <div className="col-12 col-lg-6">
                <div className="d-flex flex-column gap-1 mt-3">
                  {paidPlanItems.map((item, index) => (
                    <div
                      key={index}
                      className="free-plan-item d-flex align-items-center justify-content-between p-2"
                    >
                      <span className="free-plain-item-text">{item}</span>
                      <img
                        src={require("./assets/freeCheck.svg").default}
                        alt=""
                      />
                    </div>
                  ))}
                </div>
              </div>

              <div className="col-12 col-lg-6 paid-plan-image"></div>
              {/* <div className="col-12">
                {!this.props.appState.isPremium ? (
                  <>
                    <div className="premiumbanner">
                      <div className="d-flex align-items-center justify-content-between">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 5,
                          }}
                        >
                          <h3 className="subscr-title">
                            Lifetime subscription{" "}
                          </h3>
                          <p className="subscr-subtitle">
                            The subscription tokens will be used to buy BUNNY
                          </p>
                        </div>
                        <div>
                          <div className="d-flex gap-2 flex-column flex-lg-row">
                            <img
                              src={require(`./assets/usdcIcon.svg`).default}
                              width={28}
                              height={28}
                              alt=""
                            ></img>
                            <h3 className="subscr-price">100 USDC</h3>
                          </div>
                          <p className="subscr-note">*Exclusive offer</p>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mt-3">
                      <div
                        style={{
                          color: "#F7F7FC",
                          fontSize: "14px",
                          fontWeight: "500",
                          lineHeight: "20px",
                        }}
                      >
                        Subscribe <br></br> to the Premium plan
                      </div>
                      <div
                        className="btn filledbtn px-3 px-lg-5"
                        style={{ whiteSpace: "pre" }}
                        type=""
                        onClick={() => {
                          this.setState({
                            subscribe_now: !this.state.subscribe_now,
                          });
                          this.handleSubscriptionTokenChange(
                            this.state.usdtAddress
                          );
                          this.handleCheckIfAlreadyApproved();
                        }}
                      >
                        Subscribe now
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="premiumbanner">
                      <div className="d-flex align-items-center justify-content-between">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 5,
                          }}
                        >
                          <h3 className="subscr-title">Welcome premium user</h3>
                          <p className="subscr-subtitle">
                            *When you unsubscribe the BUNNY will be unlocked and
                            sent to your wallet
                          </p>
                          <p className="subscr-note">
                            *When you unsubscribe the BUNNY will be unlocked and sent to
                            your wallet
                          </p>
                        </div>
                        <div>
                          <div className="d-flex gap-2">
                            <img src="/assets/img/usdt.svg"></img>
                            <h3 className="subscr-price">75 USDT</h3>
                          </div>
                          <p className="subscr-note">*Exclusive offer</p>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mt-3">
                      <div
                        style={{
                          color: "#4FAD93",
                          fontSize: "14px",
                          fontWeight: "500",
                          lineHeight: "20px",
                        }}
                      >
                        Active <br></br> Premium plan
                      </div>
                      <div
                        className="btn outline-btn px-5"
                        type=""
                        onClick={this.handleUnsubscribe}
                      >
                        Unsubscribe
                      </div>
                    </div>
                  </>
                )}
              </div> */}
            </div>
          </div>
        </div>
        {this.state.subscribe_now === true ? (
          <div className="row mt-4 justify-content-end">
            <div className="col-12 col-lg-6">
              <div className="subscribe-container p-3 position-relative">
                <div
                  className="purplediv"
                  style={{ background: "#8E97CD" }}
                ></div>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center gap-2">
                    <img src={coinStackIcon} alt="coin stack" />
                    <h6 className="free-plan-title">
                      BUNNY Tools Premium Subscription
                    </h6>
                  </div>
                  <img
                    src={require(`./assets/clearFieldIcon.svg`).default}
                    height={28}
                    width={28}
                    className="cursor-pointer"
                    onClick={() => this.setState({ subscribe_now: false })}
                    alt="close subscription"
                  />
                </div>
                <div className="d-flex mt-4 align-items-end justify-content-between flex-column-reverse flex-lg-row w-100">
                  <div className="d-flex flex-column gap-3 subscribe-input-container">
                    <span className="token-amount-placeholder">
                      Token Amount
                    </span>
                    <div
                      className="input-container px-0"
                      style={{ width: "100%" }}
                    >
                      <input
                        type="number"
                        disabled
                        min={1}
                        max={365}
                        id="token_amount"
                        name="token_amount"
                        placeholder=" "
                        className="text-input"
                        value={this.state.formattedPrice}
                        style={{ width: "100%" }}
                      />
                      <label
                        htmlFor="token_amount"
                        className="label"
                        onClick={() => focusInput("token_amount")}
                      >
                        Subscription Token Amount
                      </label>
                    </div>
                  </div>
                  <div className="d-flex flex-column align-items-end justify-content-lg-end">
                    <span className="token-balance-placeholder">
                      USDT Balance
                    </span>
                    <h6 className="account-token-amount">
                      {" "}
                      {getFormattedNumber(
                        this.state.tokenBalance / 10 ** tokenDecimals,
                        6
                      )}
                    </h6>
                  </div>
                </div>
                <hr className="form-divider my-4" />
                <div className="d-flex justify-content-between align-items-center">
                  <div
                    className="subscription-token-wrapper  p-2 d-flex align-items-center justify-content-between "
                    style={{ width: "40%" }}
                  >
                    <span className="token-amount-placeholder">
                      Subscription token:
                    </span>
                    <div className="d-flex align-items-center gap-2">
                      <img
                        src="/assets/img/usdt.svg"
                        height={24}
                        width={24}
                        alt="usdt"
                      />
                      <span className="usdt-text">USDT</span>
                    </div>
                  </div>
                  <button
                    className="btn success-button px-4"
                    onClick={(e) =>
                      this.state.isApproved === false
                        ? this.handleApprove(e)
                        : this.handleSubscribe()
                    }
                  >
                    {this.state.isApproved === true &&
                    this.state.loadspinner === false ? (
                      "Subscribe"
                    ) : this.state.isApproved === false &&
                      this.state.loadspinner === false ? (
                      "Approve"
                    ) : (
                      <div
                        className="spinner-border "
                        role="status"
                        style={{ height: "1.5rem", width: "1.5rem" }}
                      ></div>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}

        {this.state.userPools && this.state.userPools.length > 0 && (
          <>
            <h4 className="d-block mb-5 mt-5" id="my-fav">
              My Earnings
            </h4>
            <div
              style={{
                gap: "50px 0px",
                display: "grid",
                gridTemplateColumns: "repeat(1, 1fr)",
              }}
            >
              <div
                className="row p-0 m-0 poolrows"
                style={{
                  gap: "50px 0px",
                }}
              >
                {this.state.ethStake &&
                  this.state.ethStake
                    .slice(0, this.state.ethStake.length)
                    .map((pool, index) => (
                      <NavLink
                        to="/earn/dypius"
                        style={{
                          display:
                            this.state.userPools.length > 0
                              ? this.state.userPools.find(
                                  (obj) => obj.contract_address == pool.id
                                )
                                ? "block"
                                : "none"
                              : "none",
                          position: "relative",
                        }}
                      >
                        <div
                          style={{
                            display:
                              this.state.userPools.length > 0
                                ? this.state.userPools.find(
                                    (obj) => obj.contract_address == pool.id
                                  )
                                  ? "block"
                                  : "none"
                                : "none",
                            position: "relative",
                          }}
                        >
                          <div className="d-flex justify-content-center align-items-center ethereumTagwrapper">
                            <img
                              src="/assets/img/esc.svg"
                              className="popup-chains-icon"
                            />
                            <h6
                              className={`d-flex justify-content-center align-items-center chain-popup-text-active`}
                            >
                              Ethereum
                            </h6>
                          </div>
                          <TopPoolsCard
                            key={index}
                            chain={this.props.networkId}
                            top_pick={pool.top_pick}
                            tokenName={pool.pair_name}
                            apr={pool.apy_percent + "%"}
                            tvl={"$" + getFormattedNumber(pool.tvl_usd)}
                            lockTime={
                              pool.lock_time ? pool.lock_time : "No Lock"
                            }
                            tokenLogo={
                              pool.icon
                                ? pool.icon
                                : pool.pair_name === "BUNNY"
                                ? "dyplogo.svg"
                                : "idypius.svg"
                            }
                            onShowDetailsClick={() => {}}
                            onHideDetailsClick={() => {}}
                            cardType={"table"}
                            details={false}
                            isNewPool={
                              pool.new_pool === "Yes" ? true : false
                            }
                            isStaked={
                              this.state.userPools.length > 0
                                ? this.state.userPools.find(
                                    (obj) =>
                                      obj.contract_address == pool.id
                                  )
                                  ? true
                                  : false
                                : false
                            }
                            isAccount={true}
                            expired={
                              pool.expired === "Yes" ? true : false
                            }
                          />
                        </div>
                      </NavLink>
                    ))}
                {this.state.bnbStake &&
                  this.state.bnbStake
                    .slice(0, this.state.bnbStake.length)
                    .map((pool, index) => (
                      <NavLink
                        to="/earn/dypius"
                        style={{
                          display:
                            this.state.userPools.length > 0
                              ? this.state.userPools.find(
                                  (obj) => obj.contract_address == pool.id
                                )
                                ? "block"
                                : "none"
                              : "none",
                          position: "relative",
                        }}
                      >
                        <div
                          style={{
                            display:
                              this.state.userPools.length > 0
                                ? this.state.userPools.find(
                                    (obj) => obj.contract_address == pool.id
                                  )
                                  ? "block"
                                  : "none"
                                : "none",
                            position: "relative",
                          }}
                        >
                          <div className="d-flex justify-content-center align-items-center bnbTagwrapper">
                            <img
                              src={require("../../assets/bnblogo.svg").default}
                              alt=""
                              style={{ height: 20, width: 20 }}
                              className="popup-chains-icon"
                            ></img>
                            <h6
                              className={`d-flex justify-content-center align-items-center chain-popup-text-active`}
                            >
                              BNB Chain
                            </h6>
                          </div>

                          <TopPoolsCard
                            key={index}
                            chain={this.props.networkId}
                            top_pick={pool.top_pick}
                            tokenName={pool.pair_name}
                            apr={pool.apy_percent + "%"}
                            tvl={"$" + getFormattedNumber(pool.tvl_usd)}
                            lockTime={
                              pool.lock_time ? pool.lock_time : "No Lock"
                            }
                            tokenLogo={
                              pool.icon
                                ? pool.icon
                                : pool.pair_name === "BUNNY"
                                ? "dyplogo.svg"
                                : "idypius.svg"
                            }
                            onShowDetailsClick={() => {}}
                            onHideDetailsClick={() => {}}
                            cardType={"table"}
                            details={false}
                            isNewPool={
                              pool.new_pool === "Yes" ? true : false
                            }
                            isStaked={
                              this.state.userPools.length > 0
                                ? this.state.userPools.find(
                                    (obj) =>
                                      obj.contract_address == pool.id
                                  )
                                  ? true
                                  : false
                                : false
                            }
                            isAccount={true}
                            expired={
                              pool.expired === "Yes" ? true : false
                            }
                          />
                        </div>
                      </NavLink>
                    ))}
                {this.state.avaxStake &&
                  this.state.avaxStake
                    .slice(0, this.state.avaxStake.length)
                    .map((pool, index) => (
                      <NavLink
                        to="/earn/dypius"
                        style={{
                          display:
                            this.state.userPools.length > 0
                              ? this.state.userPools.find(
                                  (obj) => obj.contract_address == pool.id
                                )
                                ? "block"
                                : "none"
                              : "none",
                          position: "relative",
                        }}
                      >
                        <div
                          style={{
                            display:
                              this.state.userPools.length > 0
                                ? this.state.userPools.find(
                                    (obj) => obj.contract_address == pool.id
                                  )
                                  ? "block"
                                  : "none"
                                : "none",
                            position: "relative",
                          }}
                        >
                          <div className="d-flex justify-content-center align-items-center avaxTagWrapper">
                            <img
                              src={require("../../assets/wavax.svg").default}
                              alt=""
                              style={{ height: 20, width: 20 }}
                              className="popup-chains-icon"
                            ></img>
                            <h6
                              className={`d-flex justify-content-center align-items-center chain-popup-text-active`}
                            >
                              Avalanche
                            </h6>
                          </div>

                          <TopPoolsCard
                            key={index}
                            chain={this.props.networkId}
                            top_pick={pool.top_pick}
                            tokenName={pool.pair_name}
                            apr={pool.apy_percent + "%"}
                            tvl={"$" + getFormattedNumber(pool.tvl_usd)}
                            lockTime={
                              pool.lock_time ? pool.lock_time : "No Lock"
                            }
                            tokenLogo={
                              pool.icon
                                ? pool.icon
                                : pool.pair_name === "BUNNY"
                                ? "dyplogo.svg"
                                : "idypius.svg"
                            }
                            onShowDetailsClick={() => {}}
                            onHideDetailsClick={() => {}}
                            cardType={"table"}
                            details={false}
                            isNewPool={
                              pool.new_pool === "Yes" ? true : false
                            }
                            isStaked={
                              this.state.userPools.length > 0
                                ? this.state.userPools.find(
                                    (obj) =>
                                      obj.contract_address == pool.id
                                  )
                                  ? true
                                  : false
                                : false
                            }
                            isAccount={true}
                            expired={
                              pool.expired === "Yes" ? true : false
                            }
                          />
                        </div>
                      </NavLink>
                    ))}
              </div>
            </div>
          </>
        )}

        <div className="container-lg px-0 mt-4">
          <div className="active-projects-container2 px-3 px-lg-5 pb-5 pb-lg-0 pt-0 mt-0">
            <div className="d-flex flex-column gap-3 py-4">
              <h6 className="launchpad-hero-title">NFT staking vault</h6>
            </div>
            <div className="py-4 w-100 otherpools-wrapper">
              <ClaimRewards2 />
              <ClaimRewards />
            </div>
 
          </div>
        </div>

  
        <div className="container-lg px-0 mt-4">
          <div className="active-projects-container2 px-3 px-lg-5 pb-5 pb-lg-0 pt-0 mt-0">
            <div className="d-flex flex-column gap-3 py-4">
              <h6 className="launchpad-hero-title">APR booster vault</h6>
            </div>
            <div className="py-4 w-100 otherpools-wrapper">
            <ClaimRewards3 />
            <ClaimRewards4 />
            <ClaimRewards5 />
            <ClaimRewards6 />
            </div>
 
          </div>
        </div>


        <TierLevels display={"none"} infoDisplay={"flex"} />
      </div>
    ) : (
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ minHeight: "65vh" }}
      >
        <div class="spinner-border text-info" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="locker container-lg">
        <div>
          <div className="mb-4">{this.GetSubscriptionForm()}</div>
        </div>
      </div>
    );
  }
}
