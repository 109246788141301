import React from 'react';
import cn from 'classnames';
import Toaster from '../toaster';
import style from '../styles/pages/audit.module.css';

class UserLayout extends React.Component {
  render() {
    return (
      <>
        <Toaster />
        <div className="container-lg dashboardwrapper px-0">{this.props.children}</div>
      </>
    );
  }
}

export default UserLayout;
