import React from 'react';
import axios from 'axios';
import cn from 'classnames';

// COMPONENTS
import UserLayout from './UserLayout';
import Audit from './Audits';
import LatestAudits from './LatestAudits';

// CONFIG
import config from '../config';

// CONTEXT
import { Context } from '../context';

// STYLES
import style from '../styles/pages/audit.module.css';

class AuditPage extends React.Component {
  static contextType = Context;

  constructor(props) {
    super(props);
    this.state = {
      token_address: '',
      token_chain_id: 1,
      audits: [],
      pathname: window.location.pathname,
      query: new URLSearchParams(window.location.search)
    };
  }

  async componentDidMount() {
    try {
      const url_audits = `${config.url_api}/v1/audits`;
      const res_audits = await axios.get(url_audits);
      const audits = res_audits.data;
      this.sortAudits(audits);
    } catch (error) {
      console.error('Failed to fetch audits:', error);
    }
  }

  sortAudits(audits) {
    for (let i = 0; i < audits.length; i++) {
      for (let j = 0; j < audits.length; j++) {
        if (audits[j + 1]) {
          const current = audits[j];
          const next = audits[j + 1];

          if (new Date(current.created_at).valueOf() < new Date(next.created_at).valueOf()) {
            audits[j] = next;
            audits[j + 1] = current;
          }
        }
      }
    }
    this.setState({ audits });
  }

  render() {
    return (
      <UserLayout pathname={this.state.pathname}>
        <section className={cn(style['sectionaudit'])}>
          <div className={cn(style['sectionaudit-left'])}>
            <Audit
              address={this.state.token_address}
              chainId={this.state.token_chain_id}
            />
          </div>

          <div className={cn(style['sectionaudit-right'])}>
            <LatestAudits
              data={this.state.audits}
              setToken={(address, chain_id) => {
                this.setState({
                  token_address: address,
                  token_chain_id: chain_id,
                });
              }}
            />
          </div>
        </section>
      </UserLayout>
    );
  }
}

export default AuditPage;
