/* eslint-disable no-undef */
import React, { useState, useEffect, useMemo } from "react";
import "./dashboard.css";
import NewsCard from "../newsCard/NewsCard";
import GovCard from "../gov-card/GovCard";

import ExplorerCard from "../explorer-card/ExplorerCard";
import BackVideo from "./assets/bgvid.MP4";
import "./sec.scss";
import "./bundle.scss";

import TrendingNews from "../newsCard/TrendingNews";
import rightarrow from "./assets/right-arrow.svg";
import { NavLink } from "react-router-dom";
import useWindowSize from "../../functions/useWindowSize";
import axios from "axios";
import getFormattedNumber from "../../functions/get-formatted-number";

import MigrationBanner from "../migrationbanner/MigrationBanner";
import StakeAvaxIDyp from "../FARMINNG/stakeAvaxiDyp";
import StakeDypiusEth from "../FARMINNG/constant-staking-dypius-new";
import StakeDypiusAvax from "../FARMINNG/stakeDypiusAvax";
import StakeDypiusBsc from "../FARMINNG/bscConstantStakeDypius";
import InitConstantStakingiDYP from "../FARMINNG/constant-staking-idyp-new-front";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import closeX from "../earnOther/assets/closeX.svg";
import ethereumIcon from "../top-pools-card/assets/ethereum.svg";
import bnbIcon from "../top-pools-card/assets/bsc.svg";
import avaxIcon from "../top-pools-card/assets/avax.svg";
import { ClickAwayListener } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import moreinfo from "../FARMINNG/assets/more-info.svg";
import StakeDypiusBscOther from "../earnOther/stakingPools/bscStakeDypiusOther";
import StakeDypiusEthOther from "../earnOther/stakingPools/ethStakeDypiusOther";
import StakeDypiusAvaxOther from "../earnOther/stakingPools/avaxStakeDypiusOther";
import CountDown from "react-countdown";
import EarnHero from "../earn/EarnHero/EarnHero";
// import LandFlyout from "../LandFlyout/LandFlyout";
import LotteryCardComp from "../lottery/LoterryCard";
import ClaimRewards from "../FARMINNG/claimRewards";
import ClaimRewards2 from "../customNFTSTAKING/claimRewards2";
import ClaimRewards4 from "../customNFTSTAKING/claimRewards4";
import ClaimRewards5 from "../customNFTSTAKING/claimRewards5";
import ClaimRewards6 from "../customNFTSTAKING/claimRewards6";

import {
  useContractWrite,
  usePrepareContractWrite,
  useAccount,
  useNetwork,
  useProvider,
} from "wagmi";
import ClaimRewards3 from "../customNFTSTAKING/claimRewards3";

import chestTagOpen from "./assets/vaultTag.svg";
import "../top-pools-card/top-pools.css";
import { ethers } from "ethers";

import PropTypes from "prop-types";

const STAKING_CONTRACT_ADDRESS = "0x9935433D935d3D6c8550fe5eDC7813a23c5F5245";
const stakingContractABI = [
  {
    type: "constructor",
    stateMutability: "nonpayable",
    inputs: [
      {
        type: "address",
        name: "_stakingDestinationAddress",
        internalType: "address",
      },
      { type: "uint256", name: "_rate", internalType: "uint256" },
      { type: "uint256", name: "_expiration", internalType: "uint256" },
      { type: "address", name: "_erc20Address", internalType: "address" },
    ],
  },
  {
    type: "event",
    name: "ExpirationChanged",
    inputs: [
      {
        type: "uint256",
        name: "newExpiration",
        internalType: "uint256",
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "LockTimeChanged",
    inputs: [
      {
        type: "uint256",
        name: "newLockTime",
        internalType: "uint256",
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "OwnershipTransferred",
    inputs: [
      {
        type: "address",
        name: "previousOwner",
        internalType: "address",
        indexed: true,
      },
      {
        type: "address",
        name: "newOwner",
        internalType: "address",
        indexed: true,
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "Paused",
    inputs: [
      {
        type: "address",
        name: "account",
        internalType: "address",
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "RateChanged",
    inputs: [
      {
        type: "uint256",
        name: "newRate",
        internalType: "uint256",
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "Unpaused",
    inputs: [
      {
        type: "address",
        name: "account",
        internalType: "address",
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
    name: "LOCKUP_TIME",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
    name: "_depositBlocks",
    inputs: [
      { type: "address", name: "", internalType: "address" },
      { type: "uint256", name: "", internalType: "uint256" },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
    name: "calculateReward",
    inputs: [
      { type: "address", name: "account", internalType: "address" },
      { type: "uint256", name: "tokenId", internalType: "uint256" },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      { type: "uint256[]", name: "rewards", internalType: "uint256[]" },
    ],
    name: "calculateRewards",
    inputs: [
      { type: "address", name: "account", internalType: "address" },
      { type: "uint256[]", name: "tokenIds", internalType: "uint256[]" },
    ],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "claimRewards",
    inputs: [
      { type: "uint256[]", name: "tokenIds", internalType: "uint256[]" },
    ],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "deposit",
    inputs: [
      { type: "uint256[]", name: "tokenIds", internalType: "uint256[]" },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "uint256[]", name: "", internalType: "uint256[]" }],
    name: "depositsOf",
    inputs: [{ type: "address", name: "account", internalType: "address" }],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "emergencyWithdraw",
    inputs: [
      { type: "uint256[]", name: "tokenIds", internalType: "uint256[]" },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "address", name: "", internalType: "address" }],
    name: "erc20Address",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
    name: "expiration",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "pure",
    outputs: [{ type: "bytes4", name: "", internalType: "bytes4" }],
    name: "onERC721Received",
    inputs: [
      { type: "address", name: "", internalType: "address" },
      { type: "address", name: "", internalType: "address" },
      { type: "uint256", name: "", internalType: "uint256" },
      { type: "bytes", name: "", internalType: "bytes" },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "address", name: "", internalType: "address" }],
    name: "owner",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "pause",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "bool", name: "", internalType: "bool" }],
    name: "paused",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
    name: "rate",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "renounceOwnership",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "setExpiration",
    inputs: [{ type: "uint256", name: "_expiration", internalType: "uint256" }],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "setLockTime",
    inputs: [{ type: "uint256", name: "_lockTime", internalType: "uint256" }],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "setRate",
    inputs: [{ type: "uint256", name: "_rate", internalType: "uint256" }],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "address", name: "", internalType: "address" }],
    name: "stakingDestinationAddress",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
    name: "stakingTime",
    inputs: [{ type: "address", name: "", internalType: "address" }],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "transferOwnership",
    inputs: [{ type: "address", name: "newOwner", internalType: "address" }],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "unpause",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "withdraw",
    inputs: [
      { type: "uint256[]", name: "tokenIds", internalType: "uint256[]" },
    ],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "withdrawTokens",
    inputs: [],
  },
];

const Dashboard = ({
  isConnected,
  coinbase,
  the_graph_result,
  lp_id,
  handleConnection,
  the_graph_resultbsc,
  the_graph_resultavax,
  referrer,
  handleSwitchNetwork,
  isPremium,
  onConnectWallet,
  aggregatorPools,
}) => {
  const [topPools, setTopPools] = useState([]);
  const [cawsLandCard, setCawsLandCard] = useState([]);
  const [theBnbPool, setTheBnbPool] = useState({});
  const [totalTvl, settotalTvl] = useState(0);
  const [totalTvlETH, settotalTvlETH] = useState(0);
  const [totalTvlBNB, settotalTvlBNB] = useState(0);
  const [totalTvlAVAX, settotalTvlAVAX] = useState(0);
  const [count, setCount] = useState(0);
  const [selectedNftIds, setSelectedNftIds] = useState([]);
  const provider = useProvider();
  const { address } = useAccount();
  const [wbnbPrice, setWbnbPrice] = useState(0);
  const [ethPrice, setEthPrice] = useState(0);
  const [avaxPrice, setAvaxPrice] = useState(0);

  const [selectedTab, setselectedTab] = useState("deposit");
  const [selectedBtn, setselectedBtn] = useState("flexible");
  const [selectedPool, setselectedPool] = useState([]);
  const [showDetails, setShowDetails] = useState(false);
  const [aprTooltip, setaprTooltip] = useState(false);
  const [livePremiumOnly, setlivePremiumOnly] = useState(true);

  const [landCard, setLandCard] = useState({});
  const [activeCard, setActiveCard] = useState();
  const [activeCardFarm, setActiveCardFarm] = useState();
  const [network, setNetwork] = useState("Elastos Smart Chain");

  const { chain } = useNetwork();

  const [cardIndex, setcardIndex] = useState();
  const [details, setDetails] = useState();
  const [popularNewsData, setPopularNewsData] = useState([]);
  const [activeCard2, setActiveCard2] = useState();
  const [loading, setLoading] = useState(true);
  const [userPools, setuserPools] = useState([]);
  const wbsc_address = "0x2170Ed0880ac9A755fd29B2688956BD959F933F8";
  let premiumDay = new Date("2024-04-17T15:00:00.000+02:00");

  const stakingContract = useMemo(
    () =>
      new ethers.Contract(
        STAKING_CONTRACT_ADDRESS,
        stakingContractABI,
        provider
      ),
    [provider]
  );
  const [loadingClaim, setLoadingClaim] = useState(false);
  const [stakedNftIds, setStakedNftIds] = useState([]);
  const [totalRewards, setTotalRewards] = useState("0");

  useEffect(() => {
    const fetchStakedNFTs = async () => {
      if (!address) return;
      try {
        const stakedNftIds = await stakingContract.depositsOf(address);
        setStakedNftIds(stakedNftIds.map((id) => id.toString()));

        const rewards = await stakingContract.calculateRewards(
          address,
          stakedNftIds
        );
        const total = rewards.reduce(
          (acc, reward) => acc + parseFloat(ethers.utils.formatEther(reward)),
          0
        );
        setTotalRewards(total.toFixed(18));
      } catch (error) {
        console.error("Failed to fetch staked NFTs and rewards", error);
      }
    };

    fetchStakedNFTs();
  }, [address, stakingContract]);

  const { config } = usePrepareContractWrite({
    address: STAKING_CONTRACT_ADDRESS,
    abi: stakingContractABI,
    functionName: "claimRewards",
    args: [stakedNftIds],
  });

  const { writeAsync } = useContractWrite(config);

  const handleClaim = async () => {
    if (stakedNftIds.length === 0) {
      toast.error("No staked NFTs available for claiming rewards.", {
        className: "custom-toast error-toast",
      });
      return;
    }

    setLoadingClaim(true);
    try {
      const tx = await writeAsync();
      const receipt = await tx.wait();
      if (receipt.status === 1) {
        toast.success("Rewards claimed successfully.", {
          className: "custom-toast success-toast",
        });
        await refreshData();
      } else {
        throw new Error("Transaction failed");
      }
    } catch (error) {
      toast.error(`Claiming rewards failed: ${error.message}`, {
        className: "custom-toast error-toast",
      });
    }
    setLoadingClaim(false);
  };

  const { BigNumber } = window;
  const refreshData = () => {
    console.log("Data refreshed");
  };
  const fetchUserPools = async () => {
    if (coinbase && coinbase.includes("0x")) {
      const result = await axios
        .get(`https://api.dyp.finance/api/user_pools/${coinbase}`)
        .then((data) => {
          return data.data.PoolsUserIn;
        });
      setuserPools(result);
    }
  };
  useEffect(() => {
    setNetwork("ELASTOS"); // Set default network on component mount
  }, []);

  const getBSCPrice = async () => {
    await axios
      .get("https://api.dyp.finance/api/the_graph_bsc_v2")
      .then((data) => {
        setWbnbPrice(data.data.the_graph_bsc_v2.usd_per_eth);
      });
  };

  const getETHPrice = async () => {
    await axios
      .get("https://api.dyp.finance/api/the_graph_eth_v2")
      .then((data) => {
        setEthPrice(data.data.the_graph_eth_v2.usd_per_eth);
      });
  };

  const getAvaxPrice = async () => {
    await axios
      .get("https://api.dyp.finance/api/the_graph_avax_v2")
      .then((data) => {
        setAvaxPrice(data.data.the_graph_avax_v2.usd_per_eth);
      });
  };

  const fetchTotalTvl = async () => {
    const staking = window.constant_staking_dypius_bscother1;
    const staking_eth = window.constant_staking_dypius_ethother1;
    const staking_avax = window.constant_staking_dypius_avaxother1;

    const wbnbContract = new window.bscWeb3.eth.Contract(
      window.TOKEN_ABI,
      window.config.reward_token_wbnb_address
    );
    const baseContract = new window.baseWeb3.eth.Contract(
      window.TOKEN_ABI,
      window.config.reward_token_dypius_base_address
    );

    const wavaxContract = new window.avaxWeb3.eth.Contract(
      window.TOKEN_ABI,
      window.config.wavax_address
    );

    const tvl = await wbnbContract.methods
      .balanceOf(staking._address)
      .call()
      .catch((e) => {
        console.log(e);
        return 0;
      });

    const tvl_eth = await baseContract.methods
      .balanceOf(staking_eth._address)
      .call()
      .catch((e) => {
        console.log(e);
        return 0;
      });

    const tvl_avax = await wavaxContract.methods
      .balanceOf(staking_avax._address)
      .call()
      .catch((e) => {
        console.log(e);
        return 0;
      });

    const tvlFormatted = new BigNumber(tvl).div(1e18).toFixed(4);
    const tvlEthFormatted = new BigNumber(tvl_eth).div(1e18).toFixed(4);
    const tvlAvaxFormatted = new BigNumber(tvl_avax).div(1e18).toFixed(4);

    if (wbnbPrice !== 0 && ethPrice !== 0 && avaxPrice !== 0) {
      const finalTvl = tvlFormatted * wbnbPrice;
      const finalEthTvl = tvlEthFormatted * ethPrice;
      const finalAvaxTvl = tvlAvaxFormatted * avaxPrice;

      settotalTvlETH(finalEthTvl);
      settotalTvlBNB(finalTvl);
      settotalTvlAVAX(finalAvaxTvl);

      // settotalTvl(
      //   Number(finalTvl) + Number(finalEthTvl) + Number(finalAvaxTvl)
      // );
    }
  };

  const fetchAllPools = async () => {
    // const bnbFarmingPool = await axios
    //   .get("https://api.dyp.finance/api/the_graph_bsc_v2")
    //   .catch((err) => console.error(err));

    const bnbStakingPool = await axios
      .get(`https://api.dyp.finance/api/get_staking_info_bnb_new`)
      .catch((err) => {
        console.log(err);
      });

    const avaxStakingPool = await axios
      .get(`https://api.dyp.finance/api/get_staking_info_avax`)
      .catch((err) => {
        console.log(err);
      });

    const avaxStakingPoolNew = await axios
      .get(`https://api.dyp.finance/api/get_staking_info_avax_new`)
      .catch((err) => {
        console.log(err);
      });

    const eth_result = await axios
      .get(`https://api.dyp.finance/api/get_staking_info_eth`)
      .catch((err) => {
        console.log(err);
      });

    const eth_result2 = await axios
      .get(`https://api.dyp.finance/api/get_staking_info_eth_new`)
      .catch((err) => {
        console.log(err);
      });

    if (
      bnbStakingPool &&
      bnbStakingPool.status === 200 &&
      avaxStakingPool &&
      avaxStakingPool.status === 200 &&
      avaxStakingPoolNew &&
      avaxStakingPoolNew.status === 200 &&
      eth_result &&
      eth_result.status === 200 &&
      eth_result2 &&
      eth_result2.status === 200 &&
      // bnbFarmingPool &&
      // bnbFarmingPool.status === 200 &&
      aggregatorPools > 0
    ) {
      let temparray = Object
        .entries
        // bnbFarmingPool.data.the_graph_bsc_v2.lp_data
        ();
      let bnbpool = temparray.filter((item) => {
        setWbnbPrice(bnbFarmingPool.data.the_graph_bsc_v2.usd_per_eth);
        return (
          item[1].id ===
          "0x1bc61d08a300892e784ed37b2d0e63c85d1d57fb-0x5bc3a80a1f2c4fb693d9dddcebbb5a1b5bb15d65"
        );
      });
      setTheBnbPool(bnbpool);
      const testbnbFarming = bnbpool[0];
      const finalBnbFarmingpool = testbnbFarming[1];

      const dypBnb = bnbStakingPool.data.stakingInfoDYPBnb;
      const avaxIdyp = avaxStakingPool.data.stakingInfoiDYPAvax;
      const avaxDyp = avaxStakingPoolNew.data.stakingInfoDYPAvax;
      const ethereumIdyp = eth_result.data.stakingInfoiDYPEth;
      const ethereumDyp = eth_result2.data.stakingInfoDYPEth;

      const bnbAggregatorPool = aggregatorPools.find((item) => {
        return item.name.toLowerCase() === "bnb";
      });
      const bnbAggregatorPool_formatted = [bnbAggregatorPool].map((item) => {
        return {
          ...item,
          chain: "bnb",
          type: "staking",
          tvl_usd: item.poolList[0].tvl,
          id: item.poolList[0].contractAddress,
          apy_percent: item.poolList[0].aprPercent,
          lock_time: item.poolList[0].lockTime + " days",
          pair_name: item.name,
        };
      });

      const ethAggregatorPool = aggregatorPools.find((item) => {
        return item.name.toLowerCase() === "esc";
      });

      const avaxAggregatorPool = aggregatorPools.find((item) => {
        return item.name.toLowerCase() === "avax";
      });

      const avaxAggregatorPool_formatted = [avaxAggregatorPool].map((item) => {
        return {
          ...item,
          chain: "avax",
          type: "staking",
          tvl_usd: item.poolList[0].tvl,
          id: item.poolList[0].contractAddress,
          apy_percent: item.poolList[0].aprPercent,
          lock_time: item.poolList[0].lockTime + " days",
          pair_name: item.name,
        };
      });

      const ethAggregatorPool_formatted = [ethAggregatorPool].map((item) => {
        return {
          ...item,
          chain: "base",
          type: "staking",
          tvl_usd: item.poolList[0].tvl,
          id: item.poolList[0].contractAddress,
          apy_percent: item.poolList[0].aprPercent,
          lock_time: item.poolList[0].lockTime + " days",
          pair_name: item.name,
        };
      });

      const allpoolsEthereum = [...ethereumDyp, ...ethereumIdyp];
      const object2Ethereum = allpoolsEthereum.map((item) => {
        return {
          ...item,
          tvl_usd: item.tvl_usd,
          chain: "eth",
          type: "staking",
        };
      });

      const cleanCardsEthereum = object2Ethereum.filter((item) => {
        return item.expired !== "Yes";
      });

      const sortedAprsEthereum = cleanCardsEthereum.sort(function (a, b) {
        return b.tvl_usd - a.tvl_usd;
      });

      const object2Avax = avaxDyp.map((item) => {
        return {
          ...item,
          tvl_usd: item.tvl_usd,
          chain: "avax",
          type: "staking",
        };
      });
      const cleanCardsAvax = avaxIdyp.filter((item) => {
        return item.expired !== "Yes";
      });

      const object2idypAvax = cleanCardsAvax.map((item) => {
        return {
          ...item,
          tvl_usd: item.tvl_usd,
          chain: "avax",
          type: "staking",
        };
      });

      const cleanCards2Avax = object2Avax.filter((item) => {
        return item.expired !== "Yes";
      });

      const allActiveCardsAvax = [...object2idypAvax, ...cleanCards2Avax];

      const sortedAprsAvax = allActiveCardsAvax.sort(function (a, b) {
        return b.tvl_usd - a.tvl_usd;
      });

      const object2Bnb = dypBnb.map((item) => {
        return {
          ...item,
          tvl_usd: item.tvl_usd,
          chain: "bnb",
          type: "staking",
        };
      });

      const cleanCards2Bnb = object2Bnb.filter((item) => {
        return item.expired === "No";
      });

      const sortedAprsBnb = cleanCards2Bnb.sort(function (a, b) {
        return b.tvl_usd - a.tvl_usd;
      });

      const allPools = [
        ...sortedAprsEthereum,
        ...sortedAprsAvax,
        ...sortedAprsBnb,
        ...bnbAggregatorPool_formatted,
        ...ethAggregatorPool_formatted,
        ...avaxAggregatorPool_formatted,
        finalBnbFarmingpool,
      ].sort(function (a, b) {
        return b.tvl_usd - a.tvl_usd;
      });

      setTopPools(allPools.slice(0, 2));
    }
  };

  // let network = parseInt(network);

  const eth_address = "ETH";

  const expirearrayStakeBscDyp2 = ["14 July 2023", "5 August 2023"];

  const { LP_IDs_V2BNB } = window;

  const LP_IDBNB_Array = [
    LP_IDs_V2BNB.wbnb[0],
    LP_IDs_V2BNB.wbnb[1],
    LP_IDs_V2BNB.wbnb[2],
    LP_IDs_V2BNB.wbnb[3],
    LP_IDs_V2BNB.wbnb[4],
  ];

  const avax_address = "AVAX";

  const faqItems = [
    {
      title: "What is Bunny Stake?",
      option: "Staking",
      pathName: "/earn/bunny",
      section: "earnFaq",
      pool: null,
      faqIndex: 1,
    },
    {
      title: "What is the Reinvest function?",
      option: "Staking",
      pathName: "/earn/bunny",
      section: "earnFaq",
      pool: null,
      faqIndex: 14,
    },
    {
      title: "What is APR?",
      option: "Farming",
      pathName: "/earn/bunny",
      section: "earnFaq",
      pool: null,
      faqIndex: 6,
    },
    {
      title: "What is Bunny Vault?",
      option: "Vault",
      pathName: "/earn/bunny",
      section: "earnFaq",
      pool: null,
      faqIndex: 0,
    },
    {
      title: "What is Bunny Bridge?",
      option: "Bridge",
      pathName: "/bridge",
      section: "earnFaq",
      pool: null,
      faqIndex: 0,
    },
    {
      title: "Will my lock period reset if I deposit ad...",
      option: "Farming",
      pathName: "/earn/bunny",
      section: "earnFaq",
      pool: null,
      faqIndex: 4,
    },
  ];

  const fetchPopularNewsData = async () => {
    const result = await fetch(`https://news-manage.dyp.finance/api/populars/3`)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setPopularNewsData(data);
      })
      .catch(console.error);

    return result;
  };

  useEffect(() => {
    fetchUserPools();
  }, [network, coinbase]);

  useEffect(() => {
    fetchAllPools();
    fetchPopularNewsData();
    setTimeout(() => {
      setLoading(false);
    }, 2500);
  }, [aggregatorPools]);

  const windowSize = useWindowSize();

  const aprOpen = () => {
    setaprTooltip(true);
  };
  const aprClose = () => {
    setaprTooltip(false);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width:
      windowSize.width > 1400 ? "auto" : windowSize.width > 786 ? "50%" : "95%",
    boxShadow: 24,
    p: 4,
    overflow: "auto",
    minHeight: 200,
    overflowX: "hidden",
    borderRadius: "10px",
    height: windowSize.width < 500 ? "480px" : "auto",
    background: `#1A1A36`,
  };
  useEffect(() => {
    fetchTotalTvl();
  }, [wbnbPrice, ethPrice, avaxPrice, count]);

  useEffect(() => {
    getBSCPrice();
    getETHPrice();
    getAvaxPrice();
  }, []);

  return (
    <>
      <div className="d-none">
        <CountDown
          date={premiumDay}
          onComplete={() => {
            setlivePremiumOnly(false);
          }}
        />
      </div>
      <div className="container-lg dashboardwrapper px-0">
        {/* <LandFlyout /> */}

        <div className="mb-4">
          <EarnHero />
        </div>
        {/* <div className="d-flex m-0 flex-column flex-xxl-row justify-content-between gap-4"> */}
        <div>
          <div className="d-flex flex-column gap-4 justify-content-between">
            <div className="d-flex flex-column flex-md-row m-0 gap-3 justify-content-between">
              {/* <Calculator /> */}
              <MigrationBanner />

              <LotteryCardComp />

              {/* <div className="md:max-w-[200px] m-0 align-items-center justify-content-between gap-2 w-100 pb-2">

                <div className="flex flex-col gap-4">  
                <h6 class="top-pools-title">External apps</h6>
                <div className="w-full migration-banner-wrapper2 p-3">
                  <div
                    className="purplediv"
                    style={{ background: "#8890c4" }}
                  ></div>
                  <div className="d-flex align-items-center gap-2">
                    <h6 className="migration-banner-title mb-0">Crowdfund</h6>
                  </div>

                  <div className="d-flex align-items-center gap-2 mt-3">
                    <a href={"https://elafund.com/"} className="btn filled-btn">
                     To dapp
                    </a>
                  </div>
                </div>

                <div className="w-full migration-banner-wrapper2 p-3">
                  <div
                    className="purplediv"
                    style={{ background: "#8890c4" }}
                  ></div>
                  <div className="d-flex align-items-center gap-2">
                    <h6 className="migration-banner-title mb-0">Elawars</h6>
                  </div>

                  <div className="d-flex align-items-center gap-2 mt-3">
                    <a href={"https://elawars.com/"} className="btn filled-btn">
                     To dapp
                    </a>
                  </div>
                </div>
                </div>

              </div> */}
            </div>
            <div className="mb-4">
              <div
                style={{ position: "relative", width: "100%", height: "280px" }}
              >
                <video
                  className="migration-banner-wrapper"
                  autoPlay
                  loop
                  playsInline
                  muted
                  style={{
                    padding: "0px",
                    borderRadius: "20px",
                    overflow: "hidden",
                    zIndex: "0",
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                >
                  <source src={BackVideo} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>

                <div
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    borderRadius: "20px",
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(40, 38, 84, 0.71)", // Adjust opacity as needed
                  }}
                ></div>

                <div
                  style={{
                    position: "absolute",
                    width: "100%",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    whiteSpace: "space-between",
                    gap: "8px",
                    marginTop: "12px", // Adjust as needed for alignment
                  }}
                >
                  <h2 className="h1">Check us out on ElaCity </h2>
                  <a
                    className="btn outline-btn"
                    rel="noreferrer"
                    href="https://ela.city/marketplace/collections/0xdf90de01099a6575676651b52cc9f333d31cfb40"
                    target="_blank"
                  >
                    Go to Elacity
                  </a>
                </div>
              </div>
            </div>

            <div>
             

              <div className="mt-4 row m-0 align-items-center justify-content-between gap-2 w-100">
                <h6 className="top-pools-title">NFT staking vault</h6>
                <NavLink
                  to="/earn/nft-staking"
                  className="view-more-title d-flex justify-content-center align-items-center gap-1"
                >
                  View all <img src={rightarrow} alt="" />{" "}
                </NavLink>
              </div>

              <div className="w-100 otherpools-wrapper">
                <>
                  {" "}
                  <ClaimRewards
                    selectedNftIds={selectedNftIds}
                    refreshData={refreshData}
                  />
                  <ClaimRewards2
                    selectedNftIds={selectedNftIds}
                    refreshData={refreshData}
                  />
                </>
              </div>

              <div className="mt-4 row m-0 align-items-center justify-content-between gap-2 w-100">
                <h6 className="top-pools-title">APR boost vault</h6>
                <NavLink
                  to="/earn/nft-staking"
                  className="view-more-title d-flex justify-content-center align-items-center gap-1"
                >
                  View all <img src={rightarrow} alt="" />{" "}
                </NavLink>
              </div>

              <div className="w-100 otherpools-wrapper">
                <ClaimRewards3
                  selectedNftIds={selectedNftIds}
                  refreshData={refreshData}
                />
                <ClaimRewards4
                  selectedNftIds={selectedNftIds}
                  refreshData={refreshData}
                />
                <ClaimRewards5
                  selectedNftIds={selectedNftIds}
                  refreshData={refreshData}
                />
                <ClaimRewards6
                  selectedNftIds={selectedNftIds}
                  refreshData={refreshData}
                />
              </div>

              {windowSize.width > 786 ? (
                <div>
                  <div className="row m-0 gap-4 toppool-allwrapper">
                    {/* {topPools.length > 0 &&
                    //  && (network === 1 ||
                    //   network === 1030 ||
                    //   network === 8453 ||
                    //   network === 0 ||
                    //   network === 1482601649)
                    loading === false ? (
                      topPools.slice(0, 2).map((item, index) => {
                        return (
                          <div
                            key={index}
                            className="poolscardwrapper cursor-pointer position-relative p-0 position-relative"
                          >
                            {item.chain === "bnb" && (
                              <div className="d-flex justify-content-end align-items-center bnbTagwrapper pe-2">
                                <img
                                  src={require("./assets/bnblogo.svg").default}
                                  alt=""
                                  style={{ height: 20, width: 20 }}
                                  className="popup-chains-icon"
                                ></img>
                                <h6
                                  className={`d-flex justify-content-center align-items-center chain-popup-text-active`}
                                >
                                  BNB Chain
                                </h6>
                              </div>
                            )}
                            {item.chain === "esc" && (
                              <div className="d-flex justify-content-end pe-2 align-items-center ethereumTagwrapper">
                                <img
                                  src={require("./assets/ethereum.svg").default}
                                  alt=""
                                  style={{ height: 20, width: 20 }}
                                  className="popup-chains-icon"
                                ></img>
                                <h6
                                  className={`d-flex justify-content-center align-items-center chain-popup-text-active`}
                                >
                                  Ethereum
                                </h6>
                              </div>
                            )}
                            {item.chain === "base" && (
                              <div className="d-flex justify-content-end pe-2 align-items-center baseTagwrapper">
                                <img
                                  src={require("./assets/base.svg").default}
                                  alt=""
                                  style={{ height: 20, width: 20 }}
                                  className="popup-chains-icon"
                                ></img>
                                <h6
                                  className={`d-flex justify-content-center align-items-center chain-popup-text-active`}
                                >
                                  Base Chain
                                </h6>
                              </div>
                            )}
                            {item.chain === "avax" && (
                              <div className="d-flex justify-content-end align-items-center pe-2 avaxTagWrapper">
                                <img
                                  src={require("./assets/avaxlogo.svg").default}
                                  alt=""
                                  style={{ height: 20, width: 20 }}
                                  className="popup-chains-icon"
                                ></img>
                                <h6
                                  className={`d-flex justify-content-center align-items-center chain-popup-text-active`}
                                >
                                  Avalanche
                                </h6>
                              </div>
                            )}

                            <TopPoolsCard
                              network={network.toString()}
                              isNewPool={item.new_pool === "Yes" ? true : false}
                              isStaked={
                                userPools.length > 0
                                  ? userPools.find(
                                      (obj) => obj.contract_address === item.id
                                    )
                                    ? true
                                    : false
                                  : false
                              }
                              chain={network}
                              top_pick={item.top_pick}
                              tokenName={item.pair_name}
                              apr={item.apy_percent + "%"}
                              tvl={
                                item.type === "staking" &&
                                item.pair_name === "esc"
                                  ? "$" +getFormattedNumber(totalTvlETH)
                                  : item.type === "staking" &&
                                    item.pair_name === "BNB"
                                  ? "$" +getFormattedNumber(totalTvlBNB)
                                  : item.pair_name === "AVAX" &&
                                    item.type === "staking"
                                  ? "$" +getFormattedNumber(totalTvlAVAX)
                                  : item.tvl_usd === "--"
                                  ? item.tvl_usd
                                  : "$" + getFormattedNumber(item.tvl_usd)
                              }
                              lockTime={item.lock_time ? item.lock_time : 30}
                              tokenLogo={
                                item.icon
                                  ? item.icon
                                  : item.pair_name === "iDYP"
                                  ? "idypius.svg"
                                  : item.pair_name === "DYP"
                                  ? "dyplogo.svg"
                                  : item.pair_name === "BNB"
                                  ? "bnbChain.svg"
                                  : item.pair_name === "esc"
                                  ? "elastos.svg"
                                  : item.pair_name === "AVAX"
                                  ? "avax.svg"
                                  : "newCawsLogo.png"
                              }
                              onShowDetailsClick={() => {
                                setActiveCard(topPools[index]);
                                setcardIndex(index);
                                setDetails(index);
                                setselectedPool(item);
                                setShowDetails(true);
                              }}
                              onHideDetailsClick={() => {
                                setActiveCard(null);
                                setDetails();
                              }}
                              cardType={"table"}
                              details={details === index ? true : false}
                              expired={false}
                              isPremium={isPremium}
                            />
                          </div>
                        );
                      })
                    ) : (
                      <div
                        className="w-100 d-flex justify-content-center align-items-center mt-5"
                        style={{ gridColumn: "1 / 3" }}
                      >
                        <FadeLoader color="#7770DF" />
                      </div>
                    )} */}
                  </div>
                  {/* {activeCardFarm && network === 56 ? (
                    <BscFarmingFunc
                      is_wallet_connected={isConnected}
                      wbnbPrice={wbnbPrice}
                      coinbase={coinbase}
                      latestTvl={theBnbPool[0][1].tvl_usd}
                      the_graph_result={the_graph_resultbsc}
                      lp_id={LP_IDBNB_Array[cardIndex]}
                      chainId={network.toString()}
                      handleConnection={handleConnection}
                      expired={false}
                      handleSwitchNetwork={handleSwitchNetwork}
                      latestApr={theBnbPool[0][1].apy_percent}
                      liquidity={wbsc_address}
                      constant={window.farming_activebsc_1}
                      staking={window.constant_staking_newbscactive1}
                      token={window.token_newbsc}
                      lp_symbol={"USD"}
                      lock="3 Days"
                      rebase_factor={1}
                      expiration_time={"18 July 2024"}
                      fee="0.4"
                      finalApr={theBnbPool[0][1].apy_percent}
                      lockTime={3}
                      listType={"table"}
                    />
                  ) : (
                    <></>
                  )} */}
                </div>
              ) : (
                <div className="d-flex flex-column gap-4">
                  {/* <div className="row m-0 gap-4 toppool-allwrapper">
                    {topPools.length > 0 &&
                    // (network === 1 ||
                    //   network === 1030 ||
                    //   network === 8453 ||
                    //   network === 0 ||
                    //   network === 1482601649)&&
                    loading === false ? (
                      topPools.slice(0, 2).map((item, index) => {
                        return (
                          <div className="poolscardwrapper cursor-pointer position-relative p-0 position-relative">
                            {item.chain === "bnb" && (
                              <div className="d-flex justify-content-end align-items-center bnbTagwrapper pe-2">
                                <img
                                  src={require("./assets/bnblogo.svg").default}
                                  alt=""
                                  style={{ height: 20, width: 20 }}
                                  className="popup-chains-icon"
                                ></img>
                                <h6
                                  className={`d-flex justify-content-center align-items-center chain-popup-text-active`}
                                >
                                  BNB Chain
                                </h6>
                              </div>
                            )}
                            {item.chain === "esc" && (
                              <div className="d-flex justify-content-end pe-2 align-items-center ethereumTagwrapper">
                                <img
                                  src={require("./assets/ethereum.svg").default}
                                  alt=""
                                  style={{ height: 20, width: 20 }}
                                  className="popup-chains-icon"
                                ></img>
                                <h6
                                  className={`d-flex justify-content-center align-items-center chain-popup-text-active`}
                                >
                                  Ethereum
                                </h6>
                              </div>
                            )}
                            {item.chain === "base" && (
                              <div className="d-flex justify-content-end pe-2 align-items-center baseTagwrapper">
                                <img
                                  src={require("./assets/base.svg").default}
                                  alt=""
                                  style={{ height: 20, width: 20 }}
                                  className="popup-chains-icon"
                                ></img>
                                <h6
                                  className={`d-flex justify-content-center align-items-center chain-popup-text-active`}
                                >
                                  Base
                                </h6>
                              </div>
                            )}
                            {item.chain === "avax" && (
                              <div className="d-flex justify-content-end align-items-center pe-2 avaxTagWrapper">
                                <img
                                  src={require("./assets/avaxlogo.svg").default}
                                  alt=""
                                  style={{ height: 20, width: 20 }}
                                  className="popup-chains-icon"
                                ></img>
                                <h6
                                  className={`d-flex justify-content-center align-items-center chain-popup-text-active`}
                                >
                                  Avalanche
                                </h6>
                              </div>
                            )}
                            <TopPoolsCard
                              key={index}
                              network={network.toString()}
                              isNewPool={item.new_pool === "Yes" ? true : false}
                              isStaked={
                                userPools.length > 0
                                  ? userPools.find(
                                      (obj) => obj.contract_address === item.id
                                    )
                                    ? true
                                    : false
                                  : false
                              }
                              chain={network}
                              top_pick={item.top_pick}
                              tokenName={item.pair_name}
                              apr={item.apy_percent + "%"}
                              tvl={
                                item.type === "staking" &&
                                item.pair_name === "esc"
                                  ? "$" +getFormattedNumber(totalTvlETH)
                                  : item.type === "staking" &&
                                    item.pair_name === "BNB"
                                  ? "$" +getFormattedNumber(totalTvlBNB)
                                  : item.pair_name === "AVAX" &&
                                    item.type === "staking"
                                  ? "$" +getFormattedNumber(totalTvlAVAX)
                                  : item.tvl_usd === "--"
                                  ? item.tvl_usd
                                  : "$" + getFormattedNumber(item.tvl_usd)
                              }
                              lockTime={item.lock_time ? item.lock_time : 30}
                              tokenLogo={
                                item.icon
                                  ? item.icon
                                  : item.pair_name === "iDYP"
                                  ? "idypius.svg"
                                  : item.pair_name === "DYP"
                                  ? "dyplogo.svg"
                                  : item.pair_name === "BNB"
                                  ? "bnbChain.svg"
                                  : item.pair_name === "esc"
                                  ? "elastos.svg"
                                  : item.pair_name === "AVAX"
                                  ? "avax.svg"
                                  : "newCawsLogo.png"
                              }
                              onShowDetailsClick={() => {
                                setActiveCard(topPools[index]);
                                setcardIndex(index);
                                setDetails(index);
                                setselectedPool(item);
                                setShowDetails(true);
                              }}
                              onHideDetailsClick={() => {
                                setActiveCard(null);
                                setDetails();
                              }}
                              cardType={"table"}
                              details={details === index ? true : false}
                              expired={false}
                              isPremium={isPremium}
                            />
                          </div>
                        );
                      })
                    ) : (
                      <div
                        className="w-100 d-flex justify-content-center align-items-center mt-5"
                        style={{ gridColumn: "1 / 3" }}
                      >
                        <FadeLoader color="#7770DF" />
                      </div>
                    )}
                  </div> */}
                </div>
              )}
            </div>
            <div className="row m-0 align-items-center justify-content-between gap-2 w-100">
              <h6 className="top-pools-title">News</h6>
              <NavLink
                className="view-more-title d-flex justify-content-center align-items-center gap-1"
                to="/news"
              >
                View all <img src={rightarrow} alt="" />
              </NavLink>
              <div className="d-flex flex-column flex-md-row gap-3 justify-content-between px-0">
                {popularNewsData.length !== 0 && (
                  <>
                    {" "}
                    <TrendingNews
                      image={popularNewsData[0]?.image}
                      title={popularNewsData[0]?.title}
                      date={popularNewsData[0]?.date}
                      link={popularNewsData[0]?.id}
                    />
                    <div className="d-flex flex-column flex-lg-row gap-3 regular-news">
                      <NewsCard
                        image={popularNewsData[1]?.image}
                        title={popularNewsData[1]?.title}
                        date={popularNewsData[1]?.date}
                        link={popularNewsData[1]?.id}
                      />
                      <NewsCard
                        image={popularNewsData[2]?.image}
                        title={popularNewsData[2]?.title}
                        date={popularNewsData[2]?.date}
                        link={popularNewsData[2]?.id}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>

            {/* <div className="row w-100  m-0 mt-4 position-relative mb-4">
              <div className="d-flex align-items-center gap-2 tags-position">
                <div className="expired-caws-tag px-3 py-1">
                  <span className="expired-caws-span">Upcoming</span>
                </div>
              </div>
              <div className="col-12 px-0">
                <div className="caws-wod-stake-wrapper d-flex align-items-center w-100 p-4 p-lg-5">
                  <div className="stake-stats-wrapper flex-row flex-lg-column d-flex align-items-center justify-content-center gap-4 gap-lg-2">
                    <div className="stake-stats-item-2 d-flex flex-column align-items-center justify-content-center">
                      <h6>50%</h6>
                      <span>APR</span>
                    </div>
                    <div className="stake-stats-item-2 d-flex flex-column align-items-center justify-content-center">
                      <h6 className="blurrypool">CARROT</h6>
                      <span>Rewards</span>
                    </div>
                    <div className="stake-stats-item-2 d-flex flex-column align-items-center justify-content-center">
                      <h6>No Lock</h6>
                      <span>Lock Time</span>
                    </div>
                  </div>
                  <div className="d-flex align-items-start align-items-lg-center justify-content-between h-100 w-100 position-relative">
                    <div className="d-flex flex-column gap-4">
                      <div className="d-flex flex-column gap-2">
                        <h6 className="market-stake-title">
                          Mrs Bunny &amp; Bunny Punk
                        </h6>
                        <span className="market-stake-desc">
                          Combine your Mrs Bunny and Bunny Punk NFTs to earn
                          daily boosted rewards.
                        </span>
                      </div>
                      <div className="d-flex align-items-center gap-3">
                        <button className="btn rewards-btn px-4 py-2">
                          Coming soon
                        </button>
                      </div>
                    </div>
                    <div className="tvl-wrapper">
                      <h6 className="market-stake-tvl">$ 0.00</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>

          <div className="right-side-wrapper d-flex flex-column flex-md-row flex-xxl-column gap-4">
            {/* <div className="launchpad-section-wrapper d-flex flex-column gap-3 gap-xxl-1">
              <LaunchpadCard />
            </div> */}

            {/* <ChainlinkCard />
            <div
              className="faq-items-wrapper d-flex flex-column"
              style={{ gap: "11px" }}
            >
              <h6 className="header">FAQs</h6>
              <div className="faq-grid">
                {faqItems.map((faq, index) => (
                  <FaqCard
                    key={index}
                    title={faq.title}
                    option={faq.option}
                    pathName={faq.pathName}
                    section={faq.section}
                    pool={faq.pool}
                    faqIndex={faq.faqIndex}
                  />
                ))}
              </div>
            </div> */}
          </div>
        </div>
      </div>
      {showDetails && (
        <Modal
          open={showDetails}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="d-flex flex-column justify-content-center position-relative">
              <div className="d-flex flex-column gap-3 align-items-center justify-content-between">
                <div className="d-flex gap-2 w-100 align-items-center">
                  <div className="d-flex align-items-center gap-5 w-100">
                    <span
                      className={
                        selectedTab === "deposit"
                          ? "switchchain-txt-active"
                          : "switchchain-txt"
                      }
                      onClick={() => {
                        setselectedTab("deposit");
                      }}
                    >
                      Deposit
                    </span>
                    <span
                      className={
                        selectedTab === "withdraw"
                          ? "switchchain-txt-active"
                          : "switchchain-txt"
                      }
                      onClick={() => {
                        setselectedTab("withdraw");
                      }}
                    >
                      Withdraw
                    </span>
                  </div>
                  <img
                    src={closeX}
                    alt=""
                    className="close-x position-relative cursor-pointer "
                    onClick={() => {
                      setShowDetails(false);
                      setselectedTab("deposit");
                      setDetails(888);
                    }}
                    style={{
                      bottom: "17px",
                      alignSelf: "end",
                      width: 16,
                      height: 16,
                    }}
                  />
                </div>
                {selectedPool?.name !== "BNB" &&
                  selectedPool?.name !== "ETH" &&
                  selectedPool?.name !== "AVAX" && (
                    <div className="locktimewrapper align-items-center gap-2">
                      <button
                        className={
                          selectedPool?.lock_time?.split(" ")[0] === "No"
                            ? "method-btn-active"
                            : "method-btn-disabled"
                        }
                      >
                        Flexible
                      </button>
                      <button
                        className={
                          parseInt(selectedPool?.lock_time?.split(" ")[0]) ===
                          30
                            ? "method-btn-active"
                            : "method-btn-disabled"
                        }
                      >
                        30 Days
                      </button>
                      <button
                        className={
                          parseInt(selectedPool?.lock_time?.split(" ")[0]) ===
                          60
                            ? "method-btn-active"
                            : "method-btn-disabled"
                        }
                      >
                        60 Days
                      </button>
                      <button
                        className={
                          parseInt(selectedPool?.lock_time?.split(" ")[0]) ===
                          90
                            ? "method-btn-active"
                            : "method-btn-disabled"
                        }
                      >
                        90 Days
                      </button>
                      <button
                        className={
                          parseInt(selectedPool?.lock_time?.split(" ")[0]) ===
                          120
                            ? "method-btn-active"
                            : "method-btn-disabled"
                        }
                      >
                        120 Days
                      </button>
                    </div>
                  )}
                {selectedPool?.name !== "BNB" &&
                  selectedPool?.name !== "ETH" &&
                  selectedPool?.name !== "AVAX" && (
                    <div className="info-pool-wrapper p-3 w-100">
                      <div className="info-pool-inner-wrapper d-flex flex-column flex-lg-row align-items-center gap-2">
                        <div className="info-pool-item p-2">
                          <div className="d-flex justify-content-between gap-1 align-items-center">
                            <span className="info-pool-left-text">
                              APR{" "}
                              <ClickAwayListener onClickAway={aprClose}>
                                <Tooltip
                                  open={aprTooltip}
                                  disableFocusListener
                                  disableHoverListener
                                  disableTouchListener
                                  placement="top"
                                  title={
                                    <div className="tooltip-text">
                                      {
                                        "APR reflects the interest rate of earnings on an account over the course of one year."
                                      }
                                    </div>
                                  }
                                >
                                  <img
                                    src={moreinfo}
                                    alt=""
                                    onClick={aprOpen}
                                  />
                                </Tooltip>
                              </ClickAwayListener>
                            </span>
                            <span className="info-pool-right-text">
                              {selectedPool?.apy_performancefee}%
                            </span>
                          </div>
                        </div>
                        <div
                          className={`info-pool-item d-flex gap-2 justify-content-between p-2`}
                        >
                          <span className="info-pool-left-text">Chain</span>
                          <span className="info-pool-right-text d-flex gap-1 align-items-center">
                            <img
                              src={
                                selectedPool?.chain === "bnb"
                                  ? bnbIcon
                                  : selectedPool.chain === "esc"
                                  ? ethereumIcon
                                  : avaxIcon
                              }
                              alt=""
                              width={12}
                              height={12}
                            />
                            {selectedPool?.chain === "bnb"
                              ? "BNB Chain"
                              : selectedPool.chain === "esc"
                              ? "Elastos"
                              : "Avalanche"}
                          </span>
                        </div>
                        <div className="info-pool-item p-2">
                          <div className="d-flex justify-content-between gap-1 align-items-center">
                            <span className="info-pool-left-text">TVL</span>
                            <span className="info-pool-right-text">
                              ${getFormattedNumber(selectedPool.tvl_usd, 2)}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                {activeCard &&
                selectedPool?.id ===
                  "0x41b8a58f4307ea722ad0a964966caa18a6011d93" ? (
                  <InitConstantStakingiDYP
                    selectedPool={selectedPool}
                    selectedTab={selectedTab}
                    is_wallet_connected={isConnected}
                    coinbase={coinbase}
                    the_graph_result={the_graph_result}
                    lp_id={lp_id[cardIndex]}
                    chainId={network.toString()}
                    handleConnection={handleConnection}
                    handleSwitchNetwork={handleSwitchNetwork}
                    expired={false}
                    staking={window.constant_staking_idyp_5}
                    listType={"table"}
                    finalApr={selectedPool?.apy_performancefee}
                    apr={selectedPool?.apy_percent}
                    liquidity={eth_address}
                    expiration_time={"18 July 2024"}
                    other_info={false}
                    fee_s={selectedPool?.performancefee}
                    lockTime={
                      selectedPool?.lock_time?.split(" ")[0] === "No"
                        ? "No Lock"
                        : parseInt(selectedPool?.lock_time?.split(" ")[0])
                    }
                    onConnectWallet={() => {
                      onConnectWallet();
                      setShowDetails(false);
                      setActiveCard();
                      setselectedPool([]);
                      setDetails();
                    }}
                  />
                ) : activeCard &&
                  selectedPool?.id ===
                    "0xe026fb242d9523dc8e8d8833f7309dbdbed59d3d" ? (
                  <StakeAvaxIDyp
                    selectedPool={selectedPool}
                    selectedTab={selectedTab}
                    is_wallet_connected={isConnected}
                    coinbase={coinbase}
                    the_graph_result={the_graph_resultavax}
                    chainId={network.toString()}
                    handleConnection={handleConnection}
                    handleSwitchNetwork={handleSwitchNetwork}
                    expired={false}
                    staking={window.constant_staking_idypavax_7}
                    listType={"table"}
                    finalApr={selectedPool.apy_performancefee}
                    apr={selectedPool?.apy_percent}
                    liquidity={avax_address}
                    expiration_time={"18 July 2024"}
                    other_info={false}
                    fee_s={selectedPool?.performancefee}
                    lockTime={
                      selectedPool?.lock_time?.split(" ")[0] === "No"
                        ? "No Lock"
                        : parseInt(selectedPool?.lock_time?.split(" ")[0])
                    }
                    onConnectWallet={() => {
                      onConnectWallet();
                      setShowDetails(false);
                      setActiveCard();
                      setselectedPool([]);
                      setDetails();
                    }}
                  />
                ) : activeCard &&
                  selectedPool?.chain === "bnb" &&
                  selectedPool?.id ===
                    "0x8cee06119fffecdd560ee83b26cccfe8e2fe6603" ? (
                  <StakeDypiusBsc
                    selectedPool={selectedPool}
                    selectedTab={selectedTab}
                    staking={window.constant_staking_dypius_bsc1}
                    apr={selectedPool?.apy_percent}
                    liquidity={wbsc_address}
                    expiration_time={"09 Nov 2024"}
                    finalApr={selectedPool?.apy_performancefee}
                    lockTime={
                      selectedPool?.lock_time?.split(" ")[0] === "No"
                        ? "No Lock"
                        : parseInt(selectedPool?.lock_time?.split(" ")[0])
                    }
                    listType={"table"}
                    other_info={false}
                    fee={selectedPool?.performancefee}
                    is_wallet_connected={isConnected}
                    coinbase={coinbase}
                    the_graph_result={the_graph_resultbsc}
                    chainId={network.toString()}
                    handleConnection={handleConnection}
                    handleSwitchNetwork={handleSwitchNetwork}
                    expired={false}
                    referrer={referrer}
                    onConnectWallet={() => {
                      onConnectWallet();
                      setShowDetails(false);
                      setActiveCard();
                      setselectedPool([]);
                      setDetails();
                    }}
                  />
                ) : activeCard &&
                  selectedPool?.chain === "avax" &&
                  selectedPool?.id ===
                    "0x8cee06119fffecdd560ee83b26cccfe8e2fe6603" ? (
                  <StakeDypiusAvax
                    selectedPool={selectedPool}
                    selectedTab={selectedTab}
                    staking={window.constant_staking_dypius_avax1}
                    apr={selectedPool?.apy_percent}
                    liquidity={avax_address}
                    expiration_time={"09 Nov 2024"}
                    finalApr={selectedPool?.apy_performancefee}
                    lockTime={
                      selectedPool?.lock_time?.split(" ")[0] === "No"
                        ? "No Lock"
                        : parseInt(selectedPool?.lock_time?.split(" ")[0])
                    }
                    listType={"table"}
                    other_info={false}
                    fee_s={selectedPool?.performancefee}
                    is_wallet_connected={isConnected}
                    coinbase={coinbase}
                    the_graph_result={the_graph_resultavax}
                    chainId={network.toString()}
                    handleConnection={handleConnection}
                    handleSwitchNetwork={handleSwitchNetwork}
                    expired={false}
                    referrer={referrer}
                    onConnectWallet={() => {
                      onConnectWallet();
                      setShowDetails(false);
                      setActiveCard();
                      setselectedPool([]);
                      setDetails();
                    }}
                  />
                ) : activeCard &&
                  selectedPool?.id ===
                    "0x5b9E45dA128b8F1Dc8E9065263D0AFd098C341D6" &&
                  selectedPool.name !== "AVAX" ? (
                  <StakeDypiusEth
                    selectedPool={selectedPool}
                    selectedTab={selectedTab}
                    staking={window.constant_staking_dypius_eth1}
                    apr={selectedPool?.apy_percent}
                    liquidity={eth_address}
                    expiration_time={"09 Nov 2024"}
                    finalApr={selectedPool?.apy_performancefee}
                    lockTime={
                      selectedPool?.lock_time?.split(" ")[0] === "No"
                        ? "No Lock"
                        : parseInt(selectedPool?.lock_time?.split(" ")[0])
                    }
                    listType={"table"}
                    other_info={false}
                    fee={selectedPool?.performancefee}
                    is_wallet_connected={isConnected}
                    coinbase={coinbase}
                    the_graph_result={the_graph_result}
                    chainId={network.toString()}
                    handleConnection={handleConnection}
                    handleSwitchNetwork={handleSwitchNetwork}
                    expired={false}
                    referrer={referrer}
                    onConnectWallet={() => {
                      onConnectWallet();
                      setShowDetails(false);
                      setActiveCard();
                      setselectedPool([]);
                      setDetails();
                    }}
                  />
                ) : activeCard && selectedPool.name === "BNB" ? (
                  <StakeDypiusBscOther
                    selectedTab={selectedTab}
                    selectedBtn={selectedBtn}
                    selectedPool={selectedPool}
                    staking={window.constant_staking_dypius_bscother1}
                    coinbase={coinbase}
                    the_graph_result={the_graph_result}
                    expiration_time={"09 Nov 2024"}
                    lockTime={parseInt(selectedPool.poolList[0].lockTime)}
                    finalApr={selectedPool.maxAPR}
                    fee={selectedPool.poolList[0].performancefee}
                    apr={selectedPool?.poolList[0].aprPercent}
                    earlyFee={selectedPool?.poolList[0].earlyFee}
                    expired={
                      selectedPool?.poolList[0].expired === "No" ? false : true
                    }
                    maximumDeposit={selectedPool?.poolList[0].maximumDeposit}
                    poolCap={selectedPool?.poolList[0].poolCap}
                    chainId={network.toString()}
                    onConnectWallet={() => {
                      onConnectWallet();
                      setShowDetails(false);
                      setActiveCard();
                      setselectedPool([]);
                      setDetails();
                    }}
                    is_wallet_connected={isConnected}
                    livePremiumOnly={false}
                    isPremium={isPremium}
                    totalTvl={totalTvlBNB}
                    onRefreshTvl={() => {
                      setCount(count + 1);
                    }}
                  />
                ) : activeCard && selectedPool.name === "esc" ? (
                  <StakeDypiusEthOther
                    selectedTab={selectedTab}
                    selectedBtn={selectedBtn}
                    selectedPool={selectedPool}
                    staking={window.constant_staking_dypius_ethother1}
                    coinbase={coinbase}
                    the_graph_result={the_graph_result}
                    expiration_time={"09 Nov 2024"}
                    lockTime={parseInt(selectedPool.poolList[0].lockTime)}
                    finalApr={selectedPool.maxAPR}
                    fee={selectedPool.poolList[0].performancefee}
                    apr={selectedPool?.poolList[0].aprPercent}
                    earlyFee={selectedPool?.poolList[0].earlyFee}
                    expired={
                      selectedPool?.poolList[0].expired === "No" ? false : true
                    }
                    maximumDeposit={selectedPool?.poolList[0].maximumDeposit}
                    poolCap={113}
                    chainId={network.toString()}
                    onConnectWallet={() => {
                      onConnectWallet();
                      setShowDetails(false);
                      setActiveCard();
                      setselectedPool([]);
                      setDetails();
                    }}
                    is_wallet_connected={isConnected}
                    livePremiumOnly={livePremiumOnly}
                    isPremium={isPremium}
                    totalTvl={totalTvlETH}
                    onRefreshTvl={() => {
                      setCount(count + 1);
                    }}
                  />
                ) : activeCard && selectedPool.name === "AVAX" ? (
                  <StakeDypiusAvaxOther
                    selectedTab={selectedTab}
                    selectedBtn={selectedBtn}
                    selectedPool={selectedPool}
                    staking={window.constant_staking_dypius_avaxother1}
                    coinbase={coinbase}
                    the_graph_result={the_graph_result}
                    expiration_time={"09 Nov 2024"}
                    lockTime={30}
                    finalApr={selectedPool.maxAPR}
                    fee_s={selectedPool.poolList[0].performancefee}
                    apr={selectedPool?.poolList[0].aprPercent}
                    earlyFee={selectedPool?.poolList[0].earlyFee}
                    expired={
                      selectedPool?.poolList[0].expired === "No" ? false : true
                    }
                    maximumDeposit={selectedPool?.poolList[0].maximumDeposit}
                    poolCap={selectedPool?.poolList[0].poolCap}
                    chainId={network.toString()}
                    onConnectWallet={() => {
                      onConnectWallet();
                      setShowDetails(false);
                      setActiveCard();
                      setselectedPool([]);
                      setDetails();
                    }}
                    is_wallet_connected={isConnected}
                    isPremium={isPremium}
                    livePremiumOnly={livePremiumOnly}
                    totalTvl={totalTvlAVAX}
                    onRefreshTvl={() => {
                      setCount(count + 1);
                    }}
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
          </Box>
        </Modal>
      )}
    </>
  );
};

Dashboard.propTypes = {
  refreshData: PropTypes.func.isRequired,
};

export default Dashboard;
