import React, { useEffect } from "react";

const Toast = ({ message, type, clearToast }) => {
  useEffect(() => {
    const timeout = setTimeout(clearToast, 5000); // Automatically clears the toast after 3 seconds
    return () => clearTimeout(timeout);
  }, [clearToast]);

  const toastClasses = {
    success: "button-main button-main text-white",
    danger: "bg-red-100 text-red-500 dark:bg-red-800 dark:text-red-200",
    warning: "bg-orange-100 text-orange-500 dark:bg-orange-700 dark:text-orange-200"
  }[type] || "";

  return (
    <div className={`fixed top-4 right-4 z-50 max-w-sm p-4 mb-4 flex text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800 ${toastClasses}`} role="alert">
      <div className="flex items-center justify-center space-y-2">
        <div className="text-sm font-normal">{message}</div>
        <button
          type="button"
          onClick={clearToast}
          className="nomargin text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
          aria-label="Close"
        >
          <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" clipRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default Toast;
