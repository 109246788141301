import React, { useState, useEffect } from "react";
import "./custom.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { WagmiConfig, createClient, configureChains, useSigner } from "wagmi";
import { publicProvider } from "wagmi/providers/public";
import { ethers } from "ethers";
import { jsonRpcProvider } from "wagmi/providers/jsonRpc";
import Timer3 from "./components/CountDown";
import confetti from "canvas-confetti";
import walleticon from "./assets/walleticon.svg";
import copy from "./assets/copy.svg";
import submit from "./assets/submit.svg";
import "./governance-new.css";
import Comp1 from "./components/compo1";
import GameRulesModal from "./components/InfoModal";
import ticketsvg from "./ticket.svg";
import axios from "axios";
import getFormattedNumber from "../../functions/get-formatted-number";

const abi = [
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_token",
				"type": "address"
			}
		],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "target",
				"type": "address"
			}
		],
		"name": "AddressEmptyCode",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "AddressInsufficientBalance",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "numOfTickets",
				"type": "uint256"
			}
		],
		"name": "buyTickets",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "drawWinnerTickets",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "FailedInnerCall",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			}
		],
		"name": "OwnableInvalidOwner",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "OwnableUnauthorizedAccount",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "pause",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "token",
				"type": "address"
			}
		],
		"name": "SafeERC20FailedOperation",
		"type": "error"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "timestamp",
				"type": "uint256"
			}
		],
		"name": "DrawExecuted",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "lotteryId",
				"type": "uint256"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "ticketSupply",
				"type": "uint256"
			}
		],
		"name": "LotteryClose",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "lotteryId",
				"type": "uint256"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "ticketSupply",
				"type": "uint256"
			}
		],
		"name": "LotteryOpen",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "Paused",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "refundAll",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "user",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "RefundProcessed",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "restartDraw",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "buyer",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "numOfTickets",
				"type": "uint256"
			}
		],
		"name": "TicketsPurchased",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "unpause",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "Unpaused",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "winner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "ticketNumber",
				"type": "uint256"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "WinnerSelected",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address[3]",
				"name": "winners",
				"type": "address[3]"
			},
			{
				"indexed": true,
				"internalType": "uint256[3]",
				"name": "ticketNumbers",
				"type": "uint256[3]"
			},
			{
				"indexed": false,
				"internalType": "uint256[3]",
				"name": "amounts",
				"type": "uint256[3]"
			}
		],
		"name": "WinnersDrawn",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "winner",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "WinningsWithdrawn",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "withdrawCommission",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "withdrawWinnings",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "checkWinningsAmount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "currentWinningReward",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "duration",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "expiration",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getTickets",
		"outputs": [
			{
				"internalType": "address[]",
				"name": "",
				"type": "address[]"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "addr",
				"type": "address"
			}
		],
		"name": "getWinningsForAddress",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "isWinner",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "lastWinners",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "lastWinnersAmount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "maxTickets",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "operatorTotalCommission",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "paused",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "remainingTickets",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "ticketCommission",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "ticketPrice",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "tickets",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "ticketsBought",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "token",
		"outputs": [
			{
				"internalType": "contract IERC20",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalPaidOut",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "winnings",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "winningTicketNumbers",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
];

const tokenAbi = [
  {
    type: "constructor",
    inputs: [
      { name: "_name", type: "string", internalType: "string" },
      { name: "_symbol", type: "string", internalType: "string" },
    ],
    stateMutability: "nonpayable",
  },
  {
    name: "ERC20InsufficientAllowance",
    type: "error",
    inputs: [
      { name: "spender", type: "address", internalType: "address" },
      { name: "allowance", type: "uint256", internalType: "uint256" },
      { name: "needed", type: "uint256", internalType: "uint256" },
    ],
  },
  {
    name: "ERC20InsufficientBalance",
    type: "error",
    inputs: [
      { name: "sender", type: "address", internalType: "address" },
      { name: "balance", type: "uint256", internalType: "uint256" },
      { name: "needed", type: "uint256", internalType: "uint256" },
    ],
  },
  {
    name: "ERC20InvalidApprover",
    type: "error",
    inputs: [{ name: "approver", type: "address", internalType: "address" }],
  },
  {
    name: "ERC20InvalidReceiver",
    type: "error",
    inputs: [{ name: "receiver", type: "address", internalType: "address" }],
  },
  {
    name: "ERC20InvalidSender",
    type: "error",
    inputs: [{ name: "sender", type: "address", internalType: "address" }],
  },
  {
    name: "ERC20InvalidSpender",
    type: "error",
    inputs: [{ name: "spender", type: "address", internalType: "address" }],
  },
  {
    name: "Approval",
    type: "event",
    inputs: [
      {
        name: "owner",
        type: "address",
        indexed: true,
        internalType: "address",
      },
      {
        name: "spender",
        type: "address",
        indexed: true,
        internalType: "address",
      },
      {
        name: "value",
        type: "uint256",
        indexed: false,
        internalType: "uint256",
      },
    ],
    anonymous: false,
  },
  {
    name: "DataDelivery",
    type: "event",
    inputs: [
      {
        name: "_from",
        type: "address",
        indexed: true,
        internalType: "address",
      },
      { name: "_to", type: "address", indexed: true, internalType: "address" },
      {
        name: "_value",
        type: "uint256",
        indexed: false,
        internalType: "uint256",
      },
      { name: "data", type: "bytes", indexed: false, internalType: "bytes" },
    ],
    anonymous: false,
  },
  {
    name: "Transfer",
    type: "event",
    inputs: [
      { name: "from", type: "address", indexed: true, internalType: "address" },
      { name: "to", type: "address", indexed: true, internalType: "address" },
      {
        name: "value",
        type: "uint256",
        indexed: false,
        internalType: "uint256",
      },
    ],
    anonymous: false,
  },
  {
    name: "allowance",
    type: "function",
    inputs: [
      { name: "owner", type: "address", internalType: "address" },
      { name: "spender", type: "address", internalType: "address" },
    ],
    outputs: [{ name: "", type: "uint256", internalType: "uint256" }],
    stateMutability: "view",
  },
  {
    name: "approve",
    type: "function",
    inputs: [
      { name: "spender", type: "address", internalType: "address" },
      { name: "value", type: "uint256", internalType: "uint256" },
    ],
    outputs: [{ name: "", type: "bool", internalType: "bool" }],
    stateMutability: "nonpayable",
  },
  {
    name: "balanceOf",
    type: "function",
    inputs: [{ name: "account", type: "address", internalType: "address" }],
    outputs: [{ name: "", type: "uint256", internalType: "uint256" }],
    stateMutability: "view",
  },
  {
    name: "burn",
    type: "function",
    inputs: [
      { name: "_from", type: "address", internalType: "address" },
      { name: "_value", type: "uint256", internalType: "uint256" },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    name: "callBack",
    type: "function",
    inputs: [],
    outputs: [{ name: "", type: "address", internalType: "address" }],
    stateMutability: "view",
  },
  {
    name: "decimals",
    type: "function",
    inputs: [],
    outputs: [{ name: "", type: "uint8", internalType: "uint8" }],
    stateMutability: "view",
  },
  {
    name: "factory",
    type: "function",
    inputs: [],
    outputs: [{ name: "", type: "address", internalType: "address" }],
    stateMutability: "view",
  },
  {
    name: "initialize",
    type: "function",
    inputs: [
      { name: "_maxSupply", type: "uint256", internalType: "uint256" },
      { name: "_decimals", type: "uint8", internalType: "uint8" },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    name: "maxSupply",
    type: "function",
    inputs: [],
    outputs: [{ name: "", type: "uint256", internalType: "uint256" }],
    stateMutability: "view",
  },
  {
    name: "mint",
    type: "function",
    inputs: [
      { name: "_to", type: "address", internalType: "address" },
      { name: "_value", type: "uint256", internalType: "uint256" },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    name: "name",
    type: "function",
    inputs: [],
    outputs: [{ name: "", type: "string", internalType: "string" }],
    stateMutability: "view",
  },
  {
    name: "setCallBack",
    type: "function",
    inputs: [{ name: "_callBack", type: "address", internalType: "address" }],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    name: "symbol",
    type: "function",
    inputs: [],
    outputs: [{ name: "", type: "string", internalType: "string" }],
    stateMutability: "view",
  },
  {
    name: "totalSupply",
    type: "function",
    inputs: [],
    outputs: [{ name: "", type: "uint256", internalType: "uint256" }],
    stateMutability: "view",
  },
  {
    name: "transfer",
    type: "function",
    inputs: [
      { name: "to", type: "address", internalType: "address" },
      { name: "value", type: "uint256", internalType: "uint256" },
    ],
    outputs: [{ name: "", type: "bool", internalType: "bool" }],
    stateMutability: "nonpayable",
  },
  {
    name: "transfer",
    type: "function",
    inputs: [
      { name: "to", type: "address", internalType: "address" },
      { name: "value", type: "uint256", internalType: "uint256" },
      { name: "_data", type: "bytes", internalType: "bytes" },
    ],
    outputs: [{ name: "", type: "bool", internalType: "bool" }],
    stateMutability: "nonpayable",
  },
  {
    name: "transferFrom",
    type: "function",
    inputs: [
      { name: "from", type: "address", internalType: "address" },
      { name: "to", type: "address", internalType: "address" },
      { name: "value", type: "uint256", internalType: "uint256" },
    ],
    outputs: [{ name: "", type: "bool", internalType: "bool" }],
    stateMutability: "nonpayable",
  },
];

const contractAddress = "0x6BD404349a03C9b4A796dfDd274a0e8ee5917C5a";
const tokenAddress = "0xaB1F7E5BF2587543FE41f268c59d35dA95f046e0"; // Replace with your token address

const AppLottery2 = () => {
  const [lotteryOpen, setLotteryOpen] = useState(false);
  const [endTimestamp, setEndTimestamp] = useState(0);
  const [countdown, setCountdown] = useState(0);
  const [ticketQuantity, setTicketQuantity] = useState(1);
  const [operatorTotalCommission, setOperatorTotalCommission] = useState(0);
  const [currentWinningReward, setCurrentWinningReward] = useState(0);
  const [lastWinner, setLastWinner] = useState(null);
  const [lastWinnerAmount, setLastWinnerAmount] = useState(0);
  const [remainingTickets, setRemainingTickets] = useState(0);
  const [winningTicketNumber, setWinningTicketNumber] = useState(0);
  const { data: signer } = useSigner();
  const [contract, setContract] = useState(null);
  const [tokenContract, setTokenContract] = useState(null);
  const [userTickets, setUserTickets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isOperator, setIsOperator] = useState(false);
  const [isRulesModalVisible, setIsRulesModalVisible] = useState(false);
  const [dexPairData, setDexPairData] = useState(null);


  const fetchDexscreenerData = async () => {
    try {
      const response = await axios.get(
        "https://api2.chainge.finance/v1/getPrice?chain=FSN&symbol=XCHNG&contractAddress=0xab1f7e5bf2587543fe41f268c59d35da95f046e0"
      );
      if (response.status === 200) {
        const dexData = response.data.data;
        setDexPairData({
          price: dexData.price,
          updateTime: dexData.updateTime,
          source: dexData.source,
        });
      }
    } catch (error) {
      console.error("Failed to fetch from Dexscreener:", error);
    }
  };
  
  
  // Fetch current winning reward
  const fetchCurrentWinningReward = async () => {
    try {
      const provider = new ethers.providers.JsonRpcProvider("https://mainnet.fusionnetwork.io");
      const contract = new ethers.Contract(contractAddress, abi, provider);
      const reward = await contract.currentWinningReward();
      if (reward) {
        setCurrentWinningReward(ethers.utils.formatEther(reward));
      }
    } catch (error) {
      console.error("Error fetching current winning reward:", error);
    }
  };
  
  useEffect(() => {
    fetchDexscreenerData();
    fetchCurrentWinningReward();
  }, []);

  const handleOpenRulesModal = () => {
    setIsRulesModalVisible(true);
  };


  useEffect(() => {
    if (winningTicketNumber > 0) {
      // Trigger confetti effect when the winner is drawn
      confetti({
        particleCount: 100,
        spread: 70,
        origin: { y: 0.6 },
      });
    }
  }, [winningTicketNumber]);

  const handleConfettiEffects = () => {
    // Confetti burst effect
    confetti({
      particleCount: 200,
      startVelocity: 30,
      spread: 360,
      origin: {
        x: Math.random(),
        y: Math.random() - 0.2,
      },
    });

    // Confetti cannon effect
    confetti({
      particleCount: 100,
      angle: 60,
      spread: 55,
      origin: { x: 0 },
    });
    confetti({
      particleCount: 100,
      angle: 120,
      spread: 55,
      origin: { x: 1 },
    });
  };

  const handleWithdrawWinnings = () => {
    withdrawWinnings();
    handleConfettiEffects();
  };

  useEffect(() => {
    const fetchUserTickets = async () => {
      if (contract && signer) {
        try {
          const address = await signer.getAddress();
          const tickets = await contract.getTickets();
          const userTickets = tickets
            .map((ticket, index) => ({ ticket, index }))
            .filter(
              (ticketObj) =>
                ticketObj.ticket.toLowerCase() === address.toLowerCase()
            )
            .map((ticketObj) => ticketObj.index);

          setUserTickets(userTickets);
        } catch (error) {
          console.error("Error fetching user tickets:", error);
        }
      }
    };

    fetchUserTickets();
  }, [contract, signer]);

  useEffect(() => {
    if (signer) {
      const newContract = new ethers.Contract(contractAddress, abi, signer);
      setContract(newContract);

      const newTokenContract = new ethers.Contract(
        tokenAddress,
        tokenAbi,
        signer
      );
      setTokenContract(newTokenContract);

      const checkOperator = async () => {
        try {
          const operator = await newContract.owner();
          const userAddress = await signer.getAddress();
          setIsOperator(operator === userAddress);
        } catch (error) {
          console.error("Error fetching operator:", error);
        }
      };
      checkOperator();

      const fetchExpiration = async () => {
        try {
          const expiration = await newContract.expiration();
          if (expiration) {
            setEndTimestamp(expiration.toNumber());
          }
        } catch (error) {
          console.error("Error fetching expiration:", error);
        }
      };
      fetchExpiration();

 

      const fetchOperatorTotalCommission = async () => {
        try {
          const commission = await newContract.operatorTotalCommission();
          if (commission) {
            setOperatorTotalCommission(ethers.utils.formatEther(commission));
          }
        } catch (error) {
          console.error("Error fetching operator total commission:", error);
        }
      };
      fetchOperatorTotalCommission();

      const fetchLastWinner = async () => {
        try {
          const winner = await newContract.lastWinners(0);
          if (winner) {
            setLastWinner(winner);
          }
        } catch (error) {
          console.error("Error fetching last winner:", error);
        }
      };
      fetchLastWinner();

      const fetchLastWinnerAmount = async () => {
        try {
          const amount = await newContract.lastWinnersAmount(0);
          if (amount) {
            setLastWinnerAmount(ethers.utils.formatEther(amount));
          }
        } catch (error) {
          console.error("Error fetching last winner amount:", error);
        }
      };
      fetchLastWinnerAmount();

      const fetchRemainingTickets = async () => {
        try {
          const tickets = await newContract.remainingTickets();
          if (tickets) {
            setRemainingTickets(tickets.toNumber());
          }
        } catch (error) {
          console.error("Error fetching remaining tickets:", error);
        }
      };
      fetchRemainingTickets();

      const fetchWinningTicketNumber = async () => {
        try {
          const ticketNumber = await newContract.winningTicketNumbers(0);
          if (ticketNumber) {
            setWinningTicketNumber(ticketNumber.toNumber());
          }
        } catch (error) {
          console.error("Error fetching winning ticket number:", error);
        }
      };
      fetchWinningTicketNumber();

      newContract.on("WinnersDrawn", (winners, ticketNumbers, amounts) => {
        console.log("WinnersDrawn event received", {
          winners,
          ticketNumbers,
          amounts,
        });

        if (winners && winners.length > 0) {
          setLastWinner(winners[0]);
        } else {
          setLastWinner(null); // or handle it as per your requirement
        }

        if (ticketNumbers && ticketNumbers.length > 0) {
          console.log("Ticket number:", ticketNumbers[0]);
          if (ticketNumbers[0]) {
            setWinningTicketNumber(ticketNumbers[0].toNumber());
          } else {
            setWinningTicketNumber(null);
          }
        } else {
          setWinningTicketNumber(null); // or handle it as per your requirement
        }

        if (amounts && amounts.length > 0) {
          console.log("Amount:", amounts[0]);
          if (amounts[0]) {
            setLastWinnerAmount(ethers.utils.formatEther(amounts[0]));
          } else {
            setLastWinnerAmount(null);
          }
        } else {
          setLastWinnerAmount(null); // or handle it as per your requirement
        }

        console.log(
          `Winners: ${winners}, Ticket Numbers: ${ticketNumbers}, Amounts: ${amounts}`
        );
      });

      return () => {
        newContract.removeAllListeners("WinnersDrawn");
      };
    }
  }, [signer]);

  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = Math.floor(Date.now() / 1000);
      const remainingTime = endTimestamp - currentTime;
      if (remainingTime <= 0) {
        clearInterval(interval);
        if (lotteryOpen) {
          drawWinner();
        }
        setCountdown(0);
      } else {
        setCountdown(remainingTime);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [endTimestamp, lotteryOpen]);

  const drawWinner = async () => {
    if (!contract) {
      toast.error("Contract is not loaded");
      return;
    }

    try {
      toast.info("Drawing winner...");
      const tx = await contract.drawWinnerTickets();
      await tx.wait();
      toast.success("Winner drawn successfully");
    } catch (error) {
      toast.error("Failed to draw winner. Please try again later.");
      console.error("Error drawing winner:", error);
    }
  };

  const buyTickets = async () => {
    if (!contract || !tokenContract) {
      toast.error("Contracts are not loaded");
      return;
    }

    setLoading(true);
    try {
      const totalCost = ethers.utils.parseEther(
        (ticketQuantity * 10).toString()
      );
      const userAddress = await signer.getAddress();

      toast.info("Approving token transfer...");

      // Approve the lottery contract to spend tokens on behalf of the user
      const approveTx = await tokenContract.approve(contractAddress, totalCost);
      await approveTx.wait();

      toast.info("Token transfer approved. Buying tickets...");

      // Call the buyTickets method on the lottery contract
      const tx = await contract.buyTickets(ticketQuantity);
      await tx.wait();

      toast.success("Tickets purchased successfully!");

      // Fetch and set user tickets after purchasing
      const tickets = await contract.getTickets();
      const userTickets = tickets
        .map((ticket, index) => ({ ticket, index }))
        .filter(
          (ticketObj) =>
            ticketObj.ticket.toLowerCase() === userAddress.toLowerCase()
        )
        .map((ticketObj) => ticketObj.index);

      setUserTickets(userTickets);
    } catch (error) {
      toast.error("Failed to buy tickets. Please try again later.");
      console.error("Error buying tickets:", error);
    } finally {
      setLoading(false);
    }
  };

  const refundAll = async () => {
    if (!contract) return;

    try {
      const tx = await contract.refundAll();
      await tx.wait();
      toast.success("Refunded all tickets successfully!");
    } catch (error) {
      toast.error("Failed to refund all tickets.");
      console.error("Error refunding all tickets:", error);
    }
  };

  const restartDraw = async () => {
    if (!contract) return;

    try {
      const tx = await contract.restartDraw();
      await tx.wait();
      toast.success("Draw restarted successfully!");
      const expiration = await contract.expiration();
      setEndTimestamp(expiration.toNumber());
    } catch (error) {
      toast.error("Failed to restart draw.");
      console.error("Error restarting draw:", error);
    }
  };

  const withdrawCommission = async () => {
    if (!contract) return;

    try {
      const tx = await contract.withdrawCommission();
      await tx.wait();
      toast.success("Commission withdrawn successfully!");
    } catch (error) {
      toast.error("Failed to withdraw commission.");
      console.error("Error withdrawing commission:", error);
    }
  };

  const withdrawWinnings = async () => {
    if (!contract) return;

    try {
      const tx = await contract.withdrawWinnings();
      await tx.wait();
      toast.success("Winnings withdrawn successfully!");
    } catch (error) {
      toast.error("Failed to Claim Winnings.");
      console.error("Error withdrawing winnings:", error);
    }
  };

  useEffect(() => {
    fetchDexscreenerData();
  }, []);

  return (
    <WagmiConfig client={client}>
      <div className="container-lg dashboardwrapper px-0">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
        />

        <div className="mb-3">
          <div>
            {/* <button className="btn filled-btn" onClick={handleOpenRulesModal}>View Lottery Rules</button>

  <GameRulesModal 
    modalId="gameRulesModal"
    visible={isRulesModalVisible}
    onModalClose={handleCloseRulesModal}
  /> */}
          </div>

          {winningTicketNumber > 0 && (
            <div className="mx-auto row w-100 flex-column flex-lg-row earn-hero gap-4 gap-lg-0 p-3 p-lg-4 justify-content-between">
              <div className="animate__animated animate__fadeInDown d-flex align-items-center w-100">
                <div className="d-flex flex-column flex-md-row align-items-center justify-content-between gap-3 w-100">
                  <div className="d-flex flex-column flex-md-row align-items-center gap-2">
                    <div className="migration-banner-title mb-0 text-3xl text-center">
                      Ticket{" "}
                      <span className="warning-txt text-4xl font-bold my-4">
                        #{winningTicketNumber}
                      </span>{" "}
                      from{" "}
                      <span>
                        {lastWinner.slice(0, 5) +
                          "..." +
                          lastWinner.slice(38, 42)}
                      </span>{" "}
                      won
                    </div>
                    <span className="warning-txt text-4xl font-bold my-4">
                      {lastWinnerAmount} XCHNG
                    </span>
                  </div>
                  <button
                    className="connectbtn btn mb-3"
                    disabled={loading}
                    onClick={handleWithdrawWinnings}
                  >
                    Claim Winnings
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="d-flex flex-column flex-md-row m-0 gap-3 justify-content-between">
          <div className="migration-banner-wrapper3 p-3">
            <div className="purplediv" style={{ background: "#8890c4" }}></div>
            <div className="text-center">
              <h6 className="migration-banner-title mb-0">The XCHNG Lottery</h6>
 
              <h1 className="warning-txt text-4xl font-bold mt-4">
                {currentWinningReward && dexPairData
                  ? new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format(currentWinningReward * dexPairData.price)
                  : "Loading..."}
              </h1>
              <span className="text-md text-white">
                {" "}
                {parseFloat(currentWinningReward).toFixed(0)} XCHNG
              </span>
              <h6 className="migration-banner-title mt-4">in prizes!</h6>
            </div>
            <div className="d-flex flex-column gap-3">
              <span className="migration-progress-info mb-0 text-center mt-4">
                <p className="migration-banner-subtitle my-6">
                  One ticket costs: 10 XCHNG
                </p>

                <div className="quantity-selector input-group justify-center">
                  <span className="input-group-btn">
                    <button
                      className="btn outline-btn"
                      type="button"
                      onClick={() => setTicketQuantity(ticketQuantity - 1)}
                      disabled={ticketQuantity === 1}
                    >
                      -
                    </button>
                  </span>
                  <input
                    type="number"
                    className="text-center outlines bg-transparent"
                    value={ticketQuantity}
                    onChange={(e) =>
                      setTicketQuantity(parseInt(e.target.value))
                    }
                    min={1}
                    max={1500} // Limiting to 1500 tickets per purchase
                  />
                  <span className="input-group-btn">
                    <button
                      className="btn outline-btn"
                      type="button"
                      onClick={() => setTicketQuantity(ticketQuantity + 1)}
                      disabled={ticketQuantity === 1500}
                    >
                      +
                    </button>
                  </span>
                </div>
                <div className="my-4">
                  <div className="animated-element mx-auto sc-eDnVMP sc-gKHVLF sc-goEyvm kpeGcW kFsaVr heXTyF">
                    <div className="sc-dLOxbp cJimZN">
                      <button
                        className="sc-bcPKhP xiIkU sc-jdEOXE bvJmG"
                        data-theme="light"
                        variant="primary"
                        scale="md"
                        onClick={buyTickets}
                      >
                        Buy Tickets
                      </button>
                    </div>
                    <div className="sc-jTmrli RrxyI">
                      <img
                        src={ticketsvg}
                        alt="Ticket SVG"
                        width={"100%"}
                        height={100}
                      />

                      {/* <svg
                        viewBox="0 0 296 121"
                        width="100%"
                        height="auto"
                        color="text"
                        xmlns="http://www.w3.org/2000/svg"
                        className="sc-grXZZQ ktVOoD"
                        style={{ width: "100%", height: "auto" }}
                      >
                        <g filter="url(#filter0_dd_ticket_purchase_card)">
                          <path
                            d="M4 16C4 7.16344 11.1634 0 20 0H66V113H20C11.1634 113 4 105.837 4 97V16Z"
                            fill="#FFB237"
                          ></path>
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M69.4931 2.94568C68.9511 1.38001 67.6569 0 66 0V10H90V0C88.3431 0 87.0489 1.38001 86.5069 2.94568C85.2868 6.4696 81.9389 9 78 9C74.0611 9 70.7132 6.4696 69.4931 2.94568Z"
                            fill="#FFB237"
                          ></path>
                          <rect
                            x="66"
                            y="10"
                            width="10"
                            height="93"
                            fill="#FFB237"
                          ></rect>
                          <path
                            d="M78 103V10"
                            stroke="#FFB237"
                            strokeWidth="4"
                            strokeDasharray="4 4"
                          ></path>
                          <rect
                            x="80"
                            y="10"
                            width="10"
                            height="93"
                            fill="#FFB237"
                          ></rect>
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M69.4931 110.054C68.9511 111.62 67.6569 113 66 113V103H90V113C88.3431 113 87.0489 111.62 86.5069 110.054C85.2868 106.53 81.9389 104 78 104C74.0611 104 70.7132 106.53 69.4931 110.054Z"
                            fill="#FFB237"
                          ></path>
                          <path
                            d="M90 0H276C284.837 0 292 7.16344 292 16V97C292 105.837 284.837 113 276 113H90V0Z"
                            fill="#FFB237"
                          ></path>
                        </g>
                        <defs>
                          <filter
                            id="filter0_dd_ticket_purchase_card"
                            x="0"
                            y="0"
                            width="296"
                            height="121"
                            filterUnits="userSpaceOnUse"
                            colorInterpolationFilters="sRGB"
                          >
                            <feFlood
                              floodOpacity="0"
                              result="BackgroundImageFix"
                            ></feFlood>
                            <feColorMatrix
                              in="SourceAlpha"
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            ></feColorMatrix>

                            <feOffset dx="2" dy="2"></feOffset>
                            <feColorMatrix
                              type="matrix"
                              values="0 0 0 0 1 0 0 0 0.686275 0 0 0 0 0 0 0 1 0"
                            ></feColorMatrix>
                            <feBlend
                              mode="normal"
                              in2="BackgroundImageFix"
                              result="effect1_dropShadow_ticket_purchase_card"
                            ></feBlend>
                            <feColorMatrix
                              in="SourceAlpha"
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            ></feColorMatrix>
                            <feOffset dy="4"></feOffset>
                            <feGaussianBlur stdDeviation="2"></feGaussianBlur>
                            <feColorMatrix
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                            ></feColorMatrix>
                            <feBlend
                              mode="normal"
                              in2="effect1_dropShadow_ticket_purchase_card"
                              result="effect2_dropShadow_ticket_purchase_card"
                            ></feBlend>
                            <feBlend
                              mode="normal"
                              in="SourceGraphic"
                              in2="effect2_dropShadow_ticket_purchase_card"
                              result="shape"
                            ></feBlend>
                          </filter>
                        </defs>
                      </svg> */}
                    </div>
                  </div>
                </div>
              </span>
            </div>
          </div>

          <div className="explorercard-wrapper d-flex position-relative w-full">
            <div
              className="purplediv"
              style={{ background: "#8890C4", top: "15px" }}
            ></div>
            <div className="col-12 col-lg-12 d-flex flex-column gap-3">
              <div className="justify-content-between gap-2 align-items-center">
                <h6 className="migration-banner-title mb-0">Your Tickets</h6>
              </div>

              <div className=" ">
                <div className="flex flex-wrap gap-4 w-full mt-4 max-h-[140px] overflow-y-scroll overflow-x-hidden py-2">
                  {userTickets.length > 0 ? (
                    userTickets.map((ticket, index) => (
                      <div
                        key={index}
                        className="sc-eDnVMP sc-gKHVLF sc-goEyvm as8888 kFsaVr heXTyF"
                      >
                        <div className="sc-dLOxbp cJimZN">
                          <span className="ml-4 text-2xl font-bold">
                            #{ticket}
                          </span>
                        </div>
                        <div className="sc-jTmrli RrxyI">
                          <img
                            src={ticketsvg}
                            alt="Ticket SVG"
                            width={"100%"}
                            height={"100%"}
                          />
                        </div>
                      </div>
                    ))
                  ) : (
                    <span className="no-tickets">No tickets purchased</span>
                  )}
                </div>
              </div>

              <Timer3 />
            </div>
          </div>
        </div>

        <Comp1 />
        {isOperator && (
          <div className="mt-4">
            <div className="">
              <h6 className="migration-banner-title mb-3">Admin Functions</h6>
            </div>

            <div className="gap-4 d-flex position-relative w-full flex-wrap">
              <button
                className="btn filled-btn"
                disabled={loading}
                onClick={drawWinner}
              >
                Draw Winner
              </button>
              <button
                className="btn filled-btn"
                disabled={loading}
                onClick={restartDraw}
              >
                Restart Draw
              </button>
              <button
                className="btn filled-btn"
                disabled={loading}
                onClick={refundAll}
              >
                Refund All
              </button>
              <button
                className="btn filled-btn"
                disabled={loading}
                onClick={withdrawCommission}
              >
                Withdraw Commission
              </button>
              <button
                className="btn filled-btn"
                disabled={loading}
                onClick={withdrawWinnings}
              >
                Claim Winnings
              </button>
            </div>
          </div>
        )}
      </div>
    </WagmiConfig>
  );
};

// Helper function to format time
const formatTime = (seconds) => {
  const days = Math.floor(seconds / (3600 * 24));
  const hours = Math.floor((seconds % (3600 * 24)) / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  const formatValue = (value, unit) => {
    return value > 0 ? `${value} ${unit}${value > 1 ? "s" : ""}, ` : "";
  };

  return (
    formatValue(days, "day") +
    formatValue(hours, "hour") +
    formatValue(minutes, "minute") +
    `${remainingSeconds} second${remainingSeconds > 1 ? "s" : ""}`
  );
};

const fsnchain = {
  id: 32659,
  name: "FSN",
  network: "Fusion Mainnet",
  nativeCurrency: {
    name: "FSN",
    symbol: "FSN",
    decimals: 18,
  },
  rpcUrls: {
    default: "https://mainnet.fusionnetwork.io",
  },
  blockExplorers: {
    default: { name: "Fusion Scanner", url: "https://fsnscan.com/" },
  },
  testnet: false,
};

const { chains, provider } = configureChains(
  [fsnchain],
  [
    jsonRpcProvider({
      rpc: (chain) => {
        if (chain.id === fsnchain.id) {
          return { http: chain.rpcUrls.default };
        }
        return null;
      },
    }),
    publicProvider(),
  ]
);

const client = createClient({
  autoConnect: true,
  provider,
});

export default AppLottery2;
