import { ethers } from "ethers";
import { MULTICALL_ADDR, FLEXIBLE_ADDR, PAIR_ADDR, TOKEN_ADDR, LOCK_ADDR, ROUTER_ADDR } from '../abis/address'
import MultiCallABI from '../abis/MultiCallABI.json'
import FlexibleABI from '../abis/FlexibleABI.json'
import ERC20ABI from '../abis/ERC20ABI.json';
import PairABI from '../abis/PancakePairABI.json'
import LockABI from '../abis/LockABI.json'
import RouterABI from '../abis/RouterABI.json'

export const RPC_ENDPOINT = 'https://rpc.glidefinance.io/'

export const simpleRpcProvider = new ethers.providers.JsonRpcProvider(RPC_ENDPOINT);

export const getContract = (abi, address, signer) => {
  const signerOrProvider = signer ?? simpleRpcProvider
  return new ethers.Contract(address, abi, signerOrProvider)
}


export const getMulticallContract = (signer) => {
  return getContract(MultiCallABI, MULTICALL_ADDR, signer)
}

export const getFlexibleContract = (signer) => {
  return getContract(FlexibleABI, FLEXIBLE_ADDR, signer)
}

export const getLockContract = (signer) => {
  return getContract(LockABI, LOCK_ADDR, signer);
}

export const getPairContract = (signer) => {
  return getContract(PairABI, PAIR_ADDR, signer)
}

export const getTokenContract = (signer) => {
  return getContract(ERC20ABI, TOKEN_ADDR, signer)
}

export const getRouterContract = (signer) => {
  return getContract(RouterABI, ROUTER_ADDR, signer);
}
export const multicall = async (abi, calls) => {
  try {
    const itf = new ethers.utils.Interface(abi)
    const multi = getMulticallContract();
    const calldata = calls.map((call) => [call.address.toLowerCase(), itf.encodeFunctionData(call.name, call.params)])
    const { returnData } = await multi.aggregate(calldata)
    const res = returnData.map((call, i) => itf.decodeFunctionResult(calls[i].name, call))

    return res
  } catch (error) {
    console.log(error);
  }
}