import React, { useEffect, useState } from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import { useAccount, useNetwork } from "wagmi";
import routeIcon from "./route-icon.svg"; // Ensure this path is correct

const steps = [
  {
    title: "Connect wallet",
    description: "Connect your wallet to start the minting process.",
    isActive: (props) => props.isConnected,
  },
  {
    title: "Connect to Fusion Mainnet",
    description: "Select the Fusion Mainnet to proceed.",
    isActive: (props) => props.isConnected && props.networkId === 32659,
  },
  {
    title: "Select Quantity",
    description: "Select the number of NFTs you want to mint.",
    isActive: (props) => props.selectedQuantity > 0,
  },
  {
    title: "Mint NFT",
    description: "Mint your NFT by confirming the transaction.",
    isActive: (props) => props.isMinted,
  },
];

const MintingTimeline = ({ selectedQuantity, isMinted }) => {
  const { address, isConnected } = useAccount();
  const { chain } = useNetwork();
  const [networkId, setNetworkId] = useState(null);

  useEffect(() => {
    if (chain) {
      setNetworkId(chain.id);
    }
  }, [chain]);

  const props = {
    isConnected,
    networkId,
    selectedQuantity,
    isMinted,
  };

  return (
    <div className="stepsborder">
      <div>
        <h6 className="guidetitle">
          <img src={routeIcon} alt="Route Icon" />
          Minting Process Guide
        </h6>
        <div className="separator"></div>
        <Timeline
          sx={{
            [`& .${timelineItemClasses.root}:before`]: {
              flex: 0,
              padding: 0,
            },
          }}
        >
          {steps.map((step, index) => (
            <TimelineItem key={index}>
              <TimelineSeparator>
                <TimelineDot
                  className={step.isActive(props) ? "greendot" : "passivedot"}
                />
                {index < steps.length - 1 && (
                  <TimelineConnector
                    className={
                      step.isActive(props) ? "greenline" : "passiveline"
                    }
                  />
                )}
              </TimelineSeparator>
              <TimelineContent>
                <div className="gap-1 d-flex flex-column gap-2 align-items-start timeline-wrapper-item">
                  <h4 className="listtext">{step.title}</h4>
                  <span className="status-desc">{step.description}</span>
                </div>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      </div>
    </div>
  );
};

export default MintingTimeline;
