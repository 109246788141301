import React, { useState, useEffect, useCallback } from "react";
import "./custom.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { WagmiConfig, createClient, configureChains, useSigner } from "wagmi";
import { publicProvider } from "wagmi/providers/public";
import { ethers } from "ethers";
import { jsonRpcProvider } from "wagmi/providers/jsonRpc";
import Timer3 from "./components/CountDown";
import confetti from "canvas-confetti";
import walleticon from "./assets/walleticon.svg";
import copy from "./assets/copy.svg";
import submit from "./assets/submit.svg";
import "./governance-new.css";
import Comp1 from "./components/compo1";
import GameRulesModal from "./components/InfoModal";
import ticketsvg from "./ticket.svg";
import axios from "axios";
import getFormattedNumber from "../../functions/get-formatted-number";
import "../FARMINNG/CustomToastStyles.css";
import premium from "../account/premium.svg";
import "./_leaderboard.scss";


const abi = [
  {
    inputs: [
      {
        internalType: "address",
        name: "_token",
        type: "address",
      },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "target",
        type: "address",
      },
    ],
    name: "AddressEmptyCode",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "AddressInsufficientBalance",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "numOfTickets",
        type: "uint256",
      },
    ],
    name: "buyTickets",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "drawWinnerTickets",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "FailedInnerCall",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "owner",
        type: "address",
      },
    ],
    name: "OwnableInvalidOwner",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "OwnableUnauthorizedAccount",
    type: "error",
  },
  {
    inputs: [],
    name: "pause",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "token",
        type: "address",
      },
    ],
    name: "SafeERC20FailedOperation",
    type: "error",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "timestamp",
        type: "uint256",
      },
    ],
    name: "DrawExecuted",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "lotteryId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "ticketSupply",
        type: "uint256",
      },
    ],
    name: "LotteryClose",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "lotteryId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "ticketSupply",
        type: "uint256",
      },
    ],
    name: "LotteryOpen",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "Paused",
    type: "event",
  },
  {
    inputs: [],
    name: "refundAll",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "user",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "RefundProcessed",
    type: "event",
  },
  {
    inputs: [],
    name: "restartDraw",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "buyer",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "numOfTickets",
        type: "uint256",
      },
    ],
    name: "TicketsPurchased",
    type: "event",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "unpause",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "Unpaused",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "winner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "uint256",
        name: "ticketNumber",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "WinnerSelected",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address[3]",
        name: "winners",
        type: "address[3]",
      },
      {
        indexed: true,
        internalType: "uint256[3]",
        name: "ticketNumbers",
        type: "uint256[3]",
      },
      {
        indexed: false,
        internalType: "uint256[3]",
        name: "amounts",
        type: "uint256[3]",
      },
    ],
    name: "WinnersDrawn",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "winner",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "WinningsWithdrawn",
    type: "event",
  },
  {
    inputs: [],
    name: "withdrawCommission",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "withdrawWinnings",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "checkWinningsAmount",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "currentWinningReward",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "duration",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "expiration",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getTickets",
    outputs: [
      {
        internalType: "address[]",
        name: "",
        type: "address[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "addr",
        type: "address",
      },
    ],
    name: "getWinningsForAddress",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "isWinner",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    name: "lastWinners",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    name: "lastWinnersAmount",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "maxTickets",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "operatorTotalCommission",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "paused",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "remainingTickets",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "ticketCommission",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "ticketPrice",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    name: "tickets",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    name: "ticketsBought",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "token",
    outputs: [
      {
        internalType: "contract IERC20",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    name: "winnings",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    name: "winningTicketNumbers",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];

const tokenAbi = [
  {
    type: "event",
    name: "Approval",
    inputs: [
      {
        type: "address",
        name: "owner",
        internalType: "address",
        indexed: true,
      },
      {
        type: "address",
        name: "spender",
        internalType: "address",
        indexed: true,
      },
      {
        type: "uint256",
        name: "value",
        internalType: "uint256",
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "OwnershipTransferred",
    inputs: [
      {
        type: "address",
        name: "previousOwner",
        internalType: "address",
        indexed: true,
      },
      {
        type: "address",
        name: "newOwner",
        internalType: "address",
        indexed: true,
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "Transfer",
    inputs: [
      { type: "address", name: "from", internalType: "address", indexed: true },
      { type: "address", name: "to", internalType: "address", indexed: true },
      {
        type: "uint256",
        name: "value",
        internalType: "uint256",
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
    name: "allowance",
    inputs: [
      { type: "address", name: "owner", internalType: "address" },
      { type: "address", name: "spender", internalType: "address" },
    ],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [{ type: "bool", name: "", internalType: "bool" }],
    name: "approve",
    inputs: [
      { type: "address", name: "spender", internalType: "address" },
      { type: "uint256", name: "amount", internalType: "uint256" },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
    name: "balanceOf",
    inputs: [{ type: "address", name: "account", internalType: "address" }],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "uint8", name: "", internalType: "uint8" }],
    name: "decimals",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [{ type: "bool", name: "", internalType: "bool" }],
    name: "decreaseAllowance",
    inputs: [
      { type: "address", name: "spender", internalType: "address" },
      { type: "uint256", name: "subtractedValue", internalType: "uint256" },
    ],
  },
  {
    type: "function",
    stateMutability: "pure",
    outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
    name: "getMaxTotalSupply",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [{ type: "bool", name: "", internalType: "bool" }],
    name: "increaseAllowance",
    inputs: [
      { type: "address", name: "spender", internalType: "address" },
      { type: "uint256", name: "addedValue", internalType: "uint256" },
    ],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "mint",
    inputs: [
      { type: "address", name: "_to", internalType: "address" },
      { type: "uint256", name: "_amount", internalType: "uint256" },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "string", name: "", internalType: "string" }],
    name: "name",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "address", name: "", internalType: "address" }],
    name: "owner",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "renounceOwnership",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "string", name: "", internalType: "string" }],
    name: "symbol",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
    name: "totalSupply",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [{ type: "bool", name: "", internalType: "bool" }],
    name: "transfer",
    inputs: [
      { type: "address", name: "recipient", internalType: "address" },
      { type: "uint256", name: "amount", internalType: "uint256" },
    ],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [{ type: "bool", name: "", internalType: "bool" }],
    name: "transferFrom",
    inputs: [
      { type: "address", name: "sender", internalType: "address" },
      { type: "address", name: "recipient", internalType: "address" },
      { type: "uint256", name: "amount", internalType: "uint256" },
    ],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "transferOwnership",
    inputs: [{ type: "address", name: "newOwner", internalType: "address" }],
  },
];

const contractAddress = "0x2f44A1D1df45eA87266066aeee624fE3d3A7A2Ee";
const tokenAddress = "0x75740FC7058DA148752ef8a9AdFb73966DEb42a8";

const AppLottery = () => {
  const [lotteryOpen, setLotteryOpen] = useState(false);
  const [endTimestamp, setEndTimestamp] = useState(0);
  const [countdown, setCountdown] = useState(0);
  const [ticketQuantity, setTicketQuantity] = useState(1);
  const [currentWinningReward, setCurrentWinningReward] = useState(0);
  const [remainingTickets, setRemainingTickets] = useState(0);
  const { data: signer } = useSigner();
  const [contract, setContract] = useState(null);
  const [tokenContract, setTokenContract] = useState(null);
  const [userTickets, setUserTickets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isOperator, setIsOperator] = useState(false);
  const [dexPairData, setDexPairData] = useState(null);
  const [operatorTotalCommission, setOperatorTotalCommission] = useState(0);
  const [ticketCommission, setTicketCommission] = useState(0);
  const [totalCommissionEarned, setTotalCommissionEarned] = useState(0);
  const [lastWinners, setLastWinners] = useState([]);
  const [lastWinnersAmounts, setLastWinnersAmounts] = useState([]);
  const [winningTicketNumbers, setWinningTicketNumbers] = useState([]);
  const [userWinnings, setUserWinnings] = useState(0);
  const [historyTab, setHistoryTab] = useState(false);
  const [highscores, setHighscores] = useState([]);

  const fetchDexscreenerData = async () => {
    try {
      const response = await axios.get(
        "https://api.dexscreener.com/latest/dex/pairs/elastos/0x2f45C6Df50E7F8517dAc64943a2161bBCFBa1182"
      );
      if (response.status === 200) {
        setDexPairData(response.data.pairs[0]);
      }
    } catch (error) {
      console.error("Failed to fetch from Dexscreener:", error);
    }
  };

  const fetchCurrentWinningReward = async () => {
    try {
      const provider = new ethers.providers.JsonRpcProvider(
        "https://rpc.glidefinance.io"
      );
      const contract = new ethers.Contract(contractAddress, abi, provider);
      const reward = await contract.currentWinningReward();
      if (reward) {
        setCurrentWinningReward(ethers.utils.formatEther(reward));
      }
    } catch (error) {
      console.error("Error fetching current winning reward:", error);
    }
  };

  const fetchHighscores = async () => {
    try {
      const provider = new ethers.providers.JsonRpcProvider(
        "https://rpc.glidefinance.io"
      );
      const contract = new ethers.Contract(contractAddress, abi, provider);
  
      const ticketsBoughtCount = {};
      const totalBunnyWon = {};
  
      // Fetch all TicketsPurchased events
      const totalTickets = await contract.queryFilter("TicketsPurchased");
      totalTickets.forEach((event) => {
        const buyer = event.args.buyer;
        const numOfTickets = event.args.numOfTickets.toNumber();
        if (!ticketsBoughtCount[buyer]) {
          ticketsBoughtCount[buyer] = 0;
        }
        ticketsBoughtCount[buyer] += numOfTickets;
      });
  
      // Fetch all WinnerSelected events
      const winnersCount = {};
      const winners = await contract.queryFilter("WinnerSelected");
      winners.forEach((winnerEvent) => {
        const winnerAddress = winnerEvent.args.winner;
        const amountWon = ethers.utils.formatEther(winnerEvent.args.amount);
        if (!winnersCount[winnerAddress]) {
          winnersCount[winnerAddress] = 0;
        }
        if (!totalBunnyWon[winnerAddress]) {
          totalBunnyWon[winnerAddress] = 0;
        }
        winnersCount[winnerAddress]++;
        totalBunnyWon[winnerAddress] += parseFloat(amountWon);
      });
  
      // Combine and sort by tickets bought and then wins
      const allUsers = Object.keys(ticketsBoughtCount).map((address) => ({
        address,
        wins: winnersCount[address] || 0,
        ticketsBought: ticketsBoughtCount[address],
        bunnyWon: totalBunnyWon[address] || 0,
      }));
  
      const highscoreList = allUsers
        .sort((a, b) => b.ticketsBought - a.ticketsBought || b.wins - a.wins)
        .slice(0, 10);
  
      setHighscores(highscoreList);
    } catch (error) {
      console.error("Error fetching highscores:", error);
    }
  };
  
  useEffect(() => {
    fetchDexscreenerData();
    fetchCurrentWinningReward();
    fetchHighscores();
  }, []);
  
  
  


  const handleConfettiEffects = () => {
    confetti({
      particleCount: 200,
      startVelocity: 30,
      spread: 360,
      origin: {
        x: Math.random(),
        y: Math.random() - 0.2,
      },
    });
    confetti({
      particleCount: 100,
      angle: 60,
      spread: 55,
      origin: { x: 0 },
    });
    confetti({
      particleCount: 100,
      angle: 120,
      spread: 55,
      origin: { x: 1 },
    });
  };

  const fetchUserTickets = useCallback(async () => {
    if (contract && signer) {
      try {
        const address = await signer.getAddress();
        const tickets = await contract.getTickets();
        const userTickets = tickets
          .map((ticket, index) => ({ ticket, index }))
          .filter(
            (ticketObj) =>
              ticketObj.ticket.toLowerCase() === address.toLowerCase()
          )
          .map((ticketObj) => ticketObj.index);

        setUserTickets(userTickets);

        const winnings = await contract.getWinningsForAddress(address);
        setUserWinnings(ethers.utils.formatEther(winnings));
      } catch (error) {
        console.error("Error fetching user tickets:", error);
      }
    }
  }, [contract, signer]);

  useEffect(() => {
    fetchDexscreenerData();
    fetchCurrentWinningReward();
    fetchHighscores();
  }, []);
  

  useEffect(() => {
    fetchUserTickets();
  }, [contract, signer, fetchUserTickets]);

  useEffect(() => {
    if (signer) {
      const newContract = new ethers.Contract(contractAddress, abi, signer);
      setContract(newContract);

      const newTokenContract = new ethers.Contract(
        tokenAddress,
        tokenAbi,
        signer
      );
      setTokenContract(newTokenContract);

      const checkOperator = async () => {
        try {
          const operator = await newContract.owner();
          const userAddress = await signer.getAddress();
          setIsOperator(operator === userAddress);
        } catch (error) {
          console.error("Error fetching operator:", error);
        }
      };
      checkOperator();

      const fetchExpiration = async () => {
        try {
          const expiration = await newContract.expiration();
          if (expiration) {
            setEndTimestamp(expiration.toNumber());
          }
        } catch (error) {
          console.error("Error fetching expiration:", error);
        }
      };
      fetchExpiration();

      const fetchOperatorTotalCommission = async () => {
        try {
          const commission = await newContract.operatorTotalCommission();
          if (commission) {
            setOperatorTotalCommission(ethers.utils.formatEther(commission));
          }
        } catch (error) {
          console.error("Error fetching operator total commission:", error);
        }
      };
      fetchOperatorTotalCommission();

      const fetchLastWinners = async () => {
        try {
          const winners = [];
          const amounts = [];
          for (let i = 0; i < 3; i++) {
            const winner = await newContract.lastWinners(i);
            const amount = await newContract.lastWinnersAmount(i);
            winners.push(winner);
            amounts.push(ethers.utils.formatEther(amount));
          }
          setLastWinners(winners);
          setLastWinnersAmounts(amounts);
        } catch (error) {
          console.error("Error fetching last winners:", error);
        }
      };
      fetchLastWinners();

      const fetchRemainingTickets = async () => {
        try {
          const tickets = await newContract.remainingTickets();
          if (tickets) {
            setRemainingTickets(tickets.toNumber());
          }
        } catch (error) {
          console.error("Error fetching remaining tickets:", error);
        }
      };
      fetchRemainingTickets();

      const fetchWinningTicketNumbers = async () => {
        try {
          const ticketNumbers = [];
          for (let i = 0; i < 3; i++) {
            const ticketNumber = await newContract.winningTicketNumbers(i);
            ticketNumbers.push(ticketNumber.toNumber());
          }
          setWinningTicketNumbers(ticketNumbers);
        } catch (error) {
          console.error("Error fetching winning ticket numbers:", error);
        }
      };
      fetchWinningTicketNumbers();

      newContract.on("WinnersDrawn", (winners, ticketNumbers, amounts) => {
        if (winners && winners.length > 0) {
          setLastWinners(winners);
        } else {
          setLastWinners([]);
        }

        if (ticketNumbers && ticketNumbers.length > 0) {
          setWinningTicketNumbers(
            ticketNumbers.map((number) => number.toNumber())
          );
        } else {
          setWinningTicketNumbers([]);
        }

        if (amounts && amounts.length > 0) {
          setLastWinnersAmounts(
            amounts.map((amount) => ethers.utils.formatEther(amount))
          );
        } else {
          setLastWinnersAmounts([]);
        }
      });

      return () => {
        newContract.removeAllListeners("WinnersDrawn");
      };
    }
  }, [signer]);

 

  const drawWinner = useCallback(async () => {
    if (!contract) {
      toast.error("Contract is not loaded", {
        className: "custom-toast error-toast",
      });
      return;
    }

    try {
      toast.info("Drawing winner...", {
        className: "custom-toast info-toast",
      });
      const tx = await contract.drawWinnerTickets();
      await tx.wait();
      toast.success("Winner drawn successfully", {
        className: "custom-toast success-toast",
      });
    } catch (error) {
      toast.error("Failed to draw winner. Please try again later.", {
        className: "custom-toast error-toast",
      });
      console.error("Error drawing winner:", error);
    }
  }, [contract]);

  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = Math.floor(Date.now() / 1000);
      const remainingTime = endTimestamp - currentTime;
      if (remainingTime <= 0) {
        clearInterval(interval);
        if (lotteryOpen) {
          drawWinner();
        }
        setCountdown(0);
      } else {
        setCountdown(remainingTime);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [endTimestamp, lotteryOpen, drawWinner]);

  const buyTickets = async () => {
    if (!contract || !tokenContract) {
      toast.error("Contracts are not loaded", {
        className: "custom-toast error-toast",
      });
      return;
    }

    setLoading(true);
    try {
      const totalCost = ethers.utils.parseEther(
        (ticketQuantity * 10).toString()
      );
      const userAddress = await signer.getAddress();

      toast.info("Approving token transfer...", {
        className: "custom-toast info-toast",
      });

      const approveTx = await tokenContract.approve(contractAddress, totalCost);
      await approveTx.wait();

      toast.success("Token transfer approved. Buying tickets...", {
        className: "custom-toast success-toast",
      });

      const tx = await contract.buyTickets(ticketQuantity);
      await tx.wait();

      toast.success("Tickets purchased successfully!", {
        className: "custom-toast success-toast",
      });

      const tickets = await contract.getTickets();
      const userTickets = tickets
        .map((ticket, index) => ({ ticket, index }))
        .filter(
          (ticketObj) =>
            ticketObj.ticket.toLowerCase() === userAddress.toLowerCase()
        )
        .map((ticketObj) => ticketObj.index);

      setUserTickets(userTickets);
    } catch (error) {
      toast.error("Failed to buy tickets. Please try again later.", {
        className: "custom-toast error-toast",
      });
      console.error("Error buying tickets:", error);
    } finally {
      setLoading(false);
    }
  };

  const refundAll = async () => {
    if (!contract) return;

    try {
      const tx = await contract.refundAll();
      await tx.wait();
      toast.success("Refunded all tickets successfully!", {
        className: "custom-toast success-toast",
      });
    } catch (error) {
      toast.error("Failed to refund all tickets.", {
        className: "custom-toast error-toast",
      });
      console.error("Error refunding all tickets:", error);
    }
  };

  const restartDraw = async () => {
    if (!contract) return;

    try {
      const tx = await contract.restartDraw();
      await tx.wait();
      toast.success("Draw restarted successfully!", {
        className: "custom-toast success-toast",
      });
      const expiration = await contract.expiration();
      setEndTimestamp(expiration.toNumber());
    } catch (error) {
      toast.error("Failed to restart draw.", {
        className: "custom-toast error-toast",
      });
      console.error("Error restarting draw:", error);
    }
  };

  const fetchOperatorTotalCommission = useCallback(async () => {
    if (!contract) return;
    try {
      const commission = await contract.operatorTotalCommission();
      if (commission) {
        setOperatorTotalCommission(ethers.utils.formatEther(commission));
      }
    } catch (error) {
      console.error("Error fetching operator total commission:", error);
    }
  }, [contract]);

  const fetchTicketCommission = useCallback(async () => {
    if (!contract) return;
    try {
      const commission = await contract.ticketCommission();
      if (commission) {
        setTicketCommission(ethers.utils.formatEther(commission));
      }
    } catch (error) {
      console.error("Error fetching ticket commission:", error);
    }
  }, [contract]);

  const fetchTotalCommissionEarned = useCallback(async () => {
    if (!contract) return;
    try {
      const totalTickets = await contract.totalTicketsSold();
      const ticketCommission = await contract.ticketCommission();
      const totalCommission = totalTickets.mul(ticketCommission);
      setTotalCommissionEarned(ethers.utils.formatEther(totalCommission));
    } catch (error) {
      console.error("Error fetching total commission earned:", error);
    }
  }, [contract]);

  const withdrawCommission = async () => {
    if (!contract) return;

    try {
      setLoading(true);
      const tx = await contract.withdrawCommission();
      await tx.wait();
      toast.success("Commission withdrawn successfully!", {
        className: "custom-toast success-toast",
      });
      fetchOperatorTotalCommission();
    } catch (error) {
      toast.error("Failed to withdraw commission.", {
        className: "custom-toast error-toast",
      });
      console.error("Error withdrawing commission:", error);
    } finally {
      setLoading(false);
    }
  };

  const withdrawWinnings = async () => {
    if (!contract) return;

    try {
      const tx = await contract.withdrawWinnings();
      await tx.wait();
      toast.success("Winnings withdrawn successfully!", {
        className: "custom-toast success-toast",
      });
      const address = await signer.getAddress();
      const winnings = await contract.getWinningsForAddress(address);
      setUserWinnings(ethers.utils.formatEther(winnings));
    } catch (error) {
      toast.error("Failed to Claim Winnings.", {
        className: "custom-toast error-toast",
      });
      console.error("Error withdrawing winnings:", error);
    }
  };

  useEffect(() => {
    if (contract) {
      fetchOperatorTotalCommission();
      fetchTicketCommission();
      fetchTotalCommissionEarned();
    }
  }, [contract, fetchOperatorTotalCommission, fetchTicketCommission, fetchTotalCommissionEarned]);

  return (
    <WagmiConfig client={client}>
      <div className="container-lg dashboardwrapper px-0">
        <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} />

        <div className="d-flex flex-column flex-md-row m-0 gap-3 justify-content-between">
          <div className="migration-banner-wrapper3 p-3">
            <div className="purplediv" style={{ background: "#8890c4" }}></div>
            <div className="text-center">
              <h6 className="migration-banner-title mb-0">The Bunny Lottery</h6>

              <h1 className="warning-txt text-4xl font-bold mt-4">
                {currentWinningReward && dexPairData
                  ? new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format(currentWinningReward * dexPairData.priceUsd)
                  : "Loading..."}
              </h1>
              <span className="text-md text-white">
                {" "}
                {parseFloat(currentWinningReward).toFixed(0)} BUNNY
              </span>
              <h6 className="migration-banner-title mt-4">in prizes!</h6>
            </div>
            <div className="d-flex flex-column gap-3">
              <span className="migration-progress-info mb-0 text-center mt-4">
                <p className="migration-banner-subtitle my-6">
                  One ticket costs: 10 BUNNY
                </p>

                <div className="quantity-selector input-group justify-center">
                  <span className="input-group-btn">
                    <button
                      className="btn outline-btn"
                      type="button"
                      onClick={() => setTicketQuantity(ticketQuantity - 1)}
                      disabled={ticketQuantity === 1}
                    >
                      -
                    </button>
                  </span>
                  <input
                    type="number"
                    className="text-center outlines bg-transparent"
                    value={ticketQuantity}
                    onChange={(e) =>
                      setTicketQuantity(parseInt(e.target.value))
                    }
                    min={1}
                    max={1500}
                  />
                  <span className="input-group-btn">
                    <button
                      className="btn outline-btn"
                      type="button"
                      onClick={() => setTicketQuantity(ticketQuantity + 1)}
                      disabled={ticketQuantity === 1500}
                    >
                      +
                    </button>
                  </span>
                </div>
                <div className="my-4">
                  <div className="animated-element mx-auto sc-eDnVMP sc-gKHVLF sc-goEyvm kpeGcW kFsaVr heXTyF">
                    <div className="sc-dLOxbp cJimZN">
                      <button
                        className="sc-bcPKhP xiIkU sc-jdEOXE bvJmG"
                        data-theme="light"
                        variant="primary"
                        scale="md"
                        onClick={buyTickets}
                      >
                        Buy Tickets
                      </button>
                    </div>
                    <div className="sc-jTmrli RrxyI">
                      <img
                        src={ticketsvg}
                        alt="Ticket SVG"
                        width={"100%"}
                        height={100}
                      />
                    </div>
                  </div>
                </div>
              </span>
            </div>
          </div>

          <div className="explorercard-wrapper d-flex position-relative w-full">
            <div className="purplediv" style={{ background: "#8890C4", top: "15px" }}></div>
            <div className="col-12 col-lg-12 d-flex flex-column gap-3">
              <div className="justify-content-between gap-2 align-items-center">
                <h6 className="migration-banner-title mb-0">Your Tickets</h6>
              </div>
              <div className="flex flex-wrap gap-4 w-full mt-4 max-h-[140px] overflow-y-scroll overflow-x-hidden py-2">
                {userTickets.length > 0 ? (
                  userTickets.map((ticket, index) => (
                    <div key={index} className="sc-eDnVMP sc-gKHVLF sc-goEyvm as8888 kFsaVr heXTyF">
                      <div className="sc-dLOxbp cJimZN">
                        <span className="ml-4 text-2xl font-bold">
                          #{ticket}
                        </span>
                      </div>
                      <div className="sc-jTmrli RrxyI">
                        <img
                          src={ticketsvg}
                          alt="Ticket SVG"
                          width={"100%"}
                          height={"100%"}
                        />
                      </div>
                    </div>
                  ))
                ) : (
                  <span className="no-tickets">No tickets purchased</span>
                )}
              </div>
              <div>
                {userWinnings > 0 && (
                  <div className="alert alert-success d-flex flex-column gap-3">
                  Congratulations! You have won {userWinnings} BUNNY. Click on
                    Claim Winnings to receive your prize.
                    <button className="btn filled-btn" disabled={loading} onClick={withdrawWinnings}>
                Claim Winnings
              </button>
                  </div>
                  
                )}
              </div>
              <Timer3 />
            </div>
          </div>
        </div>



<div className="mt-4">
  <div className="govcard1 d-flex flex-column gap-2 p-0">
    <div className="d-flex w-100 position-relative justify-content-between govcard1 leaderboard-title-wrapper p-2">
      <h6 className="migration-banner-title py-2 w-full text-center">Highscores</h6>
    </div>
    <div className="p-2 overflow-x-scroll">
      <table className="playerTable w-full table-auto">
        <thead>
          <tr className="playerRow">
            <th className="playerHeader font-montserrat p-2">Rank</th>
            <th className="playerHeader font-montserrat p-2">Player</th>
            <th className="playerHeader text-center font-montserrat p-2">Wins</th>
            <th className="playerHeader text-center font-montserrat p-2">Tickets</th>
            <th className="playerHeader text-center font-montserrat p-2">BUNNY Won</th>
          </tr>
        </thead>
        <tbody>
          {highscores.length > 0 ? (
            highscores.map((score, index) => (
              <tr className="playerInnerRow" key={index}>
                <td className="playerData col-1 font-montserrat p-2">#{index + 1}</td>
                <td className="playerName col-5 font-montserrat p-2">
                  <div className="position-relative d-flex align-items-center">
                    <span>{`${score.address.slice(0, 4)}...${score.address.slice(-4)}`}</span>
                  </div>
                </td>
                <td className="playerScore col-2 text-center font-montserrat p-2">{score.wins}</td>
                <td className="playerReward text-center col-2 font-montserrat p-2">{score.ticketsBought}</td>
                <td className="playerReward text-center col-2 font-montserrat p-2">{score.bunnyWon.toFixed(0)}</td>
              </tr>
            ))
          ) : (
            <tr className="playerInnerRow">
              <td colSpan="5" className="text-center p-2">No highscores yet</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  </div>

  <div className="mt-4">
    <div className="govcard1 d-flex flex-column gap-2 p-0">
      <div className="d-flex w-100 position-relative justify-content-between govcard1 leaderboard-title-wrapper p-2">
        <h6 className="migration-banner-title py-2 w-full text-center">Lottery History</h6>
      </div>
      <div className="p-2 overflow-x-scroll">
        <table className="playerTable w-full table-auto">
          <thead>
            <tr className="playerRow">
              <th className="playerHeader font-montserrat p-2">Winner</th>
              <th className="playerHeader text-center font-montserrat p-2">BUNNY Won</th>
              <th className="playerHeader text-center font-montserrat p-2">Ticket Number</th>
            </tr>
          </thead>
          <tbody>
            {lastWinners.length > 0 ? (
              lastWinners.map((winner, index) => (
                <tr className="playerInnerRow" key={index}>
                  <td className="playerName col-5 font-montserrat p-2">
                    <div className="position-relative d-flex align-items-center">
                      <span>{`${winner.slice(0, 4)}...${winner.slice(-4)}`}</span>
                    </div>
                  </td>
                  <td className="playerReward text-center col-2 font-montserrat p-2">
                    {parseFloat(lastWinnersAmounts[index] || 0).toFixed(2)}
                  </td>
                  <td className="playerReward text-center col-2 font-montserrat p-2">#{winningTicketNumbers[index]}</td>
                </tr>
              ))
            ) : (
              <tr className="playerInnerRow">
                <td colSpan="3" className="text-center p-2">Connect your wallet to see history</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
        <Comp1 />

        {isOperator && (
          <div className="mt-4">
            <div>
              <h6 className="migration-banner-title mb-3">Admin Functions</h6>
            </div>
            <div className="gap-4 d-flex position-relative w-full flex-wrap">
              <button className="btn filled-btn" disabled={loading} onClick={drawWinner}>
                Draw Winner
              </button>
              <button className="btn filled-btn" disabled={loading} onClick={restartDraw}>
                Restart Draw
              </button>
              <button className="btn filled-btn" disabled={loading} onClick={refundAll}>
                Refund All
              </button>
              <button className="btn filled-btn" disabled={loading} onClick={withdrawCommission}>
                Withdraw Commission
              </button>
              <button className="btn filled-btn" disabled={loading} onClick={withdrawWinnings}>
                Claim Winnings
              </button>
              <div>
                <span>Operator Total Commission: {operatorTotalCommission} BUNNY</span>
              </div>
              <div>
                <span>Ticket Commission: {ticketCommission} BUNNY</span>
              </div>
              <div>
                <span>Total Commission Earned: {totalCommissionEarned} BUNNY</span>
              </div>
            </div>
          </div>
        )}
 
      </div>
    </WagmiConfig>
  );
};

const elastosChain = {
  id: 20,
  name: "ESC",
  network: "elastos",
  nativeCurrency: {
    name: "ELA",
    symbol: "ELA",
    decimals: 18,
  },
  rpcUrls: {
    default: "https://rpc.glidefinance.io",
  },
  blockExplorers: {
    default: { name: "Elastos Explorer", url: "https://esc.elastos.io/" },
  },
  testnet: false,
};

const { provider } = configureChains(
  [elastosChain],
  [
    jsonRpcProvider({
      rpc: (chain) => {
        if (chain.id === elastosChain.id) {
          return { http: chain.rpcUrls.default };
        }
        return null;
      },
    }),
    publicProvider(),
  ]
);

const client = createClient({
  autoConnect: true,
  provider,
});

export default AppLottery;