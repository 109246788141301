import React, { useState, useEffect } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { ethers } from "ethers";
import { formattedNum } from "../../../../../../functions/formatUSD";
import getFormattedNumber from "../../../../../../functions/get-formatted-number";

const CAWS_PREMIUM_CONTRACT_ADDRESS = "0xE27934fB3683872e35b8d9E57c30978e1260c614"; // Replace with the actual address
const INFURA_ENDPOINT = "https://rpc.glidefinance.io"; // Replace with your Infura project ID

const nftContractABI = [
  {
    type: "constructor",
    stateMutability: "nonpayable",
    inputs: [
      { type: "string", name: "_initBaseURI", internalType: "string" },
      { type: "string", name: "_initNotRevealedUri", internalType: "string" },
    ],
  },
  {
    type: "event",
    name: "Approval",
    inputs: [
      {
        type: "address",
        name: "owner",
        internalType: "address",
        indexed: true,
      },
      {
        type: "address",
        name: "approved",
        internalType: "address",
        indexed: true,
      },
      {
        type: "uint256",
        name: "tokenId",
        internalType: "uint256",
        indexed: true,
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "ApprovalForAll",
    inputs: [
      {
        type: "address",
        name: "owner",
        internalType: "address",
        indexed: true,
      },
      {
        type: "address",
        name: "operator",
        internalType: "address",
        indexed: true,
      },
      { type: "bool", name: "approved", internalType: "bool", indexed: false },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "OwnershipTransferred",
    inputs: [
      {
        type: "address",
        name: "previousOwner",
        internalType: "address",
        indexed: true,
      },
      {
        type: "address",
        name: "newOwner",
        internalType: "address",
        indexed: true,
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "Transfer",
    inputs: [
      { type: "address", name: "from", internalType: "address", indexed: true },
      { type: "address", name: "to", internalType: "address", indexed: true },
      {
        type: "uint256",
        name: "tokenId",
        internalType: "uint256",
        indexed: true,
      },
    ],
    anonymous: false,
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "approve",
    inputs: [
      { type: "address", name: "to", internalType: "address" },
      { type: "uint256", name: "tokenId", internalType: "uint256" },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
    name: "balanceOf",
    inputs: [{ type: "address", name: "owner", internalType: "address" }],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "string", name: "", internalType: "string" }],
    name: "baseExtension",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
    name: "cost",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "address", name: "", internalType: "address" }],
    name: "getApproved",
    inputs: [{ type: "uint256", name: "tokenId", internalType: "uint256" }],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "bool", name: "", internalType: "bool" }],
    name: "isApprovedForAll",
    inputs: [
      { type: "address", name: "owner", internalType: "address" },
      { type: "address", name: "operator", internalType: "address" },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
    name: "maxSupply",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "payable",
    outputs: [],
    name: "mint",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "string", name: "", internalType: "string" }],
    name: "name",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "string", name: "", internalType: "string" }],
    name: "notRevealedUri",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "address", name: "", internalType: "address" }],
    name: "owner",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "address", name: "", internalType: "address" }],
    name: "ownerOf",
    inputs: [{ type: "uint256", name: "tokenId", internalType: "uint256" }],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "pause",
    inputs: [{ type: "bool", name: "_state", internalType: "bool" }],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "bool", name: "", internalType: "bool" }],
    name: "paused",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
    name: "randomNum",
    inputs: [
      { type: "uint256", name: "_mod", internalType: "uint256" },
      { type: "uint256", name: "_seed", internalType: "uint256" },
      { type: "uint256", name: "_salt", internalType: "uint256" },
    ],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "renounceOwnership",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "reveal",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "bool", name: "", internalType: "bool" }],
    name: "revealed",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "safeTransferFrom",
    inputs: [
      { type: "address", name: "from", internalType: "address" },
      { type: "address", name: "to", internalType: "address" },
      { type: "uint256", name: "tokenId", internalType: "uint256" },
    ],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "safeTransferFrom",
    inputs: [
      { type: "address", name: "from", internalType: "address" },
      { type: "address", name: "to", internalType: "address" },
      { type: "uint256", name: "tokenId", internalType: "uint256" },
      { type: "bytes", name: "_data", internalType: "bytes" },
    ],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "setApprovalForAll",
    inputs: [
      { type: "address", name: "operator", internalType: "address" },
      { type: "bool", name: "approved", internalType: "bool" },
    ],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "setBaseExtension",
    inputs: [
      { type: "string", name: "_newBaseExtension", internalType: "string" },
    ],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "setBaseURI",
    inputs: [{ type: "string", name: "_newBaseURI", internalType: "string" }],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "setCost",
    inputs: [{ type: "uint256", name: "_newCost", internalType: "uint256" }],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "setNotRevealedURI",
    inputs: [
      { type: "string", name: "_notRevealedURI", internalType: "string" },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "bool", name: "", internalType: "bool" }],
    name: "supportsInterface",
    inputs: [{ type: "bytes4", name: "interfaceId", internalType: "bytes4" }],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "string", name: "", internalType: "string" }],
    name: "symbol",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
    name: "tokenByIndex",
    inputs: [{ type: "uint256", name: "index", internalType: "uint256" }],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
    name: "tokenOfOwnerByIndex",
    inputs: [
      { type: "address", name: "owner", internalType: "address" },
      { type: "uint256", name: "index", internalType: "uint256" },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "string", name: "", internalType: "string" }],
    name: "tokenURI",
    inputs: [{ type: "uint256", name: "tokenId", internalType: "uint256" }],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
    name: "totalSupply",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "transferFrom",
    inputs: [
      { type: "address", name: "from", internalType: "address" },
      { type: "address", name: "to", internalType: "address" },
      { type: "uint256", name: "tokenId", internalType: "uint256" },
    ],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "transferOwnership",
    inputs: [{ type: "address", name: "newOwner", internalType: "address" }],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "uint256[]", name: "", internalType: "uint256[]" }],
    name: "walletOfOwner",
    inputs: [{ type: "address", name: "_owner", internalType: "address" }],
  },
  {
    type: "function",
    stateMutability: "payable",
    outputs: [],
    name: "withdraw",
    inputs: [],
  },
];

const stakingContractABI = [
  {
    type: "constructor",
    stateMutability: "nonpayable",
    inputs: [
      {
        type: "address",
        name: "_stakingDestinationAddress",
        internalType: "address",
      },
      { type: "uint256", name: "_rate", internalType: "uint256" },
      { type: "uint256", name: "_expiration", internalType: "uint256" },
      { type: "address", name: "_erc20Address", internalType: "address" },
    ],
  },
  {
    type: "event",
    name: "ExpirationChanged",
    inputs: [
      {
        type: "uint256",
        name: "newExpiration",
        internalType: "uint256",
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "LockTimeChanged",
    inputs: [
      {
        type: "uint256",
        name: "newLockTime",
        internalType: "uint256",
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "OwnershipTransferred",
    inputs: [
      {
        type: "address",
        name: "previousOwner",
        internalType: "address",
        indexed: true,
      },
      {
        type: "address",
        name: "newOwner",
        internalType: "address",
        indexed: true,
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "Paused",
    inputs: [
      {
        type: "address",
        name: "account",
        internalType: "address",
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "RateChanged",
    inputs: [
      {
        type: "uint256",
        name: "newRate",
        internalType: "uint256",
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "Unpaused",
    inputs: [
      {
        type: "address",
        name: "account",
        internalType: "address",
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
    name: "LOCKUP_TIME",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
    name: "_depositBlocks",
    inputs: [
      { type: "address", name: "", internalType: "address" },
      { type: "uint256", name: "", internalType: "uint256" },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
    name: "calculateReward",
    inputs: [
      { type: "address", name: "account", internalType: "address" },
      { type: "uint256", name: "tokenId", internalType: "uint256" },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      { type: "uint256[]", name: "rewards", internalType: "uint256[]" },
    ],
    name: "calculateRewards",
    inputs: [
      { type: "address", name: "account", internalType: "address" },
      { type: "uint256[]", name: "tokenIds", internalType: "uint256[]" },
    ],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "claimRewards",
    inputs: [
      { type: "uint256[]", name: "tokenIds", internalType: "uint256[]" },
    ],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "deposit",
    inputs: [
      { type: "uint256[]", name: "tokenIds", internalType: "uint256[]" },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "uint256[]", name: "", internalType: "uint256[]" }],
    name: "depositsOf",
    inputs: [{ type: "address", name: "account", internalType: "address" }],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "emergencyWithdraw",
    inputs: [
      { type: "uint256[]", name: "tokenIds", internalType: "uint256[]" },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "address", name: "", internalType: "address" }],
    name: "erc20Address",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
    name: "expiration",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "pure",
    outputs: [{ type: "bytes4", name: "", internalType: "bytes4" }],
    name: "onERC721Received",
    inputs: [
      { type: "address", name: "", internalType: "address" },
      { type: "address", name: "", internalType: "address" },
      { type: "uint256", name: "", internalType: "uint256" },
      { type: "bytes", name: "", internalType: "bytes" },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "address", name: "", internalType: "address" }],
    name: "owner",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "pause",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "bool", name: "", internalType: "bool" }],
    name: "paused",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
    name: "rate",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "renounceOwnership",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "setExpiration",
    inputs: [{ type: "uint256", name: "_expiration", internalType: "uint256" }],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "setLockTime",
    inputs: [{ type: "uint256", name: "_lockTime", internalType: "uint256" }],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "setRate",
    inputs: [{ type: "uint256", name: "_rate", internalType: "uint256" }],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "address", name: "", internalType: "address" }],
    name: "stakingDestinationAddress",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
    name: "stakingTime",
    inputs: [{ type: "address", name: "", internalType: "address" }],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "transferOwnership",
    inputs: [{ type: "address", name: "newOwner", internalType: "address" }],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "unpause",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "withdraw",
    inputs: [
      { type: "uint256[]", name: "tokenIds", internalType: "uint256[]" },
    ],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "withdrawTokens",
    inputs: [],
  },
];

const provider = new ethers.providers.JsonRpcProvider(INFURA_ENDPOINT);
const nftContract = new ethers.Contract(CAWS_PREMIUM_CONTRACT_ADDRESS, nftContractABI, provider);
const stakingContract = new ethers.Contract(CAWS_PREMIUM_CONTRACT_ADDRESS, stakingContractABI, provider);

const CawsPremiumChecklist = ({
  modalId,
  nft,
  isStake,
  checked,
  checklistItemID,
  onChange,
  countDownLeft,
  checked2,
  coinbase,
  isConnected,
  width,
  height,
  showbutton
}) => {
  const [checkbtn, setCheckBtn] = useState(false);
  const [Unstakebtn, setUnstakeBtn] = useState(false);
  const [checkPassiveBtn, setcheckPassiveBtn] = useState(false);

  const [EthRewards, setEthRewards] = useState(0);
  const [ethToUSD, setethToUSD] = useState(0);
  const [loading, setloading] = useState(false);

  const convertEthToUsd = async () => {
    const res = await axios.get("https://api.coinbase.com/v2/prices/ETH-USD/spot");
    return res.data.data.amount;
  };

  const calculateReward = async (currentId) => {
    if (!coinbase || !currentId) return;

    try {
      const rewards = await stakingContract.calculateReward(coinbase, parseInt(currentId));
      const rewardInEth = ethers.utils.formatEther(rewards);
      const ethPrice = await convertEthToUsd();
      setethToUSD(Number(ethPrice) * Number(rewardInEth));
      setEthRewards(Number(rewardInEth));
    } catch (err) {
      console.error(err);
    }
  };

  const handleClaim = async (itemId) => {
    const signer = provider.getSigner(coinbase);
    const stakingContractWithSigner = stakingContract.connect(signer);

    try {
      await stakingContractWithSigner.claimRewards([itemId]);
      setEthRewards(0);
    } catch (err) {
      console.error(err);
      window.alertify.error(err.message);
    }
  };

  const handleUnstake = async (itemId) => {
    setloading(true);

    const signer = provider.getSigner(coinbase);
    const stakingContractWithSigner = stakingContract.connect(signer);

    try {
      await stakingContractWithSigner.withdraw([itemId]);
      setcheckPassiveBtn(false);
      setloading(false);
    } catch (err) {
      console.error(err);
      setloading(false);
    }
  };

  useEffect(() => {
    if (isConnected) {
      calculateReward(checklistItemID);
      if (countDownLeft <= 0 && countDownLeft !== undefined) {
        setcheckPassiveBtn(true);
      }
    }
  }, [EthRewards, checklistItemID, isConnected, countDownLeft]);

  useEffect(() => {
    setCheckBtn(checked);
    setUnstakeBtn(checked);
  }, [checked, isStake]);

  if (!nft) {
    return null;
  }

  const handleCawClick = () => {
    if (isStake === false) {
      setCheckBtn(!checkbtn);
      onChange(checklistItemID);
    } else if (isStake === true) {
      setUnstakeBtn(!Unstakebtn);
      onChange(checklistItemID);
    }
  };

  return (
    <div
      className="nft-caw-card sub-container p-0"
      data-toggle="modal"
      data-target={modalId}
      onClick={() => handleCawClick(checklistItemID)}
      style={{
        width: width,
        height: height,
        border: isStake
          ? checked === true
            ? Unstakebtn === true
              ? "2px solid #4ED5D2"
              : "none"
            : Unstakebtn === true
            ? "2px solid #4ED5D2"
            : "none"
          : checked === false && checkbtn === true && checked2 === true
          ? "2px solid #4ED5D2"
          : checked === true && checkbtn === true && checked2 === true
          ? "2px solid #4ED5D2"
          : "none"
      }}
    >
      <div
        className="elevated-stake-container"
        style={{
          background: "transparent",
          display: "flex",
          flexDirection: "column",
          gap: 5
        }}
      >
        <div className="sub-container p-0" style={{ boxShadow: "none" }}>
          <img
            src={nft.image.replace("images", "thumbs")}
            className="nft-img"
            alt=""
          />
          <p style={{ color: "var(--light-gray-99-nft)" }}>CAWS {checklistItemID}</p>
          <div className="d-flex" style={{ flexDirection: "column" }}>
            <div className="d-flex w-100 justify-content-between align-baseline">
              <p className="nft-id" style={{ color: "var(--black-nft)" }}>
                #{String(nft.name).replace("CAWS #", "")}
              </p>
              {isStake ? (
                <input
                  type="checkbox"
                  id={checklistItemID}
                  name="AddtoUnstake"
                  checked={Unstakebtn && checked2 === true}
                  onClick={() => setUnstakeBtn(!Unstakebtn)}
                />
              ) : (
                <input
                  type="checkbox"
                  id={checklistItemID}
                  name="checkbtn"
                  checked={checkbtn && isStake === false && checked2 === true}
                  onChange={() => setCheckBtn(!checkbtn)}
                />
              )}
            </div>
            {isStake && (
              <>
                <div
                  className="earn-checklist-container d-block mb-0 p-0 w-100"
                  style={{ boxShadow: "none", borderTop: "none" }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "column"
                    }}
                  >
                    <p id="earnedText" color="#C0C9FF">Earned</p>
                    <h6
                      className="rewardstxtCaws d-flex align-items-center gap-2 mb-2"
                      style={{ fontSize: 16 }}
                    >
                      <img
                        src={require("./weth.svg").default}
                        alt=""
                        style={{ height: 20, width: 20 }}
                      />
                      {getFormattedNumber(EthRewards, 6)} WETH
                    </h6>
                  </div>
                </div>
                {!showbutton && (
                  <button
                    className="claim-rewards-btn-countdown mb-1"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleClaim(checklistItemID);
                    }}
                    style={{
                      pointerEvents: EthRewards == 0 ? "none" : "auto",
                      borderColor: EthRewards == 0 ? "#14142A" : "#857DFA",
                      color: EthRewards == 0 ? "#C0C9FF" : "#857DFA",
                      background: EthRewards == 0 ? "#14142A" : "#070D27",
                      padding: 5,
                      borderRadius: 8,
                      width: "100%"
                    }}
                  >
                    Claim reward
                  </button>
                )}
              </>
            )}
          </div>
        </div>
        {isStake ? (
          <button
            className="checkbox-button"
            onClick={() => handleUnstake(checklistItemID)}
            style={{
              background: checkPassiveBtn === true
                ? "linear-gradient(90.74deg, #7770E0 0%, #554FD8 100%)"
                : "#14142A",
              pointerEvents: checkPassiveBtn === true ? "auto" : "none"
            }}
          >
            {loading ? (
              <div
                className="spinner-border "
                role="status"
                style={{ height: "1.5rem", width: "1.5rem" }}
              ></div>
            ) : (
              "Unstake"
            )}
          </button>
        ) : null}
      </div>
    </div>
  );
};

CawsPremiumChecklist.propTypes = {
  modalId: PropTypes.string,
  nft: PropTypes.object,
  isStake: PropTypes.bool,
  checked: PropTypes.bool,
  checklistItemID: PropTypes.number,
  onChange: PropTypes.func,
  countDownLeft: PropTypes.any,
  coinbase: PropTypes.string,
  isConnected: PropTypes.bool,
  width: PropTypes.any,
  height: PropTypes.any
};

export default CawsPremiumChecklist;
