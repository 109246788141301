import React, { useState, useEffect } from "react";
import { WagmiConfig, createClient, configureChains, useProvider } from "wagmi";
import { ethers } from "ethers";
import { jsonRpcProvider } from "wagmi/providers/jsonRpc";
import { publicProvider } from "wagmi/providers/public";
import { NavLink } from "react-router-dom";
import filledArrow from "./assets/filledarrow.svg"; // Adjust the path as needed
import lot from "./8617024.webp";
import "./migrationbanner.css";
import migrationBannerIcon from "./ticket.svg";
import axios from "axios";
import getFormattedNumber from "../../functions/get-formatted-number";
import useWindowSize from "../../functions/useWindowSize";


const abi = [
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_token",
				"type": "address"
			}
		],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "target",
				"type": "address"
			}
		],
		"name": "AddressEmptyCode",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "AddressInsufficientBalance",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "numOfTickets",
				"type": "uint256"
			}
		],
		"name": "buyTickets",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "drawWinnerTickets",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "FailedInnerCall",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			}
		],
		"name": "OwnableInvalidOwner",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "OwnableUnauthorizedAccount",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "token",
				"type": "address"
			}
		],
		"name": "SafeERC20FailedOperation",
		"type": "error"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "lotteryId",
				"type": "uint256"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "ticketSupply",
				"type": "uint256"
			}
		],
		"name": "LotteryClose",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "lotteryId",
				"type": "uint256"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "ticketSupply",
				"type": "uint256"
			}
		],
		"name": "LotteryOpen",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "refundAll",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "restartDraw",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address[3]",
				"name": "winners",
				"type": "address[3]"
			},
			{
				"indexed": true,
				"internalType": "uint256[3]",
				"name": "ticketNumbers",
				"type": "uint256[3]"
			},
			{
				"indexed": false,
				"internalType": "uint256[3]",
				"name": "amounts",
				"type": "uint256[3]"
			}
		],
		"name": "WinnersDrawn",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "withdrawCommission",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "withdrawWinnings",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "checkWinningsAmount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "currentWinningReward",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "duration",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "expiration",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getTickets",
		"outputs": [
			{
				"internalType": "address[]",
				"name": "",
				"type": "address[]"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "addr",
				"type": "address"
			}
		],
		"name": "getWinningsForAddress",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "isWinner",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "lastWinners",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "lastWinnersAmount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "maxTickets",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "operatorTotalCommission",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "remainingTickets",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "ticketCommission",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "ticketPrice",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "tickets",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "token",
		"outputs": [
			{
				"internalType": "contract IERC20",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "winnings",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "winningTicketNumbers",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
];

const contractAddress = "0x2f44A1D1df45eA87266066aeee624fE3d3A7A2Ee";

const LotteryCard = () => {
  const [currentWinningReward, setCurrentWinningReward] = useState(0);
  const [endTimestamp, setEndTimestamp] = useState(0);
  const [countdown, setCountdown] = useState(0);
  const provider = useProvider();

  const windowSize = useWindowSize();

  useEffect(() => {
    const fetchCurrentWinningReward = async () => {
      try {
        if (!provider) {
          console.error("Provider is not available");
          return;
        }

        const contract = new ethers.Contract(contractAddress, abi, provider);

        const reward = await contract.currentWinningReward();
        setCurrentWinningReward(ethers.utils.formatEther(reward));

        const expiration = await contract.expiration();
        setEndTimestamp(expiration.toNumber());
      } catch (error) {
        console.error("Error fetching contract data:", error);
      }
    };

    fetchCurrentWinningReward();
  }, [provider]);

  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = Math.floor(Date.now() / 1000);
      const remainingTime = endTimestamp - currentTime;
      setCountdown(remainingTime > 0 ? remainingTime : 0);
    }, 1000);

    return () => clearInterval(interval);
  }, [endTimestamp]);

  const formatTime = (seconds) => {
    const days = Math.floor(seconds / (3600 * 24));
    const hours = Math.floor((seconds % (3600 * 24)) / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    return { days, hours, minutes, seconds: remainingSeconds };
  };

  const countDownTime = formatTime(countdown);

  return (
    <div className="w-full migration-banner-wrapper2 p-3">
      <div className="purplediv" style={{ background: "#8890c4" }}></div>
      <div className="d-flex align-items-center gap-2">
        <img src={migrationBannerIcon} width={48} alt="" />
        <h6 className="migration-banner-title mb-0">Lottery</h6>
      </div>
      <div className="d-flex flex-column gap-3">
        <div className="migrated-tokens-wrapper my-4 d-flex flex-column flex-xl-row align-items-center justify-content-between p-3">
          <>
            <span className="migrated-tokens mb-0">Lottery prize pool</span>
            <h6 className="migrated-tokens-amount mb-0">
              {parseFloat(currentWinningReward).toFixed(0)} BUNNY
            </h6>
          </>
        </div>
      </div>
      <div className="mt-auto">
        <h6 className="migration-banner-title mb-0 mb-4">Next draw</h6>
        <div className="flex gap-3 flex-wrap">
          {["days", "hours", "minutes", "seconds"].map((unit, index) => (
            <div key={index} className="flex flex-col gap-5 relative flex-wrap">
              <span className="lg:text-4xl sm:text-xl text-xl font-semibold flex flex-column outlines p-2 text-center">
                <span className="lg:text-4xl sm:text-xl text-xl font-semibold flex flex-column text-center">
                  {countDownTime[unit]}
                </span>
                <span className="text-[#8486A9] text-xs sm:text-md text-center capitalize">
                  {unit.charAt(0).toUpperCase() + unit.slice(1)}
                </span>
              </span>
            </div>
          ))}
        </div>
      </div>
      <div className="d-flex align-items-center gap-2 mt-3">
        <NavLink to={"/lottery"} className="btn filled-btn">
         Buy tickets
        </NavLink>
      </div>
    </div>
  );
};

const elastosChain = {
  id: 20, // Elastos Smart Chain's chain ID
  name: "ESC",
  network: "elastos",
  nativeCurrency: {
    name: "ELA",
    symbol: "ELA",
    decimals: 18,
  },
  rpcUrls: {
    default: "https://rpc.glidefinance.io", // Elastos Smart Chain's RPC URL
  },
  blockExplorers: {
    default: { name: "Elastos Explorer", url: "https://esc.elastos.io/" },
  },
  testnet: false,
};

const { chains, provider } = configureChains(
  [elastosChain],
  [
    jsonRpcProvider({
      rpc: (chain) => {
        if (chain.id === elastosChain.id) {
          return { http: chain.rpcUrls.default };
        }
        return null;
      },
    }),
    publicProvider(),
  ]
);

const client = createClient({
  autoConnect: true,
  provider,
});

const LotteryCardComp = () => (
  <WagmiConfig client={client}>
    <LotteryCard />
  </WagmiConfig>
);

export default LotteryCardComp;