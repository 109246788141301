import React from 'react';
import axios from 'axios';
import cn from 'classnames';

// CONFIG
import config from '../config';

// CONTEXT
import { Context } from '../context';

// STYLES
import style from '../styles/pages/audit.module.css';

class LatestAudits extends React.Component {
  static contextType = Context;

  constructor(props) {
    super(props);
    this.state = {
      audits: this.props.data,
    };

    this.get_audits = this.get_audits.bind(this);
  }

  async get_audits() {
    const url = `${config.url_api}/v1/audits`;
    const res = await axios.get(url);
    const audits = res.data;

    for (let i = 0; i < audits.length; i++) {
      for (let j = 0; j < audits.length; j++) {
        if (audits[j + 1]) {
          const current = audits[j];
          const next = audits[j + 1];

          if (
            new Date(current.created_at).valueOf() <
            new Date(next.created_at).valueOf()
          ) {
            audits[j] = next;
            audits[j + 1] = current;
          }
        }
      }
    }

    if (!audits[0] || !this.state.audits[0]) {
      this.setState({ audits });
      return;
    }

    if (
      audits[0].address.toLowerCase() !==
      this.state.audits[0].address.toLowerCase()
    ) {
      audits[0].animation = true;
    }

    this.setState({ audits });

    setTimeout(() => {
      audits[0].animation = false;
      this.setState({ audits });
    }, 2000);
  }

  componentDidMount() {
    this.interval = setInterval(this.get_audits, 10000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    return (
      <div className={cn(style['latestaudits'])}>
        <div className={cn(style['latestaudits-header'])}>LATEST AUDITS</div>
        <div className={cn(style['latestaudits-body'])}>
          {this.state.audits &&
            this.state.audits.map((current, index) => (
              <div
                key={index}
                className={cn(
                  style['latestaudits-body-item'],
                  current.animation
                    ? style['latestaudits-body-itemanimation']
                    : null
                )}
              >
                <div className={cn(style['latestaudits-body-item-left'])}>
                  <img
                    width="32"
                    height="32"
                    className={cn(style['latestaudits-body-item-left-img'])}
                    src={
                      config.blockchain_chains[Number(current.chain_id)]
                        .token_img
                    }
                    alt={
                      config.blockchain_chains[Number(current.chain_id)]
                        .token_name
                    }
                    title={
                      config.blockchain_chains[Number(current.chain_id)]
                        .token_name
                    }
                  />

                  <div
                    className={cn(style['latestaudits-body-item-left-info'])}
                  >
                    <div
                      className={cn(
                        style['latestaudits-body-item-left-info-symbol']
                      )}
                    >
                      {current.symbol}
                    </div>

                    <div
                      className={cn(
                        style['latestaudits-body-item-left-info-name']
                      )}
                    >
                      {current.name}
                    </div>
                  </div>
                </div>

                <div className={cn(style['latestaudits-body-item-date'])}>
                  {new Date(current.created_at).toDateString()}
                </div>

                <button
                  onClick={() => {
                    this.props.setToken(current.address, current.chain_id);
                  }}
                  className={cn(style['latestaudits-body-item-view'])}
                >
                  VIEW
                </button>
              </div>
            ))}
        </div>
      </div>
    );
  }
}

export default LatestAudits;
