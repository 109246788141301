import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import {
  useContractWrite,
  usePrepareContractWrite,
  useAccount,
  useProvider,
  useNetwork,
} from "wagmi";
import { ethers } from "ethers";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import chestTagOpen from "./vaultTag.svg";
import axios from "axios";
import getFormattedNumber from "../../functions/get-formatted-number";
import { Link } from "react-router-dom";

const STAKING_CONTRACT_ADDRESS = "0x08Eac6F8f505F2c032f189bf716da9541Cf7e5eE";
const stakingContractABI = [
  {
    type: "constructor",
    stateMutability: "nonpayable",
    inputs: [
      {
        type: "address",
        name: "_stakingDestinationAddress",
        internalType: "address",
      },
      { type: "uint256", name: "_rate", internalType: "uint256" },
      { type: "uint256", name: "_expiration", internalType: "uint256" },
      { type: "address", name: "_erc20Address", internalType: "address" },
    ],
  },
  {
    type: "event",
    name: "ExpirationChanged",
    inputs: [
      {
        type: "uint256",
        name: "newExpiration",
        internalType: "uint256",
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "LockTimeChanged",
    inputs: [
      {
        type: "uint256",
        name: "newLockTime",
        internalType: "uint256",
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "OwnershipTransferred",
    inputs: [
      {
        type: "address",
        name: "previousOwner",
        internalType: "address",
        indexed: true,
      },
      {
        type: "address",
        name: "newOwner",
        internalType: "address",
        indexed: true,
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "Paused",
    inputs: [
      {
        type: "address",
        name: "account",
        internalType: "address",
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "RateChanged",
    inputs: [
      {
        type: "uint256",
        name: "newRate",
        internalType: "uint256",
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "Unpaused",
    inputs: [
      {
        type: "address",
        name: "account",
        internalType: "address",
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
    name: "LOCKUP_TIME",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
    name: "_depositBlocks",
    inputs: [
      { type: "address", name: "", internalType: "address" },
      { type: "uint256", name: "", internalType: "uint256" },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
    name: "calculateReward",
    inputs: [
      { type: "address", name: "account", internalType: "address" },
      { type: "uint256", name: "tokenId", internalType: "uint256" },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      { type: "uint256[]", name: "rewards", internalType: "uint256[]" },
    ],
    name: "calculateRewards",
    inputs: [
      { type: "address", name: "account", internalType: "address" },
      { type: "uint256[]", name: "tokenIds", internalType: "uint256[]" },
    ],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "claimRewards",
    inputs: [
      { type: "uint256[]", name: "tokenIds", internalType: "uint256[]" },
    ],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "deposit",
    inputs: [
      { type: "uint256[]", name: "tokenIds", internalType: "uint256[]" },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "uint256[]", name: "", internalType: "uint256[]" }],
    name: "depositsOf",
    inputs: [{ type: "address", name: "account", internalType: "address" }],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "emergencyWithdraw",
    inputs: [
      { type: "uint256[]", name: "tokenIds", internalType: "uint256[]" },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "address", name: "", internalType: "address" }],
    name: "erc20Address",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
    name: "expiration",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "pure",
    outputs: [{ type: "bytes4", name: "", internalType: "bytes4" }],
    name: "onERC721Received",
    inputs: [
      { type: "address", name: "", internalType: "address" },
      { type: "address", name: "", internalType: "address" },
      { type: "uint256", name: "", internalType: "uint256" },
      { type: "bytes", name: "", internalType: "bytes" },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "address", name: "", internalType: "address" }],
    name: "owner",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "pause",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "bool", name: "", internalType: "bool" }],
    name: "paused",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
    name: "rate",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "renounceOwnership",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "setExpiration",
    inputs: [{ type: "uint256", name: "_expiration", internalType: "uint256" }],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "setLockTime",
    inputs: [{ type: "uint256", name: "_lockTime", internalType: "uint256" }],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "setRate",
    inputs: [{ type: "uint256", name: "_rate", internalType: "uint256" }],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "address", name: "", internalType: "address" }],
    name: "stakingDestinationAddress",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
    name: "stakingTime",
    inputs: [{ type: "address", name: "", internalType: "address" }],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "transferOwnership",
    inputs: [{ type: "address", name: "newOwner", internalType: "address" }],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "unpause",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "withdraw",
    inputs: [
      { type: "uint256[]", name: "tokenIds", internalType: "uint256[]" },
    ],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "withdrawTokens",
    inputs: [],
  },
];

const ClaimRewards2 = ({ refreshData }) => {
  const { address } = useAccount();
  const provider = useProvider();
  const { chain } = useNetwork();
  const [floorPriceELA, setFloorPriceELA] = useState(null);
  const [elaToUsdRate, setElaToUsdRate] = useState(null);
  const [dexPairData, setDexPairData] = useState(null);
  const [loadingClaim, setLoadingClaim] = useState(false);
  const [stakedNftIds, setStakedNftIds] = useState([]);
  const [totalRewards, setTotalRewards] = useState("0");
  const [isLoadingFloorPrice, setIsLoadingFloorPrice] = useState(true);
  const [isLoadingElaToUsdRate, setIsLoadingElaToUsdRate] = useState(true);

  const stakingContract = useMemo(
    () =>
      new ethers.Contract(
        STAKING_CONTRACT_ADDRESS,
        stakingContractABI,
        provider
      ),
    [provider]
  );

  useEffect(() => {
    const fetchFloorPrice = async () => {
      try {
        const response = await axios.get(
          "https://ela.city/api/info/getcollections?onlyAppropriate=1&withStats=1"
        );
        if (response.status === 200 && response.data.status === "success") {
          const collection = response.data.data.find(
            (item) => item.collectionName === "Gold APR Boost"
          );
          if (collection) {
            setFloorPriceELA(collection.floor.price);
          }
        }
      } catch (error) {
        console.error("Failed to fetch floor price:", error);
      } finally {
        setIsLoadingFloorPrice(false);
      }
    };

    fetchFloorPrice();
  }, []);

  useEffect(() => {
    const fetchElaToUsdRate = async () => {
      try {
        const response = await axios.get(
          "https://api.coingecko.com/api/v3/simple/price?ids=elastos&vs_currencies=usd"
        );
        if (response.status === 200) {
          setElaToUsdRate(response.data.elastos.usd);
        }
      } catch (error) {
        console.error("Failed to fetch ELA to USD rate:", error);
      } finally {
        setIsLoadingElaToUsdRate(false);
      }
    };

    fetchElaToUsdRate();
  }, []);

  useEffect(() => {
    const fetchDexscreenerData = async () => {
      try {
        const response = await axios.get(
          "https://api.dexscreener.com/latest/dex/pairs/elastos/0x2f45C6Df50E7F8517dAc64943a2161bBCFBa1182"
        );
        if (response.status === 200) {
          setDexPairData(response.data.pairs[0]);
        }
      } catch (error) {
        console.error("Failed to fetch from Dexscreener:", error);
      }
    };

    fetchDexscreenerData();
  }, []);

  useEffect(() => {
    const fetchStakedNFTs = async () => {
      if (!address) return;
      try {
        const stakedNftIds = await stakingContract.depositsOf(address);
        setStakedNftIds(stakedNftIds.map((id) => id.toString()));

        const rewards = await stakingContract.calculateRewards(
          address,
          stakedNftIds
        );
        const total = rewards.reduce(
          (acc, reward) => acc + parseFloat(ethers.utils.formatEther(reward)),
          0
        );
        setTotalRewards(total.toFixed(9)); // Format to 9 decimal places
      } catch (error) {
        console.error("Failed to fetch staked NFTs and rewards", error);
      }
    };

    fetchStakedNFTs();
  }, [address, stakingContract]);

  const { config } = usePrepareContractWrite({
    address: STAKING_CONTRACT_ADDRESS,
    abi: stakingContractABI,
    functionName: "claimRewards",
    args: [stakedNftIds],
  });

  const { writeAsync } = useContractWrite(config);

  const handleClaim = async () => {
    if (stakedNftIds.length === 0) {
      toast.error("No staked NFTs available for claiming rewards.", {
        className: "custom-toast error-toast",
      });
      return;
    }

    setLoadingClaim(true);
    try {
      const tx = await writeAsync();
      const receipt = await tx.wait();
      if (receipt.status === 1) {
        toast.success("Rewards claimed successfully.", {
          className: "custom-toast success-toast",
        });
        await refreshData();
      } else {
        throw new Error("Transaction failed");
      }
    } catch (error) {
      toast.error(`Claiming rewards failed: ${error.message}`, {
        className: "custom-toast error-toast",
      });
    }
    setLoadingClaim(false);
  };

  return (
    <div>
      <div className="poolscardwrapper cursor-pointer position-relative">
        <img
          src={chestTagOpen}
          alt="pool-tag"
          className="dashboard-pool-tag d-lg-flex"
        />
        <div
          className="purplediv"
          style={{ background: "#8890C4", top: "12px" }}
        ></div>
        <div className="d-flex flex-column gap-0">
          <div className="d-flex m-0 justify-content between gap-2 align-items-center justify-content-between title-apr-wrapper">
            <div className="d-flex align-items-center">
              <h6 className="token-name d-flex align-items-center gap-2">
                <img
                  src={require(`./silver.png`)}
                  alt=""
                  className="tokenlogo"
                  width={32}
                  height={32}
                />
                Silver
              </h6>
            </div>
            <div className="d-flex align-items-baseline gap-1">
              {chain?.id === 20 ? (
                <h6 className="apr-amount">{stakedNftIds.length}</h6>
              ) : (
                <h6 className="apr-amount blurrypool">{stakedNftIds.length}</h6>
              )}
              <h6 className="apr-title">Staked</h6>
            </div>
          </div>

          <div className="d-flex m-0 justify-content between gap-2 align-items-center justify-content-between title-apr-wrapper">
            <div className="d-flex flex-column">
              <h6 className="tvl-text">Rewards USD</h6>
              {chain?.id === 20 ? (
                <h6 className="tvl-amount">
                  {dexPairData
                    ? `${getFormattedNumber(
                        parseFloat(totalRewards) *
                          parseFloat(dexPairData.priceUsd),
                        2
                      )} USD`
                    : "Loading..."}
                </h6>
              ) : (
                <h6 className="tvl-amount blurrypool">
                  {dexPairData
                    ? `${getFormattedNumber(
                        parseFloat(totalRewards) *
                          parseFloat(dexPairData.priceUsd),
                        2
                      )} USD`
                    : "Loading..."}
                </h6>
              )}
            </div>

            <div className="d-flex flex-column align-items-end">
              <h6 className="tvl-text">Rewards</h6>
              <h6 className="tvl-amount">
                {/* {floorPriceELA && elaToUsdRate
                  ? `${getFormattedNumber(
                      floorPriceELA * elaToUsdRate,
                      0
                    )} USD`
                  : "Loading..."} */}Bunny
              </h6>
            </div>
          </div>
          <div className="d-flex m-0 justify-content between gap-2 align-items-center justify-content-between bottomwrapper">
            <div className="d-flex flex-column">
              <h6 className="tvl-text">Rewards Bunny</h6>
              {chain?.id === 20 ? (
                <h6 className="tvl-amount">
                  {parseFloat(totalRewards).toFixed(4)} bunny
                </h6>
              ) : (
                <h6 className="tvl-amount blurrypool">
                  {parseFloat(totalRewards).toFixed(4)} bunny
                </h6>
              )}
            </div>
            <div className="d-flex flex-column align-items-end">
              {stakedNftIds.length > 0 ? (
                <h6
                  onClick={handleClaim}
                  disabled={loadingClaim}
                  className={`locktime-amount hover:underline ${
                    loadingClaim ? "loading" : ""
                  }`}
                >
                  {loadingClaim ? "Claiming..." : "Claim Rewards"}
                </h6>
              ) : (
                <Link to="/earn/nft-staking" className="locktime-amount hover:underline hover:text-white">
                  Stake NFT
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

ClaimRewards2.propTypes = {
  refreshData: PropTypes.func.isRequired,
};

export default ClaimRewards2;