import React, { useState, useEffect, useCallback, useMemo } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import headsImage from "./assets/head.png";
import tailsImage from "./assets/tail.png";
import "./custom.css";
import useWindowSize from "../../functions/useWindowSize";
import BalanceDisplay from "./BalanceDisplay";
import { useAccount, useProvider, useSigner } from 'wagmi';
import { ethers } from 'ethers';



const contractAddress = "0xc058E03b5F8496C5b3EF7e805e7d84B4b4F744c6"; // Replace with your contract address
const contractABI = [
  {
    "inputs": [],
    "stateMutability": "nonpayable",
    "type": "constructor"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "owner",
        "type": "address"
      }
    ],
    "name": "OwnableInvalidOwner",
    "type": "error"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "account",
        "type": "address"
      }
    ],
    "name": "OwnableUnauthorizedAccount",
    "type": "error"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "user",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "amount",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "bool",
        "name": "win",
        "type": "bool"
      },
      {
        "indexed": false,
        "internalType": "uint8",
        "name": "side",
        "type": "uint8"
      }
    ],
    "name": "Bet",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "contractBalance",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "payout",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "remainingBalance",
        "type": "uint256"
      }
    ],
    "name": "Debug",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": false,
        "internalType": "address",
        "name": "owner",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "amount",
        "type": "uint256"
      }
    ],
    "name": "Funded",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "previousOwner",
        "type": "address"
      },
      {
        "indexed": true,
        "internalType": "address",
        "name": "newOwner",
        "type": "address"
      }
    ],
    "name": "OwnershipTransferred",
    "type": "event"
  },
  {
    "inputs": [
      {
        "internalType": "uint8",
        "name": "side",
        "type": "uint8"
      }
    ],
    "name": "bunnyFlip",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "payable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "fundContract",
    "outputs": [],
    "stateMutability": "payable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "getBalance",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "getWinPercentages",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "headsWinPercentage",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "tailsWinPercentage",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "headsWins",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "name": "lastBets",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "amount",
        "type": "uint256"
      },
      {
        "internalType": "bool",
        "name": "win",
        "type": "bool"
      },
      {
        "internalType": "uint8",
        "name": "side",
        "type": "uint8"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "owner",
    "outputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "renounceOwnership",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "tailsWins",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "totalBets",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "newOwner",
        "type": "address"
      }
    ],
    "name": "transferOwnership",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "withdrawAll",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  }
];

const BunnyFlip = ({ contract }) => {
  const [side, setSide] = useState(0);
  const [betAmount, setBetAmount] = useState("");
  const [message, setMessage] = useState("");
  const windowSize = useWindowSize();
  const [gameHistory, setGameHistory] = useState([]);
  const [flipCount, setFlipCount] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);
  const [winPercentages, setWinPercentages] = useState({
    heads: "0",
    tails: "0",
  });

  const { isConnected } = useAccount();
  const provider = useProvider();
  const { data: signer } = useSigner();

 
const BalanceDisplay = ({ provider, contract }) => {
  const [balance, setBalance] = useState(null);

  useEffect(() => {
    const fetchBalance = async () => {
      if (!contract) return;

      try {
        const balance = await contract.getBalance();
        setBalance(ethers.utils.formatEther(balance));
      } catch (error) {
        console.error('Error fetching balance:', error);
      }
    };

    fetchBalance();
  }, [contract]);

  if (balance === null) {
    return <span>Loading...</span>;
  }

  return <span>{balance} ELA</span>;
};



  const gameContract = useMemo(() => {
    if (signer) {
      return new ethers.Contract(contractAddress, contractABI, signer);
    } else {
      return new ethers.Contract(contractAddress, contractABI, provider);
    }
  }, [contractAddress, contractABI, signer, provider]);

  const fetchGameHistory = useCallback(async () => {
    if (!provider || !gameContract) {
      console.error("Provider or contract is not initialized.");
      return;
    }

    try {
      const currentBlock = await provider.getBlockNumber();
      const fromBlock = Math.max(currentBlock - 5000, 0);
      const events = await gameContract.queryFilter("Bet", fromBlock, "latest");

      const bets = events
      .map((event) => ({
        amount: ethers.utils.formatEther(event.args.amount.toString()),
        win: event.args.win,
        side: event.args.side,
        transactionHash: event.transactionHash,
      }))
      .reverse();
    

      setGameHistory(bets.slice(-200000));
    } catch (error) {
      console.error("Error fetching game history:", error);
    }
  }, [provider, gameContract]);

  useEffect(() => {
    fetchGameHistory();
  }, [fetchGameHistory]);

 

  const fetchWinPercentages = useCallback(async () => {
    if (!gameContract) {
      console.error("Contract is not initialized.");
      return;
    }
  
    try {
      const percentages = await gameContract.getWinPercentages();
      setWinPercentages({
        heads: ethers.utils.formatUnits(percentages.headsWinPercentage, 2),
        tails: ethers.utils.formatUnits(percentages.tailsWinPercentage, 2),
      });
    } catch (error) {
      console.error("Error fetching win percentages:", error);
    }
  }, [gameContract]);
  
  

useEffect(() => {
  fetchWinPercentages();
}, [fetchWinPercentages]); // Ensure it runs when the function itself or its dependencies change



  const handleFlip = async (amount) => {
    if (!isConnected) {
      toast.error("Please connect your wallet first.");
      return;
    }

    if (!amount || parseFloat(amount) <= 0) {
      setMessage("Please enter a valid bet amount.");
      toast.error("Please enter a valid bet amount.");
      return;
    }

    setIsProcessing(true); // Start processing

    try {
      const weiValue = ethers.utils.parseEther(amount.toString());
      const result = await gameContract.bunnyFlip(side, {
        value: weiValue,
      });

      if (result && result.transactionHash) {
        setFlipCount(flipCount + 1); // Trigger re-fetch of game history
      }

      const win = result.events.Bet.returnValues.win.toString();
      const newGameResult = {
        amount: ethers.utils.formatEther(weiValue),
        win: win,
        side: side,  // Make sure this 'side' corresponds correctly at the time of bet placement
        transactionHash: result.transactionHash,
      };

      setGameHistory(prevHistory => [newGameResult, ...prevHistory.slice(0, 9)]);
      setMessage(`Transaction successful: ${result.transactionHash}`);
      if (win) {
        toast.success(`You won! Bet: ${amount} ELA`);
      } else {
        toast.error(`You lost. Bet: ${amount} ELA`);
      }
    } catch (error) {
      setMessage(`Error: ${error.message}`);
      toast.error(`Transaction failed: ${error.message}`);
    } finally {
      setIsProcessing(false); // End processing regardless of outcome
    }
  };

  const CoinImage = ({ side }) => {
    const numericSide = Number(side);  // Ensure 'side' is treated as a number
    return (
      <img
        src={numericSide === 0 ? headsImage : tailsImage}
        alt={numericSide === 0 ? "Heads" : "Tails"}
        style={{ width: "50px", height: "50px" }}
      />
    );
  };

  const Overlay = () => (
    <div style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 999,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
        fontSize: '24px'
    }}>
        Processing transaction...
    </div>
  );

  const handlePresetBet = (presetAmount) => {
    setBetAmount(presetAmount);
    handleFlip(presetAmount);
  };

  return (
    <div className="">
      {isProcessing && <Overlay />}

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <div className="mb-3">
        <h6 className="migration-banner-title mb-3">
          Bunny Coin Flip <span className="new-beta-text text-xs">Beta</span>
        </h6>
        <h6 className="migration-banner-subtitle text-white mb-3">
          Remember, coinflip is for fun, not for profit. Play responsibly. If
          you feel it's becoming more than a game, contact us.
        </h6>
        <div className="d-flex gap-3">
          <span className="outlines">
            <span className="">HEADS</span> {winPercentages.heads}%
          </span>
          <span className="outlines">
            <span>TAILS</span> {100 - winPercentages.heads}%
          </span>
        </div>
      </div>

      {!isConnected && (
        <div className="alert alert-warning" role="alert">
          Wallet Not Connected. Please connect your wallet to interact with the app.
        </div>
      )}

      <div className="d-flex flex-column flex-md-row m-0 gap-3 justify-content-between">
        <div className="migration-banner-wrapper p-3">
          <div className="purplediv" style={{ background: "#8890c4" }}></div>
          <div className="d-flex align-items-center gap-2">
            <h6 className="migration-banner-title mb-0">Heads Or Tails</h6>
          </div>
          <div className="d-flex flex-column gap-3">
            <div className="my-4">
              <>
                <span className="migrated-tokens mb-0">
                  <div className="mb-3 text-white flex gap-3 items-center">
                    <button
                      className={`bzs ${side === 0 ? "selected" : ""} ms-2`}
                      onClick={() => setSide(0)}
                    >
                      <img
                        src={headsImage}
                        alt="Heads"
                        style={{ width: "100px", height: "100px" }}
                      />
                    </button>
                    <button
                      className={`bzs ${side === 1 ? "selected" : ""} ms-2`}
                      onClick={() => setSide(1)}
                    >
                      <img
                        src={tailsImage}
                        alt="Tails"
                        style={{ width: "100px", height: "100px" }}
                      />
                    </button>
                  </div>
                </span>
              </>
            </div>
            <span className="migration-progress-info">
              <h6 className="migration-banner-title mb-0">Quick bet</h6>
              <div className="text-white flex gap-3 items-center my-4">
                {[0.1, 0.5, 2].map((amount) => (
                  <button
                    key={amount}
                    className="btn outline-btn"
                    style={{ padding: "6px 24px" }}
                    onClick={() => handlePresetBet(amount)}
                  >
                    {amount} ELA
                  </button>
                ))}
              </div>
            </span>
            <span className="migration-progress-info mb-0">
              <h6 className="migration-banner-title mb-0">Or enter amount</h6>
              <p className="migration-banner-subtitle mb-0">
                *min 0.1 ELA - max 2 ELA
              </p>
              <div className="d-flex align-items-center gap-3 my-4">
                <input
                  type="text"
                  className="text-lg outline-btn bg-transparent border-1 border migration-inner-progress w-100 d-flex align-items-center justify-content-end px-3"
                  value={betAmount}
                  onChange={(e) => {
                    let inputValue = e.target.value.replace(/,/g, ".");
                    const regex = /^[0-9]*\.?[0-9]*$/; // Regular expression to allow numbers and '.' character
                    if (inputValue === "" || regex.test(inputValue)) {
                      // Limit the maximum input value to 3
                      if (parseFloat(inputValue) > 2) {
                        inputValue = "2";
                      }
                      setBetAmount(inputValue);
                    }
                  }}
                  onBlur={() => {
                    // Ensure that the amount is not less than 0.01 when the user leaves the field
                    if (parseFloat(betAmount) < 0.1) {
                      setBetAmount("0.1");
                    }
                  }}
                  placeholder="Amount in ELA"
                />

                <button
                  className="btn filled-btn text-xl"
                  onClick={() => handleFlip(betAmount)}
                  disabled={!isConnected}
                >
                  Flip
                </button>
              </div>
            </span>
            <div className="migration-outer-progress d-flex align-items-center w-full justify-content-start">
              <div className="progress-dots d-flex align-items-center justify-content-between">
                <span className="migration-dot"></span>
                <span className="migration-dot"></span>
                <span className="migration-dot"></span>
                <span className="migration-dot"></span>
                <span className="migration-dot"></span>
                <span className="migration-dot"></span>
                <span className="migration-dot"></span>
                <span className="migration-dot"></span>
                <span className="migration-dot"></span>
                <span className="migration-dot"></span>
              </div>
              <div className="migration-inner-progress w-full d-flex align-items-center justify-content-end px-3">
                <div className="d-flex align-items-center gap-2 w-full">
                  <>
                    <h6 className="migration-percentage mb-0 space-between w-full d-flex text-xs md:text-lg">
                      <BalanceDisplay provider={provider} contract={gameContract} />
                      <span className="ml-auto">Pool balance </span>
                    </h6>
                    <span className="migration-dash"></span>
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="explorercard-wrapper d-flex position-relative w-full">
          <div
            className="purplediv"
            style={{ background: "#8890C4", top: "15px" }}
          ></div>
          <div className="col-12 col-lg-12 d-flex flex-column gap-3">
            <div className="justify-content-between gap-2 align-items-center">
              <h6 className="migration-banner-title mb-0">
                Recent Game History
              </h6>
            </div>
            <ul className="list-group w-full d-flex flex-column gap-1 max-h-96 overflow-y-scroll">
              {gameHistory.map((game) => (
                <li key={game.transactionHash} className="btn outline-btn text-white text-left text-sm py-2 px-3 border-bottom border-gray-400">
                  <div className="d-flex gap-4 justify-content-between align-items-center overflow-x-scroll md:overflow-hidden items-center">
                    <div className="col-4">
                      <p className="mb-0">
                        <CoinImage side={game.side} />
                      </p>
                      <p className="mb-0 d-flex gap-1">
                        <strong>Bet:</strong>
                        {game.amount} ELA
                      </p>
                    </div>
                    <div className="col-10">
                      <p className="mb-0">
                        <strong>Result: </strong>
                        <span
                          className={game.win ? "text-success" : "text-danger"}
                        >
                          {game.win ? "WON" : "LOST"}
                        </span>
                      </p>
                      <p className="mb-0 d-flex gap-1">
                        <strong>TX:</strong>{" "}
                        <a
                          className="d-flex gap-1"
                          href={`https://esc.elastos.io/tx/${game.transactionHash}/internal-transactions`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {game.transactionHash}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            viewBox="0 0 16 16"
                          >
                            <path d="M14 13.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H1.5v11h11v-1.5a.5.5 0 0 1 .5-.5z" />
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L10 9.293V7.5a.5.5 0 0 1 1 0v3a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1 0-1h1.793L4.646 5.354a.5.5 0 0 1 0-.708z" />
                          </svg>
                        </a>{" "}
                      </p>
                    </div>
                  </div>
                </li>
              ))}
            </ul>

            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BunnyFlip;



























