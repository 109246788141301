import React from "react";
import "./_nftPlaceHolder.scss";

const NftPlaceHolder = ({ onMintClick, width }) => {
  return (
    <div className="placeholder-wrapper nft-caw-card" style={{ width: width }}>
      <a
        className="placeholder-button"
        href="/apr"
        target={"_blank"}
        rel="noreferrer"
      >
        <div className="placeholder-content">
     

<video
    src="https://bunnydomain.mypinata.cloud/ipfs/QmNMFuR2HoLtPWA6Qi9qGMXW9D5rAecFjUH73Dg9teQbLC"
    alt=""
    className="placeholder-content-img grayscale"
    autoPlay
    loop
    playsInline
    muted
  >
    Your browser does not support the video tag.
  </video>


 


          <p className="placeholder-content-text">
            You can view all your NFTs to manage them
          </p>
          <a
            className="placeholder-button"
            href="/apr"
            target={"_blank"}
            rel="noreferrer"
          >
           Mint now
          </a>
        </div>
      </a>
    </div>
  );
};

export default NftPlaceHolder;
