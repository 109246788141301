import React, { useState, useEffect, useCallback, useMemo } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import headsImage from "./assets/head.png";
import tailsImage from "./assets/tail.png";
import "./custom.css";
import useWindowSize from "../../functions/useWindowSize";
import BalanceDisplay from "./BalanceDisplay";
import { useAccount, useProvider, useSigner } from "wagmi";
import { ethers } from "ethers";

const contractAddress = "0xc058E03b5F8496C5b3EF7e805e7d84B4b4F744c6"; // Replace with your contract address
const contractABI = [
  {
    inputs: [],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "owner",
        type: "address",
      },
    ],
    name: "OwnableInvalidOwner",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "OwnableUnauthorizedAccount",
    type: "error",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "user",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "bool",
        name: "win",
        type: "bool",
      },
      {
        indexed: false,
        internalType: "uint8",
        name: "side",
        type: "uint8",
      },
    ],
    name: "Bet",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "contractBalance",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "payout",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "remainingBalance",
        type: "uint256",
      },
    ],
    name: "Debug",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "Funded",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    inputs: [
      {
        internalType: "uint8",
        name: "side",
        type: "uint8",
      },
    ],
    name: "bunnyFlip",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [],
    name: "fundContract",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [],
    name: "getBalance",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getWinPercentages",
    outputs: [
      {
        internalType: "uint256",
        name: "headsWinPercentage",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "tailsWinPercentage",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "headsWins",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    name: "lastBets",
    outputs: [
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        internalType: "bool",
        name: "win",
        type: "bool",
      },
      {
        internalType: "uint8",
        name: "side",
        type: "uint8",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "tailsWins",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "totalBets",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "withdrawAll",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

const BunnyFlip = ({ contract }) => {
  const [side, setSide] = useState(0);
  const [betAmount, setBetAmount] = useState("");
  const [message, setMessage] = useState("");
  const windowSize = useWindowSize();
  const [gameHistory, setGameHistory] = useState([]);
  const [flipCount, setFlipCount] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);
  const [winPercentages, setWinPercentages] = useState({
    heads: "0",
    tails: "0",
  });

  const { isConnected } = useAccount();
  const provider = useProvider();
  const { data: signer } = useSigner();

  const BalanceDisplay = ({ provider, contract }) => {
    const [balance, setBalance] = useState(null);

    useEffect(() => {
      const fetchBalance = async () => {
        if (!contract) return;

        try {
          const balance = await contract.getBalance();
          setBalance(ethers.utils.formatEther(balance));
        } catch (error) {
          console.error("Error fetching balance:", error);
        }
      };

      fetchBalance();
    }, [contract]);

    if (balance === null) {
      return <span>Loading...</span>;
    }

    return <span>{balance} ELA</span>;
  };

  const gameContract = useMemo(() => {
    if (signer) {
      return new ethers.Contract(contractAddress, contractABI, signer);
    } else {
      return new ethers.Contract(contractAddress, contractABI, provider);
    }
  }, [contractAddress, contractABI, signer, provider]);

  const fetchGameHistory = useCallback(async () => {
    if (!provider || !gameContract) {
      console.error("Provider or contract is not initialized.");
      return;
    }

    try {
      const currentBlock = await provider.getBlockNumber();
      const fromBlock = Math.max(currentBlock - 5000, 0);
      const events = await gameContract.queryFilter("Bet", fromBlock, "latest");

      const bets = events
        .map((event) => ({
          amount: ethers.utils.formatEther(event.args.amount.toString()),
          win: event.args.win,
          side: event.args.side,
          transactionHash: event.transactionHash,
        }))
        .reverse();

      setGameHistory(bets.slice(-200000));
    } catch (error) {
      console.error("Error fetching game history:", error);
    }
  }, [provider, gameContract]);

  useEffect(() => {
    fetchGameHistory();
  }, [fetchGameHistory]);

  const fetchWinPercentages = useCallback(async () => {
    if (!gameContract) {
      console.error("Contract is not initialized.");
      return;
    }

    try {
      const percentages = await gameContract.getWinPercentages();
      setWinPercentages({
        heads: ethers.utils.formatUnits(percentages.headsWinPercentage, 2),
        tails: ethers.utils.formatUnits(percentages.tailsWinPercentage, 2),
      });
    } catch (error) {
      console.error("Error fetching win percentages:", error);
    }
  }, [gameContract]);

  useEffect(() => {
    fetchWinPercentages();
  }, [fetchWinPercentages]); // Ensure it runs when the function itself or its dependencies change

  const handleFlip = async (amount) => {
    if (!isConnected) {
      toast.error("Please connect your wallet first.");
      return;
    }

    if (!amount || parseFloat(amount) <= 0) {
      setMessage("Please enter a valid bet amount.");
      toast.error("Please enter a valid bet amount.");
      return;
    }

    setIsProcessing(true); // Start processing

    try {
      const weiValue = ethers.utils.parseEther(amount.toString());
      const result = await gameContract.bunnyFlip(side, {
        value: weiValue,
      });

      if (result && result.transactionHash) {
        setFlipCount(flipCount + 1); // Trigger re-fetch of game history
      }

      const win = result.events.Bet.returnValues.win.toString();
      const newGameResult = {
        amount: ethers.utils.formatEther(weiValue),
        win: win,
        side: side, // Make sure this 'side' corresponds correctly at the time of bet placement
        transactionHash: result.transactionHash,
      };

      setGameHistory((prevHistory) => [
        newGameResult,
        ...prevHistory.slice(0, 9),
      ]);
      setMessage(`Transaction successful: ${result.transactionHash}`);
      if (win) {
        toast.success(`You won! Bet: ${amount} ELA`);
      } else {
        toast.error(`You lost. Bet: ${amount} ELA`);
      }
    } catch (error) {
      setMessage(`Error: ${error.message}`);
      toast.error(`Transaction failed: ${error.message}`);
    } finally {
      setIsProcessing(false); // End processing regardless of outcome
    }
  };

  const CoinImage = ({ side }) => {
    const numericSide = Number(side); // Ensure 'side' is treated as a number
    return (
      <img
        src={numericSide === 0 ? headsImage : tailsImage}
        alt={numericSide === 0 ? "Heads" : "Tails"}
        style={{ width: "50px", height: "50px" }}
      />
    );
  };

  const Overlay = () => (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        zIndex: 999,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "white",
        fontSize: "24px",
      }}
    >
      Processing transaction...
    </div>
  );

  const handlePresetBet = (presetAmount) => {
    setBetAmount(presetAmount);
    handleFlip(presetAmount);
  };

  return (
    <div className="">
      {isProcessing && <Overlay />}

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <div className="mb-3">
        <h6 className="migration-banner-title mb-3">Blockchain Dapp</h6>
        <h6 className="migration-banner-subtitle text-white mb-3">
          Create your 1st blockchain application with Elastos Smart Chain,
          Web3.js,Hardhat.org, React.js, Next.js and Solidity smart contracts
        </h6>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 w-full">
        <div className="migration-banner-wrapper2 w-full">
          <div className="d-flex flex-column gap-4">
            <div className="max-h-44 overflow-hidden">
              <img
                alt="logo"
                src="./nftminting.png"
                class="w-full rounded-md"
              />
            </div>
            <div className="p-3 flex flex-col gap-4 ">
              <div className="d-flex flex-col align-items-left gap-2">
                <h6 className="migration-banner-title mb-0">
                  NFT minting dapp
                </h6>
                <p>Build Web3.0 Minting App With React Js, Solidity Smart...</p>
              </div>
              <div className="d-flex align-items-center gap-2">
                <a
                  href="./Minting-dapp.zip"
                  className="btn filled-btn"
                  download
                >
                  Download code
                </a>

                <a
                  href="https://minting-demo1.netlify.app/"
                  target="_blank"
                  rel="noreferrer"
                  className="btn outline-btn"
                  style={{ padding: "6px 24px" }}
                >
                  Demo
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="migration-banner-wrapper2 w-full grayscale">
          <div className="d-flex flex-column gap-4">
            <div className="max-h-44 overflow-hidden">
              <img
                alt="logo"
                src="./nftstaking.png"
                class="w-full rounded-md"
              />
            </div>
            <div className="p-3 flex flex-col gap-4 ">
              <div className="d-flex flex-col align-items-left gap-2">
                <h6 className="migration-banner-title mb-0">
                  NFT staking dapp
                </h6>
                <p>
                  Build Web3.0 NFT Staking App With React Js, Solidity Smart...
                </p>
              </div>
              <div className="d-flex align-items-center gap-2">
                <a disabled className="btn filled-btn">
                  Download code
                </a>
                <a
                  disabled
                  className="btn outline-btn"
                  style={{ padding: "6px 24px" }}
                >
                  Demo
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="container mx-auto p-4 mt-12">
  <ol class="relative text-gray-500 border-l border-gray-200 dark:border-gray-700 dark:text-gray-400">
    <li class="mb-10 ml-6">            
        <span class="absolute flex items-center justify-center w-8 h-8 bg-[#857dfa] rounded-full -left-4 ring-4 ring-white dark:ring-gray-900 ">
            1
        </span>
        <h3 class="font-medium leading-tight">Download Visual Studio Code</h3>
        <p class="text-sm mb-4">Visual Studio Code is a powerful, free code editor from Microsoft. You can download it from the following link:</p>
        <a href="https://code.visualstudio.com/" target="_blank" class="btn filled-btn">
          Download Visual Studio Code
        </a>
    </li>
    <li class="mb-10 ml-6">
        <span class="absolute flex items-center justify-center w-8 h-8 bg-[#857dfa] rounded-full -left-4 ring-4 ring-white dark:ring-gray-900">
           2
        </span>
        <h3 class="font-medium leading-tight">Download Node.js</h3>
        <p class="text-sm mb-4">Node.js is a JavaScript runtime used to run the dapp. You can download it from the following link:</p>
        <a href="https://nodejs.org/" target="_blank" class="btn filled-btn">
          Download Node.js
        </a>
    </li>
    <li class="mb-10 ml-6">
        <span class="absolute flex items-center justify-center w-8 h-8 bg-[#857dfa] rounded-full -left-4 ring-4 ring-white dark:ring-gray-900">
            3
        </span>
        <h3 class="font-medium leading-tight">Install Dependencies</h3>
        <p class="text-sm mb-4">Navigate to the project directory and install the required dependencies by running the following command:</p>
        <kbd class="px-2 py-1.5 text-xs font-semibold text-gray-800 bg-[#857dfa] border border-gray-200 rounded-lg dark:bg-gray-600 dark:text-gray-100 dark:border-gray-500">npm install</kbd> 
    </li>
    <li class="mb-10 ml-6">
        <span class="absolute flex items-center justify-center w-8 h-8 bg-[#857dfa] rounded-full -left-4 ring-4 ring-white dark:ring-gray-900">
           4
        </span>
        <h3 class="font-medium leading-tight">Start the Development Server</h3>
        <p class="text-sm mb-4">Start the development server to run the dapp locally by running the following command:</p>
        <kbd class="px-2 py-1.5 text-xs font-semibold text-gray-800 bg-[#857dfa] border border-gray-200 rounded-lg dark:bg-gray-600 dark:text-gray-100 dark:border-gray-500">npm start</kbd> 
    </li>
    <li class="ml-6">
        <span class="absolute flex items-center justify-center w-8 h-8 bg-[#857dfa] rounded-full -left-4 ring-4 ring-white dark:ring-gray-900">
           5
        </span>
        <h3 class="font-medium leading-tight">Follow Instructions in README</h3>
        <p class="text-sm mb-4">Please follow the instructions provided in the README file for detailed setup and usage information.</p>
    </li>
  </ol>
</div>



    </div>
  );
};

export default BunnyFlip;
