import React from "react";  // Ensure React is in scope
import PropTypes from "prop-types";  // Import PropTypes for prop validation
import { Redirect } from "react-router-dom";

export function RedirectPathToHomeOnly({ location }) {
  return <Redirect to={{ ...location, pathname: '/' }} />;
}

// Add prop-types validation
RedirectPathToHomeOnly.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    // Include other location properties if needed
  }).isRequired,
};
