import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import { ethers } from "ethers";
import PropTypes from "prop-types";
import getFormattedNumber from "../../functions/get-formatted-number";
import { formattedNum } from "../../functions/formatUSD";
import Address from "./address";
import WalletModal from "../WalletModal";
import "./top-pools.css";
import ellipse from "./assets/ellipse.svg";
import arrowup from "./assets/arrow-up.svg";
import moreinfo from "./assets/more-info.svg";
import wallet from "./assets/wallet.svg";
import Tooltip from "@mui/material/Tooltip";
import { useHistory } from "react-router-dom";
import NftStakeCheckListPremiumModal from "../caws/NftMinting/components/NftMinting/NftStakeChecklistModal/NftStakeChecklistPremiumModal";
import useWindowSize from "../../functions/useWindowSize";
import OutsideClickHandler from "react-outside-click-handler";
import NftStaking from "./simple";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { useAccount, useProvider } from "wagmi";
import ClaimRewards2 from "./claimBtnStake";

const CAWS_PREMIUM_CONTRACT_ADDRESS = "0x9935433D935d3D6c8550fe5eDC7813a23c5F5245";
const INFURA_ENDPOINT = "https://rpc.glidefinance.io";

const contractABI = [
  {
    type: "constructor",
    stateMutability: "nonpayable",
    inputs: [
      {
        type: "address",
        name: "_stakingDestinationAddress",
        internalType: "address",
      },
      { type: "uint256", name: "_rate", internalType: "uint256" },
      { type: "uint256", name: "_expiration", internalType: "uint256" },
      { type: "address", name: "_erc20Address", internalType: "address" },
    ],
  },
  {
    type: "event",
    name: "ExpirationChanged",
    inputs: [
      {
        type: "uint256",
        name: "newExpiration",
        internalType: "uint256",
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "LockTimeChanged",
    inputs: [
      {
        type: "uint256",
        name: "newLockTime",
        internalType: "uint256",
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "OwnershipTransferred",
    inputs: [
      {
        type: "address",
        name: "previousOwner",
        internalType: "address",
        indexed: true,
      },
      {
        type: "address",
        name: "newOwner",
        internalType: "address",
        indexed: true,
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "Paused",
    inputs: [
      {
        type: "address",
        name: "account",
        internalType: "address",
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "RateChanged",
    inputs: [
      {
        type: "uint256",
        name: "newRate",
        internalType: "uint256",
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "Unpaused",
    inputs: [
      {
        type: "address",
        name: "account",
        internalType: "address",
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
    name: "LOCKUP_TIME",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
    name: "_depositBlocks",
    inputs: [
      { type: "address", name: "", internalType: "address" },
      { type: "uint256", name: "", internalType: "uint256" },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
    name: "calculateReward",
    inputs: [
      { type: "address", name: "account", internalType: "address" },
      { type: "uint256", name: "tokenId", internalType: "uint256" },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      { type: "uint256[]", name: "rewards", internalType: "uint256[]" },
    ],
    name: "calculateRewards",
    inputs: [
      { type: "address", name: "account", internalType: "address" },
      { type: "uint256[]", name: "tokenIds", internalType: "uint256[]" },
    ],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "claimRewards",
    inputs: [
      { type: "uint256[]", name: "tokenIds", internalType: "uint256[]" },
    ],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "deposit",
    inputs: [
      { type: "uint256[]", name: "tokenIds", internalType: "uint256[]" },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "uint256[]", name: "", internalType: "uint256[]" }],
    name: "depositsOf",
    inputs: [{ type: "address", name: "account", internalType: "address" }],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "emergencyWithdraw",
    inputs: [
      { type: "uint256[]", name: "tokenIds", internalType: "uint256[]" },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "address", name: "", internalType: "address" }],
    name: "erc20Address",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
    name: "expiration",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "pure",
    outputs: [{ type: "bytes4", name: "", internalType: "bytes4" }],
    name: "onERC721Received",
    inputs: [
      { type: "address", name: "", internalType: "address" },
      { type: "address", name: "", internalType: "address" },
      { type: "uint256", name: "", internalType: "uint256" },
      { type: "bytes", name: "", internalType: "bytes" },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "address", name: "", internalType: "address" }],
    name: "owner",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "pause",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "bool", name: "", internalType: "bool" }],
    name: "paused",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
    name: "rate",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "renounceOwnership",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "setExpiration",
    inputs: [{ type: "uint256", name: "_expiration", internalType: "uint256" }],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "setLockTime",
    inputs: [{ type: "uint256", name: "_lockTime", internalType: "uint256" }],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "setRate",
    inputs: [{ type: "uint256", name: "_rate", internalType: "uint256" }],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "address", name: "", internalType: "address" }],
    name: "stakingDestinationAddress",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
    name: "stakingTime",
    inputs: [{ type: "address", name: "", internalType: "address" }],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "transferOwnership",
    inputs: [{ type: "address", name: "newOwner", internalType: "address" }],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "unpause",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "withdraw",
    inputs: [
      { type: "uint256[]", name: "tokenIds", internalType: "uint256[]" },
    ],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "withdrawTokens",
    inputs: [],
  },
];
const provider = new ethers.providers.JsonRpcProvider(INFURA_ENDPOINT);
const contract = new ethers.Contract(CAWS_PREMIUM_CONTRACT_ADDRESS, contractABI, provider);

const CawsDetailsPremium = ({
  coinbase,
  isConnected,
  listType,
  chainId,
  handleConnection,
  renderedPage,
  expired,
  isPremium,
}) => {
  const [myNFTs, setMyNFTs] = useState([]);
  const [amountToStake, setamountToStake] = useState("");
  const [mystakes, setMystakes] = useState([]);
  const [color, setColor] = useState("#F13227");
  const [status, setStatus] = useState("");
  const [showApprove, setshowApprove] = useState(true);
  const [showChecklistModal, setshowChecklistModal] = useState(false);
  const [EthRewards, setEthRewards] = useState(0);
  const [showStaked, setshowStaked] = useState(true);
  const [showToStake, setshowToStake] = useState(false);
  const [ethToUSD, setethToUSD] = useState(0);
  const [openStakeChecklist, setOpenStakeChecklist] = useState(false);
  const [showUnstakeModal, setShowUnstakeModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [countDownLeft, setCountDownLeft] = useState(59000);
  const [approvedNfts, setApprovedNfts] = useState([]);
  const [cawspopup, setCawspopup] = useState(false);
  const [count, setcount] = useState(0);
  const [count2, setcount2] = useState(0);
  const [hide, setHide] = useState("");
  const windowSize = useWindowSize();
  const navigate = useHistory();
  const [totalStakes, setTotalStakes] = useState(0);
  const { address } = useAccount();
  const provider = useProvider();
  const [selectedNftIds, setSelectedNftIds] = useState([]);

  const refreshData = () => {
    console.log('Data refreshed');
  };

  const contract = useMemo(
    () => new ethers.Contract(CAWS_PREMIUM_CONTRACT_ADDRESS, contractABI, provider),
    [provider]
  );

  const fetchTotalStakes = async () => {
    try {
      if (address) {
        const stakedNftIds = await contract.depositsOf(address);
        setTotalStakes(stakedNftIds.length);
      }
    } catch (error) {
      console.error("Error fetching total stakes:", error);
    }
  };

  useEffect(() => {
    if (isConnected && chainId === "20") {
      fetchTotalStakes();
    }
  }, [isConnected, chainId, address]);

  const checkApproval = async () => {
    if (!address) return;

    const result = await contract.isApprovedForAll(address, CAWS_PREMIUM_CONTRACT_ADDRESS);
    setshowApprove(!result);
    setStatus(result ? "" : " *Please approve before deposit");
    setColor(result ? "#939393" : "#F13227");
  };

  const myNft = async () => {
    if (!address) return;

    const myNftIds = await contract.tokensOfOwner(address);
    const nfts = await Promise.all(myNftIds.map((id) => contract.tokenURI(id)));
    setMyNFTs(nfts.reverse());
  };

  const getStakesIds = async () => {
    if (!address) return [];

    const stakedNftIds = await contract.depositsOf(address);
    return stakedNftIds.map((id) => parseInt(id));
  };

  const myStakes = async () => {
    const stakedNftIds = await getStakesIds();
    const stakedNfts = await Promise.all(stakedNftIds.map((id) => contract.tokenURI(id)));
    setMystakes(stakedNfts.reverse());
  };

  const handleClaimAll = async () => {
    if (!address) return;

    const stakedNftIds = await getStakesIds();
    const rewards = await contract.calculateRewards(address, stakedNftIds);
    const totalRewards = rewards.reduce((acc, reward) => acc + parseFloat(ethers.utils.formatEther(reward)), 0);
    setEthRewards(totalRewards);
  };

  const claimRewards = async () => {
    if (!address) return;

    const stakedNftIds = await getStakesIds();
    const signer = provider.getSigner(address);
    const stakingContractWithSigner = contract.connect(signer);
    try {
      await stakingContractWithSigner.claimRewards(stakedNftIds);
      setEthRewards(0);
    } catch (err) {
      console.error(err);
    }
  };

  const convertEthToUsd = async () => {
    const res = await axios.get("https://api.coinbase.com/v2/prices/ETH-USD/spot");
    return res.data.data.amount;
  };

  const setUSDPrice = async () => {
    const ethprice = await convertEthToUsd();
    setethToUSD(Number(ethprice) * Number(EthRewards));
  };

  const calculateCountdown = async () => {
    if (!address) return;

    const stakingTime = await contract.stakingTime(address);
    const lockupTime = await contract.LOCKUP_TIME();
    const finalDay = parseInt(stakingTime) + parseInt(lockupTime);
    setCountDownLeft(finalDay * 1000 - Date.now());
  };

  const handleUnstakeAll = async () => {
    if (!address) return;

    const stakedNftIds = await getStakesIds();
    const signer = provider.getSigner(address);
    const stakingContractWithSigner = contract.connect(signer);
    try {
      await stakingContractWithSigner.withdraw(stakedNftIds);
    } catch (err) {
      console.error(err);
      setShowUnstakeModal(false);
    }
  };

  const handleNavigateToPlans = () => {
    navigate.push("/plans");
  };

  const showCawsPopup = () => {
    setCawspopup(true);
  };

  useEffect(() => {
    if (isConnected && chainId === "20") {
      myNft();
      myStakes();
      checkApproval();
      handleClaimAll();
    }
  }, [isConnected, chainId, count]);

  useEffect(() => {
    if (isConnected && chainId === "20") {
      checkApproval();
      calculateCountdown();
    }
  }, [isConnected, chainId, count2]);

  const getApprovedNfts = (data) => {
    setApprovedNfts(data);
    return data;
  };

  useEffect(() => {
    if (isConnected) {
      setUSDPrice();
    }
  }, [isConnected, EthRewards]);

  return (
    <div className="container-lg p-0">
      <div
        className={`allwrappercaws allwrapper-active mb-2 `}
        style={{
          borderRadius: listType !== "table" && "0px",
        }}
      >
        {/* Left Side Content */}
        <div className="leftside2 w-100">
          <div className="activewrapper position-relative flex-row-reverse flex-lg-row align-items-end align-items-lg-center">
            {/* Active Status */}
            <div className="d-flex flex-column flex-lg-row align-items-end align-items-lg-center justify-content-between gap-2 gap-lg-5">
              {/* Earn Rewards Info */}
              <h6 className="activetxt flex items-center gap-1">
                <img src={ellipse} alt="" className="position-relative" style={{ top: "-1px" }} />
                Active status
              </h6>
              <div className="d-flex align-items-center justify-content-between gap-2">
                <h6 className="earnrewards-text">Earn rewards in:</h6>
                <h6 className="earnrewards-token d-flex align-items-center gap-1">CARROT</h6>
              </div>
              {/* Pool Cap */}
              <div className="d-flex align-items-center justify-content-between gap-2">
                <h6 className="earnrewards-text">Pool Cap:</h6>
                <h6 className="earnrewards-token d-flex align-items-center gap-1">1000 NFTs</h6>
              </div>
              {/* Maximum Deposit */}
              <div className="d-flex align-items-center justify-content-between gap-2">
                <h6 className="earnrewards-text">Maximum deposit:</h6>
                <h6 className="earnrewards-token d-flex align-items-center gap-1">1000 NFTs</h6>
              </div>
            </div>
            {/* Get CAWS Button */}
            <div className="d-flex align-items-center justify-content-between gap-3 position-relative">
              <div className="d-flex align-items-center justify-content-between gap-3 cursor-pointer" onClick={showCawsPopup}>
                <h6 className="bottomitems">Get BUNNY</h6>
              </div>
              {cawspopup === true && (
                <div className="position-absolute">
                  {/* CAWS Links */}
                  <OutsideClickHandler
                    onOutsideClick={() => { setCawspopup(false); }}
                  >
                    <div className="tooltip d-flex justify-content-center" style={{ opacity: 1, width: 145 }}>
                      <div className="d-flex flex-column gap-2 align-items-start">
                        <a href="https://ela.city/marketplace/collections/0xe27934fb3683872e35b8d9e57c30978e1260c614" target="_blank" rel="noreferrer" onClick={() => { setCawspopup(false); }}>
                          <h6 className="bottomitems">
                            <img src={arrowup} alt="" />
                            ElaCity Marketplace
                          </h6>
                        </a>
                      </div>
                    </div>
                  </OutsideClickHandler>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* Pools Details */}
        <div className="pools-details-wrapper d-flex m-0 container-lg border-0">
          <div className="row w-100 justify-content-between gap-4 gap-lg-0">
            {/* Start Staking Block */}
            <div className="firstblockwrapper col-12 col-md-6 col-lg-2">
              <div className="d-flex flex-row flex-lg-column align-items-center align-items-lg-start justify-content-between gap-4" style={{ height: "100%" }}>
                <h6 className="start-title">Start Staking</h6>
                {/* Render Address Button */}
                <ConnectButton
                  label={"connect"}
                  accountStatus={{
                    smallScreen: "address",
                    largeScreen: "address",
                  }}
                  showBalance={false}
                  chainStatus={"none"}
                />
              </div>
            </div>
            {/* Stake NFTs Block */}
            <div className={`otherside-border col-12 col-md-6 ${renderedPage === "dashboard" ? "col-lg-3" : "col-lg-4"}`}>
              <div className="d-flex flex-column gap-2 justify-content-between h-full">
                <h6 className="deposit-txt d-flex align-items-center gap-2 justify-content-between">Stake NFTs</h6>
                <button className={`btn ${mystakes.length === 4 ? "disabled-btn" : "filledbtn"}`} disabled={mystakes.length === 4 || totalStakes === 200} onClick={() => { setshowChecklistModal(true); setOpenStakeChecklist(true); setApprovedNfts([]); setHide("staked"); }}>
                  <NftStaking />
                </button>
              </div>
            </div>
            {/* Rewards Block */}
            <div className={`otherside-border col-12 col-md-6 ${renderedPage === "dashboard" ? "col-lg-5" : "col-lg-4"}`}>
              <ClaimRewards2 selectedNftIds={selectedNftIds} refreshData={refreshData} />
            </div>
            {/* Unstake Block */}
            <div className={`otherside-border col-12 col-md-6 col-lg-2`}>
              <h6 className="deposit-txt d-flex align-items-center gap-2 justify-content-between">Stake Booster</h6>
              <button className="btn disabled-btn">
                Coming shortly
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

CawsDetailsPremium.propTypes = {
  coinbase: PropTypes.string,
  isConnected: PropTypes.bool,
  listType: PropTypes.string,
  chainId: PropTypes.string,
  handleConnection: PropTypes.func,
  renderedPage: PropTypes.string,
  expired: PropTypes.bool,
  isPremium: PropTypes.bool,
};

export default CawsDetailsPremium;