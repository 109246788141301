import React, { useEffect, useState, useRef, useMemo, useCallback } from "react";
import "./tierlevels.css";
import stakingIcon from "../assets/stakingIcon.svg";
import dypLogo from "../../top-pools-card/assets/dyplogo.svg";
import timerIcon from "../assets/timerIcon.svg";
import Slider from "react-slick";
import LaunchPoolsCard from "./LaunchPoolsCard";
import initConstantStakingNew from "../../FARMINNG/constant-staking-new-front";
import getFormattedNumber from "../../../functions/get-formatted-number";
import customSliderArrow from '../assets/customSliderArrow.svg';
import { NavLink } from "react-router-dom";
import axios from "axios";

const badgeIcons = {
  bronze: require("../assets/bronzeBadge.svg").default,
  silver: require("../assets/silverBadge.svg").default,
  gold: require("../assets/goldBadge.svg").default,
  platinum: require("../assets/platinumBadge.svg").default,
  diamond: require("../assets/diamondBadge.svg").default,
};

const TierCard = ({ tier, isCurrent }) => (
  <div
    className={`current-tier-wrapper ${isCurrent ? 'selected-tier-wrapper' : 'next-tier-wrapper'} d-flex flex-column align-items-center justify-content-start gap-2 position-relative p-3`}
  >
    <span className={isCurrent ? "current-tier" : "next-tier"}>
      {isCurrent ? "Current tier" : "Next tier"}
    </span>
    <h6 className={isCurrent ? "current-tier-title" : "tier-title"}>{tier.title}</h6>
    <img
      src={tier.badge}
      alt={`${tier.title} Badge`}
      className="tier-medal"
    />
    <h6 className="tier-dyp-amount">{getFormattedNumber(tier.amount)} BUNNY</h6>
  </div>
);

const TierLevels = ({
  coinbase,
  chainId,
  handleConnection,
  the_graph_result,
  isConnected,
  lp_id,
  display,
  infoDisplay
}) => {
  const [balance, setBalance] = useState(0);
  const [showdetails, setShowdetails] = useState(false);
  const [activeCard, setActiveCard] = useState(null);
  const [cardIndex, setCardIndex] = useState(0);
  const [details, setDetails] = useState(null);
  const slider = useRef();

  const fetchBalance = useCallback(async () => {
    try {
      console.log("Coinbase Address:", coinbase);
      const response = await axios.get(
        `https://esc.elastos.io/api?module=account&action=tokenbalance&contractaddress=0x75740FC7058DA148752ef8a9AdFb73966DEb42a8&address=${coinbase}`
      );
      console.log("API Response:", response.data);
      if (response.data.status === "1") {
        const rawBalance = response.data.result;
        const formattedBalance = parseFloat(rawBalance) / 10 ** 18;
        console.log("Raw Balance:", rawBalance);
        console.log("Formatted Balance:", formattedBalance);
        setBalance(formattedBalance);
      } else {
        console.error("Error fetching balance");
      }
    } catch (error) {
      console.error("Error fetching balance:", error);
    }
  }, [coinbase]);

  useEffect(() => {
    if (coinbase) {
      fetchBalance();
    }
  }, [coinbase, fetchBalance]);

  const tiers = [
    { title: "Bronze", amount: 5, badge: badgeIcons.bronze },
    { title: "Silver", amount: 15, badge: badgeIcons.silver },
    { title: "Gold", amount: 20, badge: badgeIcons.gold },
    { title: "Platinum", amount: 50000, badge: badgeIcons.platinum },
    { title: "Diamond", amount: 100000, badge: badgeIcons.diamond }
  ];

  const getCurrentTierIndex = useCallback((balance) => {
    console.log("Checking tiers with balance:", balance);
    for (let i = tiers.length - 1; i >= 0; i--) {
      console.log(`Checking if balance ${balance} >= tier amount ${tiers[i].amount}`);
      if (balance >= tiers[i].amount) {
        return i;
      }
    }
    return 0; // Default to Bronze if no higher tier is matched
  }, [tiers]);

  const currentTierIndex = useMemo(() => {
    const index = getCurrentTierIndex(balance);
    console.log("Current Tier Index:", index);
    return index;
  }, [balance, getCurrentTierIndex]);

  const currentTier = tiers[currentTierIndex];
  const nextTier = tiers[currentTierIndex + 1] || currentTier;

  const next = () => {
    slider.current.slickNext();
  };

  const previous = () => {
    slider.current.slickPrev();
  };

  const stake = [
    {
      icon: "dyplogo.svg",
      top_pick: true,
      tokenName: "BUNNY",
      apy: "1.08",
      tvl_usd: "48543.20",
      lockTime: "No lock",
    },
    {
      icon: "dyplogo.svg",
      top_pick: false,
      tokenName: "BUNNY",
      apy: "1.08",
      tvl_usd: "48543.20",
      lockTime: "90 Days",
    },
    {
      icon: "idypius.svg",
      top_pick: false,
      tokenName: "iBUNNY",
      apy: "1.08",
      tvl_usd: "48543.20",
      lockTime: "No lock",
    },
  ];

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1138,
        settings: {
          arrows: false,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          fade: false,
          arrows: false,
        },
      },
    ],
  };

  const stakeArrayStakeNew = [
    window.constant_staking_new1,
    window.constant_staking_new2,
  ];

  const aprArrayStake = [25, 50];
  const eth_address = "ETH";
  const feeArrayStake = [0.25, 0.5];
  const lockarray = ["No Lock", 90];

  const ConstantStaking1 = initConstantStakingNew({
    staking: stakeArrayStakeNew[cardIndex],
    apr: aprArrayStake[cardIndex],
    liquidity: eth_address,
    expiration_time: "14 December 2024",
    other_info: false,
    fee: feeArrayStake[cardIndex],
    coinbase: coinbase,
    handleConnection: handleConnection,
    chainId: chainId,
    lockTime: lockarray[cardIndex],
    listType: "table",
  });


  return (
    <div className="container-lg px-0 mt-4">
      <div className="d-flex flex-column gap-3">
        <h6 className="launchpad-hero-title">My tier level</h6>
        <span className="launchpad-hero-desc tier-level-title" style={{ display: display }}>
          Discover your Launchpad tier and check how much you are eligible to benefit. Increase your tier by depositing assets to Launchpools.
        </span>
      </div>
      <div className="active-projects-container px-3 px-lg-5 pb-5 pb-lg-0 pt-5 mt-4">
        <div className={`tier-info-wrapper ${infoDisplay === 'flex' ? 'd-flex' : 'd-none'} flex-column flex-lg-row align-items-center justify-content-between p-2 p-lg-3 gap-2 gap-lg-0 mx-0 mx-lg-3`}>
          <span className="tier-info-text">In order to increase the current tier and benefit more of the Launchpad projects, participants need to stake Bunny in a staking pool.</span>
          <NavLink to='https://glidefinance.io/info/token/0x75740FC7058DA148752ef8a9AdFb73966DEb42a8'>
            <button className="btn filledbtn px-4" style={{ whiteSpace: 'pre' }}>Buy Bunny</button>
          </NavLink>
        </div>
        <div
          className="d-flex justify-content-start justify-content-lg-center mt-4 position-relative tiers-container px-3 px-lg-0"
          style={{ gap: "100px", paddingBottom: "170px" }}
        >
          <hr className="tier-divider d-none d-lg-flex" />
          {tiers.map((tier, index) => (
            <TierCard key={index} tier={tier} isCurrent={currentTier.title === tier.title} />
          ))}
        </div>
      </div>
      <div style={{ display: display }}>
        <h6 className="launchpad-hero-title mt-5">Launchpools</h6>
        <div className="row w-100 gap-4 gap-lg-0 mx-0 flex-column flex-lg-row active-projects-container d-flex align-items-center justify-content-between mt-4 p-3">
          <div className="col-12 col-lg-4 d-flex flex-column gap-5">
            <div className="d-flex flex-column gap-3">
              <p
                className="d-flex align-items-center gap-2 staking-pill py-2 px-3"
                style={{ border: "none" }}
              >
                <img src={stakingIcon} alt="staking icon" height={24} width={24} />
                Staking
              </p>
              <div className="d-flex align-items-center gap-2">
                <img src={dypLogo} alt="dyp logo" />
                <h6
                  style={{
                    fontWeight: "600",
                    fontSize: "20px",
                    lineHeight: "18px",
                    color: "#f7f7fc",
                  }}
                >
                  BUNNY
                </h6>
              </div>
              <p className="launchpad-hero-desc">
                Bunny is a powerful, decentralized ecosystem build on next-gen infrastructure.
              </p>
              <div className="d-flex flex-column flex-lg-row align-items-center gap-4 gap-lg-5">
                <div className="d-flex flex-row flex-lg-column align-items-center justify-content-between w-100 gap-1">
                  <span className="time-left">Total rewards</span>
                  <div className="date-content">0.00</div>
                </div>
                <div className="d-flex flex-row flex-lg-column align-items-center justify-content-between w-100 gap-1">
                  <span className="time-left">Lock time</span>
                  <div className="date-content">90-days</div>
                </div>
              </div>
            </div>
            <div className="d-flex flex-column gap-1">
              <div className="time-left">Time until staking ends</div>
              <div className="d-flex align-items-center gap-2">
                <div
                  className="d-flex align-items-start justify-content-start position-relative"
                  style={{ right: "1rem", width: "fit-content" }}
                >
                  <div
                    className="d-flex flex-column align-items-center gap-2"
                    style={{ width: "65px" }}
                  >
                    <div className="timer-text">00</div>
                    <div className="details-warning">days</div>
                  </div>
                  <div className="timer-text d-none d-lg-flex"> : </div>
                  <div
                    className="d-flex flex-column align-items-center gap-2"
                    style={{ width: "65px" }}
                  >
                    <div className="timer-text">00</div>
                    <div className="details-warning">hours</div>
                  </div>
                  <div className="timer-text d-none d-lg-flex"> : </div>
                  <div
                    className="d-flex flex-column align-items-center gap-2"
                    style={{ width: "65px" }}
                  >
                    <div className="timer-text">00</div>
                    <div className="details-warning">minutes</div>
                  </div>
                  <div className="timer-text d-none d-lg-flex"> : </div>
                  <div
                    className="d-flex flex-column align-items-center gap-2"
                    style={{ width: "65px" }}
                  >
                    <div className="timer-text">00</div>
                    <div className="details-warning">seconds</div>
                  </div>
                </div>
                <img src={timerIcon} width={56} height={56} className="d-none d-lg-flex" alt="timer icon" />
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-7">
            <div className="row flex-column-reverse flex-lg-row">
              <div className="col-12 col-lg-8">
                <Slider ref={(c) => (slider.current = c)} {...settings}>
                  {stake.map((pool, index) => (
                    <LaunchPoolsCard
                      key={index}
                      chain={"eth"}
                      top_pick={pool.top_pick}
                      tokenName={pool.tokenName}
                      apr={pool.apy + "%"}
                      tvl={"$" + getFormattedNumber(pool.tvl_usd)}
                      lockTime={pool.lockTime}
                      onShowDetailsClick={() => {
                        setActiveCard(stake[index]);
                        setCardIndex(index);
                        setDetails(index);
                      }}
                      onHideDetailsClick={() => {
                        setActiveCard(null);
                        setDetails(null);
                      }}
                      details={details === index}
                    />
                  ))}
                </Slider>
              </div>
              <div className="col-12 col-lg-4 d-flex flex-column justify-content-between">
                <h6 className="time-left d-flex justify-content-center">
                  Tier progress
                </h6>
                <div className="d-flex flex-column gap-2 mt-2 position-relative">
                  <div className="d-flex align-items-center gap-2">
                    <div className="tier-roadmap-number p-1 d-flex align-items-center justify-content-center">
                      <span className="roadmap-number">1</span>
                    </div>
                    <span className="launch-pool-value">Need to buy BUNNY</span>
                  </div>
                  <div className="roadmap-line"></div>
                  <div className="d-flex align-items-center gap-2">
                    <div className="tier-roadmap-number p-1 d-flex align-items-center justify-content-center">
                      <span className="roadmap-number">2</span>
                    </div>
                    <span className="launch-pool-value">Need to hold BUNNY</span>
                  </div>
                  <div className="roadmap-line"></div>
                  <div className="d-flex align-items-center gap-2">
                    <div className="tier-roadmap-number p-1 d-flex align-items-center justify-content-center">
                      <span className="roadmap-number">3</span>
                    </div>
                    <span className="launch-pool-value">Need to stake BUNNY</span>
                  </div>
                </div>
                <div className="d-flex justify-content-center align-items-center gap-4">
                  <div className="p-3 d-flex justify-content-center align-items-center cursor-pointer" onClick={() => previous()}>
                    <img src={customSliderArrow} alt="previous arrow" className="prev-arrow" />
                  </div>
                  <div className="p-3 d-flex justify-content-center align-items-center cursor-pointer" onClick={() => next()}>
                    <img src={customSliderArrow} alt="next arrow" className="next-arrow" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {activeCard && (
        <ConstantStaking1
          is_wallet_connected={isConnected}
          coinbase={coinbase}
          the_graph_result={the_graph_result}
          lp_id={lp_id[cardIndex]}
          chainId={chainId}
          handleConnection={handleConnection}
        />
      )}
    </div>
  );
};

export default TierLevels;
