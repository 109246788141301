import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  useAccount,
  useContractWrite,
  usePrepareContractWrite,
  useProvider,
} from "wagmi";
import { ethers } from "ethers";
import NftPlaceHolderVideo from "../caws/NftMinting/components/General/NftPlaceHolder/NftPlaceHolderVideo4";
import "./_nftPlaceHolder.scss";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./CustomToastStyles.css";
import useWindowSize from "../../functions/useWindowSize";
import closeX from "./closeX.svg";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

const NFT_CONTRACT_ADDRESS = "0xec768a345adeE8f03886E83F89eD2151a4c1Db0D";
const STAKING_CONTRACT_ADDRESS = "0x7ae688A55D8Ea9DC8C2EA5986ef7747EAD51B765";

const nftContractABI = [
  {
    type: "constructor",
    stateMutability: "nonpayable",
    inputs: [
      { type: "string", name: "_initBaseURI", internalType: "string" },
      { type: "string", name: "_initNotRevealedUri", internalType: "string" },
    ],
  },
  {
    type: "event",
    name: "Approval",
    inputs: [
      {
        type: "address",
        name: "owner",
        internalType: "address",
        indexed: true,
      },
      {
        type: "address",
        name: "approved",
        internalType: "address",
        indexed: true,
      },
      {
        type: "uint256",
        name: "tokenId",
        internalType: "uint256",
        indexed: true,
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "ApprovalForAll",
    inputs: [
      {
        type: "address",
        name: "owner",
        internalType: "address",
        indexed: true,
      },
      {
        type: "address",
        name: "operator",
        internalType: "address",
        indexed: true,
      },
      { type: "bool", name: "approved", internalType: "bool", indexed: false },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "OwnershipTransferred",
    inputs: [
      {
        type: "address",
        name: "previousOwner",
        internalType: "address",
        indexed: true,
      },
      {
        type: "address",
        name: "newOwner",
        internalType: "address",
        indexed: true,
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "Transfer",
    inputs: [
      { type: "address", name: "from", internalType: "address", indexed: true },
      { type: "address", name: "to", internalType: "address", indexed: true },
      {
        type: "uint256",
        name: "tokenId",
        internalType: "uint256",
        indexed: true,
      },
    ],
    anonymous: false,
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "approve",
    inputs: [
      { type: "address", name: "to", internalType: "address" },
      { type: "uint256", name: "tokenId", internalType: "uint256" },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
    name: "balanceOf",
    inputs: [{ type: "address", name: "owner", internalType: "address" }],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "string", name: "", internalType: "string" }],
    name: "baseExtension",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
    name: "cost",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "address", name: "", internalType: "address" }],
    name: "getApproved",
    inputs: [{ type: "uint256", name: "tokenId", internalType: "uint256" }],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "bool", name: "", internalType: "bool" }],
    name: "isApprovedForAll",
    inputs: [
      { type: "address", name: "owner", internalType: "address" },
      { type: "address", name: "operator", internalType: "address" },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
    name: "maxSupply",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "payable",
    outputs: [],
    name: "mint",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "string", name: "", internalType: "string" }],
    name: "name",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "string", name: "", internalType: "string" }],
    name: "notRevealedUri",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "address", name: "", internalType: "address" }],
    name: "owner",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "address", name: "", internalType: "address" }],
    name: "ownerOf",
    inputs: [{ type: "uint256", name: "tokenId", internalType: "uint256" }],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "pause",
    inputs: [{ type: "bool", name: "_state", internalType: "bool" }],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "bool", name: "", internalType: "bool" }],
    name: "paused",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
    name: "randomNum",
    inputs: [
      { type: "uint256", name: "_mod", internalType: "uint256" },
      { type: "uint256", name: "_seed", internalType: "uint256" },
      { type: "uint256", name: "_salt", internalType: "uint256" },
    ],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "renounceOwnership",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "reveal",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "bool", name: "", internalType: "bool" }],
    name: "revealed",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "safeTransferFrom",
    inputs: [
      { type: "address", name: "from", internalType: "address" },
      { type: "address", name: "to", internalType: "address" },
      { type: "uint256", name: "tokenId", internalType: "uint256" },
    ],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "safeTransferFrom",
    inputs: [
      { type: "address", name: "from", internalType: "address" },
      { type: "address", name: "to", internalType: "address" },
      { type: "uint256", name: "tokenId", internalType: "uint256" },
      { type: "bytes", name: "_data", internalType: "bytes" },
    ],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "setApprovalForAll",
    inputs: [
      { type: "address", name: "operator", internalType: "address" },
      { type: "bool", name: "approved", internalType: "bool" },
    ],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "setBaseExtension",
    inputs: [
      { type: "string", name: "_newBaseExtension", internalType: "string" },
    ],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "setBaseURI",
    inputs: [{ type: "string", name: "_newBaseURI", internalType: "string" }],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "setCost",
    inputs: [{ type: "uint256", name: "_newCost", internalType: "uint256" }],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "setNotRevealedURI",
    inputs: [
      { type: "string", name: "_notRevealedURI", internalType: "string" },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "bool", name: "", internalType: "bool" }],
    name: "supportsInterface",
    inputs: [{ type: "bytes4", name: "interfaceId", internalType: "bytes4" }],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "string", name: "", internalType: "string" }],
    name: "symbol",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
    name: "tokenByIndex",
    inputs: [{ type: "uint256", name: "index", internalType: "uint256" }],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
    name: "tokenOfOwnerByIndex",
    inputs: [
      { type: "address", name: "owner", internalType: "address" },
      { type: "uint256", name: "index", internalType: "uint256" },
    ],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "string", name: "", internalType: "string" }],
    name: "tokenURI",
    inputs: [{ type: "uint256", name: "tokenId", internalType: "uint256" }],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
    name: "totalSupply",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "transferFrom",
    inputs: [
      { type: "address", name: "from", internalType: "address" },
      { type: "address", name: "to", internalType: "address" },
      { type: "uint256", name: "tokenId", internalType: "uint256" },
    ],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "transferOwnership",
    inputs: [{ type: "address", name: "newOwner", internalType: "address" }],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "uint256[]", name: "", internalType: "uint256[]" }],
    name: "walletOfOwner",
    inputs: [{ type: "address", name: "_owner", internalType: "address" }],
  },
  {
    type: "function",
    stateMutability: "payable",
    outputs: [],
    name: "withdraw",
    inputs: [],
  },
];

const stakingContractABI = [
  {
    inputs: [
      {
        internalType: "uint256[]",
        name: "tokenIds",
        type: "uint256[]",
      },
    ],
    name: "claimRewards",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256[]",
        name: "tokenIds",
        type: "uint256[]",
      },
    ],
    name: "deposit",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256[]",
        name: "tokenIds",
        type: "uint256[]",
      },
    ],
    name: "emergencyWithdraw",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_stakingDestinationAddress",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "_rate",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_expiration",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "_erc20Address",
        type: "address",
      },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "newExpiration",
        type: "uint256",
      },
    ],
    name: "ExpirationChanged",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "newLockTime",
        type: "uint256",
      },
    ],
    name: "LockTimeChanged",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    inputs: [],
    name: "pause",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "Paused",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "newRate",
        type: "uint256",
      },
    ],
    name: "RateChanged",
    type: "event",
  },
  {
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_expiration",
        type: "uint256",
      },
    ],
    name: "setExpiration",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_lockTime",
        type: "uint256",
      },
    ],
    name: "setLockTime",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_rate",
        type: "uint256",
      },
    ],
    name: "setRate",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "unpause",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "Unpaused",
    type: "event",
  },
  {
    inputs: [
      {
        internalType: "uint256[]",
        name: "tokenIds",
        type: "uint256[]",
      },
    ],
    name: "withdraw",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "withdrawTokens",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    name: "_depositBlocks",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "calculateReward",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
      {
        internalType: "uint256[]",
        name: "tokenIds",
        type: "uint256[]",
      },
    ],
    name: "calculateRewards",
    outputs: [
      {
        internalType: "uint256[]",
        name: "rewards",
        type: "uint256[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "depositsOf",
    outputs: [
      {
        internalType: "uint256[]",
        name: "",
        type: "uint256[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "erc20Address",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "expiration",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "LOCKUP_TIME",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
      {
        internalType: "address",
        name: "",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    name: "onERC721Received",
    outputs: [
      {
        internalType: "bytes4",
        name: "",
        type: "bytes4",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "paused",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "rate",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "stakingDestinationAddress",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    name: "stakingTime",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];

const useUserNFTs = () => {
  const { address } = useAccount();
  const provider = useProvider();
  const nftContract = useMemo(
    () => new ethers.Contract(NFT_CONTRACT_ADDRESS, nftContractABI, provider),
    [provider]
  );
  const stakingContract = useMemo(
    () =>
      new ethers.Contract(
        STAKING_CONTRACT_ADDRESS,
        stakingContractABI,
        provider
      ),
    [provider]
  );

  const [userNFTs, setUserNFTs] = useState([]);
  const [stakedNFTs, setStakedNFTs] = useState([]);
  const [totalRewards, setTotalRewards] = useState(0);
  const [loading, setLoading] = useState(false);
  const [refreshTrigger, setRefreshTrigger] = useState(false);
 
 
  const fetchNFTs = useCallback(async () => {
    if (!address) return;

    setLoading(true);
    try {
      const balance = await nftContract.balanceOf(address);
      const nfts = [];
      for (let i = 0; i < balance.toNumber(); i++) {
        const tokenId = await nftContract.tokenOfOwnerByIndex(address, i);
        const tokenURI = await nftContract.tokenURI(tokenId);
        const response = await fetch(tokenURI);
        const metadata = await response.json();
        const isApproved = await nftContract.isApprovedForAll(
          address,
          STAKING_CONTRACT_ADDRESS
        );
        nfts.push({
          tokenId: tokenId.toString(),
          image: metadata.image,
          isApproved,
        });
      }
      setUserNFTs(nfts);

      const stakedNftIds = await stakingContract.depositsOf(address);
      const stakedNfts = await Promise.all(
        stakedNftIds.map(async (tokenId) => {
          let nft = nfts.find((nft) => nft.tokenId === tokenId.toString());
          if (!nft) {
            const tokenURI = await nftContract.tokenURI(tokenId);
            const response = await fetch(tokenURI);
            const metadata = await response.json();
            nft = { tokenId: tokenId.toString(), image: metadata.image };
          }
          return nft;
        })
      );
      setStakedNFTs(stakedNfts);

      const rewards = await stakingContract.calculateRewards(
        address,
        stakedNftIds
      );
      const total = rewards.reduce(
        (acc, reward) => acc + parseFloat(ethers.utils.formatEther(reward)),
        0
      );
      setTotalRewards(total.toFixed(18));
    } catch (error) {
      console.error("Failed to fetch NFTs", error);
      toast.error("Failed to fetch NFTs", {
        className: "custom-toast error-toast",
      });
    } finally {
      setLoading(false);
    }
  }, [address, nftContract, stakingContract]);

  useEffect(() => {
    fetchNFTs();
  }, [fetchNFTs, address, refreshTrigger]);

  return {
    userNFTs,
    stakedNFTs,
    fetchNFTs,
    stakingContract,
    nftContract, // Expose nftContract here
    totalRewards,
    loading,
    triggerRefresh: () => setRefreshTrigger((prev) => !prev),
  };
};

const SelectNFTs = ({ onSelect, showStaked }) => {
  const { userNFTs, stakedNFTs, loading } = useUserNFTs();
  const [selectedNFTs, setSelectedNFTs] = useState([]);
  const width = "100%";

  const toggleNFTSelection = (tokenId) => {
    setSelectedNFTs((prevSelected) =>
      prevSelected.includes(tokenId)
        ? prevSelected.filter((id) => id !== tokenId)
        : [...prevSelected, tokenId]
    );
  };

  const toggleSelectAll = () => {
    if (selectedNFTs.length === nftsToDisplay.length) {
      setSelectedNFTs([]);
    } else {
      setSelectedNFTs(nftsToDisplay.map((nft) => nft.tokenId));
    }
  };

  useEffect(() => {
    onSelect(selectedNFTs);
  }, [selectedNFTs, onSelect]);

  const nftsToDisplay = showStaked ? stakedNFTs : userNFTs;

  const placeholdersNeeded = Math.max(0, 4 - nftsToDisplay.length);
  const placeholders = Array.from({ length: placeholdersNeeded }).map(
    (_, index) => (
      <div key={`placeholder-${index}`} style={{ width }}>
        <NftPlaceHolderVideo width={width} />
      </div>
    )
  );

  if (loading) {
    return <div className="d-flex items-center mx-auto justify-center"><div role="status">
    <svg aria-hidden="true" class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
    </svg>
    <span class="sr-only">Loading...</span>
</div></div>;
  }

  return (
    <div>
      <div className="flex items-center mb-4">
        <label className="inline-flex items-center cursor-pointer">
          <input
            type="checkbox"
            className="sr-only peer"
            onChange={toggleSelectAll}
            checked={selectedNFTs.length === nftsToDisplay.length}
          />
          <div className="relative w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
          <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">
            Select All
          </span>
        </label>
      </div>
      <div className="grid grid-cols-2 md:grid-cols-4 items-center gap-4 overflow-y-scroll overflow-x-hidden md:max-h-80">
        {nftsToDisplay.map(({ tokenId, image }) => (
          <div key={tokenId} style={{ width }}>
            {image ? (
              <label
                className={`placeholder-wrapper nft-caw-card cursor-pointer ${
                  selectedNFTs.includes(tokenId)
                    ? "border-2 border-emerald-400 rounded-md"
                    : ""
                }`}
                style={{ width }}
              >
                <div className="placeholder-button">
                  <div className="placeholder-content">
                  <video
    src={image}
    alt={`NFT ${tokenId}`}
    className="placeholder-content-img"
    autoPlay
    loop
    playsInline
    muted
  >
    Your browser does not support the video tag.
  </video>
                    <p className="placeholder-content-text">
                     Carrot APR Booster #<label>{tokenId}</label>
                    </p>
                    <input
                      type="checkbox"
                      value={tokenId}
                      onChange={() => toggleNFTSelection(tokenId)}
                      checked={selectedNFTs.includes(tokenId)}
                      className="hidden"
                      style={{ display: "none" }}
                    />
                  </div>
                </div>
              </label>
            ) : (
              <NftPlaceHolderVideo width={width} />
            )}
          </div>
        ))}
        {placeholders}
      </div>
    </div>
  );
};

const NftStaking8 = ({ open, onClose }) => {
  const { address, isConnected } = useAccount();
  const provider = useProvider();
  const {
    userNFTs,
    stakedNFTs,
    stakingContract,
    nftContract,
    totalRewards,
    fetchNFTs,
    triggerRefresh,
  } = useUserNFTs();
  const [selectedNftIds, setSelectedNftIds] = useState([]);
  const [status, setStatus] = useState("");
  const [showStaked, setShowStaked] = useState(false);
  const [loadingClaim, setLoadingClaim] = useState(false);
  const [isBlurred, setIsBlurred] = useState(true);
  const [loadingApprove, setLoadingApprove] = useState(false);
  const [loadingDeposit, setLoadingDeposit] = useState(false);
  const [loadingUnstake, setLoadingUnstake] = useState(false);
  const [selectedTab, setselectedTab] = useState("deposit");
  const windowSize = useWindowSize();

  useEffect(() => {
    fetchNFTs();
  }, [address, NFT_CONTRACT_ADDRESS, stakingContract]);

  const { config: approveConfig } = usePrepareContractWrite({
    address: NFT_CONTRACT_ADDRESS,
    abi: nftContractABI,
    functionName: "setApprovalForAll",
    args: [STAKING_CONTRACT_ADDRESS, true],
    onError(error) {
      console.error("Failed to prepare approval transaction:", error);
    },
  });
  const { writeAsync: approveStake } = useContractWrite(approveConfig);

  const { config: depositConfig } = usePrepareContractWrite({
    address: STAKING_CONTRACT_ADDRESS,
    abi: stakingContractABI,
    functionName: "deposit",
    args: [selectedNftIds],
    onError(error) {
      console.error("Failed to prepare deposit transaction:", error);
    },
  });
  const { writeAsync: depositStake } = useContractWrite(depositConfig);

  const { config: claimConfig } = usePrepareContractWrite({
    address: STAKING_CONTRACT_ADDRESS,
    abi: stakingContractABI,
    functionName: "claimRewards",
    args: [selectedNftIds],
  });
  const { writeAsync: claimRewards } = useContractWrite(claimConfig);

  const { config: unstakeConfig, error: unstakeError } =
    usePrepareContractWrite({
      address: STAKING_CONTRACT_ADDRESS,
      abi: stakingContractABI,
      functionName: "withdraw",
      args: [selectedNftIds],
    });

  const { writeAsync: unstake } = useContractWrite(unstakeConfig);

  useEffect(() => {
    if (isConnected) {
      setStatus("Connected");
    } else {
      setStatus("Disconnected");
    }
  }, [isConnected]);

  useEffect(() => {
    if (showStaked && stakedNFTs.length > 0) {
      setIsBlurred(false);
    } else {
      setIsBlurred(true);
    }
  }, [showStaked, stakedNFTs]);

  const handleApprove = async () => {
    setLoadingApprove(true);
    try {
      const approvalTx = await approveStake();
      const approvalReceipt = await approvalTx.wait();
      if (approvalReceipt.status === 1) {
        setStatus("Approved successfully.");
        toast.success("Approved successfully.", {
          className: "custom-toast success-toast",
        });
        triggerRefresh(); // Trigger refresh
      } else {
        throw new Error("Approval failed");
      }
    } catch (error) {
      setStatus(`Approval failed: ${error.message}`);
      toast.error(`Approval failed: ${error.message}`, {
        className: "custom-toast error-toast",
      });
    }
    setLoadingApprove(false);
  };

  const handleDeposit = async () => {
    setLoadingDeposit(true);
    try {
      // Check if all selected NFTs are approved
      const approvalPromises = selectedNftIds.map(async (tokenId) => {
        const isApproved = await nftContract.isApprovedForAll(
          address,
          STAKING_CONTRACT_ADDRESS
        );
        return isApproved;
      });

      const approvalStatuses = await Promise.all(approvalPromises);

      if (!approvalStatuses.every((status) => status)) {
        throw new Error("Not all selected NFTs are approved");
      }

      // Estimate gas limit based on the number of selected NFTs
      const gasLimitPerNFT = 100000; // Adjust this base value as necessary
      const gasLimit = gasLimitPerNFT * selectedNftIds.length;

      const depositTx = await depositStake({
        gasLimit: ethers.utils.hexlify(gasLimit),
      });
      const depositReceipt = await depositTx.wait();
      if (depositReceipt.status === 1) {
        setStatus("Deposited successfully.");
        toast.success("Deposited successfully.", {
          className: "custom-toast success-toast",
        });
        triggerRefresh(); // Trigger refresh
      } else {
        throw new Error("Deposit failed");
      }
    } catch (error) {
      setStatus(`Deposit failed: ${error.message}`);
      toast.error(`Deposit failed: ${error.message}`, {
        className: "custom-toast error-toast",
      });
    }
    setLoadingDeposit(false);
  };

  const handleUnstake = async () => {
    setLoadingUnstake(true);
    try {
      const tx = await unstake();
      const receipt = await tx.wait();
      if (receipt.status === 1) {
        setStatus("Unstaked successfully.");
        toast.success("Unstaked successfully.", {
          className: "custom-toast success-toast",
        });
        triggerRefresh(); // Trigger refresh
      } else {
        throw new Error("Unstake failed");
      }
    } catch (error) {
      if (error.message.includes("You recently staked, please wait before withdrawing")) {
        setStatus("You recently staked. Please wait before withdrawing.");
        toast.error("You recently staked. Please wait before withdrawing.", {
          className: "custom-toast error-toast",
        });
      } else {
        setStatus(`Unstaking failed: ${error.message}`);
        toast.error(`Unstaking failed: ${error.message}`, {
          className: "custom-toast error-toast",
        });
      }
    }
    setLoadingUnstake(false);
  };

  const handleClaim = async () => {
    setLoadingClaim(true);
    try {
      const tx = await claimRewards();
      const receipt = await tx.wait();
      if (receipt.status === 1) {
        setStatus("Rewards claimed successfully.");
        toast.success("Rewards claimed successfully.", {
          className: "custom-toast success-toast",
        });
        triggerRefresh(); // Trigger refresh
      } else {
        throw new Error("Transaction failed");
      }
    } catch (error) {
      setStatus(`Claiming rewards failed: ${error.message}`);
      toast.error(`Claiming rewards failed: ${error.message}`, {
        className: "custom-toast error-toast",
      });
    }
    setLoadingClaim(false);
  };

  const needsApproval = selectedNftIds.some(
    (tokenId) => !userNFTs.find((nft) => nft.tokenId === tokenId)?.isApproved
  );

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width:
      windowSize.width > 1400 ? "auto" : windowSize.width > 786 ? "50%" : "95%",
    boxShadow: 24,
    p: 4,
    overflow: "auto",
    minHeight: 200,
    overflowX: "hidden",
    borderRadius: "10px",
    height: windowSize.width < 500 ? "90%" : "auto",
    background: `#1A1A36`,
  };
  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          onClose();
          setSelectedNftIds([]);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        

          <div className="left-col">
           
            <div className="d-flex align-items-center justify-content-between width-100">
              <div
                className="rarity-rank my-2 text-left"
                style={{
                  position: "relative",
                }}
              >
                <h5 className="mb-2 text-white text-xl">
                  Stakeable NFTs
                </h5>
                <h6
                  className="bal-smallTxt mb-2 text-left"
                  style={{ color: "#C0CBF7" }}
                >
                  A list of your NFT collection that can be added and removed
                  from the staking pools
                </h6>
              </div>
            </div>

            <img
              src={closeX}
              alt=""
              className="close-x position-absolute top-10 right-10  cursor-pointer"
              onClick={() => {
                onClose();
                setselectedTab("deposit");
                setSelectedNftIds([]);
              }}
              style={{
                width: 16,
                height: 16,
              }}
            />










 



            <div className="d-flex flex-column gap-3 mt-2 mb-4">
            
              <div
                className="d-flex justify-content-center align-items-center gap-5 pb-3"
                style={{ borderBottom: "1px solid #565891" }}
              >
                <div
                  className={`optionbtn-passive ${
                    !showStaked ? "optionbtn-active" : ""
                  }`}
                  onClick={() => setShowStaked(false)}
                >
                  <h5 className="optiontext" style={{ fontSize: 14 }}>
                    To Stake
                  </h5>
                </div>
                <div
                  className={`optionbtn-passive ${
                    showStaked ? "optionbtn-active" : ""
                  }`}
                  onClick={() => setShowStaked(true)}
                >
                  <h5 className="optiontext" style={{ fontSize: 14 }}>
                    Staked
                  </h5>
                </div>
              </div>
            </div>





            
            <div className="">
              <div className=" ">
                <SelectNFTs
                  onSelect={setSelectedNftIds}
                  showStaked={showStaked}
                />
              </div>
            </div>
          </div>
          <div style={{ display: "block" }} className="bottom-static-wrapper mt-4">
            <p className="d-flex bal-smallTxt  align-items-start gap-3 radioDesc text-left mt-2 claimAll-wrapper">
              <img src={require("./more-info.svg").default} alt="" />
              {!showStaked
                ? "Please choose the NFTs that you wish to stake. Once you have made your selection, you will be required to approve the process before depositing the NFTs."
                : "Please select your NFTs to Claim or to Unstake"}
            </p>

            <div className="mt-2 claimAll-wrapper bal-smallTxt ">
              <div style={{ display: showStaked === false ? "block" : "none" }}>
                <h5
                  className="select-apr d-flex"
                  style={{ gap: 12, color: "#C0C9FF" }}
                >
                  Select Pool <span className="aprText">50% APR</span>
                </h5>

                <div
                  className="text-left d-flex justify-content-between flex-column flex-xxl-row flex-lg-row flex-md-row flex-sm-row mt-2"
                  style={{ gap: 5, margin: "auto" }}
                >
                  <form className="d-flex flex-column" style={{ gap: 5 }}>
                    <input
                      type="radio"
                      id="50APR"
                      name="locktime"
                      value="50"
                      checked={true}
                      className="d-none"
                    />

                    <span className="radioDesc" style={{ color: "#F7F7FC" }}>
                      Stake your NFT to earn CARROT rewards (no lock time)
                    </span>
                  </form>
                  <div
                    className="d-flex justify-content-xxl-between justify-content-lg-between justify-content-md-between  justify-content-sm-between align-items-center"
                    style={{ gap: 5 }}
                  >
                    <span className="bal-smallTxt text-left">Staking</span>
                   

                    <span
                      id="ethPrice"
                      className="aprText"
                    >
                      {selectedNftIds.length} selected APR booster
                    </span>
                  
                  </div>
                </div>
              </div>
              <div style={{ display: showStaked === true ? "block" : "none" }}>
                <h5
                  className="select-apr d-flex text-white text-"
                  style={{ gap: 12, color: "#C0CFF" }}
                >
                  Select Pool <span className="aprText">50% APR</span>
                </h5>

                <div
                  className="text-left d-flex justify-content-between flex-column flex-xxl-row flex-lg-row flex-md-row flex-sm-row mt-2"
                  style={{ gap: 5, margin: "auto" }}
                >
                  <form className="d-flex flex-column" style={{ gap: 5 }}>
                    <input
                      type="radio"
                      id="50APR"
                      name="locktime"
                      value="50"
                      checked={true}
                      className="d-none"
                    />

                    <span className="radioDesc" style={{ color: "#F7F7FC" }}>
                      Unstake your NFT or claim your rewards to earn CARROT (no
                      lock time)
                    </span>
                  </form>
                  <div
                    className="d-flex justify-content-xxl-between justify-content-lg-between justify-content-md-between  justify-content-sm-between align-items-center"
                    style={{ gap: 5 }}
                  >
                    <span
                      id="ethPrice"
                      className="mb-0"
                      style={{
                        display: "flex",
                        color: "#4CD0CD",
                        fontWeight: 600,
                        alignItems: "center",
                      }}
                    >
                      {selectedNftIds.length} APR booster
                    </span>
                    <span
                      style={{
                        color: "#4CD0CD",
                        fontWeight: 700,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      selected
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-2">
              <div>
                <div className="mt-4 d-flex flex-column flex-xxl-row flex-lg-row flex-md-row align-items-center justify-content-between" style={{ gap: 20 }}>
                  <div className={`claimAll-wrapper col-12 col-md-6 mb-3 ${isBlurred ? "blurrypool" : ""}`}>
                 
                      <div
                        className="d-flex align-items-start justify-content-between mb-3 w-100"
                        style={{ gap: 10 }}
                      >
                        <p
                          id="earnedText"
                          className="mb-0"
                          style={{
                            display: "flex",
                            gap: 5,
                            alignItems: "baseline",
                          }}
                        >
                          Total earned
                        </p>
                        <div className="d-flex justify-content-between">
                          <h6 className="rewardstxtCaws d-flex align-items-center gap-2">
                            {totalRewards} CARROT
                          </h6>
                        </div>
                      </div>
                    <button
                      className="btn claim-reward-button"
                      onClick={handleClaim}
                      style={{
                        background:
                          selectedNftIds.length > 0
                            ? "linear-gradient(90.74deg, #7770E0 0%, #554FD8 100%)"
                            : "#14142A",
                        pointerEvents:
                          selectedNftIds.length > 0 ? "auto" : "none",
                        width: "100%",
                        borderRadius: "8px",
                        color: selectedNftIds.length > 0 ? "#FFFFFF" : "#C0C9FF",
                        margin: "auto",
                      }}
                      disabled={selectedNftIds.length === 0}
                    >
                      {loadingClaim ? (
                        <>
                          <div className="spinner-border " role="status"></div>
                        </>
                      ) : (
                        "Claim All Rewards"
                      )}
                    </button>
                  </div>

                  <div className="claimAll-wrapper col-12 col-md-6 mb-3">
                  <div
                        className="d-flex align-items-start justify-content-between mb-3 w-100"
                        style={{ gap: 10 }}
                      >
                        <p
                          id="earnedText"
                          className="mb-0"
                          style={{
                            display: "flex",
                            gap: 5,
                            alignItems: "baseline",
                          }}
                        >
                          Unstaking
                        </p>
                        <div
                    className="d-flex justify-content-xxl-between justify-content-lg-between justify-content-md-between  justify-content-sm-between align-items-center"
                    style={{ gap: 5 }}
                  >
                    <span
                      id="ethPrice"
                      className="mb-0"
                      style={{
                        display: "flex",
                        color: "#4CD0CD",
                        fontWeight: 600,
                        alignItems: "center",
                      }}
                    >
                      {selectedNftIds.length} selected APR booster
                    </span>
                   
                  </div>
                      </div>
                    {!showStaked ? (
                      <>
                        {selectedNftIds.some(
                          (tokenId) =>
                            !userNFTs.find((nft) => nft.tokenId === tokenId)
                              ?.isApproved &&
                            !stakedNFTs.find((nft) => nft.tokenId === tokenId)
                        ) && (
                          <button
                            className="btn activebtn w-100 max-w-full min-w-full mb-2"
                            onClick={handleApprove}
                            style={{
                              background:
                                selectedNftIds.length > 0
                                  ? "linear-gradient(90.74deg, #7770E0 0%, #554FD8 100%)"
                                  : "#14142A",
                              pointerEvents:
                                selectedNftIds.length > 0 ? "auto" : "none",
                            }}
                            disabled={selectedNftIds.length === 0}
                          >
                            {loadingApprove ? (
                              <div className="spinner-border" role="status"></div>
                            ) : (
                              "Approve"
                            )}
                          </button>
                        )}
                        <button
                          className="btn activebtn w-full min-w-full max-w-full"
                          onClick={handleDeposit}
                          style={{
                            background:
                              selectedNftIds.length > 0
                                ? "linear-gradient(90.74deg, #7770E0 0%, #554FD8 100%)"
                                : "#14142A",
                            pointerEvents:
                              selectedNftIds.length > 0 ? "auto" : "none",
                          }}
                          disabled={selectedNftIds.length === 0}
                        >
                          {loadingDeposit ? (
                            <div className="spinner-border" role="status"></div>
                          ) : (
                            "Deposit"
                          )}
                        </button>
                      </>
                    ) : (
                      <button
                        className="btn activebtn w-100 max-w-full min-w-full"
                        onClick={handleUnstake}
                        style={{
                          background:
                            selectedNftIds.length > 0
                              ? "linear-gradient(90.74deg, #7770E0 0%, #554FD8 100%)"
                              : "#14142A",
                          pointerEvents:
                            selectedNftIds.length > 0 ? "auto" : "none",
                          color: selectedNftIds.length > 0 ? "#FFFFFF" : "#C0C9FF",
                        }}
                        disabled={selectedNftIds.length === 0}
                      >
                        {loadingUnstake ? (
                          <div className="spinner-border" role="status"></div>
                        ) : (
                          "Unstake"
                        )}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
 
        </Box>
      </Modal>
      <ToastContainer />

    </>
  );
};

export default NftStaking8;

