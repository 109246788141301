import React, { useEffect, useState } from "react";
import earnHeroStats from "../../../assets/earnAssets/earnHeroStats.webp";
import coin from "../../../assets/earnAssets/coin.webp";
import coinBackground from "../../../assets/earnAssets/coinBackground.webp";
import increase from "../../../assets/earnAssets/increase.png";
import decrease from "../../../assets/earnAssets/decrease.png";
import bptoken from "../../top-pools-card/assets/bpicon.webp";
import getFormattedNumber from "../../../functions/get-formatted-number";
import CountUp from "react-countup";
import axios from "axios";
import totalTvlIcon from "../../../assets/earnAssets/totalTvlIcon.svg";

const EarnHero = () => {
  const [dexPairData, setDexPairData] = useState(null);
  const [cryptoPrices, setCryptoPrices] = useState({});
  const [TotalValueData, setTotalValueData] = useState(0);

  const fetchDexscreenerData = async () => {
    try {
      const response = await axios.get('https://api.dexscreener.com/latest/dex/pairs/elastos/0x2f45C6Df50E7F8517dAc64943a2161bBCFBa1182');
      if (response.status === 200) {
        setDexPairData(response.data.pairs[0]);
      }
    } catch (error) {
      console.error('Failed to fetch from Dexscreener:', error);
    }
  };

  const fetchCryptoPrices = async () => {
    try {
      const response = await axios.get('https://api.dexscreener.com/latest/dex/pairs/elastos/0x2f45C6Df50E7F8517dAc64943a2161bBCFBa1182');
      if (response.status === 200) {
        setCryptoPrices(response.data);
      }
    } catch (error) {
      console.error('Failed to fetch from CoinGecko:', error);
    }
  };

 
  const fetchTotalValue = async () => {
    try {
      const response = await axios.get('https://api.dexscreener.com/latest/dex/pairs/elastos/0xa7f81cea2c5faff9d6c523a412ad3ae379fb1c19');
      if (response.status === 200) {
        setTotalValueData(response.data.pairs[0]);
      }
    } catch (error) {
      console.error('Failed to fetch from Dexscreener:', error);
    }
  };


  useEffect(() => {
    fetchDexscreenerData();
    fetchCryptoPrices();
    fetchTotalValue();
  }, []);
 

  return (
    <div className="row w-100 flex-column flex-lg-row earn-hero gap-4 gap-lg-0 p-3 p-lg-4 justify-content-between mx-auto">
      <div className="col-12 col-lg-5 px-0 px-lg-2 d-flex flex-column justify-content-center gap-3">
        <h3 className="text-white" style={{ whiteSpace: "pre" }}>
          Bunny Earn <span className="new-beta-sidebar new-beta-text text-xs">soon</span>
                      
        </h3>
        <p className="text-white ">
          Make the most of your assets with Bunny Earn products. Bunny offers
          three ways to productively use your assets. Participate in Staking,
          Farming and Vault. Start earning today!
        </p>
        
      </div>
      <div className="col-12 col-lg-7 px-0 px-lg-2 d-flex gap-3 gap-lg-4 flex-column flex-lg-row">
        <div className="d-flex align-items-start gap-2 p-3 total-tvl-wrapper position-relative">
          <div className="purplediv" style={{ left: "1px", top: "10px" }}></div>
          <img src={totalTvlIcon} alt="total-tvl" />
          <div
            className="d-flex flex-column gap-1 position-relative"
            style={{ top: "5px" }}
          >
            <span className="total-tvl-title">Total value locked</span>
 
            <h6 className="total-tvl-content">
          $ {dexPairData && TotalValueData
        ? getFormattedNumber(
            dexPairData.liquidity.usd + TotalValueData.liquidity.usd,
            2
          )
        : 'Loading...'}
             
               </h6>

          </div>
        </div>
        <div className="d-flex gap-0 gap-lg-4">
          <div className="d-flex flex-column align-items-start">
            <div className="d-flex flex-column paid-rewards">
              <p
                style={{ fontSize: "9px", color: "#f7f7fc", fontWeight: "300" }}
              >
                Volume 24H
              </p>
              {/* <CountUp
                className="count-up"
                style={{
                  fontSize: "19px",
                  color: "#f7f7fc",
                  fontWeight: "600",
                  textAlign: "start",
                }}
                start={totalpaid?.totalPaidInUsd - 400.0}
                end={totalpaid?.totalPaidInUsd}
                duration={120}
                separator=","
                decimals={2}
                prefix="$"
              /> */}
              <h6
                className="count-up"
                style={{
                  fontSize: "19px",
                  color: "#f7f7fc",
                  fontWeight: "600",
                  textAlign: "start",
                }}
              >
            ${dexPairData ? getFormattedNumber(dexPairData.volume.h24, 2) : 'Loading...'}
              </h6>
            </div>
            <img
              src={earnHeroStats}
              style={{ width: "230px", height: "80px" }}
              alt=""
            />
          </div>
          <div className="d-flex flex-column justify-content-between">
            <div className="d-flex justify-content-start align-items-center gap-2">
              <img width="24" src={increase} alt="" />
              <h4 style={{ color: "#f7f7fc", fontWeight: "500" }}>
              {dexPairData ? getFormattedNumber(dexPairData.txns.h24.buys, 0) : 'Loading...'}
 
              </h4>
            </div>
            <div className="d-flex justify-content-start align-items-center gap-2">
              <img width="24" src={decrease} alt="" />
              <h4 style={{ color: "#f7f7fc", fontWeight: "500" }}>
              {dexPairData ? getFormattedNumber(dexPairData.txns.h24.sells, 0) : 'Loading...'}
               </h4>
               
            </div>
            <div className="d-flex justify-content-start align-items-center gap-2">
              <img width="24" src={bptoken} alt="" />
              <h4 style={{ color: "#f7f7fc", fontWeight: "500" }}>
              ${dexPairData ? getFormattedNumber(dexPairData.priceUsd, 2) : 'Loading...'}
               </h4>
            </div>
        
          </div>
        </div>
        
        <div className="position-relative d-none d-xxl-block">
          
          <img src={coin} alt="" className="coin" />
          <img src={coinBackground} alt="" className="coin" />
        </div>
      </div>
    </div>
  );
};

export default EarnHero;
