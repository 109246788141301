// MODULES
import React from 'react';
import cn from 'classnames';

// COMPONENTS
import Icon_check from '../icons/check';

// CONTEXT
import { Context } from '../context';

// STYLES
import style from './style.module.css';

class Toaster extends React.Component {
 

  componentDidMount() {}

  componentDidUpdate() {
  }

  componentWillUnmount() {}

  render() {
    return <div ref={this.ref_toaster} className={cn(style['toaster'])}></div>;
  }
}

export default Toaster;
