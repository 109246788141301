import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import Web3 from "web3";
import useWindowSize from "../../functions/useWindowSize";
import BackVideo from "./assets/FsnBunny.gif";
import "../genesisStaking/genesisStaking.scss";
import "./../dashboard/main.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./CustomToastStyles.css";
import "./assets/_timepiecebenefits.scss";
import addActive from "./assets/addActive.svg";
import addInactive from "./assets/addInactive.svg";
import subtractActive from "./assets/subtractActive.svg";
import subtractInactive from "./assets/subtractInactive.svg";
import "./assets/_land.scss";
import "./assets/_utilities.scss";
import gifImage from "./fsnbunny.gif";
import MintingTimeline from "./steps";
import "../bridge/bridge.css";
import { useNetwork } from "wagmi";
import { switchNetwork } from '@wagmi/core';

const config = {
  CONTRACT_ADDRESS: "0x2461Bf38AB64C161eC9eFC7468a2A96d845C296c",
  SCAN_LINK:
    "https://fsnscan.com/address/0x2461Bf38AB64C161eC9eFC7468a2A96d845C296c",
  NETWORK: {
    NAME: "Fusion Mainnet",
    SYMBOL: "FSN",
    ID: 32659,
  },
  NFT_NAME: "Bunny Punk X Fusion",
  SYMBOL: "Bunny Punk",
  WEI_COST_WL: "650000000000000000000",
  MAX_SUPPLY: 2000,
  WEI_COST: "650000000000000000000",
  DISPLAY_COST: 650,
  GAS_LIMIT: 285000,
  MARKETPLACE: "Pasarprotocol",
  MARKETPLACE_LINK:
    "https://pasarprotocol.io/collections/detail/fsn&0x2461Bf38AB64C161eC9eFC7468a2A96d845C296c",
  SHOW_BACKGROUND: true,
};

const ABI = [
  {
    inputs: [],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    inputs: [],
    name: "ApprovalCallerNotOwnerNorApproved",
    type: "error",
  },
  {
    inputs: [],
    name: "ApprovalQueryForNonexistentToken",
    type: "error",
  },
  {
    inputs: [],
    name: "ApprovalToCurrentOwner",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "approve",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "ApproveToCaller",
    type: "error",
  },
  {
    inputs: [],
    name: "BalanceQueryForZeroAddress",
    type: "error",
  },
  {
    inputs: [],
    name: "MintToZeroAddress",
    type: "error",
  },
  {
    inputs: [],
    name: "MintZeroQuantity",
    type: "error",
  },
  {
    inputs: [],
    name: "OwnerQueryForNonexistentToken",
    type: "error",
  },
  {
    inputs: [],
    name: "TransferCallerNotOwnerNorApproved",
    type: "error",
  },
  {
    inputs: [],
    name: "TransferFromIncorrectOwner",
    type: "error",
  },
  {
    inputs: [],
    name: "TransferToNonERC721ReceiverImplementer",
    type: "error",
  },
  {
    inputs: [],
    name: "TransferToZeroAddress",
    type: "error",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "approved",
        type: "address",
      },
      {
        indexed: true,
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "Approval",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "operator",
        type: "address",
      },
      {
        indexed: false,
        internalType: "bool",
        name: "approved",
        type: "bool",
      },
    ],
    name: "ApprovalForAll",
    type: "event",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_mintAmount",
        type: "uint256",
      },
    ],
    name: "mint",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [],
    name: "pause",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_user",
        type: "address",
      },
    ],
    name: "removeUserFromWhitelist",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "reveal",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "safeTransferFrom",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
      {
        internalType: "bytes",
        name: "_data",
        type: "bytes",
      },
    ],
    name: "safeTransferFrom",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "operator",
        type: "address",
      },
      {
        internalType: "bool",
        name: "approved",
        type: "bool",
      },
    ],
    name: "setApprovalForAll",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "_newBaseExtension",
        type: "string",
      },
    ],
    name: "setBaseExtension",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "_newBaseURI",
        type: "string",
      },
    ],
    name: "setBaseURI",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_newCost",
        type: "uint256",
      },
    ],
    name: "setCost",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_newmaxMintAmount",
        type: "uint256",
      },
    ],
    name: "setmaxMintAmount",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_user",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "_allowance",
        type: "uint256",
      },
    ],
    name: "setMintAllowance",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_newOwner",
        type: "address",
      },
    ],
    name: "setNewOwner",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_limit",
        type: "uint256",
      },
    ],
    name: "setNftPerAddressLimit",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "_notRevealedURI",
        type: "string",
      },
    ],
    name: "setNotRevealedURI",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "setOnlyWhitelisted",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "setPublic",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_price",
        type: "uint256",
      },
    ],
    name: "setPublicCost",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_price",
        type: "uint256",
      },
    ],
    name: "setWLCost",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        indexed: true,
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "Transfer",
    type: "event",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "transferFrom",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address[]",
        name: "_users",
        type: "address[]",
      },
      {
        internalType: "uint256[]",
        name: "_allowances",
        type: "uint256[]",
      },
    ],
    name: "whitelistUsers",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address[]",
        name: "_users",
        type: "address[]",
      },
    ],
    name: "whitelistUsers",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "withdraw",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    name: "addressMintedBalance",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    name: "addressWhitelisted",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "owner",
        type: "address",
      },
    ],
    name: "balanceOf",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "baseExtension",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "baseURI",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "cost",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "costWL",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "currentState",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "getApproved",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        internalType: "address",
        name: "operator",
        type: "address",
      },
    ],
    name: "isApprovedForAll",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_user",
        type: "address",
      },
    ],
    name: "isWhitelisted",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "maxMintAmount",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "maxSupply",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    name: "mintAllowance",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "name",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "nftPerAddressLimit",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "notRevealedUri",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "ownerOf",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "revealed",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes4",
        name: "interfaceId",
        type: "bytes4",
      },
    ],
    name: "supportsInterface",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "symbol",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "tokenURI",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "totalSupply",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    name: "whitelistedAddresses",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];

function Mint() {
  const [contract, setContract] = useState(null);
  const [totalSupply, setTotalSupply] = useState(0);
  const [account, setAccount] = useState(null);
  const [web3, setWeb3] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const windowSize = useWindowSize();
  const [quantity, setQuantity] = useState(1);
  const [walletDetected, setWalletDetected] = useState(true);
  const [isConnected, setIsConnected] = useState(false);
  const [selectedQuantity, setSelectedQuantity] = useState(1);
  const [isApproved, setIsApproved] = useState(false);
  const [isMinted, setIsMinted] = useState(false);
  const [networkId, setNetworkId] = useState(null);
  const [calculatedPrice, setCalculatedPrice] = useState(config.DISPLAY_COST);

  const props = {
    isConnected,
    networkId,
    selectedQuantity,
    isMinted,
  };

  useEffect(() => {
    const updatePrice = () => {
      setCalculatedPrice(config.DISPLAY_COST * quantity);
    };
    updatePrice();
  }, [quantity]);

  useEffect(() => {
    if (window.ethereum) {
      const web3Instance = new Web3(window.ethereum);
      setWeb3(web3Instance);
      window.ethereum.request({ method: "eth_accounts" }).then((accounts) => {
        if (accounts.length > 0) {
          setAccount(accounts[0]);
          setIsConnected(true);
          web3Instance.eth.net.getId().then(setNetworkId);
        }
      });
    }
  }, []);

  const handleConnectWallet = async () => {
    if (window.ethereum) {
      try {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        setAccount(accounts[0]);
        setIsConnected(true);
        const netId = await web3.eth.net.getId();
        setNetworkId(netId);
      } catch (error) {
        console.error("User rejected the connection request.");
      }
    } else {
      alert("Please install MetaMask!");
    }
  };

  const handleIncrement = () => {
    if (quantity < config.MAX_SUPPLY - totalSupply) {
      setQuantity((prevQuantity) => prevQuantity + 1);
    }
  };

  const handleDecrement = () => {
    if (quantity > 1) {
      setQuantity((prevQuantity) => prevQuantity - 1);
    }
  };

  useEffect(() => {
    const web3Instance = new Web3("https://mainnet.fusionnetwork.io");
    setWeb3(web3Instance);
    const contractInstance = new web3Instance.eth.Contract(
      ABI,
      config.CONTRACT_ADDRESS
    );
    setContract(contractInstance);
  }, []);

  useEffect(() => {
    if (contract) {
      contract.methods
        .totalSupply()
        .call()
        .then((supply) => {
          setTotalSupply(parseInt(supply, 10));
        })
        .catch(console.error);
    }
  }, [contract]);

  useEffect(() => {
    if (
      typeof window.ethereum !== "undefined" &&
      window.ethereum !== null &&
      typeof window.ethereum.on === "function"
    ) {
      const web3Instance = new Web3(window.ethereum);
      setWeb3(web3Instance);
      setWalletDetected(true);

      const handleAccountsChanged = (accounts) => {
        if (accounts.length === 0) {
          setAccount("");
        } else if (accounts[0] !== account) {
          setAccount(accounts[0]);
        }
      };

      window.ethereum.on("accountsChanged", handleAccountsChanged);

      return () => {
        window.ethereum.off("accountsChanged", handleAccountsChanged);
      };
    } else {
      setWalletDetected(false);
    }
  }, [account]);

  useEffect(() => {
    const loadBlockchainData = async () => {
      try {
        const ethereum = window.ethereum;
        if (!ethereum) {
          console.error("No Ethereum wallet found.");
          return;
        }

        const web3Instance = new Web3(
          ethereum || "https://mainnet.fusionnetwork.io"
        );
        setWeb3(web3Instance);
        const accounts = await ethereum.request({
          method: "eth_requestAccounts",
        });

        if (accounts.length > 0) {
          setAccount(accounts[0]);
        } else {
          console.error(
            "No Ethereum account found. Please ensure MetaMask is connected."
          );
        }

        const contractInstance = new web3Instance.eth.Contract(
          ABI,
          config.CONTRACT_ADDRESS
        );
        setContract(contractInstance);
        const supply = await contractInstance.methods.totalSupply().call();
        setTotalSupply(parseInt(supply, 10));
      } catch (error) {
        console.error("Failed to load web3, accounts, or contract.", error);
      }
    };

    loadBlockchainData();
  }, []);

  useEffect(() => {
    async function loadWeb3() {
      if (window.ethereum) {
        try {
          const web3Instance = new Web3(window.ethereum);
          setWeb3(web3Instance);
          const accounts = await web3Instance.eth.getAccounts();
          if (accounts.length > 0) {
            setAccount(accounts[0]);
          } else {
            console.error(
              "No Ethereum account found. Please ensure MetaMask is connected."
            );
          }
        } catch (error) {
          console.error(
            "Failed to load web3, accounts, or Ethereum provider.",
            error
          );
        }
      } else {
        console.log("Elastos wallet is not connected");
      }
    }

    loadWeb3();
  }, []);

  useEffect(() => {
    function handleAccountsChanged(accounts) {
      if (accounts.length === 0) {
        console.log("Please connect to MetaMask.");
        setAccount(null);
      } else {
        setAccount(accounts[0]);
      }
    }

    if (window.ethereum) {
      const web3Instance = new Web3(window.ethereum);
      setWeb3(web3Instance);

      window.ethereum.on("accountsChanged", handleAccountsChanged);

      return () => {
        window.ethereum.removeListener(
          "accountsChanged",
          handleAccountsChanged
        );
      };
    } else {
      console.log("No Ethereum wallet detected.");
    }
  }, []);

  const handleConnection = (walletType) => {
    setShowModal(false);
  };

  useEffect(() => {
    if (
      typeof window.ethereum !== "undefined" &&
      window.ethereum !== null &&
      typeof window.ethereum.on === "function"
    ) {
      window.ethereum.on("accountsChanged", function (accounts) {
        const web3Instance = new Web3(window.ethereum);
        setWeb3(web3Instance);
        setAccount(accounts[0]);
      });
    }
  }, []);

  const claimNFTs = async () => {
    if (!account) {
      setShowModal(true);
      return;
    }

    try {
      const networkId = await web3.eth.net.getId();
      if (networkId !== config.NETWORK.ID) {
        toast.error(
          `Please switch to the ${config.NETWORK.NAME} network in your wallet.`,
          {
            className: "custom-toast error-toast",
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );

        return;
      }

      const mintAmount = quantity;
      const value = web3.utils.toWei(
        (config.DISPLAY_COST * quantity).toString(),
        "ether"
      );

      const receipt = await contract.methods
        .mint(mintAmount)
        .send({ from: account, value });
      console.log(receipt);

      const newTotalSupply = await contract.methods.totalSupply().call();
      setTotalSupply(parseInt(newTotalSupply, 10));
      toast.success("Minting successful!");
    } catch (error) {
      console.error("Minting failed", error);
      toast.error("Minting failed. Please try again.");
    }
  };

  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      const countDate = new Date("May 3, 2024 23:59:59").getTime();
      const now = new Date().getTime();
      const gap = countDate - now;

      if (gap < 0) {
        clearInterval(countdownInterval);
        return;
      }

      const day = 1000 * 60 * 60 * 24;
      const hour = 1000 * 60 * 60;
      const minute = 1000 * 60;
      const second = 1000;

      setTimeLeft({
        days: Math.floor(gap / day),
        hours: Math.floor((gap % day) / hour),
        minutes: Math.floor((gap % hour) / minute),
        seconds: Math.floor((gap % minute) / second),
      });
    }, 1000);

    return () => clearInterval(countdownInterval);
  }, []);


  const { chain } = useNetwork();
const fusionMainnetChainId = 32659;

const handleSwitchNetwork = async () => {
  try {
    await switchNetwork({ chainId: fusionMainnetChainId });
  } catch (error) {
    console.error("Failed to switch network", error);
  }
};

  return (
    <>
      <div className="container-lg p-0">
        <div className="row justify-content-center align-items-center w-100 mx-0 px-3 py-3 p-lg-0 gap-5 gap-lg-0 flex flex-col-reverse md:flex-row">
          <div className="col-12 col-md-12 col-xxl-3 ps-2 ps-lg-0 staking-height-2">
            <div className="d-flex flex-column gap-3 justify-content-between staking-height-2">
              <div className="d-flex flex-column position-relative">
                <MintingTimeline {...props} />
                <div
                  className="genesis-desc position-relative"
                  style={{ bottom: "5px" }}
                >
                  <h6 className="font-organetto land-desc w-75">
                    How to mint {config.NFT_NAME} NFTs
                  </h6>
                </div>
              </div>

              <div className="w-100 flex-lg-row gap-3 align-items-center justify-content-center">
                <div className="linear-border">
                  <a
                    className="btn filled-btn2 px-4 w-100 font-semibold"
                    href="https://pasarprotocol.io/collections/detail/fsn&0x2461Bf38AB64C161eC9eFC7468a2A96d845C296c"
                    target="_blank"
                    rel="noreferrer"
                  >
                    View collection
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-12 col-md-12 col-xxl-5 mt-0 px-0"
            style={{ overflowX: "hidden" }}
          >
            <div className="p-4 mint-wrappernew market-mint-bg w-100 m-0 d-flex flex-column gap-5 justify-content-start staking-height">
              <h6 className="marketmintnewtitle position-relative">
                Mint your {config.NFT_NAME} NFT
                <span className="marketmintnewtitle-marked mx-2">now!</span>
              </h6>
              <div className="d-flex flex-column gap-2 pt-xxl-0 pt-lg-0 col-12 col-md-9 col-lg-7 justify-content-between align-items-start position-relative">
                <div className="mint-benefits-grid">
                  <div className="d-flex align-items-center gap-2">
                    <img src={require(`./check.svg`).default} alt="" />
                    <span className="status-desc">Exclusive Access</span>
                  </div>
                  <div className="d-flex align-items-center gap-2">
                    <img src={require(`./check.svg`).default} alt="" />
                    <span className="status-desc">Enhanced Interactions</span>
                  </div>
                  <div className="d-flex align-items-center gap-2">
                    <img src={require(`./check.svg`).default} alt="" />
                    <span className="status-desc">Special Rewards</span>
                  </div>
                  <div className="d-flex align-items-center gap-2">
                    <img src={require(`./check.svg`).default} alt="" />
                    <span className="status-desc">Expanded Functionality</span>
                  </div>{" "}
                  <div className="d-flex align-items-center gap-2">
                    <img src={require(`./check.svg`).default} alt="" />
                    <span className="status-desc">NFT staking</span>
                  </div>
                  <div className="d-flex align-items-center gap-2">
                    <img src={require(`./check.svg`).default} alt="" />
                    <span className="status-desc">Airdrops</span>
                  </div>
                </div>
              </div>
              <img
                className="md:right-20 absolute bottom-10 right-5 md:bottom-20"
                src={require(`./assets/25.svg`).default}
                alt=""
              />
            </div>
          </div>
          <div className="col-12 col-md-12 col-xxl-4 mt-0 px-0 px-lg-2">
            <div className="p-3 mint-wrappernew d-flex flex-column justify-content-between staking-height gap-2">
              <div className="row flex-column flex-xxl-row flex-xl-row flex-lg-row flex-md-row flex-sm-row gap-1 align-items-center justify-content-between">
                <div className="d-flex justify-content-between gap-2 position-relative flex-column flex-xxl-row flex-lg-row flex-md-row">
                  <span className="land-name">
                    Available NFTs to mint:{" "}
                    <span
                      className="addr-text"
                      style={{ color: "rgb(123, 216, 176)" }}
                    >
                      {config.MAX_SUPPLY - totalSupply}
                    </span>
                  </span>
                </div>
              </div>
              <div className="d-flex mt-0 flex-column flex-lg-row align-items-start gap-2 justify-content-center justify-content-xxl-between justify-content-lg-between justify-content-md-between">
                <div className="d-flex flex-column gap-2 col-12 col-lg-6">
                  <span className="land-name">Name</span>
                  <div
                    className="borderText borderText2"
                    style={{ width: "100%" }}
                  >
                    <h6
                      className="land-placeholder mb-0"
                      style={{ marginLeft: "11px" }}
                    >
                      {config.NFT_NAME}
                    </h6>
                  </div>
                </div>
                <div className="d-flex flex-column gap-2 col-12 col-lg-6">
                  <span className="land-name">Latest Mint</span>
                  <h6
                    className="land-placeholder borderText"
                    style={{
                      fontSize: "12px",
                      paddingLeft: "14px",
                      lineHeight: "40px",
                    }}
                  >
                    # {totalSupply}
                  </h6>
                </div>
              </div>
              <hr className="mint-divider m-0" />
              <div className="d-flex align-items-center justify-content-between position-relative gap-3">
                <div className="input-container position-relative col-8 col-lg-6">
                  <input
                    type="number"
                    placeholder="Nr. of NFTs to create"
                    max={config.MAX_SUPPLY - totalSupply}
                    min={1}
                    className="land-input w-100"
                    value={quantity}
                    onChange={(e) => setQuantity(parseInt(e.target.value))}
                  />
                </div>
                <div className="d-flex align-items-center gap-3">
                  <img
                    src={quantity > 1 ? subtractActive : subtractInactive}
                    alt="subtract"
                    onClick={handleDecrement}
                    style={{
                      cursor: quantity > 1 ? "pointer" : "default",
                      pointerEvents: quantity > 1 ? "auto" : "none",
                    }}
                  />
                  <img
                    src={
                      quantity < config.MAX_SUPPLY - totalSupply
                        ? addActive
                        : addInactive
                    }
                    alt="add"
                    onClick={handleIncrement}
                    style={{
                      cursor:
                        quantity < config.MAX_SUPPLY - totalSupply
                          ? "pointer"
                          : "default",
                      pointerEvents:
                        quantity < config.MAX_SUPPLY - totalSupply
                          ? "auto"
                          : "none",
                    }}
                  />
                </div>
              </div>

              <hr className="mint-divider m-0" />

              <div className="row flex-column flex-xxl-row flex-xl-row flex-lg-row flex-md-row flex-sm-row gap-1 align-items-center justify-content-between">
  <div className="d-flex justify-content-between gap-2 position-relative flex-column flex-xxl-row flex-lg-row flex-md-row">
    <span className="land-name">
      You are about to purchase 
      <span className="addr-text font-bold mr-1" style={{ color: "rgb(123, 216, 176)" }}> {quantity}</span> 
      BP X FUSION NFTs for 
      <span className="addr-text ml-1 font-bold" style={{ color: "rgb(123, 216, 176)" }}>
        {calculatedPrice} FSN
      </span>
    </span>
  </div>
</div>



              <hr className="mint-divider m-0" />

              <div className="d-flex flex-column flex-lg-row gap-3 align-items-center justify-content-between">
                <div className="d-flex flex-column flex-lg-row align-items-center align-items-lg-center justify-content-xxl-end justify-content-lg-end justify-content-center w-100">
                  <div className="w-100 flex-lg-row gap-3 align-items-center justify-content-center">
                  {chain?.id !== fusionMainnetChainId ? (
  <div className="linear-border">
    <button
      className="btn filled-btn2 px-4 w-100 font-semibold"
      onClick={handleSwitchNetwork}
    >
      Switch to Fusion Mainnet
    </button>
  </div>
) : (
  <div className="linear-border">
    <button
      className="btn filled-btn2 px-4 w-100 font-semibold"
      onClick={claimNFTs}
    >
      Mint NFT
    </button>
  </div>
)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Mint;
