import "./launchpaddetails.css";
import launchpadDetailsIcon from "../assets/launchpadDetailsIcon.svg";
import timerIcon from "../assets/timerIcon.svg";
import coinStack from "../assets/coinStackIcon.svg";
import messageWarningIcon from "../assets/messageWarningIcon.svg";
import { DropdownButton } from "react-bootstrap";
import walletIcon from "../../header/assets/walletIcon.svg";

import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import Web3 from "web3";
import useWindowSize from "../../../functions/useWindowSize";
import "./main.css";
const config = {
  CONTRACT_ADDRESS: "0xdF90dE01099A6575676651b52CC9f333D31cFB40",
  SCAN_LINK:
    "https://esc.elastos.io/address/0xdF90dE01099A6575676651b52CC9f333D31cFB40/",
  NETWORK: {
    NAME: "Elastos Smart Chain",
    SYMBOL: "ELA",
    ID: 20,
  },
  NFT_NAME: "Mrs Bunny",
  SYMBOL: "Mrs Bunny",
  WEI_COST_WL: "50000000000000000000",
  MAX_SUPPLY: 500,
  WEI_COST: "50000000000000000000",
  DISPLAY_COST: 50,
  GAS_LIMIT: 285000,
  MARKETPLACE: "ElaCity",
  MARKETPLACE_LINK:
    "https://pasarprotocol.io/collections/detail/30x2461Bf38AB64C161eC9eFC7468a2A96d845C296c",
  SHOW_BACKGROUND: true,
};

const ABI = [
  {
    inputs: [],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    inputs: [],
    name: "ApprovalCallerNotOwnerNorApproved",
    type: "error",
  },
  {
    inputs: [],
    name: "ApprovalQueryForNonexistentToken",
    type: "error",
  },
  {
    inputs: [],
    name: "ApprovalToCurrentOwner",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "approve",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "ApproveToCaller",
    type: "error",
  },
  {
    inputs: [],
    name: "BalanceQueryForZeroAddress",
    type: "error",
  },
  {
    inputs: [],
    name: "MintToZeroAddress",
    type: "error",
  },
  {
    inputs: [],
    name: "MintZeroQuantity",
    type: "error",
  },
  {
    inputs: [],
    name: "OwnerQueryForNonexistentToken",
    type: "error",
  },
  {
    inputs: [],
    name: "TransferCallerNotOwnerNorApproved",
    type: "error",
  },
  {
    inputs: [],
    name: "TransferFromIncorrectOwner",
    type: "error",
  },
  {
    inputs: [],
    name: "TransferToNonERC721ReceiverImplementer",
    type: "error",
  },
  {
    inputs: [],
    name: "TransferToZeroAddress",
    type: "error",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "approved",
        type: "address",
      },
      {
        indexed: true,
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "Approval",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "operator",
        type: "address",
      },
      {
        indexed: false,
        internalType: "bool",
        name: "approved",
        type: "bool",
      },
    ],
    name: "ApprovalForAll",
    type: "event",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_mintAmount",
        type: "uint256",
      },
    ],
    name: "mint",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [],
    name: "pause",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_user",
        type: "address",
      },
    ],
    name: "removeUserFromWhitelist",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "reveal",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "safeTransferFrom",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
      {
        internalType: "bytes",
        name: "_data",
        type: "bytes",
      },
    ],
    name: "safeTransferFrom",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "operator",
        type: "address",
      },
      {
        internalType: "bool",
        name: "approved",
        type: "bool",
      },
    ],
    name: "setApprovalForAll",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "_newBaseExtension",
        type: "string",
      },
    ],
    name: "setBaseExtension",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "_newBaseURI",
        type: "string",
      },
    ],
    name: "setBaseURI",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_newCost",
        type: "uint256",
      },
    ],
    name: "setCost",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_newmaxMintAmount",
        type: "uint256",
      },
    ],
    name: "setmaxMintAmount",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_user",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "_allowance",
        type: "uint256",
      },
    ],
    name: "setMintAllowance",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_newOwner",
        type: "address",
      },
    ],
    name: "setNewOwner",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_limit",
        type: "uint256",
      },
    ],
    name: "setNftPerAddressLimit",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "_notRevealedURI",
        type: "string",
      },
    ],
    name: "setNotRevealedURI",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "setOnlyWhitelisted",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "setPublic",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_price",
        type: "uint256",
      },
    ],
    name: "setPublicCost",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_price",
        type: "uint256",
      },
    ],
    name: "setWLCost",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        indexed: true,
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "Transfer",
    type: "event",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "transferFrom",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address[]",
        name: "_users",
        type: "address[]",
      },
      {
        internalType: "uint256[]",
        name: "_allowances",
        type: "uint256[]",
      },
    ],
    name: "whitelistUsers",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address[]",
        name: "_users",
        type: "address[]",
      },
    ],
    name: "whitelistUsers",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "withdraw",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    name: "addressMintedBalance",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    name: "addressWhitelisted",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "owner",
        type: "address",
      },
    ],
    name: "balanceOf",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "baseExtension",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "baseURI",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "cost",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "costWL",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "currentState",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "getApproved",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        internalType: "address",
        name: "operator",
        type: "address",
      },
    ],
    name: "isApprovedForAll",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_user",
        type: "address",
      },
    ],
    name: "isWhitelisted",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "maxMintAmount",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "maxSupply",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    name: "mintAllowance",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "name",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "nftPerAddressLimit",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "notRevealedUri",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "ownerOf",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "revealed",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes4",
        name: "interfaceId",
        type: "bytes4",
      },
    ],
    name: "supportsInterface",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "symbol",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "tokenURI",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "totalSupply",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    name: "whitelistedAddresses",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];


const LaunchpadDetails = () => {

  const [contract, setContract] = useState(null);
  const [totalSupply, setTotalSupply] = useState(0);
  const [account, setAccount] = useState(null);
  const [web3, setWeb3] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const windowSize = useWindowSize();
  const [quantity, setQuantity] = useState(1);
  const [walletDetected, setWalletDetected] = useState(true);  // New state for wallet detection

  const handleIncrement = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  const handleDecrement = () => {
    if (quantity > 1) {
      setQuantity((prevQuantity) => prevQuantity - 1);
    }
  };

  useEffect(() => {
    const web3Instance = new Web3("https://rpc.glidefinance.io");
    setWeb3(web3Instance);
    const contractInstance = new web3Instance.eth.Contract(
      ABI,
      config.CONTRACT_ADDRESS
    );
    setContract(contractInstance);
  }, []);

  useEffect(() => {
    if (contract) {
      contract.methods
        .totalSupply()
        .call()
        .then((supply) => {
          setTotalSupply(parseInt(supply, 10));
        })
        .catch(console.error);
    }
  }, [contract]);

  useEffect(() => {
    if (typeof window.ethereum !== 'undefined' && window.ethereum !== null && typeof window.ethereum.on === 'function') {
      const web3Instance = new Web3(window.ethereum);
      setWeb3(web3Instance);
      setWalletDetected(true); // Wallet is detected
  
      // Handle account changes
      const handleAccountsChanged = (accounts) => {
        if (accounts.length === 0) { 
          // Account disconnected
          setAccount('');
        } else if (accounts[0] !== account) {
          // Account changed
          setAccount(accounts[0]);
        }
      };
  
      // Listen for account changes
      window.ethereum.on('accountsChanged', handleAccountsChanged);
  
      // Cleanup function
      return () => {
        window.ethereum.off('accountsChanged', handleAccountsChanged);
      };
    } else {
      setWalletDetected(false); // Wallet not detected
    }
  }, [account]);
  
  

 

  useEffect(() => {
    const loadBlockchainData = async () => {
      try {
        const ethereum = window.ethereum;
        if (!ethereum) {
          console.error("No Ethereum wallet found.");
          return;
        }

        const web3Instance = new Web3(ethereum || "https://rpc.glidefinance.io");
        setWeb3(web3Instance);
        const accounts = await ethereum.request({
          method: "eth_requestAccounts",
        });

        if (accounts.length > 0) {
          setAccount(accounts[0]);
        } else {
          console.error(
            "No Ethereum account found. Please ensure MetaMask is connected."
          );
        }

        const contractInstance = new web3Instance.eth.Contract(
          ABI,
          config.CONTRACT_ADDRESS
        );
        setContract(contractInstance);
        const supply = await contractInstance.methods.totalSupply().call();
        setTotalSupply(parseInt(supply, 10));
      } catch (error) {
        console.error("Failed to load web3, accounts, or contract.", error);
      }
    };

    loadBlockchainData();
  }, []); // Ensures this runs only once after the component mounts

  useEffect(() => {
    async function loadWeb3() {
      if (window.ethereum) {
        try {
          const web3Instance = new Web3(window.ethereum);
          setWeb3(web3Instance);
          const accounts = await web3Instance.eth.getAccounts();
          if (accounts.length > 0) {
            setAccount(accounts[0]);
          } else {
            console.error(
              "No Ethereum account found. Please ensure MetaMask is connected."
            );
          }
        } catch (error) {
          console.error(
            "Failed to load web3, accounts, or Ethereum provider.",
            error
          );
        }
      } else {
        console.log("Elastos wallet is not connected");
      }
    }

    loadWeb3();
  }, []);

  useEffect(() => {
    function handleAccountsChanged(accounts) {
      if (accounts.length === 0) {
        console.log("Please connect to MetaMask.");
        setAccount(null);
      } else {
        setAccount(accounts[0]);
      }
    }
  
    if (window.ethereum) {
      const web3Instance = new Web3(window.ethereum);
      setWeb3(web3Instance);
  
      // Listen for account changes
      window.ethereum.on("accountsChanged", handleAccountsChanged);
  
      // Cleanup listener when the component unmounts
      return () => {
        window.ethereum.removeListener("accountsChanged", handleAccountsChanged);
      };
    } else {
      console.log("No Ethereum wallet detected.");
      // Optionally, handle the case where MetaMask is not installed
      // e.g., display a message or a button to download MetaMask
    }
  }, []);
  
  console.log("Account:", account);
  console.log("Web3 active:", !!web3);

 


  const handleConnection = (walletType) => {
    // Logic to connect the wallet based on the walletType
    // For demonstration, assume the walletType is set directly here
    setShowModal(false); // Close the wallet modal after connecting
    // You can also set the connected wallet state here if needed
  };

  useEffect(() => {
    if (typeof window.ethereum !== 'undefined' && window.ethereum !== null && typeof window.ethereum.on === 'function') {
      window.ethereum.on("accountsChanged", function (accounts) {
        // Reinitialize web3 instance with the new account
        const web3Instance = new Web3(window.ethereum);
        setWeb3(web3Instance);
        setAccount(accounts[0]);
      });
    }
  }, []);
  

  const claimNFTs = async () => {
    if (!account) {
      setShowModal(true); // Show the wallet modal if no account is connected
      return;
    }

    try {
      const mintAmount = quantity; // Use the quantity state as the number of NFTs to mint
      const value = web3.utils.toWei(
        (config.DISPLAY_COST * quantity).toString(),
        "ether"
      ); // Calculate the total cost for the specified quantity

      // Send the mint transaction
      const receipt = await contract.methods
        .mint(mintAmount)
        .send({ from: account, value });
      console.log(receipt);
      // After successful minting, update the total supply
      const newTotalSupply = await contract.methods.totalSupply().call();
      setTotalSupply(parseInt(newTotalSupply, 10));
    } catch (error) {
      console.error("Minting failed", error);
    }
  };
  console.log("Using account:", account);

  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      const countDate = new Date("May 3, 2024 23:59:59").getTime();
      const now = new Date().getTime();
      const gap = countDate - now;

      if (gap < 0) {
        clearInterval(countdownInterval);
        // Handle completion here
        return;
      }

      const day = 1000 * 60 * 60 * 24;
      const hour = 1000 * 60 * 60;
      const minute = 1000 * 60;
      const second = 1000;

      setTimeLeft({
        days: Math.floor(gap / day),
        hours: Math.floor((gap % day) / hour),
        minutes: Math.floor((gap % hour) / minute),
        seconds: Math.floor((gap % minute) / second),
      });
    }, 1000);

    return () => clearInterval(countdownInterval);
  }, []);



  return (
    <div className="container-lg">
      <div className="row gap-4 gap-lg-0">
        <div className="col-12 col-lg-7 p-2 p-lg-4 main-details-wrapper position-relative">
          <div
            className="purplediv"
            style={{ left: "0px", background: "#8E97CD" }}
          ></div>
          <div className="d-flex align-items-center gap-2">
            <img src={launchpadDetailsIcon} alt="" />
            <h6 className="launch-details-header">
              Carrot Booster
            </h6>
      </div>

      <> 
             {!walletDetected ? (
        <div className="d-flex flex-column gap-4 justify-content-between">
          {!walletDetected && (
            <div className="alert alert-warning" role="alert">
              No Ethereum wallet detected. Please install MetaMask or connect a
              wallet to proceed.
            </div>
          )}

          <div className="d-row p-2  gap-4 justify-content-between">
            <div className="col-12 mb-4 md-mb-0 col-md-12">
              <div
                style={{ position: "relative", width: "100%", height: "280px" }}
              >
           

                <div
                  style={{
                    position: "absolute",
                    width: "100%",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    whiteSpace: "space-between",
                    gap: "8px",
                    marginTop: "12px", // Adjust as needed for alignment
                  }}
                ></div>
              </div>
            </div>
            <div className="col-12 col-md-12">
              <div className="genesis-staking-container position-relative p-2 w-100">
                <div
                  className="purplediv"
                  style={{ background: "#09FAD2" }}
                ></div>
                <div className="d-flex align-items-center gap-2 mt-1">
                  <h6 className="genesis-title">Mrs Bunny</h6>
                </div>
                <div className="d-flex align-items-center justify-content-between mt-2">
                  <div className="d-flex flex-column">
                    <span className="info-header">Total Max Supply</span>
                    <span className="info-value">{config.MAX_SUPPLY}</span>
                  </div>
                  <div className="d-flex flex-column">
                    <span className="info-header">Cost</span>
                    <span className="info-value">50 ELA</span>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between mt-2">
                  <div className="d-flex flex-column">
                    <span className="total-nfts">Total NFTs minted</span>
                    <span className="nfts-number">{totalSupply}</span>
                  </div>

                  <div className="d-flex flex-column containerm">
                    <span className="total-nfts">Minting Ends</span>
                    <div className="d-flex align-items-center justify-content-between mt-2 gap-2">
                      <div>
                        <small id="days" className="nfts-number">
                          {timeLeft.days} dys
                        </small>
                      </div>
                      <div>
                        <small id="hours" className="nfts-number">
                          {timeLeft.hours} hrs
                        </small>
                      </div>
                      <div>
                        <small id="minutes" className="nfts-number">
                          {timeLeft.minutes} min
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center mt-3 justify-content-between">
                  <div
                    className="d-flex align-items-end gap-2"
                    style={{ width: "100%" }}
                  >
                    <span className="stake">MINTED</span>
                    <div className="available-nfts" style={{ width: "100%" }}>
                      <div
                        className="progress"
                        style={{ height: "20px", borderRadius: "50px" }}
                      >
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{
                            width: `${
                              totalSupply && config.MAX_SUPPLY
                                ? (totalSupply / config.MAX_SUPPLY) * 100
                                : 0
                            }%`,
                            backgroundColor: "#4cd0cd",
                          }}
                          aria-valuenow={
                            totalSupply && config.MAX_SUPPLY
                              ? (totalSupply / config.MAX_SUPPLY) * 100
                              : 0
                          }
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          <p
                            className="text-right"
                            style={{ color: "black", width: "100%" }}
                          >
                            {totalSupply && config.MAX_SUPPLY
                              ? (
                                  (totalSupply / config.MAX_SUPPLY) *
                                  100
                                ).toFixed(2)
                              : 0}
                            %
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-column gap-2 mt-4">
                  <div className={`stake-wrapper p-2`}>
                    <div className="d-flex align-items-center justify-content-between mt-2">
                      <button
                        className={`btn filledbtn d-flex justify-content-center align-items-center gap-2`}
                        onClick={claimNFTs}
                      >
                        Mint Booster
                      </button>

                      <div className="d-flex quantity-selector">
                        <button
                          className="btn filledbtn d-flex justify-content-center align-items-center gap-2"
                          onClick={handleDecrement}
                        >
                          -
                        </button>
                        <span className="quantity mx-2">{quantity}</span>
                        <button
                          className="btn filledbtn d-flex justify-content-center align-items-center gap-2"
                          onClick={handleIncrement}
                        >
                          +
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="d-flex flex-column gap-4 justify-content-between">
          {!walletDetected && (
            <div className="alert alert-warning" role="alert">
              No Ethereum wallet detected. Please install MetaMask or connect a
              wallet to proceed.
            </div>
          )}

          <div className=" d-row p-2  gap-4 justify-content-between">
      
            <div className="col-12 col-md-12">
              <div className="genesis-staking-container position-relative p-2 w-100">
                <div
                  className="purplediv"
                  style={{ background: "#09FAD2" }}
                ></div>
                <div className="d-flex align-items-center gap-2 mt-1">
                  <h6 className="genesis-title">Mrs Bunny</h6>
                </div>
                <div className="d-flex align-items-center justify-content-between mt-2">
                  <div className="d-flex flex-column">
                    <span className="info-header">Total Max Supply</span>
                    <span className="info-value">{config.MAX_SUPPLY}</span>
                  </div>
                  <div className="d-flex flex-column">
                    <span className="info-header">Cost</span>
                    <span className="info-value">50 ELA</span>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between mt-2">
                  <div className="d-flex flex-column">
                    <span className="total-nfts">Total NFTs minted</span>
                    <span className="nfts-number">{totalSupply}</span>
                  </div>

                  <div className="d-flex flex-column containerm">
                    <span className="total-nfts">Minting Ends</span>
                    <div className="d-flex align-items-center justify-content-between mt-2 gap-2">
                      <div>
                        <small id="days" className="nfts-number">
                          {timeLeft.days} dys
                        </small>
                      </div>
                      <div>
                        <small id="hours" className="nfts-number">
                          {timeLeft.hours} hrs
                        </small>
                      </div>
                      <div>
                        <small id="minutes" className="nfts-number">
                          {timeLeft.minutes} min
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center mt-3 justify-content-between">
                  <div
                    className="d-flex align-items-end gap-2"
                    style={{ width: "100%" }}
                  >
                    <span className="stake">MINTED</span>
                    <div className="available-nfts" style={{ width: "100%" }}>
                      <div
                        className="progress"
                        style={{ height: "20px", borderRadius: "50px" }}
                      >
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{
                            width: `${
                              totalSupply && config.MAX_SUPPLY
                                ? (totalSupply / config.MAX_SUPPLY) * 100
                                : 0
                            }%`,
                            backgroundColor: "#4cd0cd",
                          }}
                          aria-valuenow={
                            totalSupply && config.MAX_SUPPLY
                              ? (totalSupply / config.MAX_SUPPLY) * 100
                              : 0
                          }
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          <p
                            className="text-right"
                            style={{ color: "black", width: "100%" }}
                          >
                            {totalSupply && config.MAX_SUPPLY
                              ? (
                                  (totalSupply / config.MAX_SUPPLY) *
                                  100
                                ).toFixed(2)
                              : 0}
                            %
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-column gap-2 mt-4">
                  <div className={`stake-wrapper p-2`}>
                    <div className="d-flex align-items-center justify-content-between mt-2">
                      <button
                        className={`btn filledbtn d-flex justify-content-center align-items-center gap-2`}
                        onClick={claimNFTs}
                      >
                        Mint Booster
                      </button>

                      <div className="d-flex quantity-selector">
                        <button
                          className="btn filledbtn d-flex justify-content-center align-items-center gap-2"
                          onClick={handleDecrement}
                        >
                          -
                        </button>
                        <span className="quantity mx-2">{quantity}</span>
                        <button
                          className="btn filledbtn d-flex justify-content-center align-items-center gap-2"
                          onClick={handleIncrement}
                        >
                          +
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
</>

          <p className="mt-3 launch-details-content">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi
            sodales in augue vel sollicitudin. Sed nunc neque, egestas at dictum
            sit amet, pellentesque vitae nulla. Aenean at accumsan nisi, vel
            porta felis. Quisque ut fringilla mi. Aenean eget lectus non augue
            lacinia sagittis non non lectus. Vestibulum eleifend
          </p>
          <h6 className="mt-3 launch-subheader">Carrot Booster</h6>
          <p className="mt-3 launch-details-content">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi
            sodales in augue vel sollicitudin. Sed nunc neque, egestas at dictum
            sit amet, pellentesque vitae nulla. Aenean at accumsan nisi, vel
            porta felis. Quisque ut fringilla mi. Aenean eget lectus non augue
            lacinia sagittis non non lectus.
          </p>
          <p className="launch-details-content mt-3">
            Vestibulum eleifend, odio a consequat gravida, urna metus aliquet
            lacus, placerat consectetur dui ex sed risus. Proin nec dolor
            convallis, auctor nisi eget, pellentesque velit.
          </p>
          <p className="launch-details-content mt-3">
            Aliquam imperdiet risus varius finibus convallis. Nunc ut dictum
            quam. Aenean convallis vitae nunc quis condimentum. Cras dictum
            rhoncus lorem vel ullamcorper. Duis eleifend nec tellus in
            facilisis.
          </p>
          <div className="mt-3">
            <a href="#" className="visit-website">
              Visit website www.loremipsum.com
            </a>
         
            <hr className="form-divider my-4" />
            <div className="d-flex align-items center justify-content-between">
              <div className="d-flex align-items center gap-2">
                <img src={coinStack} alt="" />
                <div className="launch-details-header">My tokens</div>
              </div>
              <span className="my-rewards-text">
                My rewards: <b>23.647845 iDYP</b>
              </span>
            </div>
            <div className="tokens-container mt-4">
              <div className="token-card p-3 d-flex flex-column gap-1">
                <span className="date-title">Goal</span>
                <h6 className="date-content">0.00</h6>
              </div>
              <div className="token-card p-3 d-flex flex-column gap-1">
                <span className="date-title">Deposit token</span>
                <h6 className="date-content">0.00</h6>
              </div>
              <div className="token-card p-3 d-flex flex-column gap-1">
                <span className="date-title">Reward token</span>
                <h6 className="date-content">0.00</h6>
              </div>
              <div className="token-card p-3 d-flex flex-column gap-1">
                <span className="date-title">Your deposit</span>
                <h6 className="date-content">0.00</h6>
              </div>
              <div className="token-card p-3 d-flex flex-column gap-1">
                <span className="date-title">Total raised</span>
                <h6 className="date-content">0.00</h6>
              </div>
              <div className="token-card p-3 d-flex flex-column gap-1">
                <span className="date-title">Sale price</span>
                <h6 className="date-content">0.00</h6>
              </div>
            </div>
            <hr className="form-divider my-4" />
            <div className="d-flex align-items-start gap-2">
              <img src={messageWarningIcon} alt="" />
              <div>
                <p className="details-warning" style={{ width: "95%" }}>
                  This form is for project owners to submit their projects for
                  us to review as a potential IDO (Initial DEX Offering).
                </p>
                <p className="details-warning">
                  {" "}
                  <b>DO NOT</b> submit this form if you are looking to
                  participate in an IDO.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-5 px-0 px-lg-2">
          <div className="main-details-wrapper p-3 position-relative">
            <div
              className="purplediv"
              style={{ left: "0px", background: "#8E97CD", top: "25px" }}
            ></div>
            <div className="d-flex flex-column flex-lg-row align-items-center align-items-lg-start gap-3 gap-lg-0 justify-content-between">
              <div className="d-flex align-items-center gap-2">
                <img src={coinStack} alt="" />
                <div className="d-flex flex-column gap-1">
                  <h6 className="launch-details-header">Carrot Booster</h6>
                  <span className="details-warning">
                    Boost all your stakes and farm pools with additional 5%
                  </span>
                </div>
              </div>
              {/* <button id="dropdown-basic-button2">Connect wallet</button> */}
              {/* <DropdownButton
                id="dropdown-basic-button2"
                title={
                  <span
                    className="d-flex align-items-center gap-2 connecttitle position-relative"
                    style={{ bottom: "5px", fontSize: "12px" }}
                  >
                    <img
                      src={walletIcon}
                      alt=""
                      className="position-relative"
                      // style={{ top: 4 }}
                    />
                    Connect Wallet
                  </span>
                }
              ></DropdownButton> */}
            </div>
            <hr className="form-divider my-4" />
            <span className="my-rewards-text">
              Balance: <b>10000 WETH</b>
            </span>
            <div className="d-flex align-items-center justify-content-between mt-4 gap-2">
             <div className="d-flex align-items-center gap-4">
             <div className="position-relative">
                <h6 className="amount-txt">Amount</h6>
                <input
                  type={"number"}
                  className="styledinput"
                  placeholder="0.0"
                  style={{ width: "100%" }}
                />
              </div>
              <button className="btn maxbtn">Max</button>
             </div>
              <button className="btn filledbtn">Approve</button>
            </div>
          </div>
        
        </div>
      </div>
    </div>
  );
};

export default LaunchpadDetails;
