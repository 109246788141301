import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Box } from "@material-ui/core";
import { useAddress, useWeb3Context } from "../../hooks/web3Context";
import styled from 'styled-components'

function ConnectMenu({ theme, bigType = false, setNotification }) {
  const { connect, disconnect, connected, web3, chainID } = useWeb3Context();
  const [isConnected, setConnected] = useState(connected);
  const address = useAddress();
  let ellipsis = address
    ? address.slice(0, 2) +
    "..." +
    address.substring(address.length - 4, address.length)
    : "Connect Wallet";

  let buttonText = "Connect Wallet";
  let clickFunc = connect;

  if (isConnected) {
    buttonText = "Disconnect";
    clickFunc = disconnect;
  }
  function onConnect() {
    connect().then(msg => {
      if (msg.type === 'error') {
        setNotification(msg)
      }
    });
  }

  useEffect(() => {
    setConnected(connected);
  }, [web3, connected]);

  return (
    <div
    >
      <ConnectButton className="connect-btn-style" bigType={bigType}
        onClick={() => isConnected ? disconnect() : onConnect()}
      >
        {buttonText}
      </ConnectButton>
    </div>
  );
}

const ConnectButton = styled(Box)`
    ${({ bigType }) => bigType ? 'width: 100%; height: 48px' : 'height: 32px'};
    position: relative;
    z-index: 100;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 20px 30px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(241, 172, 93, 0.3);
    border-radius: 12px;
    background-color: transparent;
    box-shadow: inset 0 0 6px 0 rgb(241 172 93 / 30%);
    -webkit-transition: background-color 500ms ease, box-shadow 400ms ease, border 300ms ease;
    transition: background-color 500ms ease, box-shadow 400ms ease, border 300ms ease;
    font-family: 'Chakra Petch', sans-serif;
    color: #f1ac5d;
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;

    &:hover {
      opacity: .5;
    }
`;

export default ConnectMenu;
