import "./header.css";
import Web3 from "web3";
import React, { useEffect, useState } from "react";
import coin from "./assets/coins.svg";
import avax from "./assets/avax.svg";
import bnb from "./assets/bnb.svg";
import esc from "./assets/esc.svg";
import fsn from "./assets/esc.svg";
import base from "./assets/base.svg";
import skale from "./assets/skale.svg";
import conflux from "./assets/conflux.svg";
import dropdown from "./assets/dropdown.svg";
import { shortAddress } from "../../functions/shortAddress";
import logoutimg from "./assets/logout.svg";
import walletIcon from "./assets/walletIcon.svg";
import { handleSwitchNetworkhook } from "../../functions/hooks";
import { NavLink, useLocation } from "react-router-dom";
import useWindowSize from "../../functions/useWindowSize";
import toolsLogo from "../../assets/sidebarIcons/bpunk.svg";
import axios from "axios";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import usersIcon from './assets/user.svg';

const Header = ({
  chainId,
  coinbase,
  logout,
  handleSwitchNetwork,
  showModal,
  show,
  hideModal,
  handleConnection,
  isConnected,
  onSetCurrencyAmount,
}) => {
  const [gasPrice, setGasprice] = useState();
  const [escPrice, setEscprice] = useState();
  const [username, setUsername] = useState();
  const windowSize = useWindowSize();
  const [hotpairs, setHotpairs] = useState([]);
  const [escState, setEscState] = useState(false);
  const [fsnState, setFsnState] = useState(false);
  const [currencyAmount, setCurrencyAmount] = useState(0);
  const [avatar, setAvatar] = useState("../../assets/img/person.svg");
  const routeData = useLocation();
  const { ethereum } = window;
  const checklogout = localStorage.getItem("logout");

  const setActiveChain = () => {
    if (chainId === 20) {
      setEscState(true);
      setFsnState(false);
    } else if (chainId === 32659) {
      setEscState(false);
      setFsnState(true);
    }
  };

  const handleEthPool = async () => {
    if (window.ethereum) {
      await handleSwitchNetworkhook("0x14")
        .then(() => {
          handleSwitchNetwork(20);
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      window.alertify.error("No web3 detected. Please install Metamask!");
    }
  };

  function handleChainChanged() {
    setActiveChain();
    if (window.location.href.includes("pair-explorer")) {
      if (chainId === 20) {
        window.location.assign(
          "/pair-explorer/0x497070e8b6c55fd283d8b259a6971261e2021c01"
        );
      } else {
        window.location.assign(
          "/pair-explorer/0x76911e11fddb742d75b83c9e1f611f48f19234e4"
        );
      }
    }
  }

  const fetchAvatar = async () => {
    const response = await fetch(
      `https://api-image.dyp.finance/api/v1/avatar/${coinbase}`
    )
      .then((res) => res.json())
      .then((data) => {
        data.avatar
          ? setAvatar(data.avatar)
          : setAvatar("/assets/img/person.svg");
      })
      .catch(console.error);

    return response;
  };

  const fetchUsername = async () => {
    if (coinbase) {
      await axios
        .get(`https://api-image.dyp.finance/api/v1/username/${coinbase}`)
        .then((res) => {
          setUsername(res.data.username || "Grasslander");
        })
        .catch(() => {
          setUsername("Grasslander");
        });
    } else {
      setUsername("Grasslander");
    }
  };

  const welcomeTypes = ["Good morning", "Good afternoon", "Good evening"];
  const [welcomeText, setWelcomeText] = useState("");
  const hour = new Date().getHours();

  const setGreeting = () => {
    if (hour < 12) setWelcomeText(welcomeTypes[0]);
    else if (hour < 18) setWelcomeText(welcomeTypes[1]);
    else setWelcomeText(welcomeTypes[2]);
  };

  useEffect(() => {
    setGreeting();
  }, [hour]);

  useEffect(() => {
    if (chainId) {
      handleSwitchNetwork(chainId.toString());
    }
  }, [chainId]);

  useEffect(() => {
    setActiveChain();
    ethereum?.on("chainChanged", handleChainChanged);
    return () => {
      ethereum?.removeListener("chainChanged", handleChainChanged);
    };
  }, [chainId]);

  useEffect(() => {
    if (coinbase) {
      fetchAvatar();
      fetchUsername();
    } else {
      setUsername("Grasslander");
    }
  }, [coinbase, checklogout]);

  return (
    <>
      <header className="header-wrap" style={{ zIndex: 5 }}>
        <div className="container-fluid d-flex justify-content-center justify-content-lg-start">
          <div className="row w-100">
            <div className="col-1"></div>
            <div
              className={`${
                windowSize.width < 991
                  ? "col-12"
                  : windowSize.width < 1490
                  ? "col-11"
                  : "col-10"
              }`}
            >
              <div
                className="container-lg px-0 d-flex justify-content-between gap-3 align-items-center w-100"
              >
                <div className="d-none d-lg-flex flex-column gap-2 text-start">
                  <h4
                    className="text-white"
                    style={{ fontSize: "23px", fontWeight: "600" }}
                  >
                    {welcomeText}, {username}
                  </h4>
                  <span className="text-white headerdesc">
                    Discover the latest trends, breaking news, and gain access
                    to powerful dApps.{" "}
                    <span className="new-beta-sidebar">
                      <span className="new-beta-text text-xs">v1.0.1-BETA</span>
                    </span>
                  </span>
                </div>
                <NavLink to="/">
                  <img
                    src={toolsLogo}
                    width="65px"
                    className="d-flex d-lg-none"
                    alt=""
                  />
                </NavLink>
                <div className="d-flex m-0 justify-content-between gap-3 align-items-center">
                  <NavLink className="buydyp-btn btn d-none d-lg-flex" to="/buybunny">
                    <img src={coin} alt="" />
                    <span className="buy-dyp-text d-none d-lg-flex">
                      Buy BUNNY
                    </span>
                  </NavLink>
                  <div className="d-flex items-center align-items-center gap-2  position-relative">
                    <ConnectButton
                      label={"connect"}
                      accountStatus={{
                        smallScreen: "avatar",
                        largeScreen: "avatar",
                      }}
                    />
                    {/* <a class="account-user-wrapper d-flex align-items-center gap-1 active" href="/account" aria-current="page">
                      <img className="w-5" src={usersIcon} alt="" />
                        <span class="account-user d-none d-lg-flex">Account</span>
                        </a> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-1"></div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
