/* eslint-disable global-require */
import React, { Component } from "react"; // Import React to ensure it's in scope
import { BrowserRouter } from "react-router-dom";
import { Web3ContextProvider } from "./hooks/web3Context";
import { LiquidityDataProvider } from "./hooks/useLiquidityData";
import App from "./App";
import Liquidity from "./views/Liquidity/Liquidity";
import EarnHero from "../earn/EarnHero/EarnHero";

export default class Root extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Web3ContextProvider>
        <LiquidityDataProvider>
          <BrowserRouter basename={"/#"}>
          <EarnHero />
            <App />
            <Liquidity />
          </BrowserRouter>
        </LiquidityDataProvider>
      </Web3ContextProvider>
    );
  }
}
