import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { WagmiConfig, createClient, configureChains } from "wagmi";
import { jsonRpcProvider } from "wagmi/providers/jsonRpc";
import { publicProvider } from "wagmi/providers/public";
import { RainbowKitProvider, getDefaultWallets, darkTheme } from "@rainbow-me/rainbowkit";
import "@rainbow-me/rainbowkit/styles.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const elastosChain = {
  id: 20,
  name: "ESC",
  network: "Elastos Smart Chain",
  nativeCurrency: {
    name: "ELA",
    symbol: "ELA",
    decimals: 18,
  },
  rpcUrls: {
    default: "https://rpc.glidefinance.io",
  },
  blockExplorers: {
    default: { name: "Elastos Explorer", url: "https://esc.elastos.io/" },
  },
  testnet: false,
};

const fsnchain = {
  id: 32659,
  name: "FSN",
  network: "Fusion Mainnet",
  nativeCurrency: {
    name: "FSN",
    symbol: "FSN",
    decimals: 18,
  },
  rpcUrls: {
    default: "https://mainnet.fusionnetwork.io",
  },
  blockExplorers: {
    default: { name: "Fusion Scanner", url: "https://fsnscan.com/" },
  },
  testnet: false,
};

const { chains, provider } = configureChains(
  [elastosChain, fsnchain], // Add all chains here
  [
    jsonRpcProvider({
      rpc: (chain) => ({ http: chain.rpcUrls.default }),
    }),
    publicProvider(),
  ]
);

const { connectors } = getDefaultWallets({
  appName: "Bunny - Mega Hub",
  projectId: "2c81428fbd056eb72fe3c35dd9970e72", // Replace with your project ID
  chains,
});

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
});

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <WagmiConfig client={wagmiClient}>
        <RainbowKitProvider chains={chains} theme={darkTheme()}>
          <App />
          <ToastContainer />
        </RainbowKitProvider>
      </WagmiConfig>
    </BrowserRouter>
  </React.StrictMode>
);
