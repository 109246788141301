import React from "react";
import walleticon from "../assets/walleticon.svg";
import copy from "../assets/copy.svg";
import submit from "../assets/submit.svg";
import "./governance-new.css";

const Comp1 = () => {
  return (
    <div className="">
      <div className="d-flex flex-column flex-lg-row justify-content-between gap-4 gap-lg-2 cardwrapper mt-4 mb-4">
        <div className="govcard1 col-12 col-lg-3">
          <div className="purplediv"></div>
          <div className="d-flex flex-column gap-2">
            <img src={walleticon} alt="" style={{ width: 40, height: 40 }} />
            <div className="d-flex justify-content-between gap-2 align-items-baseline position-relative">
              <h6 className="govcard-title">Ticket Purchase</h6>
              <h6 className="govcard-number">1</h6>
            </div>
            <h6 className="govcard-desc">
              <ul
                id="bullets"
                style={{ listStyleType: "disc", paddingLeft: "20px" }}
              >
                <li
                  id="bullets"
                  style={{ listStyleType: "disc", paddingLeft: "5px" }}
                >
                  Each ticket costs <b>10 BUNNY</b> tokens.
                </li>
                <li
                  id="bullets"
                  style={{ listStyleType: "disc", paddingLeft: "5px" }}
                >
                  There is a <b>1 BUNNY</b> commission per ticket.
                </li>
              </ul>
            </h6>
          </div>
        </div>
        <div className="govcard2 col-12 col-lg-3">
          <div className="greendiv"></div>
          <div className="d-flex flex-column gap-2">
            <img src={copy} alt="" style={{ width: 40, height: 40 }} />
            <div className="d-flex justify-content-between gap-2 align-items-baseline position-relative">
              <h6 className="govcard-title">Winning Distribution</h6>
              <h6 className="govcard-number">2</h6>
            </div>
            <h6 className="govcard-desc">
              <ul
                id="bullets"
                style={{ listStyleType: "disc", paddingLeft: "20px" }}
              >
                <li
                  id="bullets"
                  style={{ listStyleType: "disc", paddingLeft: "5px" }}
                >
                  First Place: <b>60% </b> of the total reward pool.
                </li>
                <li
                  id="bullets"
                  style={{ listStyleType: "disc", paddingLeft: "5px" }}
                >
                  Second Place: <b>30%</b> of the total reward pool.
                </li>
                <li
                  id="bullets"
                  style={{ listStyleType: "disc", paddingLeft: "5px" }}
                >
                  Third Place: <b>10%</b> of the total reward pool.
                </li>
              </ul>{" "}
            </h6>
          </div>
        </div>
        <div className="govcard3 col-12 col-lg-3">
          <div className="orangediv"></div>
          <div className="d-flex flex-column gap-2">
            <img src={submit} alt="" style={{ width: 40, height: 40 }} />
            <div className="d-flex justify-content-between gap-2 align-items-baseline position-relative">
              <h6 className="govcard-title">Lottery Draw</h6>
              <h6 className="govcard-number">3</h6>
            </div>
            <h6 className="govcard-desc">
              The draw is conducted by the lottery operator after the lottery
              expires. Winners are chosen randomly from the pool of purchased
              tickets. The same ticket cannot win more than one prize in a
              single draw.
            </h6>
          </div>
        </div>
      </div>

      <div className="d-flex flex-column flex-lg-row justify-content-between gap-4 gap-lg-2 cardwrapper mt-4 mb-4">
        <div className="govcard1 col-12 col-lg-3">
          <div className="purplediv"></div>
          <div className="d-flex flex-column gap-2">
            <img src={walleticon} alt="" style={{ width: 40, height: 40 }} />
            <div className="d-flex justify-content-between gap-2 align-items-baseline position-relative">
              <h6 className="govcard-title">Lottery Duration</h6>
              <h6 className="govcard-number">4</h6>
            </div>
            <h6 className="govcard-desc">
              Each lottery runs for a fixed duration of 7 days.
            </h6>
          </div>
        </div>
        <div className="govcard3 col-12 col-lg-3">
          <div className="orangediv"></div>
          <div className="d-flex flex-column gap-2">
            <img src={submit} alt="" style={{ width: 40, height: 40 }} />
            <div className="d-flex justify-content-between gap-2 align-items-baseline position-relative">
              <h6 className="govcard-title">Claiming Winnings</h6>
              <h6 className="govcard-number">5</h6>
            </div>
            <h6 className="govcard-desc">
              Winners can claim their winnings after the draw. Winnings must be
              withdrawn by the winners themselves.
            </h6>
          </div>
        </div>
        <div className="govcard2 col-12 col-lg-3">
          <div className="greendiv"></div>
          <div className="d-flex flex-column gap-2">
            <img src={copy} alt="" style={{ width: 40, height: 40 }} />
            <div className="d-flex justify-content-between gap-2 align-items-baseline position-relative">
              <h6 className="govcard-title">Refunds</h6>
              <h6 className="govcard-number">6</h6>
            </div>
            <h6 className="govcard-desc">
              If the lottery expires without drawing winners, participants can
              get a refund for their tickets.
            </h6>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Comp1;
