/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import "./App.css";
import "remixicon/fonts/remixicon.css";
import { ethers } from "ethers";
import contractAbi from "./utils/contractABI.json";
import axios from "axios";
import html2canvas from "html2canvas";
import { networks } from "./utils/netwroks";
import Toast from "./components/Toast";
import bplogo from "./bplogo.png";
import bpicon from "./bpicon.webp";
import { useNetwork, useSwitchNetwork } from "wagmi";

const tld = ".bunny";
const CONTRACT_ADDRESS = "0xe19B39f7b4529f6fB0847d4cefF5D2cc199103A5";

const AppDomain = () => {
  const [currentAccount, setCurrentAccount] = useState("");
  const [domain, setDomain] = useState("");
  const [record, setRecord] = useState("");
  const [network, setNetwork] = useState("");
  // const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mints, setMints] = useState([]);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [transactionStatus, setTransactionStatus] = useState("pending"); // could be 'pending', 'completed', 'error'
  const [showPrices, setShowPrices] = useState(false);
  const togglePrices = () => setShowPrices(!showPrices);
  const [isNFTHolder, setIsNFTHolder] = useState(false);
  const { chain } = useNetwork();
  const { switchNetwork } = useSwitchNetwork();
  const elastosMainnetChainId = 20; // Elastos Mainnet Chain ID in decimal

  const handleSwitchNetwork = async () => {
    try {
      await switchNetwork(elastosMainnetChainId);
    } catch (error) {
      console.error("Failed to switch network", error);
    }
  };

  const handleChange = (e) => {
    const inputValue = e.target.value;
    if (inputValue.length <= 12 && validateDomain(inputValue)) {
      setDomain(inputValue);
      setToastMessage("");
      setToastType("");
    } else {
      setDomain(inputValue);
      setToastMessage(
        "Invalid domain. Only use letters, numbers, and hyphens (not starting/ending with a hyphen)."
      );
      setToastType("error");
    }
  };

  const validateDomain = (input) => {
    const regex = /^[a-z0-9](?:[a-z0-9-]{0,10}[a-z0-9])?$/i;
    return regex.test(input);
  };

  // Pinata API keys (should be stored securely, not hardcoded in production)
  const pinataApiKey = "0a590e47949da3491286";
  const pinataSecretApiKey =
    "3f66c4262086471c23530455c4185e2d4c1894a979d10134973e825396d06172";

  const pinFileToIPFS = async (file) => {
    const url = `https://api.pinata.cloud/pinning/pinFileToIPFS`;
    let data = new FormData();
    data.append("file", file);

    const response = await axios.post(url, data, {
      headers: {
        pinata_api_key: pinataApiKey,
        pinata_secret_api_key: pinataSecretApiKey,
      },
    });
    return `https://bunnydomain.mypinata.cloud/ipfs/${response.data.IpfsHash}`;
  };

  const pinJSONToIPFS = async (jsonBody) => {
    try {
      const url = `https://api.pinata.cloud/pinning/pinJSONToIPFS`;
      const response = await axios.post(url, JSON.stringify(jsonBody), {
        headers: {
          pinata_api_key: pinataApiKey,
          pinata_secret_api_key: pinataSecretApiKey,
          "Content-Type": "application/json",
        },
      });
      console.log("IPFS Response:", response.data);
      return `https://bunnydomain.mypinata.cloud/ipfs/${response.data.IpfsHash}`;
    } catch (error) {
      console.error("Error pinning JSON to IPFS:", error);
      throw error; // Rethrow to handle it in the calling context
    }
  };

  useEffect(() => {
    checkIfWalletIsConnected();
  }, []);

  const Overlay = ({ onClose }) => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className=" relative p-5 bg-bg-main text-white border-button-secondtxt rounded-lg shadow-lg text-center">
        <h2 className="text-lg font-bold mb-4">Transaction in Progress</h2>
        <p>Please wait until the transaction is confirmed.</p>

        <ul className="max-w-md space-y-2 text-gray-500 list-inside dark:text-gray-400 mt-4">
          <li className="flex items-center">
            <svg
              className="w-4 h-4 me-2 text-green-500 dark:text-green-400 flex-shrink-0"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
            </svg>
            Set domain and record
          </li>
          <li className="flex items-center">
            <svg
              className="w-4 h-4 me-2 text-green-500 dark:text-green-400 flex-shrink-0"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
            </svg>
            Uploading to IPFS
          </li>
          <li className="flex items-center">
            {transactionStatus === "pending" ? (
              <div role="status">
                <div className="flex items-center">
                  <svg
                    aria-hidden="true"
                    className="w-4 h-4 me-2 text-gray-200 animate-spin dark:text-gray-600 fill-button-main"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  Transaction completed
                </div>
              </div>
            ) : (
              <div className="flex items-center">
                <svg
                  className="w-4 h-4 me-2 text-green-500 dark:text-green-400 flex-shrink-0"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                </svg>
                Transaction completed
              </div>
            )}
          </li>
        </ul>

        <button
          className="absolute right-0 top-0 px-4 py-2 text-gray-700 rounded"
          onClick={onClose}
        >
          x
        </button>
      </div>
    </div>
  );

  const fetchAllMints = async () => {
    if (!currentAccount) return; // Only proceed if there is a connected account

    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(
        CONTRACT_ADDRESS,
        contractAbi.abi,
        provider
      );
      const allMints = await contract.getAllDomains();
      console.log("Fetched all minted domains:", allMints); // Log fetched domains
      setMints(allMints);
    } catch (error) {
      console.error("Failed to fetch minted domains:", error);
    }
  };

  useEffect(() => {
    fetchAllMints();
  }, [currentAccount]); // Depend on currentAccount to re-run when it changes

  const checkIfWalletIsConnected = async () => {
    const { ethereum } = window;
    if (!ethereum) {
      console.log("Elastos object not found, install MetaMask.");
      return;
    }

    const accounts = await ethereum.request({ method: "eth_accounts" });
    if (accounts.length !== 0) {
      const account = accounts[0];
      console.log("Found an authorized account:", account);
      setCurrentAccount(account);
      const chainId = await ethereum.request({ method: "eth_chainId" });
      setNetwork(networks[chainId]);

      // Add here the check for NFT ownership
      const provider = new ethers.providers.Web3Provider(ethereum);
      const contract = new ethers.Contract(
        CONTRACT_ADDRESS,
        contractAbi.abi,
        provider
      );
      try {
        const tokenId = 1; // Assuming you want to check ownership of token with ID 1
        const owner = await contract.ownerOf(tokenId);
        setIsNFTHolder(owner.toLowerCase() === account.toLowerCase());
      } catch (error) {
        console.error("Failed to check NFT ownership:", error);
        setIsNFTHolder(false); // Default to false in case of an error
      }
    } else {
      console.log("No authorized account found");
    }
  };

  function capitalizeFirstWord(str) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }
  const connectWallet = async () => {
    const { ethereum } = window;
    if (!ethereum) {
      setToastMessage("Use Essentials web browser!");
      setToastType("error");
      return;
    }
    const accounts = await ethereum.request({ method: "eth_requestAccounts" });
    console.log("Connected", accounts[0]);
    setCurrentAccount(accounts[0]);
  };

  const mintDomain = async () => {
    if (!domain) {
      setToastMessage("Please enter a domain name.");
      setToastType("error");
      return;
    }
    if (domain.length < 1) {
      setToastMessage("Domain must be at least 1 character long.");
      setToastType("error");
      return;
    }
    if (!validateDomain(domain)) {
      setToastMessage("Invalid domain name. Please check your entry.");
      setToastType("error");
      return;
    }
    if (mints.includes(`${domain}${tld}`)) {
      setToastMessage(
        "This domain has already been minted. Please choose a different one."
      );
      setToastType("error");
      return;
    }

    setLoading(true);
    setIsOverlayVisible(true);

    try {
      const canvas = await html2canvas(
        document.querySelector("#domainPreview"),
        { scale: 2 }
      );
      const blob = await new Promise((resolve) =>
        canvas.toBlob(resolve, "image/png")
      );
      const imageUrl = await pinFileToIPFS(blob);
      const metadata = {
        name: `${domain}${tld}`,
        symbol: "BNS",
        external_url: "https://domains.bunnypunk.io",
        description:
          "Bunny Name Service (.bunny) - Building Identity on Elastos blockchain with BUNNY domains",
        image: imageUrl,
        attributes: [{ trait_type: "Length", value: domain.length }],
      };
      const metadataUrl = await pinJSONToIPFS(metadata);

      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(
        CONTRACT_ADDRESS,
        contractAbi.abi,
        signer
      );
      const price = await contract.getPrice(domain);
      const priceInEther = ethers.utils.formatEther(price);
      const ethAmount = ethers.utils.parseEther(priceInEther);
      const overrides = { value: ethAmount };

      const tx = await contract.register(domain, metadataUrl, overrides);
      await tx.wait();
      const newItemId = await contract.domains(domain);

      setMints([...mints, `${domain}${tld}`]);
      setToastMessage("Domain successfully minted!");
      setToastType("success");
      console.log(`Domain minted! See transaction: ${tx.hash}`);
    } catch (error) {
      console.error("Minting failed:", error);
      setToastMessage(
        "Mint failed. Please try again, make sure your domain name is unique."
      );
      setToastType("error");
    } finally {
      setLoading(false);
      setIsOverlayVisible(false); // Hide overlay regardless of the outcome
    }
  };


  const countCharacterLengths = (domains) => {
    const counts = {};
    domains.forEach((domain) => {
      const length = domain.replace(tld, '').length;
      if (counts[length]) {
        counts[length]++;
      } else {
        counts[length] = 1;
      }
    });
    return counts;
  };
  
  const renderHighScoreOverview = () => {
    const counts = countCharacterLengths(mints);
    const sortedCounts = Object.entries(counts).sort((a, b) => a[0] - b[0]);
  
    return (
      <div className="mt-4">
        <div className="govcard1 d-flex flex-column gap-2 p-0">
          <div className="d-flex w-100 position-relative justify-content-between govcard1 leaderboard-title-wrapper p-2">
            <h6 className="migration-banner-title py-2 w-full text-center">Character overview</h6>
          </div>
          <div className="p-2 overflow-x-scroll">
            <table className="playerTable w-full table-auto">
              <thead>
                <tr className="playerRow">
                  <th className="playerHeader font-montserrat p-2">Rank</th>
                  <th className="playerHeader font-montserrat p-2">Length</th>
                  <th className="playerHeader text-center font-montserrat p-2">Total minted</th>
                </tr>
              </thead>
              <tbody>
                {sortedCounts.length > 0 ? (
                  sortedCounts.map(([length, count], index) => (
                    <tr className="playerInnerRow" key={index}>
                      <td className="playerData col-1 font-montserrat p-2">#{index + 1}</td>
                      <td className="playerName col-5 font-montserrat p-2">
                        <div className="position-relative d-flex align-items-center">
                          <span>{length} characters</span>
                        </div>
                      </td>
                      <td className="playerScore col-2 text-center font-montserrat p-2">{count}</td>
                    </tr>
                  ))
                ) : (
                  <tr className="playerInnerRow">
                    <td colSpan="3" className="text-center p-2">No domains minted yet</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };
  

  
  
  const renderMints = () => {
    // Sort the mints by length first
    const sortedMints = [...mints].sort((a, b) => a.length - b.length);
  
    return (
      <div className="justify-center items-center mt-12 rounded-lg w-full mx-auto">
        <h2 className="text-lg text-white font-semibold mb-2">
          Recently Minted Domains:
        </h2>
  
  
        <ul className="grid text-button-secondtxt font-semibold text-center grid-cols-2 sm:grid-cols-4 gap-4 p-2 text-xs max-h-[500px] overflow-y-auto">
          {sortedMints.map((mint, index) => (
            <div
              key={index}
              className="d-flex flex-column align-items-center align-items-lg-start"
            >
              <li className="stake-item2 justify-center w-full position-relative flex-column flex-lg-row d-flex align-items-center gap-2 eth-item-active">
                <img
                  src={bpicon}
                  alt=""
                  className="tokenlogo"
                  width="32"
                  height="32"
                />
                <p className="text-white">{capitalizeFirstWord(mint)}.bunny</p>
              </li>
            </div>
          ))}
        </ul>
      </div>
    );
  };
  

  const characterPrices = {
    1: 100,
    2: 100,
    3: 70,
    4: 70,
    5: 30,
    6: 30,
    7: 7,
    8: 7,
    9: 7,
    10: 7,
    11: 7,
    12: 7,
  };

  const groupCharacterPrices = (prices) => {
    const grouped = [];
    let previousPrice = null;
    let rangeStart = null;

    Object.entries(prices).forEach(([length, price], index, array) => {
      length = parseInt(length); // Ensure the length is an integer
      if (price !== previousPrice) {
        if (rangeStart !== null) {
          const range =
            rangeStart === length - 1
              ? `${rangeStart}`
              : `${rangeStart}-${length - 1}`;
          grouped.push({ range, price: previousPrice });
        }
        rangeStart = length;
        previousPrice = price;
      }

      if (index === array.length - 1) {
        // Handle the last item separately
        const range =
          rangeStart === length ? `${length}` : `${rangeStart}-${length}`;
        grouped.push({ range, price });
      }
    });

    return grouped;
  };

  const renderCharacterPrices = () => {
    const groupedPrices = groupCharacterPrices(characterPrices);

    return (
      <div>
        <button
          onClick={togglePrices}
          className="gap-0.5 justify-center overflow-hidden text-sm font-medium transition rounded-md bg-zinc-900 py-2 px-4 text-white hover:bg-zinc-700 dark:bg-blue-400/10 dark:text-blue-400 dark:ring-1 dark:ring-inset dark:ring-blue-400/20 dark:hover:bg-blue-400/10 dark:hover:text-blue-300 dark:hover:ring-blue-300"
        >
          {showPrices
            ? "Hide pricing character list"
            : "Show pricing character list"}
        </button>
        <div
          className={`transition-opacity duration-500 ${
            showPrices ? "opacity-100" : "opacity-0 pointer-events-none"
          }`}
          style={{
            maxHeight: showPrices ? "1000px" : "0",
            overflow: "hidden",
            transition: "max-height 0.5s ease-in-out",
          }}
        >
          <div className="overflow-x-auto relative shadow-md sm:rounded-lg border border-1 mt-2">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 dashboardwrapper ">
              <thead className="text-xs text-gray-700 uppercase bg-transparent border-b border-b-4 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="py-2 px-2">
                    Character
                  </th>
                  <th scope="col" className="py-2 px-2">
                    Price
                  </th>
                  <th scope="col" className="py-2 px-2">
                    Discount
                  </th>
                </tr>
              </thead>
              <tbody>
                {groupedPrices.map(({ range, price }) => {
                  const oldPrice = price; // Original price
                  const discountedPrice = price / 2; // 50% discount

                  // Function to check if a number is an integer
                  const isInteger = (num) => num % 1 === 0;

                  return (
                    <tr key={range} className="bg-transparent border-b">
                      <td className="py-2 px-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        {range}
                      </td>
                      <td className="py-2 px-2 font-medium text-gray-900 whitespace-nowrap rotated-line-through">
                        {isInteger(oldPrice)
                          ? oldPrice.toFixed(0)
                          : oldPrice.toFixed(1)}{" "}
                        ELA
                      </td>

                      <td className="py-2 px-2 font-medium text-gray-900 whitespace-nowrap text-white">
                        {isInteger(discountedPrice)
                          ? discountedPrice.toFixed(0)
                          : discountedPrice.toFixed(1)}{" "}
                        ELA
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };

  const renderNotConnectedContainer = () => (
    <button
      onClick={connectWallet}
      className="inline-flex gap-0.5 justify-center overflow-hidden text-sm font-medium transition rounded-md bg-zinc-900 py-2 px-4 text-white hover:bg-zinc-700 dark:bg-blue-400/10 dark:text-blue-400 dark:ring-1 dark:ring-inset dark:ring-blue-400/20 dark:hover:bg-blue-400/10 dark:hover:text-blue-300 dark:hover:ring-blue-300"
    >
      Connect Wallet
    </button>
  );

  const disconnectWallet = async () => {
    // Logic to disconnect the wallet
    setCurrentAccount(""); // Reset the current account state
  };

  const renderInputForm = () => (
    <div className="flex md:flex-row flex-col justify-center items-center mt-6 rounded-lg w-full max-w-screen-lg mx-auto gap-4">
      <div className="rounded-lg overflow-hidden w-full flex-auto col-12 col-md-6">
        <div
          id="domainPreview"
          className="w-full flex justify-center items-center bg-gradient-to-br from-button-main to-bg-main p-4 md:p-10 w-100 h-full min-h-96 md:w-96 md:h-96 space-y-4"
        >
          <div className="w-full h-full text-center space-y-20">
            <img src={bplogo} alt="Logo" className="w-20 md:w-20 mx-auto" />
            <h3 className="text-2xl md:text-2xl font-semibold text-white">
              {domain}
              {tld}
            </h3>
            <p className="text-sm md:text-base text-gray-400">Bunnypunk.io</p>
          </div>
        </div>
      </div>
      <div className="migration-banner-wrapper2 w-full flex flex-col gap-y-4 flex-auto col-12 col-md-6">
        <div className="relative rounded-md shadow-sm">
          <input
            type="text"
            name="Domain name"
            value={domain}
            style={{ outline: "none", "box-shadow": "none" }}
            id="name"
            className="land-input w-100"
            placeholder="Domain Name"
            onChange={handleChange}
            maxLength={12} // Set the maximum character limit to 12
          />
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <span
              className="inline-flex gap-0.5 justify-center overflow-hidden text-sm font-medium transition rounded-md bg-zinc-900 py-2 px-4 text-white hover:bg-zinc-700 dark:bg-blue-400/10 dark:text-blue-400 dark:ring-1 dark:ring-inset dark:ring-blue-400/20 dark:hover:bg-blue-400/10 dark:hover:text-blue-300 dark:hover:ring-blue-300"
              id="price-currency"
            >
              .bunny
            </span>
            <span className="text-sm text-gray-400 ml-2">
              {domain.length}/12
            </span>
          </div>
        </div>

        <div className="relative rounded-md shadow-sm">
          <input
            type="text"
            value={record}
            readOnly
            placeholder={
              currentAccount
                ? `${currentAccount}`
                : "Please connect your wallet"
            }
            onChange={(e) => setRecord(e.target.value)}
            className="pl-4 text-xs bg-transparent text-md block w-full rounded-md border border-gray-700 py-2 text-button-secondtxt shadow-sm sm:leading-6 pointer-events-none"
            maxLength={36} // Correct the comment about max length
          />
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <span className="text-xs text-gray-400 ml-2">Record</span>
          </div>
        </div>
        {renderCharacterPrices()}
        <div className="flex items-center space-x-2 p-0">
          <svg
            className="w-6 h-6 text-green-500"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M5 13l4 4L19 7"
            ></path>
          </svg>
          <span className="text-gray-400 text-xs">
            Mrs Bunny holders can mint unlimited{" "}
            <span className="text-button-secondtxt">FREE 7+ character</span>{" "}
            .bunny domains
          </span>
        </div>

        {chain?.id !== elastosMainnetChainId ? (
        <div className="linear-border">
          <button
            className="btn filled-btn2 px-4 w-100 font-semibold"
            onClick={handleSwitchNetwork}
          >
            Switch to Elastos Smart Chain
          </button>
        </div>
      ) : (
        <div className="linear-border">
          <button
            className="btn filled-btn2 px-4 w-100 font-semibold"
            onClick={mintDomain}
            disabled={loading || !validateDomain(domain)}
          >
            Mint Domain
          </button>
        </div>
      )}
      </div>
    </div>
  );

  const NotConnectedContent = () => (
    <div className="text-center mt-10">
      <h2 className="text-2xl text-white">Wallet Not Connected</h2>
      <p className="text-gray-400">
        Please connect your wallet to interact with the app.
      </p>
      <button
        onClick={connectWallet}
        className="mt-4 inline-flex gap-0.5 justify-center overflow-hidden text-sm font-medium transition rounded-md bg-zinc-900 py-2 px-4 text-white hover:bg-zinc-700 dark:bg-blue-400/10 dark:text-blue-400 dark:ring-1 dark:ring-inset dark:ring-blue-400/20 dark:hover:bg-blue-400/10 dark:hover:text-blue-300 dark:hover:ring-blue-300"
      >
        Connect Wallet
      </button>
    </div>
  );

  return (
    <div className="container-lg p-0">
      {isOverlayVisible && (
        <Overlay onClose={() => setIsOverlayVisible(false)} />
      )}

      <main>
        {!currentAccount ? (
           <>  
           {renderInputForm()}
            {mints.length > 0 && renderMints()}
            {mints.length > 0 && renderHighScoreOverview()}
          </>
        ) : (
          <>
            {renderInputForm()}
            {mints.length > 0 && renderMints()}
            {mints.length > 0 && renderHighScoreOverview()}
          </>
        )}
      </main>

      {toastMessage && (
        <Toast
          message={toastMessage}
          type={toastType}
          clearToast={() => {
            setToastMessage("");
            setToastType("");
            console.log("Toast Message:", ""); // Log the cleared message
            console.log("Toast Type:", ""); // Log the cleared type
          }}
        />
      )}
    </div>
  );
};

export default AppDomain;