// src/components/Modal.js
import React from 'react';
import PropTypes from 'prop-types';
import Close from './close.svg';

const Modal = ({ modalId, visible, children, onModalClose, maxWidth }) => {
  let className = 'modal fade';
  let style = {};

  if (visible) {
    className += ' show';
    style = { display: 'block' };
  }

  return (
    <div className={className} id={modalId} style={style} tabIndex="-1" aria-labelledby={`modalLabel` + modalId} aria-hidden="true">
      <div className="modal-dialog" style={{ maxWidth: maxWidth }}>
        <div className="modal-content">
          <span onClick={onModalClose} aria-hidden="true" data-dismiss="modal" aria-label="Close" className='close-btn'>
            <img src={Close} alt='' className='close-icon' />
          </span>
          {children}
        </div>
      </div>
    </div>
  );
};

Modal.propTypes = {
  modalId: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  visible: PropTypes.bool.isRequired,
  onModalClose: PropTypes.func.isRequired,
  maxWidth: PropTypes.number
};

Modal.defaultProps = {
  maxWidth: 500
};

export default Modal;
