import React, { useEffect, useState, useRef } from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import axios from "axios";
import { useWeb3Context } from "./hooks/web3Context";
import { Box } from "@material-ui/core";

import TopBar from "./components/TopBar/TopBar";
import Footer from "./components/Footer/Footer";
import Landing from "./views/Landing/Landing";
import Liquidity from "./views/Liquidity/Liquidity";
import { ethers } from "ethers";

import styled from "styled-components";

import { FLEXIBLE_ADDR, PAIR_ADDR, LOCK_ADDR } from "./abis/address";
import { multicall } from "./utils/contracts";
import FlexibleABI from "./abis/FlexibleABI.json";
import PairABI from "./abis/PancakePairABI.json";
import LockABI from "./abis/LockABI.json";

const CACHE_DURATION = 60000; // Cache data for 60 seconds

const cache = {
  tokenData: null,
  tokenDataTimestamp: null,
  flexData: null,
  flexDataTimestamp: null,
  lockData: null,
  lockDataTimestamp: null,
};

function App() {
  const { connect } = useWeb3Context();

  const [flexinfo, setFlexInfo] = useState({ address: FLEXIBLE_ADDR });
  const [locks, setLocks] = useState([{ address: LOCK_ADDR }, { address: LOCK_ADDR }]);
  const [balance, setBalance] = useState(0);
  const [price, setPrice] = useState({ price: 0, farmprice: 0 });
  const [loadflex, setLoadFlex] = useState(false);
  const [loadlock, setLoadLock] = useState(false);
  const [loadtoken, setLoadToken] = useState(false);

  const flexInterval = useRef(null);
  const tokenInterval = useRef(null);

  const account = "0x9A754044FbfA95d15b252453c1BB5401320A8386";

  const formatBigNumber = (value) => {
    if (!value) return 0;
    return ethers.utils.formatEther(value);
  };

  const fetchTokenData = async () => {
    if (cache.tokenData && Date.now() - cache.tokenDataTimestamp < CACHE_DURATION) {
      return cache.tokenData;
    }
    setLoadToken(true);
    try {
      let _ethprice = await axios.get(
        `https://api.coingecko.com/api/v3/coins/elastos/market_chart/range?vs_currency=usd&from=${Math.floor(Date.now() / 1000) - 3600}&to=${Math.floor(Date.now() / 1000)}`
      );
      _ethprice = _ethprice.data.prices[_ethprice.data.prices.length - 1][1];
      let calls = [
        { address: PAIR_ADDR, name: "getReserves", params: [] },
        { address: PAIR_ADDR, name: "totalSupply", params: [] },
      ];
      if (account) {
        calls.push({ address: PAIR_ADDR, name: "balanceOf", params: [account] });
      }
      const result = await multicall(PairABI, calls);
      setBalance(account ? result[2][0] : 0);
      const _price = (result[0][0] * Number(_ethprice)) / result[0][1];
      const price = (
        2 *
        Math.sqrt(Number(formatBigNumber(result[0][0])) * Number(formatBigNumber(result[0][1]))) *
        Math.sqrt(_price * _ethprice)
      ) / Number(formatBigNumber(result[1][0]));
      setPrice({ price: _price, farmprice: price });
      cache.tokenData = { price: _price, farmprice: price };
      cache.tokenDataTimestamp = Date.now();
    } catch (error) {
      console.error(error);
    }
    setLoadToken(false);
  };

  const fetchFlexibleData = async () => {
    if (cache.flexData && Date.now() - cache.flexDataTimestamp < CACHE_DURATION) {
      setFlexInfo(cache.flexData);
      return;
    }
    setLoadFlex(true);
    try {
      let calls = [
        { address: FLEXIBLE_ADDR, name: "poolInfo", params: [0] },
        { address: FLEXIBLE_ADDR, name: "performanceFee", params: [] },
        { address: FLEXIBLE_ADDR, name: "rewardPerBlock", params: [] },
      ];
      if (account) {
        calls.push({ address: FLEXIBLE_ADDR, name: "pendingRewards", params: [0, account] });
        calls.push({ address: FLEXIBLE_ADDR, name: "userInfo", params: [0, account] });
      }
      const data = await multicall(FlexibleABI, calls);
      let userinfo;
      const pendingRewards = account ? formatBigNumber(data[3][0]) : 0;
      const _userinfo = account ? data[4] : { amount: 0, maxamount: 0 };
      userinfo = { amount: _userinfo.amount, maxamount: _userinfo.amount.toString() };

      const pool = data[0];

      const performanceFee = data[1][0];
      const _rewardPerBlock = data[2][0];

      calls = [
        { address: PAIR_ADDR, name: "balanceOf", params: [FLEXIBLE_ADDR] },
      ];
      if (account) {
        calls.push({ address: PAIR_ADDR, name: "allowance", params: [account, FLEXIBLE_ADDR] });
      }
      const result = await multicall(PairABI, calls);

      const totalStaked = result[0][0];
      const allowance = account ? result[1][0] > ethers.utils.parseEther("10000") : false;

      const rate = price.farmprice && totalStaked / 1
        ? (_rewardPerBlock * 28800 * 36500 * price.price) / (totalStaked * price.farmprice)
        : null;

      const flexData = {
        address: FLEXIBLE_ADDR,
        pendingRewards,
        depositFee: pool.depositFee / 100,
        withdrawFee: pool.withdrawFee / 100,
        stakedAmount: userinfo.amount,
        maxStakedAmount: userinfo.maxamount,
        performanceFee,
        rate,
        price: price.farmprice,
        balance,
        allowance,
        totalStaked: formatBigNumber(totalStaked),
      };
      setFlexInfo(flexData);
      cache.flexData = flexData;
      cache.flexDataTimestamp = Date.now();
    } catch (error) {
      console.error(error);
    }
    setLoadFlex(false);
  };

  const fetchLockData = async () => {
    if (cache.lockData && Date.now() - cache.lockDataTimestamp < CACHE_DURATION) {
      setLocks(cache.lockData);
      return;
    }
    setLoadLock(true);
    try {
      let temp = [];
      const calls = [
        { address: LOCK_ADDR, name: "performanceFee", params: [] },
      ];
      const result = await multicall(LockABI, calls);
      const performanceFee = result[0][0];
      let allowance = false;
      if (account) {
        const calls = [
          { address: PAIR_ADDR, name: "allowance", params: [account, LOCK_ADDR] },
        ];
        const result = await multicall(PairABI, calls);
        allowance = result[0][0] > ethers.utils.parseEther("10000");
      }
      for (let i = 0; i < 2; i++) {
        let calls = [
          { address: LOCK_ADDR, name: "pools", params: [i] },
        ];
        if (account) {
          calls.push({ address: LOCK_ADDR, name: "getPendingReward", params: [i, account] });
          calls.push({ address: LOCK_ADDR, name: "withdrawableAmount", params: [i, account] });
        }
        const result = await multicall(LockABI, calls);
        const rate = result[0].totalStaked / 1
          ? (((result[0].rewardPerBlock * price.price) / (i === 0 ? 7 : 15)) * 365) /
            (result[0].totalStaked * price.farmprice)
          : null;
        temp.push({
          address: LOCK_ADDR,
          maxStakedAmount: account ? result[2][0] : 0,
          performanceFee,
          balance,
          totalStaked: ethers.utils.formatEther(result[0].totalStaked),
          depositFee: result[0].depositFee / 10,
          withdrawFee: result[0].depositFee / 10,
          pendingRewards: account ? ethers.utils.formatEther(result[1][0]) : 0,
          claimableReward: account ? ethers.utils.formatEther(result[1][1]) : 0,
          stakedAmount: account ? result[2][0] : 0,
          withdrawableAmount: account ? result[2][1] : 0,
          price: price.farmprice,
          allowance,
          index: i,
          rate,
        });
      }
      setLocks(temp);
      cache.lockData = temp;
      cache.lockDataTimestamp = Date.now();
    } catch (error) {
      console.error(error);
    }
    setLoadLock(false);
  };

  useEffect(() => {
    if (flexInterval.current) clearInterval(flexInterval.current);
    flexInterval.current = setInterval(async function () {
      if (!loadflex) await fetchFlexibleData();
      if (!loadlock) await fetchLockData();
    }, 30000); // 30 seconds interval
  }, [account, balance, price]);

  useEffect(() => {
    if (tokenInterval.current) clearInterval(tokenInterval.current);
    tokenInterval.current = setInterval(async function () {
      if (!loadtoken) await fetchTokenData();
    }, 30000); // 30 seconds interval
  }, [account]);

  return (
    <Router>
      <StyledContainer className="section-background mint sticky">
        <TopBar />

        <Switch>
          <Route exact path="/s">
            <Landing farms={[flexinfo, locks[0], locks[1]]} />
          </Route>
          <Route exact path="/liquidity">
            <Liquidity />
          </Route>
          <Route exact path="/">
            <Redirect to={"/s"} />
          </Route>
        </Switch>
        <Footer
          price={price.price}
          tokenprice={price.farmprice}
          totalStaked={
            (flexinfo.totalStaked ? flexinfo.totalStaked / 1 : 0) +
            (locks[0].totalStaked ? locks[0].totalStaked / 1 : 0) +
            (locks[1].totalStaked ? locks[1].totalStaked / 1 : 0)
          }
        />
      </StyledContainer>
    </Router>
  );
}

const StyledContainer = styled(Box)``;
export default App;
