import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import Web3 from "web3";
import useWindowSize from "../../functions/useWindowSize";
import BackVideo from "./assets/FsnBunny.gif";
import "../genesisStaking/genesisStaking.scss";
import "./../dashboard/main.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./CustomToastStyles.css";
import "./assets/_timepiecebenefits.scss";
import addActive from "./assets/addActive.svg";
import addInactive from "./assets/addInactive.svg";
import subtractActive from "./assets/subtractActive.svg";
import subtractInactive from "./assets/subtractInactive.svg";
import "./assets/_land.scss";
import "./assets/_utilities.scss";
import gifImage from "./fsnbunny.gif";
import MintingTimeline from "./stepsEla";
import "../bridge/bridge.css";
import { useNetwork } from "wagmi";
import { switchNetwork } from "@wagmi/core";
import "./mintcss.scss";
import arrowRight from "./arrow-right.svg"; // Adjust the path according to your project structure

const config = {
  CONTRACT_ADDRESS1: "0x97B1667507C2d2413d5f68A37139697AcA434Af8",
  CONTRACT_ADDRESS2: "0xdb7d64dD0f4A1C09bC490a5f8A932F2600587A63",
  CONTRACT_ADDRESS3: "0xE81E792f0275B907F20d33386CE02CC6231FA70E",
  CONTRACT_ADDRESS4: "0xec768a345adeE8f03886E83F89eD2151a4c1Db0D",
  NETWORK: {
    NAME: "Elastos Smart Chain",
    SYMBOL: "ESC",
    ID: 20,
  },
  NFT_NAME: "APR Boost",
  DISPLAY_COST: 29,
  MAX_SUPPLY: 10000,
};

const ABI = [
  {
    inputs: [
      {
        internalType: "string",
        name: "_initBaseURI",
        type: "string",
      },
      {
        internalType: "string",
        name: "_initNotRevealedUri",
        type: "string",
      },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "approved",
        type: "address",
      },
      {
        indexed: true,
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "Approval",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "operator",
        type: "address",
      },
      {
        indexed: false,
        internalType: "bool",
        name: "approved",
        type: "bool",
      },
    ],
    name: "ApprovalForAll",
    type: "event",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "approve",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "mint",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    inputs: [
      {
        internalType: "bool",
        name: "_state",
        type: "bool",
      },
    ],
    name: "pause",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "reveal",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "safeTransferFrom",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
      {
        internalType: "bytes",
        name: "_data",
        type: "bytes",
      },
    ],
    name: "safeTransferFrom",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "operator",
        type: "address",
      },
      {
        internalType: "bool",
        name: "approved",
        type: "bool",
      },
    ],
    name: "setApprovalForAll",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "_newBaseExtension",
        type: "string",
      },
    ],
    name: "setBaseExtension",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "_newBaseURI",
        type: "string",
      },
    ],
    name: "setBaseURI",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_newCost",
        type: "uint256",
      },
    ],
    name: "setCost",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "_notRevealedURI",
        type: "string",
      },
    ],
    name: "setNotRevealedURI",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        indexed: true,
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "Transfer",
    type: "event",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "transferFrom",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "withdraw",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "owner",
        type: "address",
      },
    ],
    name: "balanceOf",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "baseExtension",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "cost",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "getApproved",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        internalType: "address",
        name: "operator",
        type: "address",
      },
    ],
    name: "isApprovedForAll",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "maxSupply",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "name",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "notRevealedUri",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "ownerOf",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "paused",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_mod",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_seed",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_salt",
        type: "uint256",
      },
    ],
    name: "randomNum",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "revealed",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes4",
        name: "interfaceId",
        type: "bytes4",
      },
    ],
    name: "supportsInterface",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "symbol",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "index",
        type: "uint256",
      },
    ],
    name: "tokenByIndex",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "index",
        type: "uint256",
      },
    ],
    name: "tokenOfOwnerByIndex",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "tokenURI",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "totalSupply",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_owner",
        type: "address",
      },
    ],
    name: "walletOfOwner",
    outputs: [
      {
        internalType: "uint256[]",
        name: "",
        type: "uint256[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];

const getNFTName = (address) => {
  switch (address) {
    case config.CONTRACT_ADDRESS1:
      return "Bunny APR Booster 50%";
    case config.CONTRACT_ADDRESS2:
      return "Carrot APR Booster 50%";
    case config.CONTRACT_ADDRESS3:
      return "Bunny APR Booster 25%";
    case config.CONTRACT_ADDRESS4:
      return "Carrot APR Booster 25%";
    default:
      return "";
  }
};

function Mint2() {
  const [totalSupply, setTotalSupply] = useState(0);
  const [account, setAccount] = useState(null);
  const [web3, setWeb3] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [calculatedPrice, setCalculatedPrice] = useState(config.DISPLAY_COST);
  const [lastMintedAddress, setLastMintedAddress] = useState("");
  const { chain } = useNetwork();
  const fusionMainnetChainId = 20;
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [isVideoModalOpen2, setIsVideoModalOpen2] = useState(false);
  const [isVideoModalOpen3, setIsVideoModalOpen3] = useState(false);
  const [isVideoModalOpen4, setIsVideoModalOpen4] = useState(false);
  const isOwner = true; // Replace this with your actual ownership check

  const showVideoModal = () => setIsVideoModalOpen(true);
  const hideVideoModal = () => setIsVideoModalOpen(false);

  const showVideoModal2 = () => setIsVideoModalOpen2(true);
  const hideVideoModal2 = () => setIsVideoModalOpen2(false);

  const showVideoModal3 = () => setIsVideoModalOpen3(true);
  const hideVideoModal3 = () => setIsVideoModalOpen3(false);

  const showVideoModal4 = () => setIsVideoModalOpen4(true);
  const hideVideoModal4 = () => setIsVideoModalOpen4(false);

  useEffect(() => {
    setCalculatedPrice(config.DISPLAY_COST * quantity);
  }, [quantity]);

  useEffect(() => {
    if (window.ethereum) {
      const web3Instance = new Web3(window.ethereum);
      setWeb3(web3Instance);
      window.ethereum.request({ method: "eth_accounts" }).then((accounts) => {
        if (accounts.length > 0) {
          setAccount(accounts[0]);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (web3) {
      const contractInstance = new web3.eth.Contract(
        ABI,
        config.CONTRACT_ADDRESS1
      );
      contractInstance.methods
        .totalSupply()
        .call()
        .then((supply) => {
          setTotalSupply(parseInt(supply, 10));
        });
    }
  }, [web3]);

  const handleConnectWallet = async () => {
    if (window.ethereum) {
      try {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        setAccount(accounts[0]);
        const web3Instance = new Web3(window.ethereum);
        setWeb3(web3Instance);
      } catch (error) {
        console.error("User rejected the connection request.");
      }
    } else {
      alert("Please install MetaMask!");
    }
  };

  const handleIncrement = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  const handleDecrement = () => {
    setQuantity((prevQuantity) => (prevQuantity > 1 ? prevQuantity - 1 : 1));
  };

  const getRandomInt = (max) => {
    return Math.floor(Math.random() * max);
  };

  const splitQuantityRandomly = (totalQuantity) => {
    const portions = [];
    let remaining = totalQuantity;

    while (remaining > 0) {
      const portion = getRandomInt(remaining) + 1; // Ensure at least 1 is selected
      portions.push(portion);
      remaining -= portion;
    }

    return portions;
  };

  const claimNFTs = async () => {
    if (!account) {
      handleConnectWallet();
      return;
    }

    try {
      const networkId = await web3.eth.net.getId();
      if (networkId !== config.NETWORK.ID) {
        toast.error(
          `Please switch to the ${config.NETWORK.NAME} network in your wallet.`,
          {
            className: "custom-toast error-toast",
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        return;
      }

      const contractAddresses = [
        config.CONTRACT_ADDRESS1,
        config.CONTRACT_ADDRESS2,
        config.CONTRACT_ADDRESS3,
        config.CONTRACT_ADDRESS4,
      ];

      const quantities = splitQuantityRandomly(quantity);

      let totalMinted = 0;
      let latestMintAddress = "";

      for (let i = 0; i < quantities.length; i++) {
        const randomIndex = getRandomInt(contractAddresses.length);
        const selectedAddress = contractAddresses[randomIndex];

        const contractInstance = new web3.eth.Contract(ABI, selectedAddress);
        const mintAmount = quantities[i];
        const value = web3.utils.toWei(
          (config.DISPLAY_COST * mintAmount).toString(),
          "ether"
        );

        const receipt = await contractInstance.methods
          .mint()
          .send({ from: account, value });
        console.log(receipt);

        latestMintAddress = selectedAddress;

        const newTotalSupply = await contractInstance.methods
          .totalSupply()
          .call();
        totalMinted += mintAmount;
      }

      setTotalSupply(totalMinted + totalSupply);
      setLastMintedAddress(latestMintAddress);
      toast.success("Minting successful!");
    } catch (error) {
      console.error("Minting failed", error);
      toast.error("Minting failed. Please try again.");
    }
  };

  const handleSwitchNetwork = async () => {
    try {
      await switchNetwork({ chainId: fusionMainnetChainId });
    } catch (error) {
      console.error("Failed to switch network", error);
    }
  };

  return (
    <>
      <div className="container-lg dashboardwrapper px-0">
        <div className="container-lg p-0">
          <div className="new-packages-grid mb-3">



            <div className="">
              <div>
                <div className="nft-event-package p-2 d-flex align-items-center flex-column gap-2 selected-event-package relative">
                  <video
                    src="https://bunnydomain.mypinata.cloud/ipfs/QmeGiskofxMU5cLEnA97p9XmdMYqmJHCSbBbDnPDT93j96"
                    alt=""
                    className="rounded-md w-full"
                    autoPlay
                    loop
                    playsInline
                    muted
                    onClick={showVideoModal}
                  ></video>
                  <div className="new-upcoming-tag relative d-flex align-items-center justify-content-center px-1">
                    <span className="mb-0">Gold</span>
                  </div>

                  <span className="event-package-title text-sm">
                    50% Bunny APR Boost
                  </span>
                </div>
              </div>
            </div>

            {isVideoModalOpen && (
              <div className="profile-event-popup p-4">
                <button
                  className="flex items-center justify-center text-sm bg-transparent absolute right-3 top-3 text-white p-1 border h-6 w-6 border-white hover:bg-gray-200 rounded-full p-2"
                  onClick={hideVideoModal}
                >
                  &times;
                </button>
                <div className="d-flex align-items-center justify-content-between mb-2">
                  <div className="d-flex align-items-center gap-2">
                    <h6 className="event-popup-title mb-0">
                      GOLD APR BOOST BUNNY
                    </h6>
                    <div className="event-popup-status-live d-flex align-items-center justify-content-center p-1">
                      <div
                        className="pulsatingDot"
                        style={{
                          width: "7px",
                          height: "7px",
                          marginRight: "5px",
                        }}
                      ></div>
                      <span className="mb-0 text-black">Live</span>
                    </div>
                  </div>
                </div>

                <div className="profile-event-popup-wrapper mb-3 p-2 p-lg-3 h-auto">
                  <div className="d-flex flex-column flex-lg-row align-items-start align-items-lg-center justify-content-between">
                    <div className="d-flex gap-2">
                      <video
                        src="https://bunnydomain.mypinata.cloud/ipfs/QmeGiskofxMU5cLEnA97p9XmdMYqmJHCSbBbDnPDT93j96"
                        style={{ width: "auto", height: "100px" }}
                        alt=""
                        className="rounded-md border-white border-1 border"
                        autoPlay
                        loop
                        playsInline
                        muted
                      ></video>
                      <div className="d-flex flex-column justify-content-between">
                        <div className="d-flex flex-column">
                          <h6 className="popup-second-title m-0">BUNNY</h6>
                          <span className="popup-rewards text-left">
                            20K BUNNY in Rewards
                          </span>
                        </div>
                        <div className="d-flex">
                          <span className="event-popup-chain mb-0">
                            Gameplay: Explore &amp; Mine
                          </span>
                        </div>
                        <div className="d-flex">
                          <span className="event-popup-chain mb-0">
                            Chain: Elastos Smart Chain
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="d-flex align-items-center justify-content-between mb-3">
                  <h6 className="how-it-works mb-0">How it works?</h6>
                </div>
                <div className="row mb-3 gap-3 gap-lg-0">
                  <div className="col-12 col-lg-6">
                    <div className="profile-event-popup-wrapper p-3">
                      <h6 className="popup-green-text">Details</h6>
                      <p className="popup-event-desc">
                        To participate in the event, players are required
                        to&nbsp;
                        <b>hold a Gold Bunny APR booster</b>. By engaging in the
                        game on a daily basis and exploring the BUNNY area,
                        players not only stand a chance to secure daily rewards
                        in BUNNY, but also earn ELA! 20% of each mint
                        (equivalent to 5.8 ELA) will be airdropped to a random
                        APR booster holder.
                      </p>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6">
                    <div className="profile-event-popup-wrapper p-3">
                      <h6 className="popup-green-text">Benefits</h6>
                      <ul>
                        <li className="popup-event-desc">
                          Exclusive Event Access
                        </li>
                        <li className="popup-event-desc">Daily Rewards</li>
                        <li className="popup-event-desc">ELA rewards access</li>
                        <li className="popup-event-desc">Earn BUNNY rewards</li>
                        <li className="popup-event-desc">
                          Get APR boost on farm & staking
                        </li>
                        <li className="popup-event-desc">
                          Community Engagement
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="summaryseparator mt-3"></div>

                <div className="d-flex align-items-center gap-2 mt-2">
                   
                  <span className="popup-event-desc">
                    Rewards will be distributed immediately upon minting. The
                    APR boost will be revealed and can be used for staking right
                    away.
                    <a
                      className="events-page-details d-flex align-items-center gap-2"
                      href="/earn/nft-staking"
                    >
                      Start staking
                      <img src={arrowRight} alt="arrow-right" />
                    </a>
                  </span>
                </div>
              </div>
            )}

            <div className="">
              <div>
                <div className="nft-event-package p-2 d-flex align-items-center flex-column gap-2 selected-event-package relative">
                  <video
                    src="https://bunnydomain.mypinata.cloud/ipfs/QmQX2jPXy48vRXGtvJaB32fxJc6Ljw2SwwsSwYef4LDyuw"
                    alt=""
                    className="rounded-md w-full"
                    autoPlay
                    loop
                    playsInline
                    onClick={showVideoModal2}
                    muted
                  ></video>
                  <div className="new-upcoming-tag relative d-flex align-items-center justify-content-center px-1">
                    <span className="mb-0">Gold</span>
                  </div>

                  <span className="event-package-title text-sm">
                    50% Carrot APR Boost
                  </span>
                </div>
              </div>
            </div>

            {isVideoModalOpen2 && (
              <div className="profile-event-popup p-4">
                <button
                  className="flex items-center justify-center text-sm bg-transparent absolute right-3 top-3 text-white p-1 border h-6 w-6 border-white hover:bg-gray-200 rounded-full p-2"
                  onClick={hideVideoModal2}
                >
                  &times;
                </button>
                <div className="d-flex align-items-center justify-content-between mb-2">
                  <div className="d-flex align-items-center gap-2">
                    <h6 className="event-popup-title mb-0">
                      GOLD APR BOOST CARROT
                    </h6>
                    <div className="event-popup-status-live d-flex align-items-center justify-content-center p-1">
                      <div
                        className="pulsatingDot"
                        style={{
                          width: "7px",
                          height: "7px",
                          marginRight: "5px",
                        }}
                      ></div>
                      <span className="mb-0 text-black">Live</span>
                    </div>
                  </div>
                </div>

                <div className="profile-event-popup-wrapper mb-3 p-2 p-lg-3 h-auto">
                  <div className="d-flex flex-column flex-lg-row align-items-start align-items-lg-center justify-content-between">
                    <div className="d-flex gap-2">
                      <video
                        src="https://bunnydomain.mypinata.cloud/ipfs/QmQX2jPXy48vRXGtvJaB32fxJc6Ljw2SwwsSwYef4LDyuw"
                        style={{ width: "auto", height: "100px" }}
                        alt=""
                        className="rounded-md border-white border-1 border"
                        autoPlay
                        loop
                        playsInline
                        muted
                      ></video>
                      <div className="d-flex flex-column justify-content-between">
                        <div className="d-flex flex-column">
                          <h6 className="popup-second-title m-0">CARROT</h6>
                          <span className="popup-rewards text-left">
                            1,2M CARROT in Rewards
                          </span>
                        </div>
                        <div className="d-flex">
                          <span className="event-popup-chain mb-0">
                            Gameplay: Explore &amp; Mine
                          </span>
                        </div>
                        <div className="d-flex">
                          <span className="event-popup-chain mb-0">
                            Chain: Elastos Smart Chain
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="d-flex align-items-center justify-content-between mb-3">
                  <h6 className="how-it-works mb-0">How it works?</h6>
                </div>
                <div className="row mb-3 gap-3 gap-lg-0">
                  <div className="col-12 col-lg-6">
                    <div className="profile-event-popup-wrapper p-3">
                      <h6 className="popup-green-text">Details</h6>
                      <p className="popup-event-desc">
                        To participate in the event, players are required
                        to&nbsp;
                        <b>hold a Gold CARROT APR booster</b>. By engaging in
                        the game on a daily basis and exploring the CARROT area,
                        players not only stand a chance to secure daily rewards
                        in CARROT, but also earn ELA! 20% of each mint
                        (equivalent to 5.8 ELA) will be airdropped to a random
                        APR booster holder.
                      </p>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6">
                    <div className="profile-event-popup-wrapper p-3">
                      <h6 className="popup-green-text">Benefits</h6>
                      <ul>
                        <li className="popup-event-desc">
                          Exclusive Event Access
                        </li>
                        <li className="popup-event-desc">Daily Rewards</li>
                        <li className="popup-event-desc">ELA rewards access</li>
                        <li className="popup-event-desc">
                          Earn CARROT rewards
                        </li>
                        <li className="popup-event-desc">
                          Get APR boost on farm & staking
                        </li>
                        <li className="popup-event-desc">
                          Community Engagement
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="summaryseparator mt-3"></div>

                <div className="d-flex align-items-center gap-2 mt-2">
                  <span className="popup-event-desc">
                    Rewards will be distributed immediately upon minting. The
                    APR boost will be revealed and can be used for staking right
                    away.{" "}
                    <a
                      className="events-page-details d-flex align-items-center gap-2"
                      href="/earn/nft-staking"
                    >
                      Start staking
                      <img src={arrowRight} alt="arrow-right" />
                    </a>
                  </span>
                </div>
              </div>
            )}

            <div className="">
              <div>
                <div className="nft-event-package p-2 d-flex align-items-center flex-column gap-2 selected-event-package relative">
                  <video
                    src="https://bunnydomain.mypinata.cloud/ipfs/QmNMFuR2HoLtPWA6Qi9qGMXW9D5rAecFjUH73Dg9teQbLC"
                    alt=""
                    className="rounded-md w-full"
                    autoPlay
                    loop
                    playsInline
                    onClick={showVideoModal3}

                    muted
                  ></video>
                  <div className="new-upcoming-tag relative d-flex align-items-center justify-content-center px-1">
                    <span className="mb-0">Silver</span>
                  </div>

                  <span className="event-package-title text-sm">
                    25% Bunny APR Boost
                  </span>
                </div>
              </div>
            </div>


           {isVideoModalOpen3 && (
              <div className="profile-event-popup p-4">
                <button
                  className="flex items-center justify-center text-sm bg-transparent absolute right-3 top-3 text-white p-1 border h-6 w-6 border-white hover:bg-gray-200 rounded-full p-2"
                  onClick={hideVideoModal3}
                >
                  &times;
                </button>
                <div className="d-flex align-items-center justify-content-between mb-2">
                  <div className="d-flex align-items-center gap-2">
                    <h6 className="event-popup-title mb-0">
                      SILVER APR BOOST BUNNY
                    </h6>
                    <div className="event-popup-status-live d-flex align-items-center justify-content-center p-1">
                      <div
                        className="pulsatingDot"
                        style={{
                          width: "7px",
                          height: "7px",
                          marginRight: "5px",
                        }}
                      ></div>
                      <span className="mb-0 text-black">Live</span>
                    </div>
                  </div>
                </div>

                <div className="profile-event-popup-wrapper mb-3 p-2 p-lg-3 h-auto">
                  <div className="d-flex flex-column flex-lg-row align-items-start align-items-lg-center justify-content-between">
                    <div className="d-flex gap-2">
                      <video
                        src="https://bunnydomain.mypinata.cloud/ipfs/QmNMFuR2HoLtPWA6Qi9qGMXW9D5rAecFjUH73Dg9teQbLC"
                        style={{ width: "auto", height: "100px" }}
                        alt=""
                        className="rounded-md border-white border-1 border"
                        autoPlay
                        loop
                        playsInline
                        muted
                      ></video>
                      <div className="d-flex flex-column justify-content-between">
                        <div className="d-flex flex-column">
                          <h6 className="popup-second-title m-0">BUNNY</h6>
                          <span className="popup-rewards text-left">
                            10K BUNNY in Rewards
                          </span>
                        </div>
                        <div className="d-flex">
                          <span className="event-popup-chain mb-0">
                            Gameplay: Explore &amp; Mine
                          </span>
                        </div>
                        <div className="d-flex">
                          <span className="event-popup-chain mb-0">
                            Chain: Elastos Smart Chain
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="d-flex align-items-center justify-content-between mb-3">
                  <h6 className="how-it-works mb-0">How it works?</h6>
                </div>
                <div className="row mb-3 gap-3 gap-lg-0">
                  <div className="col-12 col-lg-6">
                    <div className="profile-event-popup-wrapper p-3">
                      <h6 className="popup-green-text">Details</h6>
                      <p className="popup-event-desc">
                        To participate in the event, players are required
                        to&nbsp;
                        <b>hold a Silver Bunny APR booster</b>. By engaging in the
                        game on a daily basis and exploring the BUNNY area,
                        players not only stand a chance to secure daily rewards
                        in BUNNY, but also earn ELA! 20% of each mint
                        (equivalent to 5.8 ELA) will be airdropped to a random
                        APR booster holder.
                      </p>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6">
                    <div className="profile-event-popup-wrapper p-3">
                      <h6 className="popup-green-text">Benefits</h6>
                      <ul>
                        <li className="popup-event-desc">
                          Exclusive Event Access
                        </li>
                        <li className="popup-event-desc">Daily Rewards</li>
                        <li className="popup-event-desc">ELA rewards access</li>
                        <li className="popup-event-desc">Earn BUNNY rewards</li>
                        <li className="popup-event-desc">
                          Get APR boost on farm & staking
                        </li>
                        <li className="popup-event-desc">
                          Community Engagement
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="summaryseparator mt-3"></div>

                <div className="d-flex align-items-center gap-2 mt-2">
                  
                  <span className="popup-event-desc">
                    Rewards will be distributed immediately upon minting. The
                    APR boost will be revealed and can be used for staking right
                    away.
                    <a
                      className="events-page-details d-flex align-items-center gap-2"
                      href="/earn/nft-staking"
                    >
                      Start staking
                      <img src={arrowRight} alt="arrow-right" />
                    </a>
                  </span>
                </div>
              </div>
            )}


            <div className="">
              <div>
                <div className="nft-event-package p-2 d-flex align-items-center flex-column gap-2 selected-event-package relative">
                  <video
                    src="https://bunnydomain.mypinata.cloud/ipfs/QmXQeZcHXjtu8N3xDAAcKXGsdzcAoqujyX8M9ggXUpizek"
                    alt=""
                    className="rounded-md w-full"
                    autoPlay
                    loop
                    onClick={showVideoModal4}
                    playsInline
                    muted
                  ></video>
                  <div className="new-upcoming-tag relative d-flex align-items-center justify-content-center px-1">
                    <span className="mb-0">Silver</span>
                  </div>

                  <span className="event-package-title text-sm">
                    25% Carrot APR Boost
                  </span>
                </div>
              </div>
            </div>



            {isVideoModalOpen4 && (
              <div className="profile-event-popup p-4">
                <button
                  className="flex items-center justify-center text-sm bg-transparent absolute right-3 top-3 text-white p-1 border h-6 w-6 border-white hover:bg-gray-200 rounded-full p-2"
                  onClick={hideVideoModal4}
                >
                  &times;
                </button>
                <div className="d-flex align-items-center justify-content-between mb-2">
                  <div className="d-flex align-items-center gap-2">
                    <h6 className="event-popup-title mb-0">
                      SILVER APR BOOST CARROT
                    </h6>
                    <div className="event-popup-status-live d-flex align-items-center justify-content-center p-1">
                      <div
                        className="pulsatingDot"
                        style={{
                          width: "7px",
                          height: "7px",
                          marginRight: "5px",
                        }}
                      ></div>
                      <span className="mb-0 text-black">Live</span>
                    </div>
                  </div>
                </div>

                <div className="profile-event-popup-wrapper mb-3 p-2 p-lg-3 h-auto">
                  <div className="d-flex flex-column flex-lg-row align-items-start align-items-lg-center justify-content-between">
                    <div className="d-flex gap-2">
                      <video
                        src="https://bunnydomain.mypinata.cloud/ipfs/QmXQeZcHXjtu8N3xDAAcKXGsdzcAoqujyX8M9ggXUpizek"
                        style={{ width: "auto", height: "100px" }}
                        alt=""
                        className="rounded-md border-white border-1 border"
                        autoPlay
                        loop
                        playsInline
                        muted
                      ></video>
                      <div className="d-flex flex-column justify-content-between">
                        <div className="d-flex flex-column">
                          <h6 className="popup-second-title m-0">CARROT</h6>
                          <span className="popup-rewards text-left">
                            600K CARROT in Rewards
                          </span>
                        </div>
                        <div className="d-flex">
                          <span className="event-popup-chain mb-0">
                            Gameplay: Explore &amp; Mine
                          </span>
                        </div>
                        <div className="d-flex">
                          <span className="event-popup-chain mb-0">
                            Chain: Elastos Smart Chain
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="d-flex align-items-center justify-content-between mb-3">
                  <h6 className="how-it-works mb-0">How it works?</h6>
                </div>
                <div className="row mb-3 gap-3 gap-lg-0">
                  <div className="col-12 col-lg-6">
                    <div className="profile-event-popup-wrapper p-3">
                      <h6 className="popup-green-text">Details</h6>
                      <p className="popup-event-desc">
                        To participate in the event, players are required
                        to&nbsp;
                        <b>hold a Silver CARROT APR booster</b>. By engaging in
                        the game on a daily basis and exploring the CARROT area,
                        players not only stand a chance to secure daily rewards
                        in CARROT, but also earn ELA! 20% of each mint
                        (equivalent to 5.8 ELA) will be airdropped to a random
                        APR booster holder.
                      </p>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6">
                    <div className="profile-event-popup-wrapper p-3">
                      <h6 className="popup-green-text">Benefits</h6>
                      <ul>
                        <li className="popup-event-desc">
                          Exclusive Event Access
                        </li>
                        <li className="popup-event-desc">Daily Rewards</li>
                        <li className="popup-event-desc">ELA rewards access</li>
                        <li className="popup-event-desc">
                          Earn CARROT rewards
                        </li>
                        <li className="popup-event-desc">
                          Get APR boost on farm & staking
                        </li>
                        <li className="popup-event-desc">
                          Community Engagement
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="summaryseparator mt-3"></div>

                <div className="d-flex align-items-center gap-2 mt-2">
                  <span className="popup-event-desc">
                    Rewards will be distributed immediately upon minting. The
                    APR boost will be revealed and can be used for staking right
                    away.{" "}
                    <a
                      className="events-page-details d-flex align-items-center gap-2"
                      href="/earn/nft-staking"
                    >
                      Start staking
                      <img src={arrowRight} alt="arrow-right" />
                    </a>
                  </span>
                </div>
              </div>
            )}



          </div>

          <div className="row justify-content-center align-items-center w-100 mx-0 px-3 py-3 p-lg-0 gap-5 gap-lg-0 flex flex-col-reverse md:flex-row">
            <div className="col-12 col-md-12 col-xxl-3 ps-2 ps-lg-0 staking-height-2">
              <div className="d-flex flex-column gap-3 justify-content-between staking-height-2">
                <div className="d-flex flex-column position-relative">
                  <MintingTimeline />
                  <div
                    className="genesis-desc position-relative"
                    style={{ bottom: "5px" }}
                  >
                    <h6 className="font-organetto land-desc w-75">
                      Up to 50% {config.NFT_NAME}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-12 col-md-12 col-xxl-5 mt-0 px-0"
              style={{ overflowX: "hidden" }}
            >
              <div className="p-3 mint-wrappernew market-mint-bg w-100 m-0 d-flex flex-column gap-5 justify-content-start staking-height">
                <h6 className="marketmintnewtitle position-relative">
                  Mint your {config.NFT_NAME}er
                  <span className="marketmintnewtitle-marked mx-2">now!</span>
                </h6>
                <div className="d-flex flex-column gap-2 pt-xxl-0 pt-lg-0 col-12 col-md-9 col-lg-7 justify-content-between align-items-start position-relative w-full">
                  <div className="mint-benefits-grid">
                    <div className="col-span-2 d-flex align-items-center gap-2 mb-4">
                      <img src={require(`./check.svg`).default} alt="" />
                      <span className="status-desc md:text-sm">
                        <span
                          className="addr-text ml-1 font-bold"
                          style={{ color: "rgb(123, 216, 176)" }}
                        >
                          20% of each mint
                        </span>{" "}
                        (equivalent to{" "}
                        <span
                          className="addr-text ml-1 font-bold"
                          style={{ color: "rgb(123, 216, 176)" }}
                        >
                          5.8 ELA
                        </span>
                        ) will be airdropped to a random APR booster holder.
                      </span>
                    </div>

                    <div className=" d-flex align-items-center gap-2 mb-4">
                      <img src={require(`./check.svg`).default} alt="" />
                      <span className="status-desc md:text-sm">
                        {" "}
                        <span
                          className="addr-text ml-1 font-bold"
                          style={{ color: "rgb(123, 216, 176)" }}
                        >
                          50% APR
                        </span>{" "}
                        Bunny pool
                      </span>
                    </div>
                    <div className="d-flex align-items-center gap-2 mb-4">
                      <img src={require(`./check.svg`).default} alt="" />
                      <span className="status-desc md:text-sm">
                        <span
                          className="addr-text ml-1 font-bold"
                          style={{ color: "rgb(123, 216, 176)" }}
                        >
                          50% APR
                        </span>{" "}
                        Carrot pool
                      </span>
                    </div>
                    <div className="d-flex align-items-center gap-2 mb-4">
                      <img src={require(`./check.svg`).default} alt="" />
                      <span className="status-desc md:text-sm">
                        <span
                          className="addr-text ml-1 font-bold"
                          style={{ color: "rgb(123, 216, 176)" }}
                        >
                          25% APR
                        </span>{" "}
                        Bunny pool
                      </span>
                    </div>
                    <div className="d-flex align-items-center gap-2 mb-4">
                      <img src={require(`./check.svg`).default} alt="" />
                      <span className="status-desc md:text-sm">
                        <span
                          className="addr-text ml-1 font-bold"
                          style={{ color: "rgb(123, 216, 176)" }}
                        >
                          25% APR
                        </span>{" "}
                        Carrot pool
                      </span>
                    </div>

                    <div className="col-span-2 sm:col-span-1 d-flex align-items-center gap-2 mb-4">
                      <img src={require(`./check.svg`).default} alt="" />
                      <span className="status-desc md:text-sm">
                        Percentage of your APR is assigned randomly
                      </span>
                    </div>

                    <div className="col-span-2 sm:col-span-1 d-flex align-items-center gap-2 mb-4">
                      <img src={require(`./check.svg`).default} alt="" />
                      <span className="status-desc md:text-sm">
                        Active in farming and staking pools
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-12 col-xxl-4 mt-0 px-0 px-lg-2">
              <div className="p-3 mint-wrappernew d-flex flex-column justify-content-between staking-height gap-2">
                <div className="d-flex mt-0 flex-column flex-lg-row align-items-start gap-2 justify-content-center justify-content-xxl-between mb-2 justify-content-lg-between justify-content-md-between">
                  <div className="d-flex flex-column gap-2 col-12 col-lg-6">
                    <span className="land-name">Name</span>
                    <div
                      className="borderText borderText2"
                      style={{ width: "100%" }}
                    >
                      <h6
                        className="land-placeholder mb-0"
                        style={{ marginLeft: "11px" }}
                      >
                        {config.NFT_NAME}
                      </h6>
                    </div>
                  </div>
  
                </div>
                {/* <hr className="mint-divider m-0" />
              <div className="d-flex align-items-center justify-content-between position-relative gap-3">
                <div className="input-container position-relative col-8 col-lg-6">
                  <input
                    type="number"
                    placeholder="Nr. of NFTs to create"
                    max={config.MAX_SUPPLY - totalSupply}
                    min={1}
                    className="land-input w-100"
                    value={quantity}
                    onChange={(e) => setQuantity(parseInt(e.target.value))}
                  />
                </div>
                <div className="d-flex align-items-center gap-3">
                  <img
                    src={quantity > 1 ? subtractActive : subtractInactive}
                    alt="subtract"
                    onClick={handleDecrement}
                    style={{
                      cursor: quantity > 1 ? "pointer" : "default",
                      pointerEvents: quantity > 1 ? "auto" : "none",
                    }}
                  />
                  <img
                    src={quantity < config.MAX_SUPPLY - totalSupply ? addActive : addInactive}
                    alt="add"
                    onClick={handleIncrement}
                    style={{
                      cursor: quantity < config.MAX_SUPPLY - totalSupply ? "pointer" : "default",
                      pointerEvents: quantity < config.MAX_SUPPLY - totalSupply ? "auto" : "none",
                    }}
                  />
                </div>
              </div> */}

                <hr className="mint-divider m-0" />

                <div className="row flex-column flex-xxl-row flex-xl-row flex-lg-row flex-md-row flex-sm-row gap-1 align-items-center justify-content-between">
                  <div className="d-flex justify-content-between gap-2 position-relative flex-column flex-xxl-row flex-lg-row flex-md-row">
                    <span className="land-name">
                      <span
                        className="addr-text font-bold mr-1"
                        style={{ color: "rgb(123, 216, 176)" }}
                      >
                        {" "}
                        {quantity} {" "}
                      </span>
                      APR Booster costs
                      <span
                        className="addr-text ml-1 font-bold"
                        style={{ color: "rgb(123, 216, 176)" }}
                      >
                        {calculatedPrice} ELA
                      </span>
                    </span>
                  </div>
                </div>

                <hr className="mint-divider m-0" />

                <div className="row flex-column flex-xxl-row flex-xl-row flex-lg-row flex-md-row flex-sm-row gap-1 align-items-center justify-content-between">
                  <div className="d-flex justify-content-between gap-2 position-relative flex-column flex-xxl-row flex-lg-row flex-md-row">
                    <span className="land-name">
                      On each mint{" "}
                      <span
                        className="addr-text ml-1 font-bold"
                        style={{ color: "rgb(123, 216, 176)" }}
                      >
                        5.8 ELA
                      </span>{" "}
                      will be{" "}
                      <span
                        className="addr-text ml-1 font-bold"
                        style={{ color: "rgb(123, 216, 176)" }}
                      >
                        Airdropped
                      </span>{" "}
                      to a random holder
                    </span>
                  </div>
                </div>

                <hr className="mint-divider m-0" />
                <div className="row flex-column flex-xxl-row flex-xl-row flex-lg-row flex-md-row flex-sm-row gap-1 align-items-center justify-content-between">
                  <div className="d-flex justify-content-between gap-2 position-relative flex-column flex-xxl-row flex-lg-row flex-md-row">
                    <span className="land-name">
                    <span
                        className="addr-text ml-1 font-bold"
                        style={{ color: "rgb(123, 216, 176)" }}
                      >Rewards</span> and  <span
                      className="addr-text ml-1 font-bold"
                      style={{ color: "rgb(123, 216, 176)" }}
                    >APR boost</span> available immediately upon minting
                    </span>
                  </div>
                </div>

                <hr className="mint-divider m-0" />

                <div className="d-flex flex-column flex-lg-row gap-3 align-items-center justify-content-between">
                  <div className="d-flex flex-column flex-lg-row align-items-center align-items-lg-center justify-content-xxl-end justify-content-lg-end justify-content-center w-100">
                    <div className="w-100 flex-lg-row gap-3 align-items-center justify-content-center">
                      {chain?.id !== fusionMainnetChainId ? (
                        <div className="linear-border">
                          <button
                            className="btn filled-btn2 px-4 w-100 font-semibold mt-2"
                            onClick={handleSwitchNetwork}
                          >
                            Switch to Elastos Smart Chain
                          </button>
                        </div>
                      ) : (
                        <div className="linear-border mt-2">
                          <button
                            className="btn filled-btn2 px-4 w-100 font-semibold"
                            onClick={claimNFTs}
                          >
                           Reserve My Spot
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Mint2;
