import React, { useState, useEffect } from "react";
import { useNetwork } from "wagmi";
import getFormattedNumber from "../../../functions/getFormattedNumber2";
import useWindowSize from "../../../functions/useWindowSize";
import TopOtherPoolsNftCard from "../TopOtherPoolsNftCard";
import NftStaking from "../../FARMINNG/simple";
import NftStaking2 from "../../FARMINNG/simple2";
import NftStaking3 from "../../FARMINNG/simple3";
import NftStaking4 from "../../FARMINNG/simple4";
import NftStaking5 from "../../FARMINNG/simple5";
import NftStaking6 from "../../FARMINNG/simple6";
import NftStaking7 from "../../FARMINNG/simple7";
import NftStaking8 from "../../FARMINNG/simple8";
import ChainTabs from "../../FARMINNG/TabsChain";

const saveSelectedChain = (chain) => {
  localStorage.setItem("selectedChain", chain);
};

const loadSelectedChain = () => {
  return localStorage.getItem("selectedChain") || "fsn";
};

const EarnOtherContentNft = ({
  coinbase,
  poolClickedType,
  poolClicked,
  chainId,
  isConnected,
  handleConnection,
  routeOption,
  handleSwitchNetwork,
  isPremium,
  onCloseCard,
  totalTvl,
}) => {
  const { chain } = useNetwork();
  const [option, setOption] = useState(routeOption);
  const [listStyle, setListStyle] = useState("list");
  const [myStakes, setMyStakes] = useState(false);
  const [expiredPools, setExpiredPools] = useState(false);
  const [count, setCount] = useState(0);
  const [clickedCawsPool, setClickedCawsPool] = useState(false);
  const [modal1Visible, setModal1Visible] = useState(false);
  const [modal2Visible, setModal2Visible] = useState(false);
  const [modal3Visible, setModal3Visible] = useState(false);
  const [modal4Visible, setModal4Visible] = useState(false);
  const [modal5Visible, setModal5Visible] = useState(false);
  const [modal6Visible, setModal6Visible] = useState(false);
  const [modal7Visible, setModal7Visible] = useState(false);
  const [modal8Visible, setModal8Visible] = useState(false);
  const [selectedPool, setSelectedPool] = useState(null);
  const [selectedChain, setSelectedChain] = useState(loadSelectedChain());

  const toggleInactive = () => {
    setCount(count + 1);
    setExpiredPools(!expiredPools);
    if (option === "Farming" && count % 2 === 0) {
    }
  };

  const windowSize = useWindowSize();

  useEffect(() => {
    if (
      poolClickedType === "details-nft" &&
      poolClicked === true &&
      clickedCawsPool === false
    ) {
      setMyStakes(false);
      setExpiredPools(false);
      setClickedCawsPool(true);
    }
  }, [poolClickedType, poolClicked, clickedCawsPool]);

  const openModal1 = (pool) => {
    setSelectedPool(pool);
    setModal1Visible(true);
  };

  const closeModal1 = () => {
    setSelectedPool(null);
    setModal1Visible(false);
  };  
 

  const openModal8 = (pool) => {
    setSelectedPool(pool);
    setModal8Visible(true);
  };

  const closeModal8 = () => {
    setSelectedPool(null);
    setModal8Visible(false);
  };  
 

  const closeModal5 = () => {
    setSelectedPool(null);
    setModal5Visible(false);
  };  
  const closeModal7 = () => {
    setSelectedPool(null);
    setModal7Visible(false);
  };  
  const closeModal6 = () => {
    setSelectedPool(null);
    setModal6Visible(false);
  };  

  const openModal2 = (pool) => {
    setSelectedPool(pool);
    setModal2Visible(true);
  };

  const openModal3 = (pool) => {
    setSelectedPool(pool);
    setModal3Visible(true);
  };  
  const openModal4 = (pool) => {
    setSelectedPool(pool);
    setModal4Visible(true);

  }; 
  
  const openModal5 = (pool) => {
    setSelectedPool(pool);
    setModal5Visible(true);
  };    
  const openModal7 = (pool) => {
    setSelectedPool(pool);
    setModal7Visible(true);
  };  
  const openModal6 = (pool) => {
    setSelectedPool(pool);
    setModal6Visible(true);
  };

  const closeModal2 = () => {
    setSelectedPool(null);
    setModal2Visible(false);
  };  
  const closeModal4 = () => {
    setSelectedPool(null);
    setModal4Visible(false);
  };

  const closeModal3 = () => {
    setSelectedPool(null);
    setModal3Visible(false);
  };

  const handleChainSelect = (chain) => {
    setSelectedChain(chain);
    saveSelectedChain(chain);
    // Fetch the relevant data based on the selected chain
  };

  useEffect(() => {
    if (chain) {
      setSelectedChain(chain.id);
      saveSelectedChain(chain.id);
    }
  }, [chain]);


  return (
    <>
      <div className="row justify-content-center w-100 mx-0">
        <ChainTabs onChainSelect={handleChainSelect} />
        {windowSize.width > 786 ? (
          <div
            className="row justify-content-between align-items-center p-2 options-container bg-transparent"
            style={{ marginTop: "30px" }}
          >
            <div className="col-12 col-lg-3 px-0">
              <div className="total-value-locked-container p-2 d-flex justify-content-between align-items-center">
                <span style={{ fontWeight: "300", fontSize: "13px" }}>
                  Total value locked
                </span>
                <h6
                  className="ml-1 text-white blurrypool"
                  style={{ fontWeight: "600", fontSize: "17px" }}
                >
                  ${getFormattedNumber(totalTvl, 0)}
                </h6>
              </div>
            </div>

            <div className="col-lg-2 d-flex justify-content-end align-items-center gap-1 gap-lg-3 px-0">
              <h5 className="text-white inactive-pools">Past pools</h5>
              <div
                className={`pill-box ${myStakes && "pill-box-active"}`}
                onClick={() => {
                  setMyStakes(!myStakes);
                  toggleInactive();
                }}
              >
                <div className="pill"></div>
              </div>
            </div>
          </div>
        ) : (
          <div
            className="d-flex gap-3 justify-content-between flex-row-reverse align-items-center p-2 options-container"
            style={{ marginTop: "24px", marginBottom: "24px" }}
          >
            <div className="col-lg-2 d-flex justify-content-end align-items-center gap-1 gap-lg-3">
              <h5 className="text-white inactive-pools">Past pools</h5>
              <div
                className={`pill-box ${myStakes && "pill-box-active"}`}
                onClick={() => {
                  setMyStakes(!myStakes);
                  toggleInactive();
                }}
              >
                <div className="pill"></div>
              </div>
            </div>
            <div className="col-lg-4 col-xl-3 px-0">
              <div className="total-value-locked-container p-2 d-flex justify-content-between align-items-center">
                <span
                  className=""
                  style={{ fontWeight: "300", fontSize: "13px" }}
                >
                  Total value locked
                </span>
                <h6
                  className="ml-1 text-white blurrypool"
                  style={{ fontWeight: "600", fontSize: "17px" }}
                >
                  ${getFormattedNumber(totalTvl, 0)}
                </h6>
              </div>
            </div>
          </div>
        )}
      </div>

      {selectedChain === 20 ? (
        <div className="w-100 otherpools-wrapper">
          {!expiredPools && (
            <>
              <div onClick={() => openModal1("Bunny Punk - CARROT")}>
                <TopOtherPoolsNftCard
                  lockTime="No lock"
                  chain="Carrot"
                  apr={25}
                  tokenLogo={["carrot.webp"]}
                  expired={false}
                  top_pick={false}
                  tokenName="Bunny Punk"
                  tokenTicker="CARROT"
                  chainId={chainId}
                  handleConnection={handleConnection}
                  handleSwitchNetwork={handleSwitchNetwork}
                  isConnected={isConnected}
                  coinbase={coinbase}
                  isNewPool={true}
                  isPremium={isPremium}
                  clickedCawsPool={clickedCawsPool}
                  onCloseCard={() => {
                    onCloseCard();
                    setClickedCawsPool(false);
                  }}
                />
              </div>
              <div onClick={() => openModal2("Bunny Punk - BUNNY")}>
                <TopOtherPoolsNftCard
                  lockTime="No lock"
                  chain="Bunny"
                  apr={25}
                  tokenLogo={["bpicon.webp"]}
                  expired={false}
                  top_pick={false}
                  tokenName="Bunny Punk"
                  tokenTicker="BUNNY"
                  chainId={chainId}
                  handleConnection={handleConnection}
                  handleSwitchNetwork={handleSwitchNetwork}
                  isConnected={isConnected}
                  coinbase={coinbase}
                  isNewPool={true}
                  isPremium={isPremium}
                  clickedCawsPool={clickedCawsPool}
                  onCloseCard={() => {
                    onCloseCard();
                    setClickedCawsPool(false);
                  }}
                />
              </div> 
            

              <div onClick={() => openModal5("APR BOOST - BUNNY GOLD")}>
                <TopOtherPoolsNftCard
                  lockTime="No lock"
                  chain="Bunny"
                  apr={50}
                  tokenLogo={["gold.png"]}
                  expired={false}
                  top_pick={false}
                  tokenName="Gold APR Boost"
                  tokenTicker="BUNNY"
                  chainId={chainId}
                  handleConnection={handleConnection}
                  handleSwitchNetwork={handleSwitchNetwork}
                  isConnected={isConnected}
                  coinbase={coinbase}
                  isNewPool={true}
                  isPremium={isPremium}
                  clickedCawsPool={clickedCawsPool}
                  onCloseCard={() => {
                    onCloseCard();
                    setClickedCawsPool(false);
                  }}
                />
              </div>  
              <div onClick={() => openModal6("APR BOOST - Carrot GOLD")}>
                <TopOtherPoolsNftCard
                  lockTime="No lock"
                  chain="Carrot"
                  apr={50}
                  tokenLogo={["gold.png"]}
                  expired={false}
                  top_pick={false}
                  tokenName="Gold APR Boost"
                  tokenTicker="CARROT"
                  chainId={chainId}
                  handleConnection={handleConnection}
                  handleSwitchNetwork={handleSwitchNetwork}
                  isConnected={isConnected}
                  coinbase={coinbase}
                  isNewPool={true}
                  isPremium={isPremium}
                  clickedCawsPool={clickedCawsPool}
                  onCloseCard={() => {
                    onCloseCard();
                    setClickedCawsPool(false);
                  }}
                />
              </div>  



              <div onClick={() => openModal7("APR BOOST - BUNNY SILVER")}>
                <TopOtherPoolsNftCard
                  lockTime="No lock"
                  chain="Bunny"
                  apr={25}
                  tokenLogo={["silver.png"]}
                  expired={false}
                  top_pick={false}
                  tokenName="Silver APR Boost"
                  tokenTicker="BUNNY"
                  chainId={chainId}
                  handleConnection={handleConnection}
                  handleSwitchNetwork={handleSwitchNetwork}
                  isConnected={isConnected}
                  coinbase={coinbase}
                  isNewPool={true}
                  isPremium={isPremium}
                  clickedCawsPool={clickedCawsPool}
                  onCloseCard={() => {
                    onCloseCard();
                    setClickedCawsPool(false);
                  }}
                />
              </div>  


              <div onClick={() => openModal8("APR BOOST - CARROT SILVER")}>
                <TopOtherPoolsNftCard
                  lockTime="No lock"
                  chain="Carrot"
                  apr={25}
                  tokenLogo={["silver.png"]}
                  expired={false}
                  top_pick={false}
                  tokenName="Silver APR Boost"
                  tokenTicker="CARROT"
                  chainId={chainId}
                  handleConnection={handleConnection}
                  handleSwitchNetwork={handleSwitchNetwork}
                  isConnected={isConnected}
                  coinbase={coinbase}
                  isNewPool={true}
                  isPremium={isPremium}
                  clickedCawsPool={clickedCawsPool}
                  onCloseCard={() => {
                    onCloseCard();
                    setClickedCawsPool(false);
                  }}
                />
              </div>  










              <div className="blurrypool">  
                <TopOtherPoolsNftCard
                  lockTime="No lock"
                  chain="Wela"
                  apr={75}
                  tokenLogo={["esc.svg"]}
                  expired={false}
                  top_pick={false}
                  tokenName="APR Jackpot"
                  tokenTicker="BUNNY"
                  chainId={chainId}
                  handleConnection={handleConnection}
                  handleSwitchNetwork={handleSwitchNetwork}
                  isConnected={isConnected}
                  coinbase={coinbase}
                  isNewPool={true}
                  isPremium={isPremium}
                  clickedCawsPool={clickedCawsPool}
                  onCloseCard={() => {
                    onCloseCard();
                    setClickedCawsPool(false);
                  }}
                />
              </div>  
            

            

         

              <div className="blurrypool">
                <TopOtherPoolsNftCard
                  lockTime="No lock"
                  chain="Bunny"
                  apr={25}
                  tokenLogo={["mrsbunny.png"]}
                  expired={false}
                  top_pick={false}
                  tokenName="Mrs Bunny"
                  tokenTicker="BUNNY"
                  chainId={chainId}
                  handleConnection={handleConnection}
                  handleSwitchNetwork={handleSwitchNetwork}
                  isConnected={isConnected}
                  coinbase={coinbase}
                  isNewPool={true}
                  isPremium={isPremium}
                  clickedCawsPool={clickedCawsPool}
                  onCloseCard={() => {
                    onCloseCard();
                    setClickedCawsPool(false);
                  }}
                />
              </div>
            </>
          )}
        </div>
      ) : selectedChain === 32659 ? (
        <div className="w-100 otherpools-wrapper">
          {!expiredPools && (
            <>
              <div onClick={() => openModal3("Bunny Punk - BUNNY")}>
                <TopOtherPoolsNftCard
                  lockTime="No lock"
                  chain="Bunny"
                  apr={25}
                  tokenLogo={["bpicon.webp"]}
                  expired={false}
                  top_pick={false}
                  tokenName="BP X FUSION"
                  tokenTicker="BUNNY"
                  chainId={chainId}
                  handleConnection={handleConnection}
                  handleSwitchNetwork={handleSwitchNetwork}
                  isConnected={isConnected}
                  coinbase={coinbase}
                  isNewPool={true}
                  isPremium={isPremium}
                  clickedCawsPool={clickedCawsPool}
                  onCloseCard={() => {
                    onCloseCard();
                    setClickedCawsPool(false);
                  }}
                />
              </div>
            </>
          )}
        </div>
      ) : (
        <div className="w-100 otherpools-wrapper blurrypool">
          {!expiredPools && (
            <>
              <div onClick={() => openModal1("Bunny Punk - CARROT")}>
                <TopOtherPoolsNftCard
                  lockTime="No lock"
                  chain="Carrot"
                  apr={25}
                  tokenLogo={["carrot.webp"]}
                  expired={false}
                  top_pick={false}
                  tokenName="Bunny Punk"
                  tokenTicker="CARROT"
                  chainId={chainId}
                  handleConnection={handleConnection}
                  handleSwitchNetwork={handleSwitchNetwork}
                  isConnected={isConnected}
                  coinbase={coinbase}
                  isNewPool={true}
                  isPremium={isPremium}
                  clickedCawsPool={clickedCawsPool}
                  onCloseCard={() => {
                    onCloseCard();
                    setClickedCawsPool(false);
                  }}
                />
              </div>
              <div onClick={() => openModal2("Bunny Punk - BUNNY")}>
                <TopOtherPoolsNftCard
                  lockTime="No lock"
                  chain="Bunny"
                  apr={25}
                  tokenLogo={["bpicon.webp"]}
                  expired={false}
                  top_pick={false}
                  tokenName="Bunny Punk"
                  tokenTicker="BUNNY"
                  chainId={chainId}
                  handleConnection={handleConnection}
                  handleSwitchNetwork={handleSwitchNetwork}
                  isConnected={isConnected}
                  coinbase={coinbase}
                  isNewPool={true}
                  isPremium={isPremium}
                  clickedCawsPool={clickedCawsPool}
                  onCloseCard={() => {
                    onCloseCard();
                    setClickedCawsPool(false);
                  }}
                />
              </div>   
              <div onClick={() => openModal5("APR BOOST - BUNNY GOLD")}>
                <TopOtherPoolsNftCard
                  lockTime="No lock"
                  chain="Bunny"
                  apr={25}
                  tokenLogo={["gold.png"]}
                  expired={false}
                  top_pick={false}
                  tokenName="APR Boost"
                  tokenTicker="BUNNY"
                  chainId={chainId}
                  handleConnection={handleConnection}
                  handleSwitchNetwork={handleSwitchNetwork}
                  isConnected={isConnected}
                  coinbase={coinbase}
                  isNewPool={true}
                  isPremium={isPremium}
                  clickedCawsPool={clickedCawsPool}
                  onCloseCard={() => {
                    onCloseCard();
                    setClickedCawsPool(false);
                  }}
                />
              </div>   

 
              <div onClick={() => openModal4("Mrs Bunny - BUNNY")}>
                <TopOtherPoolsNftCard
                  lockTime="No lock"
                  chain="Bunny"
                  apr={25}
                  tokenLogo={["mrsbunny.png"]}
                  expired={false}
                  top_pick={false}
                  tokenName="Mrs Bunny"
                  tokenTicker="BUNNY"
                  chainId={chainId}
                  handleConnection={handleConnection}
                  handleSwitchNetwork={handleSwitchNetwork}
                  isConnected={isConnected}
                  coinbase={coinbase}
                  isNewPool={true}
                  isPremium={isPremium}
                  clickedCawsPool={clickedCawsPool}
                  onCloseCard={() => {
                    onCloseCard();
                    setClickedCawsPool(false);
                  }}
                />
              </div>
            </>
          )}
        </div>
      )}

      {modal1Visible && (
        <NftStaking
          open={modal1Visible}
          onClose={closeModal1}
          nftItem={selectedPool}
          coinbase={coinbase}
          isConnected={isConnected}
          // Pass any additional props needed for the modal
        />
      )}

      {modal2Visible && (
        <NftStaking2
          open={modal2Visible}
          onClose={closeModal2}
          nftItem={selectedPool}
          coinbase={coinbase}
          isConnected={isConnected}
          // Pass any additional props needed for the modal
        />
      )}
      {modal3Visible && (
        <NftStaking3
          open={modal3Visible}
          onClose={closeModal3}
          nftItem={selectedPool}
          coinbase={coinbase}
          isConnected={isConnected}
          // Pass any additional props needed for the modal
        />
      )}  
      
      {modal4Visible && (
        <NftStaking4
          open={modal4Visible}
          onClose={closeModal4}
          nftItem={selectedPool}
          coinbase={coinbase}
          isConnected={isConnected}
          // Pass any additional props needed for the modal
        />
      )}   
        {modal5Visible && (
        <NftStaking5
          open={modal5Visible}
          onClose={closeModal5}
          nftItem={selectedPool}
          coinbase={coinbase}
          isConnected={isConnected}
          // Pass any additional props needed for the modal
        />
      )}


{modal6Visible && (
        <NftStaking6
          open={modal6Visible}
          onClose={closeModal6}
          nftItem={selectedPool}
          coinbase={coinbase}
          isConnected={isConnected}
          // Pass any additional props needed for the modal
        />
      )}

{modal7Visible && (
        <NftStaking7
          open={modal7Visible}
          onClose={closeModal7}
          nftItem={selectedPool}
          coinbase={coinbase}
          isConnected={isConnected}
          // Pass any additional props needed for the modal
        />
      )}
{modal8Visible && (
        <NftStaking8
          open={modal8Visible}
          onClose={closeModal8}
          nftItem={selectedPool}
          coinbase={coinbase}
          isConnected={isConnected}
          // Pass any additional props needed for the modal
        />
      )}
    </>
  );
};

export default EarnOtherContentNft;